module viggo {

    interface UploadOptions {
        data: DOMStringMap;
        files: FileList;
        filetypes: string[];
        maxFilesize: number;
        target: Element;
        url: string;
        completeElement: HTMLElement | null;
    }
    type FileId = number;
    interface FileElement {
        file: File;
        element: HTMLElement;
    }

    interface HTMLUploader {
        element: HTMLDivElement;
        files: HTMLUListElement;
        graph: HTMLElement;
        current: HTMLElement | null;
    }

    interface Template {
        html: string; // template to insert
    }

    type TemplateCallback = (data?: any) => string;

    interface UploadTemplates {
        [index: string]: TemplateCallback | undefined;
    }

    interface IUploadItem {
        contentType: string;
        name: string;
        size: number;
        loaded: number;
        total: number;
        id: number | string;
        lastModified: Date;
        color(alpha: number): string;
        run(): Promise<XMLHttpRequest>;
        addEventListener(event: string, listener: (data: any) => void): void;
    }

    class FakeUploadItem extends viggo.classes.eventListener implements IUploadItem {
        public contentType: string;
        public name: string;
        public size: number;
        public loaded: number;
        public total: number;
        public id: number | string;
        public lastModified: Date;
        public constructor(name: string, contentType: string, size: number) {
            super();
            this.name = name;
            this.contentType = contentType;
            this.size = size;
            this.total = size;
            this.loaded = 0;
            this.id = 0;
            this.lastModified = new Date();
        }
        public color(alpha: number = 1.0) {
            return `rgba(19,128,164,${alpha})`;
        }
        public run(): Promise<XMLHttpRequest> {
            return new Promise<XMLHttpRequest>((resolve, reject) => {
                this.dispatchEvent('run', this);
                let interval = setInterval(() => {
                    this.loaded = Math.min(this.total, this.loaded + 1024 * 2);
                    this.dispatchEvent('progress', this);
                    if (this.loaded == this.total) {
                        clearInterval(interval);
                        resolve(new XMLHttpRequest());
                    }
                }, 20);
            });
        }
    }

    class UploadItem extends viggo.classes.eventListener implements IUploadItem {
        private file: File;
        private url: string;
        private data: DOMStringMap;
        private bytesLoaded: number;
        private bytesTotal: number;
        public id: number | string;
        public constructor(file: File, url: string, data: DOMStringMap) {
            super();
            this.id = 0;
            this.file = file;
            this.url = url;
            this.data = data;
            this.bytesLoaded = 0;
            this.bytesTotal = this.size;
        }
        public get contentType() {
            return this.file.type;
        }
        public get name() {
            return this.file.name;
        }
        public get size() {
            return this.file.size;
        }
        public get loaded() {
            return this.bytesLoaded;
        }
        public get total() {
            return this.bytesTotal;
        }
        public get lastModified() {
            return new Date(this.file.lastModified);
        }
        public color(alpha: number = 1.0) {
            return `rgba(19,128,164,${alpha})`;
            //return viggo.func.getColorFromValue(this.name, alpha);
        }
        public run() {
            return new Promise<XMLHttpRequest>((resolve, reject) => {
                this.dispatchEvent('run', this);

                const formData = new FormData();
                formData.append('file', this.file, this.name);
                if (this.data) {
                    for (let key in this.data) {
                        formData.append(key, this.data[key]!);
                    }
                }

                const xhr = viggo.xhr();

                xhr.open('POST', this.url, true);

                const warnNavigate = (event: BeforeUnloadEvent) => {
                    const e = __('You are about to cancel your current upload.\nDo you wish to continue?');
                    event.returnValue = e;
                    return e;
                };
                const uploadComplete = () => {
                    if (xhr.readyState == 4) {
                        xhr.removeEventListener('load', uploadComplete, false);
                        window.removeEventListener('beforeunload', warnNavigate, false);
                        if (xhr.status == 200) {
                            resolve(xhr);
                        } else {
                            reject(xhr);
                        }
                    }
                };
                xhr.addEventListener('load', uploadComplete, false);
                window.addEventListener('beforeunload', warnNavigate, false);
                xhr.upload.addEventListener('progress', (event) => {
                    this.bytesLoaded = event.loaded;
                    this.bytesTotal = event.total;
                    this.dispatchEvent('progress', this);
                }, false);
                xhr.upload.addEventListener('error', () => reject(xhr), false);
                xhr.upload.addEventListener('abort', () => reject(xhr), false);
                xhr.send(formData);
            });
        }
    }

    class Uploader extends viggo.classes.eventListener {
        protected items: IUploadItem[];
        protected files!: Element;
        protected itemMap: WeakMap<IUploadItem, Element>;
        protected completeResultElement: HTMLElement | null;
        protected templates: UploadTemplates;
        public constructor(files: Element|null, templates: UploadTemplates, completeResultElement: HTMLElement | null) {
            super();
            this.templates = templates;
            this.completeResultElement = completeResultElement;
            this.items = [];
            this.itemMap = new WeakMap();
            this.initialize(files!);
        }
        protected initialize(target: Element) {
            this.files = target;
        }
        public addItem(item: IUploadItem) {
            this.items.push(item);
            item.addEventListener('run', this.fileRun.bind(this));
            item.addEventListener('progress', this.fileProgress.bind(this));

            let li = this.createElement('pending', item);
            if (li) {
                this.itemMap.set(item, li);
                this.files.appendChild(li);
            }
        }
        protected fileRun(item: IUploadItem) {
            this.replaceLi(item, 'uploading');
        }
        protected fileProgress(item: IUploadItem) {
            let li = this.itemMap.get(item);
            if (li) {
                let progress = li.querySelector('progress');
                if (progress) {
                    progress.max = item.total;
                    progress.value = item.loaded;
                }
            }
        }
        public repaint() {}
        protected fileComplete(item: IUploadItem, xhr: XMLHttpRequest | null) {
            if (this.handleResult(item, xhr)) {
                this.replaceLi(item, 'complete');
            }
        }
        protected handleResult(item: IUploadItem, xhr: XMLHttpRequest|null) {
            if (xhr) {
                let result = viggo.ajax.parseResponse(<string>xhr.getResponseHeader('Content-Type'), xhr.responseText);
                switch (typeof result) {
                    case "number":
                        item.id = result;
                        break;
                    case "string":
                        if (result.match(/^[0-9a-zA-Z\-_]+$/)) {
                            item.id = result;
                        }
                        break;
                    case "object":
                        if (result instanceof DocumentFragment) {
                            if (this.completeResultElement) {
                                viggo.dom.empty(this.completeResultElement);
                                this.completeResultElement.appendChild(result);
                            } else {
                                result = result.firstElementChild;
                                this.replaceLi(item, result);
                                return false;
                            }
                        }
                        break;
                    case "undefined": // probably a script with no return value
                    default:
                        break;
                }
            }
            return true;
        }
        protected fileFailed(item: IUploadItem) {
            this.replaceLi(item, 'failed');
        }
        protected replaceLi(item: IUploadItem, template: string | Element | null) {
            let oldLi = this.itemMap.get(item);
            if (oldLi) {
                let newLi: Element | null = null;
                if (typeof template == "string") {
                    newLi = this.createElement(template, item);
                } else {
                    newLi = template;
                }
                if (newLi) {
                    this.itemMap.set(item, newLi);
                    oldLi.replaceWith(newLi);
                }
            }
        }
        protected createElement(templateName: string, data: any) {
            let callback = this.templates[templateName];
            if (callback) {
                let fragment = viggo.func.createView(data, callback);
                if (fragment) {
                    return <HTMLElement|null>fragment.firstElementChild;
                }
            }
            return null;
        }
        public async run() {
            for (let file of this.items) {
                try {
                    const xhr = await file.run();
                    this.fileComplete(file, xhr);
                } catch (xhr) {
                    this.handleFailedUpload(xhr);
                    this.fileFailed(file);
                }
            }
            this.finish();
        }

        private handleFailedUpload(xhr: XMLHttpRequest) {
            try {
                let contentType = xhr.getResponseHeader("Content-Type");
                viggo.ajax.textToData(contentType || "", xhr.responseText, document, 'javascript');
            } catch {
                // don't throw the error, because it will stop consecutive uploads
            }
        }

        protected finish() {
            this.dispatchEvent('finish');
        }

        public remove() {
            this.items = [];
        }
    }

    class SeparateUploader extends Uploader {
        protected graph: HTMLCanvasElement;
        protected chart: Chart;
        protected data: number[];
        protected colors: string[];
        protected backgrounds: string[];
        protected root!: HTMLElement;

        public constructor(templates: UploadTemplates, completeResultElement: HTMLElement | null) {
            super(null, templates, completeResultElement);

            this.graph = this.root.querySelector('canvas')!;
            let ctx = this.graph.getContext("2d")!;
            this.colors = [];
            this.data = [];
            this.backgrounds = [];
            this.chart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    datasets: [{
                        data: this.data,
                        backgroundColor: this.backgrounds,
                        borderColor: this.colors,
                        borderWidth: 1
                    }],
                }
            });
        }

        protected initialize() {
            this.root = this.createElement('separate-uploader', null)!;
            document.body.appendChild(this.root);
            super.initialize(this.root.querySelector('ul.attached-files')!);
        }

        protected fileRun(item: IUploadItem) {
            super.fileRun(item);
            const index = this.items.indexOf(item);
            if (index != -1) {
                const color = item.color(1.0);
                this.data.splice(index, 0, 0);
                this.colors.splice(index, 0, color);
                this.backgrounds.splice(index, 0, color);
                this.repaint();
            }
        }

        public addItem(item: IUploadItem) {
            super.addItem(item);
            this.data.push(item.total);
            this.colors.push(item.color(1.0));
            this.backgrounds.push(item.color(0.2));
        }

        public repaint() {
            super.repaint();
            this.chart.update({duration: 0});
        }

        protected fileProgress(item: IUploadItem) {
            super.fileProgress(item);
            const index = this.items.indexOf(item);
            if (index != -1) {
                this.data[index] = item.loaded;
                this.data[index + 1] = item.total - item.loaded;
                this.repaint();
            }
        }

        protected fileComplete(item: IUploadItem, xhr: XMLHttpRequest) {
            if (this.handleResult(item, xhr)) {
                this.replaceLi(item, 'complete-separate');
            }
            this.removeFinishPie(item);
        }

        protected fileFailed(item: IUploadItem) {
            super.fileFailed(item);
            this.removeFinishPie(item);
        }

        private removeFinishPie(item: IUploadItem) {
            const index = this.items.indexOf(item);
            if (index != -1) {
                this.data[index] = item.total;
                this.data.splice(index + 1, 1);
                this.backgrounds.splice(index + 1, 1);
                this.colors.splice(index + 1, 1);
            }
        }

        public remove() {
            super.remove();
            new viggo.effect({
                element: this.root,
                style: 'right',
                from: 0,
                to: -this.root.offsetWidth,
                type: 'elastic',
                duration: 500,
                complete: () => {
                    this.root.remove();
                }
            });
        }
    }

    export class upload extends viggo.classes.eventListener {
        public uploader!: Uploader;
        public static templates: UploadTemplates;
        public constructor(options: UploadOptions) {
            super();
            let skippedFiles: File[] = [];
            let goodFiles: File[] = [];
            for (let file of options.files) {
                if (options.filetypes.length) {
                    let extension = file.name.match(/\.[0-9a-zA-Z]+$/);
                    if (!extension || options.filetypes.indexOf(extension[0].toLowerCase()) == -1) {
                        skippedFiles.push(file);
                        continue;
                    }
                }
                if (options.maxFilesize > 0 && options.maxFilesize < file.size) {
                    skippedFiles.push(file);
                    continue;
                }
                goodFiles.push(file);
            }
            if (skippedFiles.length) {
                let s = "Følgende filer vil ikke blive uploadet på grund af filtype- eller størrelses restriktion.\n" + skippedFiles.map(x => x.name).join("\n");
                if (goodFiles.length) {
                    if (!confirm("Vil du uploade de resterende filer?\n" + s)) {
                        return;
                    }
                } else {
                    alert(s);
                    return;
                }
            }
            this.uploader = this.createUploader(options.target, options.completeElement);
            this.uploader.addEventListener('finish', () => {
                let event = new CustomEvent('viggoUploaderFinished', { bubbles: true, cancelable: false });
                options.target.dispatchEvent(event);
            });
            for (let file of goodFiles) {
                this.uploader.addItem(new UploadItem(file, options.url, options.data));
            }
            this.uploader.repaint();
        }
        public run(autoclose: boolean) {
            if (this.uploader) {
                if (autoclose) {
                    this.uploader.addEventListener('finish', () => {
                        this.uploader.remove();
                    });
                }
                return this.uploader.run();
            }
            return Promise.resolve();
        }
        protected createUploader(target: Element, completeElement: HTMLElement | null) {
            let parent = target.parentElement;
            let files: Element|null = null;
            if (parent) {
                files = parent.querySelector('ul.attached-files');
            }
            return files ? new Uploader(files, upload.templates, completeElement) : new SeparateUploader(upload.templates, completeElement);
        }

        public static getIcon = (function () {
            const mimetypes: ObjectOf<string[]|RegExp> = {
                audio: /^audio\//,
                docx: ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
                jpg: ["image/jpg", "image/jpeg", "image/gif", "image/bmp"],
                keynote: ["application/x-iwork-keynote-sffkey"],
                numbers: ["application/x-iwork-numbers-sffnumbers"],
                pages: ["application/x-iwork-pages-sffpages"],
                pdf: ["application/pdf"],
                png: ["image/png"],
                pptx: ["application/vnd.ms-powerpoint", "pptxapplication/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.presentationml.slideshow"],
                psd: ["application/psd"],
                txt: ["text/plain"],
                video: /^video\//,
                xlsx: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
                zip: ["application/x-zip-compressed"],
            };
            return function getIcon(contentType: string) {
                let found: string|null = null;
                for (let type in mimetypes) {
                    let row = mimetypes[type];
                    if (Array.isArray(row)) {
                        if (row.indexOf(contentType) != -1) {
                            found = type;
                        }
                    } else if (contentType.match(row)) {
                        found = type;
                    }
                    if (found) {
                        break;
                    }
                }
                if (!found) {
                    found = 'file';
                }
                return `/Content/img/file-types/${found}.svg`;
            }
        }())

        public static getData(element: HTMLElement) {
            let data: DOMStringMap = {};
            for (let name in element.dataset) {
                if (name.indexOf('drop') == 0) {
                    let param = name.replace(/^drop([A-Z])/, (all, char: string) => {
                        return char.toLowerCase();
                    });
                    data[param] = <string>element.dataset[name];
                }
            }
            return data;
        }

        public static test() {
            let u = new viggo.upload({
                data: {},
                files: <any>[],
                filetypes: [],
                maxFilesize: 0,
                completeElement: null,
                target: document.body,
                url: ""
            });
            u.uploader.addItem(new FakeUploadItem("stig.jpg", "image/jpeg", 241023));
            u.uploader.addItem(new FakeUploadItem("erik.docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", 181023));
            u.uploader.addItem(new FakeUploadItem("bjarne.mp3", "audio/mpeg", 541023));
            u.uploader.addItem(new FakeUploadItem("jesper.xslx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", 21023));
            u.uploader.addItem(new FakeUploadItem("mathias.mp4", "video/mp4", 1241023));
            u.uploader.addItem(new FakeUploadItem("martin.zip", "application/x-zip-compressed", 171023));
            u.uploader.addItem(new FakeUploadItem("nilan.pdf", "application/pdf", 71023));
            u.uploader.addItem(new FakeUploadItem("preben.psd", "application/psd", 1341023));
            u.uploader.repaint();
            u.run(true);
            return u;
        }
    }

    fetch('/Scripts/upload-templates.json').then(x => x.json()).then(json => {
        let templates: any = {};
        for (let name in json) {
            templates[name] = viggo.func.createTemplate(json[name].html);
        }
        viggo.upload.templates = templates;
    });

    document.addEventListener('change', (event: Event) => {
        let target = <HTMLInputElement>event.target;
        if (target.tagName == 'INPUT' && target.type == 'file') {
            let urlTarget = <HTMLElement|null>target.closest('[data-upload-url]');
            if (urlTarget && target.files) {
                let filetypes = target.accept.split(',').filter(x => x);
                let url = urlTarget.dataset.uploadUrl!;
                let urlResult = url.split('#');
                let resultElement: HTMLElement | null = null;
                if (urlResult.length > 1) {
                    resultElement = document.getElementById(urlResult[1]);
                }
                let uploader = new upload({
                    files: target.files,
                    filetypes: filetypes,
                    maxFilesize: 0,
                    target: target,
                    completeElement: resultElement,
                    data: viggo.upload.getData(target),
                    url: urlResult[0]
                });
                uploader.run(true);
            }
        }
    }, false);
}