module viggo {
    function zero(s: number | number): string {
        return s < 10 ? '0' + s : s+'';
    }
    interface ClockOptions {
        servertime: number;
        dateformat: string;
        timeformat: string;
        parent: HTMLElement;
    }
    export class clock {
        private dateNode: Node;
        private timeNode: Node;
        private servertime: number;
        private dateformat: string;
        private timeformat: string;
        private interval: number = 0;
        private animationFrame: number = 0;
        private static clientTime: number = Date.now();
        constructor(options: ClockOptions) {
            this.dateformat = options.dateformat;
            this.timeformat = options.timeformat;
            this.servertime = options.servertime;
            let parent = options.parent;
            let elm = parent.querySelector('.date');
            if (elm && elm.firstChild) {
                this.dateNode = elm.firstChild;
            } else {
                throw new Error('Missing element with class "date" in clock');
            }
            elm = parent.querySelector('.time');
            if (elm && elm.firstChild) {
                this.timeNode = elm.firstChild;
            } else {
                throw new Error('Missing element with class "time" in clock');
            }
            clearInterval(this.interval);
            this.refresh();
            let body = document.body;
            this.interval = window.setInterval(() => {
                cancelAnimationFrame(this.animationFrame);
                // airtame weirdness.
                if (body && body.contains(parent)) {
                    this.animationFrame = requestAnimationFrame(() => this.refresh());
                } else {
                    clearInterval(this.interval);
                }
            }, 1000);
        }
        public static setTime(date: number) {
            clock.clientTime = date;
        }
        private refresh() {
            let timedif = this.servertime - clock.clientTime;
            let now = new Date();
            now.setTime(now.getTime() + timedif);
            this.dateNode.nodeValue = now.format(this.dateformat);
            this.timeNode.nodeValue = now.format(this.timeformat);
        }
    }
}