module viggo {

    interface SearchOptions {
        input: HTMLInputElement,
        url: string,
        data: any,
        queryName: string,
        result: HTMLElement
    }

    interface SuperSearchAreas {
        [index: string]: string
    }

    interface SuperSearchTemplates {
        
    }

    interface SuperSearchOptions {
        input: HTMLInputElement;
        urls: SuperSearchAreas;
        result: HTMLElement;
        templates: SuperSearchAreas;
    }

    export class search {
        protected input: HTMLInputElement;
        protected url: string;
        protected data: any;
        protected queryName: string;
        protected result: HTMLElement;
        protected lastSearch: string = "";
        protected ajax: viggo.ajax|null = null;
        constructor(options: SearchOptions) {
            this.input = options.input;
            this.url = options.url;
            this.data = options.data;
            this.queryName = options.queryName;
            this.result = options.result;
            let timeout = 0;
            this.input.addEventListener('keyup', (event: KeyboardEvent) => {
                clearTimeout(timeout);
                if (this.ajax) {
                    this.ajax.abort();
                    this.ajax = null;
                }

                let value = this.input.value.replace(/^\s+|\s+$/g, '');
                if (value == '') {
                    if (value != this.lastSearch) {
                        this.clear();
                    }
                    this.lastSearch = '';
                } else {
                    timeout = window.setTimeout(() => this.search(value), 350);
                }
            }, true);
        }
        public clear(clearInput: boolean = true, clearResult: boolean = true) {
            if (clearResult) {
                viggo.dom.empty(this.result);
            }
            if (clearInput) {
                this.input.value = '';
            }
        }
        public search(query: string) {
            let data = this.data;
            if (typeof this.data == 'function') {
                data = data();
            } else {
                data = Object.assign({}, data);
            }
            data[this.queryName] = query;
            new viggo.ajax({
                method: 'get',
                url: this.url,
                data: this.data,
                convert: 'html',
                complete: (html: DocumentFragment) => {
                    viggo.dom.empty(this.result);
                    this.result.appendChild(this.result);
                }
            });
        }
    }

    export class supersearch {
        protected urls: SuperSearchAreas;
        protected input: HTMLInputElement;
        protected result: HTMLElement;
        protected ajax: { [index: string]: viggo.ajax } = {};
        protected templates: { [index: string]: Function };
        private selection: HTMLElement | null = null;
        private parentOffsetLeft = 0;
        private resultLimit = 100;
        private static ITEM_SELECTOR = 'div>ul>li';
        private static PARENT_SELECTOR = 'div>ul';
        constructor(options: SuperSearchOptions) {
            this.input = options.input;
            this.result = options.result;
            this.urls = options.urls;
            this.templates = {};
            for (let area in options.templates) {
                this.templates[area] = viggo.func.createTemplate(options.templates[area]);
            }
            let focus = () => {
                viggo.autocomplete.load();
                this.input.removeEventListener('focus', focus, false);
            };
            this.input.addEventListener('focus', focus, false);

            let lastQuery = '';
            let timeout = 0;
            this.input.addEventListener('keyup', (event: KeyboardEvent) => {
                let value = this.input.value.trim();
                if (value != lastQuery) {
                    lastQuery = value;
                    clearTimeout(timeout);
                    this.cancelAjax();
                    if (lastQuery) {
                        setTimeout(() => {
                            this.search(value);
                        }, 200);
                    } else {
                        this.clear();
                    }
                }
            }, false);
            this.input.addEventListener('keydown', (event: KeyboardEvent) => {
                switch (event.key) {
                    case "ArrowLeft":
                        if (this.selection) {
                            this.setSelection(this.getHorizontalSelection(false));
                            event.preventDefault();
                        }
                        break;
                    case "ArrowRight":
                        if (this.selection) {
                            this.setSelection(this.getHorizontalSelection(true));
                            event.preventDefault();
                        }
                        break;
                    case "ArrowUp":
                        if (!this.selection) {
                            let parent = this.result;
                            let list = this.getBestNewParents();
                            if (list && list.length) {
                                parent = list[list.length - 1];
                            }
                            let all = <NodeListOf<HTMLElement>>parent.querySelectorAll(supersearch.ITEM_SELECTOR);
                            this.setSelection(all[all.length - 1]);
                        } else {
                            this.setSelection(this.getVerticalSelection(-1));
                        }
                        event.preventDefault();
                        break;
                    case "ArrowDown":
                        if (!this.selection) {
                            let parent = this.result;
                            let list = this.getBestNewParents();
                            if (list && list.length) {
                                parent = list[0];
                            }
                            this.setSelection(parent.querySelector(supersearch.ITEM_SELECTOR));
                        } else {
                            this.setSelection(this.getVerticalSelection(1));
                        }
                        event.preventDefault();
                        break;
                    case "Escape":
                        this.clear();
                        break;
                    case "Enter":
                        if (this.selection) {
                            let a = this.selection.querySelector('a');
                            if (a) {
                                a.click();
                            }
                        }
                        break;
                }
            }, false);
        }
        private getBestNewParents() {
            let minLeft = 0;
            let list = Array.from(<NodeListOf<HTMLElement>>this.result.querySelectorAll(supersearch.PARENT_SELECTOR));
            if (this.parentOffsetLeft == 0) {
                this.parentOffsetLeft = list[0].offsetLeft;
            }
            return list.filter(x => Math.abs(this.parentOffsetLeft - x.offsetLeft) < 10);
        }
        private setSelection(element: HTMLElement | null) {
            if (this.selection) {
                this.selection.classList.remove('selected');
            }
            if (element) {
                let parent = <HTMLElement | null>element.closest(supersearch.PARENT_SELECTOR);
                if (parent) {
                    this.parentOffsetLeft = parent.offsetLeft;
                }
                element.classList.add('selected');
                let scroll = <HTMLElement|null>viggo.dom.parentFilter(element, (e: Element) => {
                    return viggo.getStyle(e, 'overflow-y') in { scroll: 1, auto: 1 };
                });
                if (scroll) {
                    let parentOffset = scroll.offsetTop;
                    let top = scroll.scrollTop;
                    top = Math.min(top, element.offsetTop - parentOffset);
                    top = Math.max(top, element.offsetTop - scroll.offsetHeight + element.offsetHeight - parentOffset);
                    scroll.scrollTop = top;
                }
            }
            this.selection = element;
        }
        private getVerticalSelection(offset: number) {
            let result: HTMLElement | null = null;
            if (this.selection) {
                let selectedParent = <HTMLElement>this.selection.closest(supersearch.PARENT_SELECTOR);
                let all = <NodeListOf<HTMLElement>>selectedParent.querySelectorAll(supersearch.ITEM_SELECTOR);
                let index = Array.prototype.indexOf.call(all, this.selection);
                if (index == -1) {
                    throw new Error("Selection not found in parent");
                }
                if (index + offset >= 0 && index + offset < all.length) {
                    result = all[index + offset];
                } else {
                    let allParents = <HTMLElement[]>Array.from(this.result.querySelectorAll(supersearch.PARENT_SELECTOR));
                    let left = selectedParent.offsetLeft;
                    let filtered = allParents.filter(parent => {
                        return parent != selectedParent && Math.abs(parent.offsetLeft - left) < 10;
                    });
                    let newParent: HTMLElement | null = filtered[0];
                    if (filtered.length > 1) {
                        let index = allParents.indexOf(selectedParent);
                        index = (index + allParents.length + offset) % allParents.length;
                        newParent = allParents[index];
                    }
                    if (newParent) {
                        if ((newParent.offsetTop - selectedParent.offsetTop) * offset > 0) {
                            let items = <NodeListOf<HTMLElement>>newParent.querySelectorAll(supersearch.ITEM_SELECTOR);
                            result = items[offset > 0 ? 0 : items.length - 1];
                        }
                    }

                }
            }
            return result;
        }
        private getHorizontalSelection(selectRight: boolean) {
            if (this.selection) {
                let parents = <HTMLElement[]>Array.from(this.result.querySelectorAll(supersearch.PARENT_SELECTOR));
                let selectedParent = <HTMLElement>this.selection.closest(supersearch.PARENT_SELECTOR);
                let left = selectedParent.offsetLeft;
                let top = selectedParent.offsetTop;
                parents = parents.filter((parent) => {
                    let offsetLeft = parent.offsetLeft;
                    return Math.abs(offsetLeft - left) > 10 && // accept elements not in the same column
                        Math.abs(parent.offsetTop - top) < 10 // accept elements with same top-index
                });
                let newParent = parents[0];
                if (parents.length > 1) {
                    let filtered = parents.filter(parent => {
                        return selectRight ? parent.offsetLeft > left : parent.offsetLeft < left;
                    });
                    if (filtered.length) {
                        newParent = filtered[selectRight ? 0 : filtered.length - 1];
                    } else {
                        newParent = parents[selectRight ? 0 : 1];
                    }
                }
                let newParentScroll = newParent.scrollTop;
                top = this.selection.offsetTop - selectedParent.scrollTop;
                let bestTop = Infinity;
                let result: HTMLElement|null = null;
                (<NodeListOf<HTMLElement>>newParent.querySelectorAll(supersearch.ITEM_SELECTOR)).forEach(element => {
                    let newTop = Math.abs((element.offsetTop - newParentScroll) - top);
                    if (newTop < bestTop) {
                        result = element;
                        bestTop = newTop;
                    }
                });
                return result;
            }
            return null;
        }
        public clear() {
            this.cancelAjax();
            this.selection = null;
            this.input.value = '';
            viggo.dom.empty(this.result);
            let page = document.getElementById('page');
            if (page) {
                page.classList.remove('autocomplete-searching');
            }
        }

        protected generateHtml() {
            let page = document.getElementById('page');
            if (page) {
                page.classList.add('autocomplete-searching');
            }

            let master = viggo.func.createView(this, this.templates['master']);
            let close = master.querySelector('.close');
            if (close) {
                close.addEventListener('click', () => this.clear(), false);
            }
            let areas = master.querySelector('.supersearch-areas');
            if (!areas) {
                throw new Error('Missing class="supersearch-areas" in master template');
            }

            areas.appendChild(viggo.func.createView(this, this.templates['area_users']));
            areas.appendChild(viggo.func.createView(this, this.templates['area_groups']));

            for (let area in this.urls) {
                let template = this.templates['area_' + area];
                if (!template) {
                    template = this.templates['area'];
                }
                let fragment = viggo.func.createView(this, template);
                areas.appendChild(fragment);
            }

            return master;
        }

        private cancelAjax() {
            for (var type in this.ajax) {
                this.ajax[type].abort();
                delete this.ajax[type];
            }
        }

        private prepareSearch(type: string, q: string) {
            let e = document.getElementById('supersearch-' + type);
            if (e) {
                viggo.dom.empty(e);
                let map: ObjectOfString = {
                    '<': '&lt;',
                    '>': '&gt;',
                    '&': '&amp;',
                    '"': '&quot;',
                    "'": '&apos;'
                };
                let view = viggo.func.createView({ query: q.replace(/[<>&"']/g, (char)=>map[char]||''), type: type }, this.templates['searchlink']);
                let a = view.querySelector('a');
                e.appendChild(view);
                if (a) {
                    a.addEventListener('click', (event: MouseEvent) => {
                        let setSelection = event.clientX == 0 && event.clientY == 0;
                        event.preventDefault();
                        event.stopPropagation();
                        if (a) {
                            a.classList.add('loading-spinner');
                        }
                        this.ajax[type] = new viggo.ajax({
                            method: 'get',
                            url: this.urls[type],
                            data: { q: q },
                            convert: 'html',
                            progress: false,
                            complete: (html: DocumentFragment) => {
                                delete this.ajax[type];
                                let elm = document.getElementById('supersearch-' + type);
                                if (elm) {
                                    setSelection = setSelection && !!this.selection && elm.contains(this.selection);
                                    viggo.dom.empty(elm);
                                    elm.appendChild(html);
                                    if (setSelection) {
                                        this.setSelection(elm.querySelector(supersearch.ITEM_SELECTOR));
                                    }
                                }
                            }
                        });
                    }, false);
                }
            }
        }

        public search(query: string) {
            let html = this.generateHtml();
            viggo.dom.empty(this.result);
            this.result.appendChild(html);

            for (var i in this.urls) {
                this.prepareSearch(i, query);
            }

            let userList: AutoCompleteUser[] = [],
                groupList: AutoCompleteGroup[] = [];
            let users = viggo.dom.fragment(),
                groups = viggo.dom.fragment();
            let resUsers = <HTMLElement>document.getElementById('supersearch-users');
            let resGroups = <HTMLElement>document.getElementById('supersearch-groups');
            if (!resUsers || !resGroups) {
                throw new Error('Missing supersearch-users or supersearch-groups');
            }

            let ready = viggo.autocomplete.isReady();

            let setData = () => {
                this.selection = null;
                viggo.dom.empty(resUsers);
                viggo.dom.empty(resGroups);

                if (!userList.length) {
                    users.appendChild(viggo.dom.tag('li', { className: 'nohover empty' + (ready ? '' : 'ajaxLoading') }, ready ? 'Ingen resultater' : null));
                }
                if (!groupList.length) {
                    groups.appendChild(viggo.dom.tag('li', { className: 'nohover empty' + (ready ? '' : 'ajaxLoading') }, ready ? 'Ingen resultater' : null));
                }

                resUsers.appendChild(users);
                resGroups.appendChild(groups);
            };

            new Promise(async (resolve) => {
                let list = viggo.autocomplete.queryToLists(query);

                await viggo.autocomplete.iterate((element: AutoCompleteItem) => {
                    if (viggo.autocomplete.searchElement(list, element)) {
                        element.score = viggo.autocomplete.scoreElement(list, element);
                        if (element.type == 'user') {
                            userList.push(<AutoCompleteUser>element);
                        } else if (element.type == 'group') {
                            groupList.push(<AutoCompleteGroup>element);
                        }
                    }
                });


                userList = userList.sort(viggo.autocomplete.sortCallback).slice(0, this.resultLimit);
                groupList = groupList.sort(viggo.autocomplete.sortCallback).slice(0, this.resultLimit);

                viggo.dom.empty(users);
                viggo.dom.empty(groups);

                for (let i = 0; i < userList.length; i++) {
                    users.appendChild(viggo.autocomplete.createView(userList[i], 'SuperSearchUser')!);
                }
                for (let i = 0; i < groupList.length; i++) {
                    groups.appendChild(viggo.autocomplete.createView(groupList[i], 'SuperSearchGroup')!);
                }
                setData();
                resolve(null);
            });
        }
    }
}