module viggo {
    interface WebAuthnOptions {
        username: string;
    }
    enum WebAuthnStatus {
        ok = "ok",
        error = "error"
    }
    interface WebAuthnResponse {
        status: WebAuthnStatus;
        errorMessage?: string;
    }
    interface KeyPairSalt extends CryptoKeyPair {
        salt: Uint8Array;
    }
    interface CredentialCreator {
        create(options: CredentialCreationOptions): Promise<Credential|null>;
        get(options?: CredentialRequestOptions): Promise<Credential|null>;
    }
    interface CorceCoder {
        b64enc(buf: any): any;
        coerceToBase64Url(thing: any): any;
        coerceToArrayBuffer(thing: string): any;
        b64RawEnc(buf: any): any;
    }

    const LOCAL_STORAGE_KEY = 'viggo.webauthn';
    const LOCAL_STORAGE_USERNAME = LOCAL_STORAGE_KEY + '.username';
    const VERSION = 2;

    class LocalKeyPair {
        private password: string;
        private keyPair: KeyPairSalt | null;
        private credentialId: Uint8Array | null;
        private counter: number;

        public constructor(password: string) {
            this.password = password;
            this.keyPair = null;
            this.credentialId = null;
            this.counter = 0;
        }

        public get signCounter() {
            return this.counter;
        }

        public set signCounter(value: number) {
            let item = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (item) {
                let json = JSON.parse(item);
                if (json) {
                    json.signCounter = value;
                    this.counter = value;
                    item = JSON.stringify(json);
                    localStorage.setItem(LOCAL_STORAGE_KEY, item);
                }
            }
        }

        public static async isSupported() {
            let test = new LocalKeyPair('test');
            let salt = new Uint8Array(12);
            let result = false;
            try {
                let wrappingKey = await test.getWrappingKey(salt);
                const keyPair = await window.crypto.subtle.generateKey(
                    {
                        name: 'ECDSA',
                        namedCurve: 'P-256'
                    },
                    true,
                    ['sign', 'verify']
                );
                let ivPrivate = window.crypto.getRandomValues(new Uint8Array(12));
                const wrappedPrivateKeyBuffer = await window.crypto.subtle.wrapKey(
                    'jwk',
                    keyPair.privateKey,
                    wrappingKey,
                    <any>{
                        name: 'AES-GCM',
                        iv: ivPrivate
                    }
                );


                await window.crypto.subtle.unwrapKey(
                    'jwk',
                    wrappedPrivateKeyBuffer,
                    wrappingKey,
                    <any>{
                        name: 'AES-GCM',
                        iv: ivPrivate
                    },
                    <any>{
                        name: 'ECDSA',
                        namedCurve: 'P-256'
                    },
                    true,
                    ['sign']
                );
                result = true;
            } catch (e) {
                //console.log(e);
            }
            return result;
        }

        public async getPublicKey(format: string) {
            if (!this.keyPair) {
                throw new Error("Must load or create before using rawId");
            }
            if (!this.keyPair.publicKey) {
                throw new Error("Cannot get public key");
            }
            return await window.crypto.subtle.exportKey(format, this.keyPair.publicKey);
        }

        public get privateKey() {
            if (!this.keyPair) {
                throw new Error("Must load or create before using privateKey");
            }
            return this.keyPair.privateKey;
        }

        public get publicKey() {
            if (!this.keyPair) {
                throw new Error("Must load or create before using publicKey");
            }
            if (!this.keyPair.publicKey) {
                throw new Error("Cannot get public key");
            }
            return this.keyPair.publicKey;
        }

        public get salt() {
            if (!this.keyPair) {
                throw new Error("Must load or create before using publicKey");
            }
            return this.keyPair.salt;
        }

        public getCredentialId() {
            return this.credentialId;
        }

        public async create() {
            const keyPair = await window.crypto.subtle.generateKey(
                {
                    name: 'ECDSA',
                    namedCurve: 'P-256'
                },
                true,
                ['sign', 'verify']
            );

            this.keyPair = {
                privateKey: keyPair.privateKey,
                publicKey: keyPair.publicKey,
                salt: window.crypto.getRandomValues(new Uint8Array(16))
            };
            this.credentialId = window.crypto.getRandomValues(new Uint8Array(16));
            this.counter = 0;
            await this.save();
        }
        private async getWrappingKey(salt: Uint8Array) {
            const encoder = new TextEncoder();
            const keyMaterial = await window.crypto.subtle.importKey(
                'raw',
                encoder.encode(this.password),
                <any>{
                    name: 'PBKDF2'
                },
                false,
                ['deriveBits', 'deriveKey']
            );
            const wrappingKey = await window.crypto.subtle.deriveKey(
                {
                    name: 'PBKDF2',
                    salt: salt.buffer,
                    iterations: 100000,
                    hash: 'SHA-256'
                },
                keyMaterial,
                {
                    name: 'AES-GCM',
                    length: 256
                },
                true,
                ['wrapKey', 'unwrapKey']
            );
            return wrappingKey;
        }
        private async save() {
            if (!this.keyPair) {
                throw new Error("Create keys before saving");
            }

            let wrappingKey = await this.getWrappingKey(this.keyPair.salt);

            let ivPrivate = window.crypto.getRandomValues(new Uint8Array(12));
            const privateWrap = await window.crypto.subtle.wrapKey(
                'jwk',
                this.keyPair.privateKey,
                wrappingKey,
                <any>{
                    name: 'AES-GCM',
                    iv: ivPrivate
                }
            );

            let data = {
                privateKey: Array.from(new Uint8Array(privateWrap)),
                salt: Array.from(this.keyPair.salt),
                credentialId: Array.from(this.credentialId!),
                ivPrivate: Array.from(ivPrivate)
            };
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                version: VERSION,
                signCounter: this.counter,
                data: btoa(String.fromCharCode(...new Uint8Array(CBOR.encode(data))))
            }));
        }
        public async load() {
            let storage = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (storage) {
                let json = JSON.parse(storage);
                if (json.version != VERSION || !json.data) {
                    localStorage.removeItem(LOCAL_STORAGE_KEY);
                    return false;
                }
                this.counter = json.signCounter;
                storage = <string>json.data;
                json = CBOR.decode(Uint8Array.from(atob(storage), x => x.charCodeAt(0)).buffer);
                const wrappedPrivateKeyBuffer = new Uint8Array(json.privateKey);
                const ivPrivate = new Uint8Array(json.ivPrivate);
                const salt = new Uint8Array(json.salt);

                const wrappingKey = await this.getWrappingKey(salt);

                try {
                    const unwrappedPrivateKey = await window.crypto.subtle.unwrapKey(
                        'jwk',
                        wrappedPrivateKeyBuffer,
                        wrappingKey,
                        <any>{
                            name: 'AES-GCM',
                            iv: ivPrivate
                        },
                        <any>{
                            name: 'ECDSA',
                            namedCurve: 'P-256'
                        },
                        true,
                        ['sign']
                    );

                    this.keyPair = <any>{
                        salt: salt,
                        privateKey: unwrappedPrivateKey,
                        publicKey: null
                    };
                    this.credentialId = new Uint8Array(json.credentialId);
                } catch (e) {
                    throw new Error('Unable to unwrap key');
                }
            }
            return !!storage;
        }
    }

    class FakeCredentials implements CredentialCreator {
        private keyPair: LocalKeyPair;
        private coder: CorceCoder;
        private username: string;
        public constructor(password: string, coder: CorceCoder, username: string) {
            this.keyPair = new LocalKeyPair(password);
            this.coder = coder;
            this.username = username;
        }
        public static async isSupported() {
            return await LocalKeyPair.isSupported();
        }
        public async get(options: CredentialRequestOptions): Promise<Credential> {
            let makeCredentialOptions = options.publicKey!;

            await this.keyPair.load();

            let encoder = new TextEncoder();

            let json = {
                challenge: this.coder.coerceToBase64Url(makeCredentialOptions.challenge),
                clientExtensions: {
                    appid: this.origin
                },
                hashAlgorithm: "SHA-256",
                origin: this.origin,
                type: "webauthn.get"
            };
            let clientDataJSON = encoder.encode(JSON.stringify(json));
            let authData = await this.getAuthData(false);
            let hash = await window.crypto.subtle.digest('SHA-256', clientDataJSON);
            let dataToSign = new Uint8Array(authData.byteLength + hash.byteLength);
            dataToSign.set(authData, 0);
            dataToSign.set(new Uint8Array(hash), authData.byteLength);

            let signature = new Uint8Array(await window.crypto.subtle.sign(
                {
                    name: 'ECDSA',
                    hash: 'SHA-256'
                },
                this.keyPair.privateKey,
                dataToSign
            ));

            let derSignature = new Uint8Array(signature.byteLength + 6); // signature is length 64
            derSignature.set([0x30, signature.byteLength], 0); // SEQUENCE byteLength
            derSignature.set([0x02, 32], 2); // INTEGER 32 bytes
            derSignature.set(signature.slice(0, 32), 4);
            derSignature.set([0x02, 32], 36);
            derSignature.set(signature.slice(32), 38);

            this.keyPair.signCounter += 1;

            return await this.getCredentials({
                clientDataJSON: clientDataJSON.buffer,
                authenticatorData: authData,
                signature: derSignature,
                userHandle: null
            });
        }
        private async getId() {
            let encoder = new TextEncoder();
            let user = encoder.encode(this.username);
            let salt = this.keyPair.salt;
            let data = new Uint8Array(user.byteLength + salt.byteLength);
            data.set(user, 0);
            data.set(salt, user.byteLength);
            return await window.crypto.subtle.digest('SHA-512', data);
        }
        private async getCredentials(response: AuthenticatorAttestationResponse | AuthenticatorAssertionResponse): Promise<PublicKeyCredential> {
            let rawId = await this.getId();
            return <PublicKeyCredential>{
                rawId: <ArrayBuffer>rawId,
                id: this.coder.coerceToBase64Url(rawId),
                getClientExtensionResults: function () {
                    return new Uint8Array().buffer;
                },
                isUserVerifyingPlatformAuthenticatorAvailable: function () {
                    return Promise.resolve(true);
                },
                response: response,
                type: 'public-key'
            };
        }
        // origin includes the port
        private get origin() {
            return window.location.protocol + '//' + window.location.host;
        }
        private async getAuthData(withPublicKey: boolean) {
            const encoder = new TextEncoder();
            const digest = await window.crypto.subtle.digest('SHA-256', encoder.encode(window.location.hostname)); // challenge doesn't include port in host
            const flags = withPublicKey ? 0b01000001 : 0b00000001;
            const signCounter = this.keyPair.signCounter + 1; // uint
            const aaguid = new Uint8Array(16);
            const credentialId = await this.getId();
            const credentialIdLength = credentialId.byteLength; // ushort, big endian
            let cborCredentialPublicKey = new Uint8Array(0).buffer;
            if (withPublicKey) {
                let jwk = <JsonWebKey>await this.keyPair.getPublicKey('jwk');
                const credentialPublicKey = new Map<number, any>();
                credentialPublicKey.set(1, 2); // The 1 field describes the key type. The value of 2 indicates that the key type is in the Elliptic Curve format.
                credentialPublicKey.set(3, -7); // The 3 field describes the algorithm used to generate authentication signatures. The -7 value indicates this authenticator will be using ES256.
                credentialPublicKey.set(-1, 1); // The -1 field describes this key's "curve type". The value 1 indicates the that this key uses the "P-256" curve.
                credentialPublicKey.set(-2, new Uint8Array(this.coder.coerceToArrayBuffer(jwk.x!))); // The -2 field describes the x-coordinate of this public key.
                credentialPublicKey.set(-3, new Uint8Array(this.coder.coerceToArrayBuffer(jwk.y!))); // The -3 field describes the y-coordinate of this public key.

                cborCredentialPublicKey = CBOR.encode(credentialPublicKey);
            }

            let authDataLength = digest.byteLength // origin hash
                + 1 // flags
                + 4; // sign counter

            if (withPublicKey) {
                // attested credential data
                authDataLength += aaguid.byteLength // aaguid
                    + 2 // credential id length
                    + credentialId.byteLength // credential id
                    + cborCredentialPublicKey.byteLength; // credential public key
            }

            let authData = new Uint8Array(authDataLength);

            let offset = 0;
            authData.set(new Uint8Array(digest), offset); // origin hash
            offset += digest.byteLength;
            authData.set(new Uint8Array([flags]), offset); // flags
            offset += 1;
            authData.set(new Uint8Array(new Uint32Array([signCounter]).buffer).reverse(), offset); // sign counter
            if (withPublicKey) {
                offset += 4;
                authData.set(aaguid, offset); // aaguid
                offset += aaguid.byteLength;
                let credentialLengthBuffer = new Uint16Array([credentialIdLength]); // setup credential length as a 16 bit unsigned number
                authData.set(new Uint8Array(credentialLengthBuffer.buffer).reverse(), offset); // credential id length written as big endian
                offset += 2;
                authData.set(new Uint8Array(credentialId), offset); // credential id
                offset += credentialId.byteLength;
                authData.set(new Uint8Array(cborCredentialPublicKey), offset);
            }

            return authData;
        }
        public async create(options: CredentialCreationOptions): Promise<Credential> {
            await this.keyPair.create();

            let makeCredentialOptions = options.publicKey!;

            let json = {
                challenge: this.coder.coerceToBase64Url(makeCredentialOptions.challenge),
                clientExtensions: {},
                hashAlgorithm: "SHA-256",
                origin: this.origin,
                type: "webauthn.create"
            };

            const encoder = new TextEncoder();
            let attestationObject = {
                authData: await this.getAuthData(true),
                attStmt: {},
                fmt: 'none'
            };

            this.keyPair.signCounter += 1;
            return await this.getCredentials(<any>{
                clientDataJSON: encoder.encode(JSON.stringify(json)).buffer,
                attestationObject: CBOR.encode(attestationObject),
                getTransports: function () {
                    return [];
                }
            });
        }
    }

    export class webauthn implements CorceCoder {
        private username: string;
        constructor(options: WebAuthnOptions) {
            this.username = options.username;
        }
        public static get isSupported() {
            return navigator.credentials && window.PublicKeyCredential && typeof (PublicKeyCredential) != 'undefined';
        }
        public static async isPinSupported() {
            return await FakeCredentials.isSupported();
        }
        public static get hasLocalPin() {
            let item = localStorage.getItem(LOCAL_STORAGE_KEY);
            if (item) {
                try {
                    let data = JSON.parse(item);
                    return data && data.version == VERSION;
                } catch (e) { }
            }
            return false;
        }
        public static removeLocalPin() {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
        public static getSavedUsername() {
            return localStorage.getItem(LOCAL_STORAGE_USERNAME);
        }
        public async createPin(password: string, device: string) {
            let cred = new FakeCredentials(password, this, this.username);
            return await this.doCreate(cred, device);
        }

        private async doCreate(authenticator: CredentialCreator, device: string) {
            let result: WebAuthnResponse = {
                status: WebAuthnStatus.error
            };

            let username = this.username;
            let displayName = this.username;

            // possible values: none, direct, indirect
            let attestation_type = "none";
            // possible values: <empty>, platform, cross-platform
            let authenticator_attachment = "";

            // possible values: preferred, required, discouraged
            let user_verification = "preferred";

            // possible values: true,false
            let require_resident_key = false;

            let makeCredentialOptions: PublicKeyCredentialCreationOptions;
            try {
                makeCredentialOptions = await this.createPublicKeyCredentialCreationOptions(username, displayName, attestation_type, authenticator_attachment, user_verification, require_resident_key);
            } catch (e) {
                result.errorMessage = e.message;
                return result;
            }

            // console.log("Credential Options Formatted", makeCredentialOptions);

            // console.log("Creating PublicKeyCredential...");
            let newCredential: PublicKeyCredential | null = null;
            try {
                newCredential = <PublicKeyCredential|null>await authenticator.create({
                    publicKey: makeCredentialOptions
                });
            } catch (e) {
                result.errorMessage = "Could not create credentials in browser. Probably because the username is already registered with your authenticator. Please change username or authenticator. " + e.message
                return result;
            }

            //console.log("PublicKeyCredential Created", newCredential);
            //console.log(String.fromCharCode(...new Uint8Array(newCredential.response.clientDataJSON)));
            //console.log(String.fromCharCode(...new Uint8Array(newCredential.getClientExtensionResults())));
            //console.log(CBOR.decode(((<any>newCredential.response).attestationObject)));

            if (newCredential) {
                try {
                    await this.registerNewCredential(newCredential, device);
                } catch (err) {
                    result.errorMessage = err.message;
                    return result;
                }
            }
            localStorage.setItem(LOCAL_STORAGE_USERNAME, username);
            result.status = WebAuthnStatus.ok;
            return result;
        }
        private async doGet(authenticator: CredentialCreator): Promise<WebAuthnResponse> {
            let result: WebAuthnResponse = {
                status: WebAuthnStatus.error
            }

            // ask browser for credentials (browser will ask connected authenticators)
            let credential: PublicKeyCredential | undefined;
            try {
                let makeAssertionOptions = await this.makeAssertionOptions();
                credential = <PublicKeyCredential>await authenticator.get({ publicKey: makeAssertionOptions });
                //console.log(credential);
                let response = <AuthenticatorAssertionResponse>credential.response;
                //console.log(String.fromCharCode(...new Uint8Array(response.clientDataJSON)));
                //console.log(String.fromCharCode(...new Uint8Array(response.authenticatorData)));
                //console.log(String.fromCharCode(...new Uint8Array(response.signature)));
                //console.log(CBOR.decode(response.authenticatorData));
            } catch (err) {
                let msg = err.message;
                if (msg) {
                    msg = msg.replace(/\..*/, '.');
                    msg = __(msg);
                }
                result.errorMessage = msg;
                return result;
            }

            if (credential) {
                try {
                    await this.verifyAssertionWithServer(credential);
                } catch (e) {
                    result.errorMessage = "Could not verify assertion: " + e.message;
                    return result;
                }
            }
            result.status = WebAuthnStatus.ok;
            return result;
        }

        public async getPin(password: string) {
            let cred = new FakeCredentials(password, this, this.username);
            return await this.doGet(cred);
        }

        private async makeAssertionOptions() {
            // prepare form post data
            let formData = new FormData();
            formData.append('username', this.username);

            // send to server for registering
            let makeAssertionOptions;
            let res = await fetch('/Shared/WebAuthn/AssertionOptionsPost', {
                method: 'POST', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {
                    'Accept': 'application/json'
                }
            });

            makeAssertionOptions = await res.json();

            // console.log("Assertion Options Object", makeAssertionOptions);

            // show options error to user
            if (makeAssertionOptions.status !== "ok") {
                throw new Error(makeAssertionOptions.errorMessage);
            }

            // todo: switch this to coercebase64
            const challenge = makeAssertionOptions.challenge.replace(/-/g, "+").replace(/_/g, "/");
            makeAssertionOptions.challenge = Uint8Array.from(atob(challenge), c => c.charCodeAt(0));

            // fix escaping. Change this to coerce
            makeAssertionOptions.allowCredentials.forEach(function (listItem: any) {
                var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
                listItem.id = Uint8Array.from(atob(fixedId), c => c.charCodeAt(0));
            });

            // console.log("Assertion options", makeAssertionOptions);
            return makeAssertionOptions;
        }
        public async get(): Promise<WebAuthnResponse> {
            return await this.doGet(navigator.credentials);
        }
        private async fetchMakeCredentialOptions(formData: FormData) {
            let response = await fetch('/Shared/WebAuthn/MakeCredentialOptions', {
                method: 'POST', // or 'PUT'
                body: formData, // data can be `string` or {object}!
                headers: {
                    'Accept': 'application/json'
                }
            });

            let data = await response.json();

            return data;
        }
        // This should be used to verify the auth data with the server
        private async registerCredentialWithServer(formData: FormData) {
            let response = await fetch('/Shared/WebAuthn/MakeCredential', {
                method: 'POST', // or 'PUT'
                body: JSON.stringify(formData), // data can be `string` or {object}!
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });

            let data = await response.json();

            return data;
        }
        private async registerNewCredential(newCredential: PublicKeyCredential, device: string) {
            // Move data into Arrays incase it is super long
            let attestationObject = new Uint8Array((<AuthenticatorAttestationResponse>newCredential.response).attestationObject);
            let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
            let rawId = new Uint8Array(newCredential.rawId);

            const data = {
                id: newCredential.id,
                rawId: this.coerceToBase64Url(rawId),
                type: newCredential.type,
                extensions: newCredential.getClientExtensionResults(),
                response: {
                    attestationObject: this.coerceToBase64Url(attestationObject),
                    clientDataJson: this.coerceToBase64Url(clientDataJSON)
                },
                device: device
            };

            let response;
            try {
                response = await this.registerCredentialWithServer(<any>data);
            } catch (e) {
                // console.error(e);
                throw e;
            }

            // console.log("Credential Object", response);

            // show error
            if (response.status !== "ok") {
                throw new Error("Error creating credential: " + response.errorMessage);
            }

            //console.log('Registration Successful!');

            // redirect to dashboard?
            //window.location.href = "/dashboard/" + state.user.displayName;
        }
        public async create(device: string): Promise<WebAuthnResponse> {
            return await this.doCreate(navigator.credentials, device);
        }
        private async createPublicKeyCredentialCreationOptions(username: string, displayName: string, attestation_type: string, authenticator_attachment: string, user_verification: string, require_resident_key: boolean) {
            // prepare form post data
            let data = new FormData();
            data.append('username', username);
            data.append('displayName', displayName);
            data.append('attType', attestation_type);
            data.append('authType', authenticator_attachment);
            data.append('userVerification', user_verification);
            data.append('requireResidentKey', require_resident_key.toString());

            // send to server for registering
            let makeCredentialOptions: PublicKeyCredentialCreationOptions;
            try {
                makeCredentialOptions = await this.fetchMakeCredentialOptions(data);
            } catch (e) {
                throw e;
            }

            // console.log("Credential Options Object", makeCredentialOptions);

            if ((<any>makeCredentialOptions).status !== "ok") {
                throw new Error("Error creating credential options: " + (<any>makeCredentialOptions).errorMessage);
            }

            // Turn the challenge back into the accepted format of padded base64
            makeCredentialOptions.challenge = this.coerceToArrayBuffer(<any>makeCredentialOptions.challenge);
            // Turn ID into a UInt8Array Buffer for some reason
            makeCredentialOptions.user.id = this.coerceToArrayBuffer(<any>makeCredentialOptions.user.id);

            if (makeCredentialOptions.excludeCredentials) {
                makeCredentialOptions.excludeCredentials = makeCredentialOptions.excludeCredentials.map((c: any) => {
                    c.id = this.coerceToArrayBuffer(c.id);
                    return c;
                });
            }

            if (makeCredentialOptions.authenticatorSelection && makeCredentialOptions.authenticatorSelection.authenticatorAttachment === null) {
                makeCredentialOptions.authenticatorSelection.authenticatorAttachment = undefined;
            }
            return makeCredentialOptions;
        }
        private async verifyAssertionWithServer(assertedCredential: PublicKeyCredential) {
            // Move data into Arrays incase it is super long
            let authData = new Uint8Array((<AuthenticatorAssertionResponse>assertedCredential.response).authenticatorData);
            let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
            let rawId = new Uint8Array(assertedCredential.rawId);
            let sig = new Uint8Array((<AuthenticatorAssertionResponse>assertedCredential.response).signature);
            const data = {
                id: assertedCredential.id,
                rawId: this.coerceToBase64Url(rawId),
                type: assertedCredential.type,
                extensions: assertedCredential.getClientExtensionResults(),
                response: {
                    authenticatorData: this.coerceToBase64Url(authData),
                    clientDataJson: this.coerceToBase64Url(clientDataJSON),
                    signature: this.coerceToBase64Url(sig)
                }
            };

            let response;
            try {
                let res = await fetch("/Shared/WebAuthn/MakeAssertion", {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify(data), // data can be `string` or {object}!
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

                response = await res.json();
            } catch (e) {
                // console.error("Request to server failed", e);
                throw e;
            }

            // console.log("Assertion Object", response);

            // show error
            if (response.status !== "ok") {
                throw new Error("Error doing assertion: " + response.errorMessage);
            }
        }
        public b64enc(buf: any) {
            return this.coerceToBase64Url(buf);
        }
        public coerceToBase64Url(thing: any) {
            // Array or ArrayBuffer to Uint8Array
            if (Array.isArray(thing)) {
                thing = Uint8Array.from(thing);
            }

            if (thing instanceof ArrayBuffer) {
                thing = new Uint8Array(thing);
            }

            // Uint8Array to base64
            if (thing instanceof Uint8Array) {
                thing = String.fromCharCode(...thing);
                thing = window.btoa(thing);
            }

            if (typeof thing != 'string') {
                throw new Error("could not coerce '" + name + "' to string");
            }

            // base64 to base64url
            // NOTE: "=" at the end of challenge is optional, strip it off here
            thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

            return thing;
        };
        public coerceToArrayBuffer(thing: string) {
            // base64url to base64
            thing = thing.replace(/-/g, "+").replace(/_/g, "/");

            // base64 to Uint8Array
            var str = window.atob(thing);
            var bytes = new Uint8Array(str.length);
            for (var i = 0; i < str.length; i++) {
                bytes[i] = str.charCodeAt(i);
            }
            return new Uint8Array(bytes).buffer;
        };
        // Don't drop any blanks
        public b64RawEnc(buf: any) {
            return this.b64enc(buf);
        }
    }
}