module viggo.history {
    interface LoadRequest {
        url: string,
        timestamp: number
    }

    enum LoadAppendType {
        replace = 'replace', // default
        append = 'append',
        prepend = 'prepend',
        replaceOwner = 'replaceOwner'
    }

    const originalTitle = document.title;
    let scriptVersion: string|null = null;
    let styleVersion: string | null = null;
    let reloadNext = false;

    interface LoadOptions {
        multisender?: boolean; // default: false
        clearSelector?: string; // css query
        pushState?: boolean; // default: false
        appendType?: LoadAppendType; // default: 'replace'
        noScroll?: boolean|string; // default: false
        loader?: LoadCheckInitiator;
        complete?: (this: Element, target: Element) => void; // both this and target are the same
    }

    let lastRequest: LoadRequest|null;
    let clickTimeout: number;

    // will remove ajax=[0-9]+ from url string
    function removeAjax(url: string) {
        let m = url.match(/[?&](ajax=\d+)(?:&|$)/);
        if (m) {
            let query = url.split('?');
            let params = query[1].split('&');
            params.splice(params.indexOf(m[1]), 1);
            url = query[0] + (params.length ? '?' + params.join('&') : '');
        }
        return url;
    };

    function internalLink(url: string): boolean {
        return !!url.match(/^(?:\/|\?)/);
    };

    export function reload() {
        load(window.location.href);
    }

    export function removeLastRequest() {
        lastRequest = null;
        clearTimeout(clickTimeout);
    }

    export function getBestTitle(scope: HTMLElement | DocumentFragment | Document = document) {
        let title: string[] = [];
        let result = <HTMLElement | null>document.querySelector('#nav nav ul li.selected > a');
        if (result) {
            title.push(result.innerText.trim());
        }
        result = Array.from(<NodeListOf<HTMLElement>>scope.querySelectorAll('h1,h2,h3,h4')).reduce((previous: HTMLElement|null, current: HTMLElement) => {
            if (!previous || current.tagName < previous.tagName) {
                previous = current;
            }
            return previous;
        }, null);
        if (!result) {
            result = document.querySelector('#nav nav ul li.subselected > a');
        }
        if (result && result.innerText) {
            title.push(result.innerText.trim());
        }
        return title.join(' - ');
    }

    export function pushState(url: string, title: string = '', owner = "Viggo") {
        if (!title) {
            title = getBestTitle();
        }
        window.history.pushState({ owner: owner, url: url }, title, url);
        let t = document.querySelector('title');
        if (t) {
            if (t.firstChild) {
                t.firstChild.nodeValue = title;
            } else {
                viggo.dom.empty(t);
                t.appendChild(viggo.dom.text(title));
            }
        }
        document.title = title;
    }

    export function load(url: string, object: LoadOptions = {}) {
        let hash: string|null = url.split('#')[1];
        if (hash) {
            let element = document.getElementById(hash);
            if (element) {
                element.classList.add('loading-spinner');
                viggo.load.abortLoadingElements(element);
            } else {
                url = removeAjax(url);
                hash = null;
            }
        } else {
            viggo.load.abortLoadingElements(document.body);
        }

        return new viggo.ajax({
            method: 'get',
            url: url.split("#")[0],
            convert: false,
            multisender: object.multisender,
            complete: function (this: XMLHttpRequest, response: string) {
                clickTimeout = window.setTimeout(function () {
                    lastRequest = null;
                }, 5000);
                let type: string|null = this.getResponseHeader('Content-Type');
                if (type) {
                    type = type.split(';')[0];
                }
                if (type == 'application/javascript') {
                    let func = new Function(response);
                    func();
                    return;
                }
                if (type != 'text/html') {
                    throw "Unexpected response: " + type;
                } else {
                    let scrollElement: Element | null = null;
                    let scrolls: ObjectOf<number> = {};
                    if (typeof object.noScroll == 'string') {
                        object.noScroll.split(',').forEach(selector => {
                            let element = document.querySelector(selector);
                            if (element) {
                                scrolls[selector] = element.scrollTop;
                            }
                        });
                    }
                    if (object.clearSelector) {
                        document.querySelectorAll(object.clearSelector).forEach(x => viggo.dom.empty(x));
                    }
                    let body: string | null | DocumentFragment = response;
                    let htmlId: string = '';
                    if (!hash) {
                        htmlId = (response.match(/<html .*?id="([^"]+)"/)||['',document.documentElement!.id])[1];
                        body = viggo.dom.betweenTags(response, 'body');
                        let style = response.match(/<link .*?href="([^"]+?viggo(?:\.min)?\.css[^"]+)"/);
                        if (style) {
                            let version = viggo.func.getStyleVersion(style[1]);
                            if (version && version != styleVersion) {
                                reloadNext = true;
                                console.log('full reload style');
                            }
                        }
                        let script = response.match(/<script .*?src="([^"]+?bundle(?:\.min)?\.js[^"]+)"/);
                        if (script) {
                            let version = viggo.func.getScriptVersion(script[1]);
                            if (version && version != scriptVersion) {
                                reloadNext = true;
                                console.log('full reload script');
                            }
                        }
                    }
                    let scripts: string[] = [];
                    // no javascript-downloads
                    body = body.replace(/<script (?:.*)?src="[^>]+>\s*<\/script>/g, '');
                    body = body.replace(/<script (?:.*)?type="text\/javascript"[^>]*>((?:.|\r|\n)*?)<\/script>/mg, function (all, script) {
                        scripts.push(script);
                        return "";
                    });
                    body = body.trim();
                    body = viggo.dom.parseHTML(body);

                    let owner: HTMLElement | null;
                    if (hash) {
                        owner = document.getElementById(hash);
                        if (owner) {
                            owner.classList.remove('loading-spinner');
                            switch (object.appendType) {
                                case LoadAppendType.prepend:
                                    owner.insertBefore(<DocumentFragment>body, owner.firstChild);
                                    break;
                                case LoadAppendType.append:
                                    owner.appendChild(body);
                                    break;
                                case LoadAppendType.replaceOwner:
                                    scrollElement = <Element>owner.parentNode;
                                    scrollElement.replaceChild(body, owner);
                                    if (scrollElement.classList.contains('modal')) {
                                        viggo.modal.setupClose(scrollElement);
                                    }
                                    break;
                                case LoadAppendType.replace:
                                default:
                                    viggo.dom.empty(owner);
                                    owner.appendChild(body);
                                    break;
                            }
                        }
                    } else {
                        owner = body.querySelector('div[id="page"]');
                        if (owner) {
                            document.body.replaceChild(owner, document.getElementById('page')!);
                            if (htmlId) {
                                document.documentElement!.id = htmlId;
                                switch (htmlId) {
                                    case 'area-administrator':
                                        viggo.autocomplete.setUrl("", "/Shared/Search/GetAutoCompleteDataAllUsers");
                                        break;
                                    default:
                                        viggo.autocomplete.setUrl("", "/Shared/Search/GetautocompleteData");
                                        break;
                                }
                            }
                        }
                    }
                    let realUrl = removeAjax(this.responseURL.split("#")[0]);
                    if (object.pushState) {
                        let title = getBestTitle(owner||document);
                        viggo.history.pushState(realUrl, title);
                        //if (window.ga) {
                        //    window.ga('send', 'pageview', { page: u, title: title });
                        //}
                    }
                    if (!object.noScroll) {
                        if (!hash) {
                            document.body.scrollTop = document.documentElement!.scrollTop = 0;
                        } else {
                            var x = window.pageXOffset,
                                y = window.pageYOffset;
                            let element: Element | null = scrollElement || document.getElementById(hash);
                            if (element) {
                                element.scrollIntoView();
                            }
                        }
                    } else if (typeof object.noScroll == 'string') {
                        for (let selector in scrolls) {
                            let element = document.querySelector(selector);
                            if (element) {
                                element.scrollTop = scrolls[selector];
                            }
                        }
                    }

                    if (owner && owner.id == 'page') {
                        let main = document.querySelector('main');
                        if (main) {
                            let ev = new UIEvent('scroll');
                            main.dispatchEvent(ev);
                        }
                    }

                    var ev = new CustomEvent('statepushed', { bubbles: true });
                    var target = hash ? (scrollElement || document.getElementById(hash)) : document.body;
                    if (target) {
                        target.dispatchEvent(ev);
                    }

                    let appleBugs = 0;
                    scripts.forEach(x => {
                        try {
                            if (!appleBugs) {
                                let fn = new Function(x);
                                fn.call(null);
                            }
                        } catch (e) {
                            if (viggo.error.isAppleSpecificBug(e)) {
                                appleBugs++;
                            } else {
                                let info = viggo.error.analyze(e, x);
                                console.error(e);
                                if (viggo.isDevelopment) {
                                    console.log(info.message);
                                } else {
                                    let err = new viggo.error(new ErrorEvent('error', {
                                        colno: info.columnNumber,
                                        error: e,
                                        filename: url,
                                        lineno: info.lineNumber,
                                        message: e.message
                                    }));
                                    err.report(info.message);
                                }
                            }
                        }
                    });

                    if (appleBugs) {
                        if (object.pushState) {
                            window.location.href = realUrl;
                        } else {
                            window.location.reload();
                        }
                    } else {
                        if (object.complete) {
                            object.complete.call(<Element>target, <Element>target);
                        }
                    }

                    if (owner) {
                        viggo.load.loadChildren(owner, object.loader || "click");
                        if (owner.dataset.loadClear) {
                            document.querySelectorAll(owner.dataset.loadClear).forEach(x => viggo.dom.empty(x));
                        }
                    }
                }
            },
            error: function (this: XMLHttpRequest, xhr: XMLHttpRequest, response: string, exception: Error, contentType: string) {
                if (hash) {
                    let elm = document.getElementById(hash);
                    if (elm) {
                        elm.classList.add('load-failed');
                        if (['text/javascript', 'application/javascript', 'x-application/javascript'].indexOf(contentType) != -1) {
                            eval(xhr.responseText);
                        } else if (viggo.isDevelopment) {
                            let iframe = viggo.dom.tag('iframe', { style: { width: '100%', height: xhr.status == 403 ? '150px' : '300px' } });
                            (<HTMLElement>elm).appendChild(viggo.dom.tag('h5', { title: xhr.responseURL }, `${xhr.status} ${xhr.statusText}`));
                            (<HTMLElement>elm).appendChild(iframe);
                            let doc = iframe.contentWindow!.document;
                            doc.open();
                            doc.write(xhr.responseText);
                            doc.close();
                        }
                    }
                }
            }
        });
    }

    window.addEventListener('click', function (event: MouseEvent) {
        let target = <HTMLAnchorElement>viggo.dom.parent(<Element>event.target, 'a');
        let fullReload = reloadNext;
        if (event.button === 0 && target && target.href && internalLink(target.getAttribute('href') + "") && !(event.shiftKey || event.ctrlKey || event.metaKey || event.altKey) && !target.classList.contains('no-ajax') && !target.closest('[contenteditable="true"]')) {
            var obj: LoadOptions = {};
            if (!target.classList.contains('no-history')) {
                obj.pushState = true;
            } else {
                fullReload = false;
            }
            obj.appendType = LoadAppendType.replace;
            for (let type in LoadAppendType) {
                if (target.classList.contains(type)) {
                    obj.appendType = <LoadAppendType>type;
                    fullReload = false;
                    break;
                }
            }
            let href: string = target.href;
            if (target.classList.contains('append-query-string')) {
                href = viggo.appendQueryString(href);
                fullReload = false;
            }
            obj.noScroll = target.classList.contains('no-scroll');
            obj.loader = <LoadCheckInitiator>target.dataset.loadInitiator || 'click';
            let clearSelector = target.dataset.clearArea;
            if (!clearSelector) {
                let m = target.className.match(/clearArea_([^ ]+)/);
                if (m) {
                    clearSelector = '#' + m[1];
                }
            }
            if (clearSelector) {
                obj.clearSelector = clearSelector;
                fullReload = false;
            }
            if (href.indexOf('ajax=1') != -1) {
                fullReload = false;
            }
            if (!lastRequest || lastRequest.url != href || Date.now() - lastRequest.timestamp > 10000 || !viggo.ajax.wasLastRequest('GET', href)) {
                viggo.ajax.cancelAllRequests();
                if (!fullReload) {
                    load(href, obj);
                    event.preventDefault();
                } else {
                    console.log('making full reload');
                }
                clearTimeout(clickTimeout);
                lastRequest = {
                    url: href,
                    timestamp: Date.now()
                };
            } else {
                event.preventDefault();
            }
        } else if (target && target.getAttribute('href') == '#') {
            event.preventDefault();
        }
    }, false);

    let start = function () {
        setTimeout(function () {
            var navigate = function (url: string) {
                var m = viggo.modal.getLatestModal();
                if (m) {
                    m.forceClose();
                }
                load(url, {});
            };
            scriptVersion = viggo.func.getScriptVersion();
            styleVersion = viggo.func.getStyleVersion();
            window.addEventListener('popstate', function (event: PopStateEvent) {
                if (event.state) {
                    switch (event.state.owner) {
                        case 'Viggo':
                            navigate(window.location.href);
                            break;
                        case 'ViggoModal':
                            navigate(window.location.href.replace(/#.*$/, ''));
                            break;
                    }
                } else {
                    navigate(window.location.href);
                }
            }, false);
        }, 1000);
        window.removeEventListener('load', start, false);
    };
    window.addEventListener('load', start, false);
}