module viggo {

    const translations: {
        guard: string;
        birthday: string;
        [index: string]: string
    } = {
        guard: __('Supervision'),
        birthday: __('Birthday')
    };

    const COPY_TYPE_INVALID = 0;

    class DayColumn {
        public cell: HTMLTableDataCellElement;
        public date: Date;
        public index: number;
        public container: HTMLElement;
        constructor(cell: HTMLTableDataCellElement, date: Date, index: number) {
            this.cell = cell;
            this.date = date;
            this.index = index;
            this.container = <HTMLElement>cell.querySelector('.container');
            this.cell.classList.remove('today');
            if (date.isToday()) {
                this.cell.classList.add('today');
            }
        }
        public compare(date: Date): number {
            if (this.date.getDate() == date.getDate() && this.date.getMonth() == date.getMonth() && this.date.getFullYear() == date.getFullYear()) {
                return 0;
            } else {
                return this.date < date ? -1 : 1;
            }
        }
    }

    interface CalendarTemplate {
        [index: string]: Function|undefined;
    }

    interface DatePeriod {
        From: Date;
        To: Date;
    }

    interface PeriodCalendarPartOptions {
        periodStart: Date | null;
        periodEnd: Date | null;
        validPeriods: DatePeriod[];
        invalidPeriods: DatePeriod[];
        slipDistance?: number;
    }

    interface CalendarOptions extends PeriodCalendarPartOptions {
        links: CalendarLinksDTO;
        startDate: Date;
        data: CalendarLoadData;
        element: HTMLElement;
        copyType: number;
        copyData: number[] | null;
        offline?: boolean;
        inputElementDate?: HTMLInputElement|null;
        clickElementPrevious?: HTMLElement|null;
        clickElementToday?: HTMLElement|null;
        clickElementNext?: HTMLElement|null;
        templates: {[index: string]: string};
    }

    interface CalendarAddLink {
        Title: string;
        Url: string;
    }

    interface CalendarLinksDTO {
        Add: CalendarAddLink[];
        Move?: string;
        Copy?: string;
        Delete?: string;
        Execute?: string;
        GetItems?: string;
    }

    export enum FilterResult {
        hide = 0,
        show = 1,
        dim = 2
    }

    enum CopyMode {
        none = "none",
        copy = "copy",
        cut = "cut"
    }

    export enum CalendarItemType {
        Subject = 0,
        Activity = 1,
        Presence = 2,
        Task = 3,
        Preparation = 4,
        Supervision = 5,
        Absence = 6,
        Substitute = 7,
        Calendar = 8,
        Birthday = 9,
        Guard = 10,
        Nothing = -1
    }

    export interface CalendarInterface {
        startDate: Date;
        endDate: Date;
        links: CalendarLinksDTO;
        removeItem(item: CalendarItem, repaintLater?: boolean): boolean;
        createView(name: string, item: any): DocumentFragment | null;
        isInPeriod(item: CalendarItem): boolean;
        selectedItems: CalendarItem[];
        clearSelection(): void;
        toggleSelect(item: CalendarItem): void;
        deleteSelected(): void;
        copy(event?: Event): boolean;
        cut(event?: Event): boolean;
        reload(): void;
    }

    export abstract class AbstractCalendar<GenericItem extends CalendarItem> extends viggo.classes.eventListener implements CalendarInterface {
        public static calendarStart = new Date(0 * 60 * 60 * 1000);
        public static calendarEnd = new Date(24 * 60 * 60 * 1000 - 1);
        public static hourHeight = 46;
        public static defaultTime = 30 * 60 * 1000;

        public links!: CalendarLinksDTO;
        public startDate!: Date;
        protected itemMap!: Map<CalendarItemType, Map<number, GenericItem>>;
        protected items!: GenericItem[];
        public templates!: CalendarTemplate;
        public static timeDetail = 5 * 60 * 1000;
        public static copiedItems: Map<number, viggo.CalendarItemDTO> = new Map();
        protected static copyDate: Date|null = null;
        protected static copyMode: CopyMode = CopyMode.none;
        protected pasteButton: HTMLElement|null = null;
        public selectedItems!: GenericItem[];
        protected inputElementDate?: HTMLInputElement|null;
        protected periodIncrement: number = 0;
        public loadData!: CalendarLoadData;
        public originalDate: Date;
        public abstract get name(): string;
        protected copyType!: number;
        protected copyData!: number[] | null;
        private _filter: ((item: GenericItem) => FilterResult) | null;
        private defaultFilter: (item: GenericItem) => FilterResult;
        protected abstract repositionStartDate(startDate: Date): Date;
        protected abstract repaint(): void;
        protected abstract itemClass: new (item: CalendarItemDTO) => GenericItem;
        private periodClickTimeout: number = 0;
        private ajaxRequest?: viggo.ajax;
        public readonly element: HTMLElement;
        public periodEnd!: Date;
        public periodStart!: Date;
        public validPeriods!: DatePeriod[];
        public invalidPeriods!: DatePeriod[];
        public slipDistance!: number; // miliseconds. Defaults to 5 minutes

        constructor(options: CalendarOptions) {
            super();
            this._filter = null;
            this.defaultFilter = () => FilterResult.show;
            this.element = options.element;
            (<any>this.element).viggoCalendar = this;
            this.originalDate = options.startDate;
            this.initialize(options);
            if (!options.offline) {
                viggo.wait(1).then(() => this.reload());
            }
        }
        public initializePeriod(options: PeriodCalendarPartOptions) {
            this.periodEnd = new Date(options.periodEnd ? options.periodEnd : new Date(3000, 0, 1)); // Not much has changed, but they lived under water
            this.periodStart = new Date(options.periodStart ? options.periodStart : new Date(0));
            this.slipDistance = options.slipDistance || 5 * 60 * 1000; // 5 minutes.
            this.validPeriods = options.validPeriods.sort((p1, p2) => {
                return p1.From.getTime() - p2.From.getTime();
            });
            this.invalidPeriods = options.invalidPeriods.sort((p1, p2) => {
                return p1.From.getTime() - p2.From.getTime();
            });
            if (this.periodEnd <= this.periodStart) {
                throw new Error('End date must be after start date.');
            }
        }
        public get endDate(): Date {
            let end = new Date(this.startDate);
            end.setDate(end.getDate() + this.periodIncrement);
            return end;
        }
        public setFilter(filter: ((item: GenericItem) => FilterResult)|null) {
            this._filter = filter;
        }
        protected get filter(): (item: GenericItem) => FilterResult {
            return this._filter || this.defaultFilter;
        }
        protected * visibleItems() {
            for (let item of this.items) {
                if (item.isInView()) {
                    yield item;
                }
            }
        }
        protected * visibleSelectedItems() {
            for (let item of this.selectedItems) {
                if (item.isInView()) {
                    yield item;
                }
            }
        }
        private setupCopyEventHandlers() {
            let copy = (event: Event) => this.copy(event);
            let paste = (event: Event) => this.paste(event);
            let cut = (event: Event) => this.cut(event);

            let clear = () => this.clearCopy();

            let deleteItems = (event: Event) => {
                event.preventDefault();
                this.deleteSelected();
            };

            this.element.dataset.shortcuts = "Ctrl+C Ctrl+V Ctrl+X Meta+C Meta+V Meta+X Shortcut+Escape Shortcut+Delete Shortcut+Backspace";
            this.element.addEventListener('Ctrl+C', copy, false);
            this.element.addEventListener('Ctrl+V', paste, false);
            this.element.addEventListener('Ctrl+X', cut, false);
            this.element.addEventListener('Meta+C', copy, false);
            this.element.addEventListener('Meta+V', paste, false);
            this.element.addEventListener('Meta+X', cut, false);
            this.element.addEventListener('Shortcut+Escape', clear, false);
            this.element.addEventListener('Shortcut+Delete', deleteItems, false);
            this.element.addEventListener('Shortcut+Backspace', deleteItems, false);
            this.element.addEventListener('invalidate', (event) => {
                event.preventDefault();
                this.reload();
            }, false);
        }
        protected initialize(options: CalendarOptions) {
            this.links = options.links;
            this.links.Add = this.links.Add || [];
            this.loadData = options.data;
            this.copyType = options.copyType;
            this.copyData = options.copyData;
            this.startDate = this.repositionStartDate(options.startDate);
            this.setupCopyEventHandlers();
            this.inputElementDate = options.inputElementDate;

            if (this.inputElementDate) {
                this.inputElementDate.addEventListener('datechange', (ev: Event) => {
                    let event = <DatePickerEvent>ev;
                    let lastDate = new Date(this.startDate);
                    let date = <Date>event.detail.newDate;
                    if (lastDate.getFullYear() != date.getFullYear() || lastDate.getMonth() != date.getMonth() || lastDate.getDate() != date.getDate()) {
                        this.setPeriod(date);
                    }
                });
            }
            let blur = (ev: Event) => {
                let a = (<HTMLElement>ev.target).closest('a');
                if (a) {
                    a.blur();
                }
            };
            if (options.clickElementPrevious) {
                options.clickElementPrevious.addEventListener('click', (ev: Event) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    this.previousPreiod();
                    blur(ev);
                });
            }
            if (options.clickElementToday) {
                options.clickElementToday.addEventListener('click', (ev: Event) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    let date = new Date();
                    date.setHours(0, 0, 0, 0);
                    this.setPeriod(date);
                    blur(ev);
                });
            }
            if (options.clickElementNext) {
                options.clickElementNext.addEventListener('click', (ev: Event) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    this.nextPeriod();
                    blur(ev);
                });
            }
            this.items = [];
            this.selectedItems = [];
            this.itemMap = new Map();
            this.templates = {};
            for (let name in options.templates) {
                try {
                    this.templates[name] = viggo.func.createTemplate(options.templates[name]);
                } catch (err) {
                    console.error(`Error in template "${name}"`);
                    console.error(err);
                }
            }
            this.initializePeriod(options);
        }
        protected getNumberSetting(name: string) {
            let value = this.getStringSetting(name);
            if (value) {
                let num = parseInt(value);
                return isNaN(num) ? null : num;
            }
            return null;
        }
        protected getStringSetting(name: string) {
            return localStorage.getItem(`${this.name}.${name}`);
        }
        protected setSetting(name: string, value: string | number) {
            localStorage.setItem(`${this.name}.${name}`, value+'');
        }
        protected getJsonSetting(name: string) {
            let json = this.getStringSetting(name);
            return json ? JSON.parse(json) : null;
        }
        protected setJsonSetting(name: string, value: any) {
            this.setSetting(name, JSON.stringify(value));
        }
        public reset(startDate: Date) {
            this.startDate = this.repositionStartDate(startDate);
            this.items.forEach(item => {
                if (item.temporaryMode === CalendarItemTemporaryMode.pasting) {
                    item.remove(true);
                }
            });

            this.selectedItems = [];
        }

        public resetPeriods() {
            if (!this.validPeriods.length) {
                this.createInvalidAreaAndCheckSlip(this.startDate, this.periodStart);
                this.createInvalidAreaAndCheckSlip(this.periodEnd, this.endDate);
            }
            for (let period of this.invalidPeriods) {
                this.createInvalidAreaAndCheckSlip(period.From, period.To);
            }
            if (this.validPeriods.length) {
                this.createInvalidAreaAndCheckSlip(this.startDate, this.validPeriods[0].From);
            }
            for (let i = 1; i < this.validPeriods.length; i++) {
                let previousPeriod = this.validPeriods[i - 1];
                let period = this.validPeriods[i];
                this.createInvalidAreaAndCheckSlip(previousPeriod.To, period.From);
            }
            if (this.validPeriods.length) {
                this.createInvalidAreaAndCheckSlip(this.validPeriods[this.validPeriods.length - 1].To, this.endDate);
            }
        }

        private createInvalidAreaAndCheckSlip(from: Date, to: Date) {
            let dates = this.accomidateSlip(from, to);
            if (dates) {
                this.createInvalidArea(new Date(dates[0]), new Date(dates[1]));
            }
        }

        public isInPeriod(item: GenericItem) {
            let result = item.isBetween(this.periodStart, this.periodEnd);
            if (result) {
                if (this.validPeriods.length) {
                    result = !item.isBetween(this.periodStart, this.validPeriods[0].From);
                    for (let i = 1; i < this.validPeriods.length && result; i++) {
                        let period = this.validPeriods[i];
                        let previousPeriod = this.validPeriods[i-1];
                        result = !item.isBetween(previousPeriod.To, period.From);
                    }
                    result = result && !item.isBetween(this.validPeriods[this.validPeriods.length-1].To, this.periodEnd);
                }
                for (let i = 0; i < this.invalidPeriods.length && result; i++) {
                    let period = this.invalidPeriods[i];
                    result = !item.isBetween(period.From, period.To);
                }
            }

            return result;
        }

        protected accomidateSlip(from: Date, to: Date) {
            if (from > to || to.getTime() - from.getTime() < this.slipDistance) {
                return null;
            }
            let slip: Date;
            if (from < this.startDate) {
                from = new Date(this.startDate);
                from.setHours(0, 0, 0, 0);
            } else {
                slip = new Date(from.getTime() + this.slipDistance);
                if (slip.getDate() != from.getDate()) {
                    from = slip;
                    from.setHours(0, 0, 0, 0);
                }
            }
            if (to > this.endDate) {
                to = new Date(this.endDate);
                to.setDate(to.getDate() - 1);
                to.setHours(23, 59, 59, 999);
            } else {
                slip = new Date(to.getTime() - this.slipDistance);
                if (slip.getDate() != to.getDate()) {
                    to = slip;
                    to.setHours(23, 59, 59, 999);
                }
            }
            return [from, to];
        }

        protected abstract createInvalidArea(from: Date, to: Date): void;

        public allowPaste() {
            return !!(AbstractCalendar.copiedItems.size > 0 && this.links.Copy);
        }

        public nextPeriod() {
            this.originalDate.setDate(this.originalDate.getDate() + this.periodIncrement);
            this.setPeriod(this.originalDate);
        }
        public previousPreiod() {
            this.originalDate.setDate(this.originalDate.getDate() - this.periodIncrement);
            this.setPeriod(this.originalDate);
        }
        public setPeriod(date: Date) {
            this.originalDate = date;
            this.startDate = this.repositionStartDate(date);
            if (this.inputElementDate) {
                this.inputElementDate.value = date.format('dd-MM-yyyy');
            }
            this.cacheReload();
            let url = viggo.appendQueryString('?date=' + date.format('yyyy-MM-dd'));
            clearTimeout(this.periodClickTimeout);
            this.periodClickTimeout = window.setTimeout(() => {
                this.reload(undefined, undefined, { url: url });
            }, 200);
        }

        public createView(name: string, item: any) {
            let template = this.templates[name];
            return template ? viggo.func.createView(item, template) : null;
        }

        protected hasTemplates(...names: string[]) {
            let found = true;
            for (let i = 0; i < names.length && found; i++) {
                found = names[i] in this.templates;
            }
            return found;
        }

        protected copyCut(mode: CopyMode) {
            for (var i = 0; i < this.items.length; i++) {
                this.items[i].clearCopyCut();
            }
            AbstractCalendar.copiedItems.clear();
            AbstractCalendar.copyMode = CopyMode.none;
            if (mode != CopyMode.none && this.copyType) {
                AbstractCalendar.copyDate = new Date(this.startDate);
                let counter = 0;
                for (let s of this.visibleSelectedItems()) {
                    counter++;
                    s.copyCut();
                    AbstractCalendar.copiedItems.set(<number>s.id, {
                        id: s.id,
                        group: '',
                        title: s.title,
                        elementId: s.elementId,
                        elementname: s.elementname,
                        description: s.description,
                        premises: s.premises,
                        start: new Date(s.start),
                        length: s.length,
                        type: s.type,
                        elementType: s.elementType,
                        copyType: this.copyType,
                        readonly: s.readonly,
                        backgroundColor: s.style.backgroundColor,
                        borderColor: s.style.borderColor,
                        color: s.style.color
                    });
                }
                if (counter) {
                    AbstractCalendar.copyMode = mode;
                }
            }
        }
        public copy(event?: Event): boolean {
            let result = !event || !((<HTMLElement>event.target).tagName in { INPUT: 1, TEXTAREA: 1 });
            if (result) {
                this.copyCut(CopyMode.copy);
            }
            return result;
        }
        public cut(event?: Event): boolean {
            let result = !event || !((<HTMLElement>event.target).tagName in { INPUT: 1, TEXTAREA: 1 });
            if (result) {
                this.copyCut(CopyMode.cut);
            }
            return result;
        }
        public deleteSelected() {
            let list = Array.from(this.visibleSelectedItems());
            if (list.length && this.links.Delete) {
                new viggo.ajax({
                    url: this.links.Delete,
                    method: 'post',
                    json: true,
                    data: list.map(x => x.id)
                });
                while (list.length) {
                    let e = list.pop();
                    if (e) {
                        e.remove();
                    }
                }
                this.selectedItems = [];
            }
        }
        protected pasteToDates(dates: Date[], copyData: number[] | null = null) {
            if (AbstractCalendar.copyDate && this.links.Copy) {
                let items: any = [];
                for (var i = 0; i < dates.length; i++) {
                    let date = new Date(dates[i]);
                    let add = date.getTime() - AbstractCalendar.copyDate.getTime();
                    for (var item of AbstractCalendar.copiedItems.values()) {
                        let newItem = <CalendarItemDTO>Object.assign({}, item);
                        newItem.temporaryMode = viggo.CalendarItemTemporaryMode.pasting;
                        newItem.calendar = this;
                        newItem.id = -newItem.id;
                        newItem.classes = newItem.classes ? newItem.classes + ' pasting' : 'pasting';
                        newItem.start = new Date(item.start.getTime() + add);
                        // readonly will be updated, when calendar is reloaded with new id.
                        newItem.readonly = true;
                        // this should take care of the daylight savings time issue
                        //newItem.start.setHours(item.start.getHours(), item.start.getMinutes(), item.start.getSeconds(), item.start.getMilliseconds());
                        this.addItem(new this.itemClass(newItem));
                        delete newItem.calendar;
                        items.push(newItem);
                    }
                }
                if (items.length) {
                    let data = {
                        type: AbstractCalendar.copyMode.toString(),
                        date: this.startDate.format('yyyy-MM-dd'),
                        calendar: this.name,
                        items: items,
                        copyType: this.copyType,
                        copyData: copyData
                    };
                    new viggo.ajax({
                        method: 'post',
                        url: this.links.Copy,
                        json: true,
                        data: data
                    });
                    if (AbstractCalendar.copyMode == 'cut') {
                        AbstractCalendar.copiedItems.clear();
                        AbstractCalendar.copyMode = CopyMode.none;
                        AbstractCalendar.copyDate = null;
                        if (this.pasteButton) {
                            this.pasteButton.remove();
                            this.pasteButton = null;
                        }
                    }
                    this.repaint();
                }
            }
        }
        protected paste(event: Event) {
            // the solution might be to look at the copyDate timezoneoffset and the startdate timezoneoffset, and then add the difference
            if ((!event || !((<Element>event.target).tagName in { INPUT: 1, TEXTAREA: 1 })) && AbstractCalendar.copyDate && AbstractCalendar.copyMode != CopyMode.none && this.allowPaste()) {
                let copyDate = new Date(AbstractCalendar.copyDate);
                let startDate = new Date(this.startDate);
                AbstractCalendar.copyDate.setHours(4, 0, 0, 0);
                startDate.setHours(4, 0, 0, 0);
                this.pasteToDates([startDate], this.copyData);
                AbstractCalendar.copyDate = copyDate;
            }
        }
        public clearSelection() {
            while (this.selectedItems.length) {
                let e = this.selectedItems.pop();
                if (e) {
                    e.deselect();
                }
            }
        }
        protected clearCopy() {
            this.clearSelection();
            this.copyCut(CopyMode.none);
            AbstractCalendar.copiedItems.clear();
            AbstractCalendar.copyMode = CopyMode.none;
        }

        public toggleSelect(item: GenericItem) {
            if (!item.readonly) {
                var index = this.selectedItems.indexOf(item);
                if (index === -1) {
                    this.selectedItems.push(item);
                    this.selectedItems.sort(function (a, b) {
                        return a.compare(b);
                    });
                    item.select();
                } else {
                    item.deselect();
                    this.selectedItems.splice(index, 1);
                }
            }
        }
        protected selectAll() {
            this.clearSelection();
            for (let item of this.visibleItems()) {
                this.toggleSelect(item);
            }
        }
        public sortItems() {
            this.items.sort(function (a, b) {
                return a.compare(b);
            });
        }
        public reload(data?: CalendarLoadData, complete?: () => void, pushState?: { url: string }) {
            if (!data) {
                data = Object.assign({}, this.loadData);
                data.date = this.originalDate.format('yyyy-MM-dd');
                data.startDate = this.startDate.format('yyyy-MM-dd');
                data.endDate = this.endDate.format('yyyy-MM-dd');
            }
            if (this.ajaxRequest) {
                this.ajaxRequest.abort();
            }
            if (this.links.GetItems) {
                this.cacheReload();
                this.ajaxRequest = new viggo.ajax({
                    method: 'get',
                    url: this.links.GetItems,
                    data: data,
                    convert: 'json',
                    complete: (json) => {
                        delete this.ajaxRequest;
                        if (complete) {
                            complete();
                        }
                        this.reset(this.originalDate);
                        this.createItems(json);
                        if (pushState) {
                            viggo.history.pushState(pushState.url, viggo.history.getBestTitle() + " - " + this.originalDate.format('dd-MM-yyyy'), 'Calendar');
                        }
                        this.dispatchEvent('load');
                    }
                });
            }
            return this.ajaxRequest;
        }
        protected cacheReload() {
            if (this.items.length) {
                this.items.forEach(item => item.removeAllElements());
                this.reset(this.originalDate);
                this.repaint();
            }
        }
        public addItem(item: GenericItem) {
            let result = this.dispatchEvent('additem', item);
            if (result) {
                let map = this.itemMap.get(item.type) || new Map<number, GenericItem>();
                if (item.id) {
                    if (map.has(item.id)) {
                        let oldItem = map.get(item.id);
                        if (oldItem) {
                            oldItem.remove(true);
                        }
                    }
                }
                let first = 0,
                    last = this.items.length,
                    middle;

                while (first != last) {
                    middle = Math.floor((first + last) / 2);
                    let value = item.compare(this.items[middle]);
                    if (value < 0) {
                        last = middle;
                    } else {
                        first = middle + 1;
                    }
                }

                this.items.splice(first, 0, item);
                if (item.id) {
                    map.set(item.id, item);
                } else {
                    let old = map.get(0);
                    if (old) {
                        this.removeItem(old);
                    }
                    map.set(0, item);
                }
            }
            return result;
        }
        protected getItem(type: CalendarItemType, id: number) {
            let map = this.itemMap.get(type);
            return map ? map.get(id) : map;
        }
        public removeItem(item: GenericItem, repaintLater?: boolean) {
            var pos = this.items.indexOf(item);
            if (pos != -1) {
                let map = this.itemMap.get(item.type);
                if (map) {
                    if (item.id) {
                        map.delete(item.id);
                    } else {
                        map.delete(0);
                    }
                }
                this.items.splice(pos, 1);
                item.remove();
                if (!repaintLater) {
                    this.repaint();
                }
            }
            return pos != -1;
        }
        protected createItem(data: CalendarItemDTO) {
            data.calendar = this;
            var item = new this.itemClass(data);
            return this.addItem(item) ? item : null;
        }
        protected createItems(items: any[]) {
            let calendar = this;
            let start = this.startDate;
            let end = this.endDate;
            for (let i = this.items.length - 1; i >= 0; i--) {
                let item = this.items[i];
                if (item.isBetween(start, end)) {
                    item.remove(true);
                }
            }
            // don't use arrow functions, as iOS 10.3 has shown issues with refering to itemClass
            items.forEach(function(e: any) {
                let item = new calendar.itemClass({
                    calendar: calendar,
                    id: e.id,
                    group: e.selectedColumnIds ? e.selectedColumnIds[0] : e.calendarId,
                    title: e.title || e.name,
                    elementname: e.elementName,
                    elementId: e.elementId,
                    hint: e.hint,
                    description: e.description,
                    premises: e.premises,
                    premisesConflict: e.premisesConflict,
                    hintClass: e.hintClass,
                    start: new Date(e.start||e.timeStart),
                    length: e.minutes * 60 * 1000,
                    type: typeof e.type == 'number' ? e.type : CalendarItemType[e.type.replace(/^[a-z]/, (x: string) => x.toUpperCase())],
                    links: e.links,
                    classes: e.classes,
                    readonly: e.readOnly,
                    elementType: e.elementType,
                    copyType: calendar.copyType,
                    backgroundColor: e.backgroundColor,
                    borderColor: e.borderColor,
                    color: e.color,
                    columnIds: e.selectedColumnIds,
                    selectedsubstitute: e.selectedSubstitute,
                    initials: e.initials,
                    data: e.data,
                    contextmenu: e.contextMenu ? e.contextMenu : []
                });
                /*
                let startDst = item.start.dst(),
                    endDst = item.end.dst();
                if (startDst != endDst) {
                    item.length += (<any>startDst - <any>endDst) * 60 * 60 * 1000;
                }
                */
                calendar.addItem(item);
            });
            this.repaint();
        }
        public static getFirstCopiedDate() {
            return Array.from(AbstractCalendar.copiedItems.values()).reduce((date: Date, item: CalendarItemDTO) => {
                return date < item.start ? date : item.start;
            }, new Date(2200, 0, 1, 12, 0, 0, 0));
        }
        public abstract getDate(element: HTMLElement|null, event?: Event): Date | null;
    }

    interface WeekCalendarOptions extends CalendarOptions {
        element: HTMLTableElement;
        startDay: number;
        weekLength: number;
    }

    class WeekCalendarGroups {
        private groups: WeekCalendarGroup[];
        private end: Date;
        public constructor() {
            this.groups = [];
            this.end = new Date(1970, 0, 1);
        }
        public addItem(item: WeekCalendarItem) {
            if (item.start >= this.end) {
                this.close();
            }
            let foundIndex = -1;
            for (let index = 0; index < this.groups.length && foundIndex == -1; index++) {
                let group = this.groups[index];
                if (group.addItem(item)) {
                    foundIndex = index;
                }
            }
            if (foundIndex == -1) {
                this.groups.push(new WeekCalendarGroup(item));
                this.groups.forEach(group => group.maxColumns = this.groups.length);
            }
            if (item.end > this.end) {
                this.end = item.end;
            }
        }
        public close() {
            for (let i = 0; i < this.groups.length; i++) {
                let group = this.groups[i];
                group.close(i);
            }
            this.groups = [];
        }
    }

    class WeekCalendarGroup {
        private items: WeekCalendarItem[];
        private start: Date;
        private end: Date;
        private max: number;
        public constructor(item: WeekCalendarItem) {
            this.items = [item];
            this.start = item.start;
            this.end = item.end;
            this.max = 1;
        }
        public get maxColumns() {
            return this.max;
        }
        public set maxColumns(value: number) {
            this.max = Math.max(value, this.max);
        }
        public addItem(item: WeekCalendarItem) {
            let result = this.end <= item.start;
            if (result) {
                this.items.push(item);
                this.end = item.end;
            }
            return result;
        }
        public close(index: number) {
            this.items.forEach(item => {
                item.indent = index;
                item.itemCount = this.maxColumns;
                item.update(FilterResult.show);
            });
        }
    }

    export class WeekCalendar extends AbstractCalendar<viggo.WeekCalendarItem> {
        public columns!: DayColumn[];
        public timeLine?: HTMLElement;
        private narrowItems: WeekCalendarItem[];
        private thead!: HTMLTableSectionElement;
        private tbody!: HTMLTableSectionElement;
        private tfoot!: HTMLTableSectionElement;
        private footerRows!: {[index: string]: HTMLTableRowElement | undefined};
        private headerRows!: {[index: string]: HTMLTableRowElement | undefined};
        private copyWeekButton: HTMLElement | null = null;
        public element!: HTMLTableElement;
        protected periodIncrement = 7;
        protected startDay: number;
        protected weekLength: number;
        protected itemClass: new (item: CalendarItemDTO) => viggo.WeekCalendarItem;
        private periodElements: HTMLElement[];

        constructor(options: WeekCalendarOptions) {
            super(options);
            this.narrowItems = [];
            this.periodElements = [];
            this.itemClass = viggo.WeekCalendarItem;
            this.startDay = options.startDay;
            this.weekLength = options.weekLength;
            this.setTable(this.element);
            let section = viggo.dom.parentFilter(options.element, (child) => {
                return viggo.getStyle(child, 'overflow-y') in {auto:1,scroll:1};
            }) || document.querySelector('main>section');
            if (section && section.contains(this.element)) {
                section.scrollTop = 360;
            }

            this.reset(this.originalDate);
        }
        protected clearCopy() {
            super.clearCopy();
            if (this.copyWeekButton) {
                this.copyWeekButton.remove();
                this.copyWeekButton = null;
            }
        }
        public resetPeriods() {
            for (let period of this.periodElements) {
                period.remove();
            }
            this.periodElements = [];
            super.resetPeriods();
        }
        protected createInvalidArea(from: Date, to: Date) {
            if (from < to && from < this.endDate && to > this.startDate) { // are we in range of the calendar
                let fromColumn = this.getColumn(from);
                let toColumn = this.getColumn(to);
                let start: number;
                let end: number;
                if (!fromColumn) {
                    fromColumn = this.columns[0];
                    start = 0;
                } else {
                    start = this.getTimePosition(from);
                }
                while (fromColumn != toColumn) {
                    fromColumn.container.appendChild(this.createInvalidItem(start, 1 - start));
                    start = 0;
                    fromColumn = this.columns[fromColumn.index + 1];
                }
                if (fromColumn) {
                    end = this.getTimePosition(to);
                    fromColumn.container.appendChild(this.createInvalidItem(start, end - start));
                }
            }
        }
        private createInvalidItem(top: number, height: number) {
            let element = viggo.dom.tag('div', {
                className: 'offperiod',
                style: {
                    top: `${top * 24 * AbstractCalendar.hourHeight}px`,
                    height: `${height * 24 * AbstractCalendar.hourHeight}px`
                }
            });
            this.periodElements.push(element);
            return element;
        }
        private getTimePosition(date: Date) {
            return (date.getHours() * 60 + date.getMinutes()) / (24 * 60);
        }
        protected initialize(options: WeekCalendarOptions) {
            super.initialize(options);
            this.footerRows = {};
            this.headerRows = {};
        }
        protected createColgroup() {
            let colgroup = viggo.dom.tag('colgroup');
            colgroup.appendChild(viggo.dom.tag('col', { className: 'time' }));
            let days = 'sun mon tues wednes thurs fri satur'.split(' ');
            for (let i = 0; i < this.weekLength; i++) {
                let index = (this.startDay + i) % 7;
                colgroup.appendChild(viggo.dom.tag('col', {className: `day ${days[index]}day`}));
            }
            return colgroup;
        }
        protected createThead() {
            let thead = viggo.dom.tag('thead');
            let tr = thead.appendChild(viggo.dom.tag('tr'));
            tr.appendChild(viggo.dom.tag('th', { title: __('Week') }));
            for (let i = 0; i < this.weekLength; i++) {
                tr.appendChild(viggo.dom.tag('th'));
            }
            return thead;
        }
        protected createTbody() {
            let tbody = viggo.dom.tag('tbody');
            let tr = tbody.appendChild(viggo.dom.tag('tr'));
            let th = tr.appendChild(viggo.dom.tag('th'));
            let d = { className: 'd' };
            for (let i = 0; i < 24; i++) {
                th.appendChild(viggo.dom.tag('div',
                    i < 8 || i > 16 ? d : null, // set the className to d, if out of day hours
                    i < 10 ? '0' + i : i, // hours
                    viggo.dom.tag('small', null, '00'), // minutes
                    viggo.dom.tag('i') // tag for dotted line
                ));
            }
            let node = viggo.dom.tag('td', null, viggo.dom.tag('div', { className: 'container' }));
            for (let i = 0; i < this.weekLength; i++) {
                tr.appendChild(node.cloneNode(true));
            }
            return tbody;
        }
        protected createTfoot() {
            return viggo.dom.tag('tfoot');
        }

        private getTop(ev: MouseEvent) {
            let y = this.tbody.getBoundingClientRect().top;
            return ev.clientY - y;
        }

        private getTime(y: number, column: number) {
            let t = Math.round(((y / AbstractCalendar.hourHeight * 60 * 60 * 1000) + AbstractCalendar.calendarStart.getTime()) / WeekCalendar.timeDetail) * WeekCalendar.timeDetail;
            let time = new Date(this.columns[column].date.getTime());
            time.setMilliseconds(t);
            return time;
        }

        protected setTable(table: HTMLTableElement) {
            this.element = table;
            viggo.dom.empty(table);
            (<any>this.element).viggoCalendar = this;
            table.appendChild(this.createColgroup());
            this.thead = table.appendChild(this.createThead());
            this.tbody = table.appendChild(this.createTbody());
            this.tfoot = table.appendChild(this.createTfoot());

            this.tbody.addEventListener('mousemove', (event: MouseEvent) => {
                let target = viggo.dom.parent(<Element>event.target, 'div'),
                    item = target ? target.viggoItem : null;

                if (target && item && !item.readonly && target == item.elements[item.elements.length - 1]) {
                    if (event.clientY - viggo.dom.getPos(target, true).top - target.offsetHeight >= -10) {
                        target.style.cursor = 's-resize';
                    } else {
                        target.style.cursor = '';
                    }
                }
            }, false);

            this.tbody.addEventListener('contextmenu', (event: Event) => {
                var cell = <HTMLTableDataCellElement>viggo.dom.parent(<Element>event.target, 'td');

                if (cell && AbstractCalendar.copiedItems.size && this.links.Copy) {
                    let time = this.getTime(this.getTop(<MouseEvent>event), cell.cellIndex - 1);
                    var date = new Date(time);
                    date.setHours(0, 0, 0, 0);
                    var offset = (time.getTime() - date.getTime()) / (24 * 60 * 60 * 1000) * cell.offsetHeight;
                    let pasteLine = cell.firstChild!.appendChild(viggo.dom.tag('span', {
                        className: 'current-paste-line',
                        style: {
                            top: offset + 'px'
                        }
                    }));
                    let removePasteLine = () => {
                        pasteLine.remove();
                        document.removeEventListener('click', removePasteLine, true);
                        document.removeEventListener('mousedown', removePasteLine, true);
                        document.removeEventListener('contextmenu', removePasteLine, true);
                    };
                    document.addEventListener('mousedown', removePasteLine, true);
                    document.addEventListener('click', removePasteLine, true);
                    document.addEventListener('contextmenu', removePasteLine, true);

                    if (this.allowPaste()) {
                        viggo.contextmenu.addItem({
                            title: __('Paste'),
                            className: 'calendar-paste',
                            icon: 'o-flaticon-paste',
                            click: () => {
                                if (AbstractCalendar.copiedItems.size) {
                                    let firstDate = AbstractCalendar.getFirstCopiedDate();
                                    let copyDate = AbstractCalendar.copyDate;
                                    AbstractCalendar.copyDate = firstDate;
                                    var date = this.getTime(this.getTop(<MouseEvent>event), cell.cellIndex - 1);
                                    this.pasteToDates([date], this.copyData);
                                    AbstractCalendar.copyDate = copyDate;
                                }
                            }
                        });
                        viggo.contextmenu.addItem({
                            title: __('Paste with time'),
                            className: 'calendar-paste-time',
                            icon: 'o-flaticon-paste',
                            click: () => {
                                if (AbstractCalendar.copiedItems.size) {
                                    let firstDate = AbstractCalendar.getFirstCopiedDate();
                                    let copyDate = AbstractCalendar.copyDate;
                                    AbstractCalendar.copyDate = firstDate;
                                    let date = this.getTime((firstDate.getHours() + firstDate.getMinutes() / 60) * AbstractCalendar.hourHeight, cell.cellIndex - 1);
                                    this.pasteToDates([date], this.copyData);
                                    AbstractCalendar.copyDate = copyDate;
                                }
                            }
                        });
                    }
                }
            }, false);

            this.tbody.addEventListener('mousedown', (event: MouseEvent) => {
                if (event.button != 0 || !this.links.Add.length) {
                    return;
                }

                let target = <HTMLDivElement|null>event.target;
                let item: WeekCalendarItem|null = null;
                while (target) {
                    if (target.viggoItem) {
                        item = <WeekCalendarItem>target.viggoItem;
                        break;
                    }
                    target = <HTMLDivElement|null>target.parentNode;
                }
                let mousedownCell = <HTMLTableDataCellElement|null>viggo.dom.parent(<Element>event.target, 'td');
                if (!mousedownCell) { // mousedown on scrollbar, or somewhere that aren't a TD.
                    return;
                }
                let mousedownTime = this.getTime(this.getTop(event), mousedownCell.cellIndex - 1);

                let move: (ev: MouseEvent) => void, moved = false;
                viggo.hint.block();

                if (!item) {
                    item = new WeekCalendarItem({
                        calendar: this,
                        group: '',
                        start: mousedownTime,
                        length: AbstractCalendar.defaultTime,
                        title: "",
                        elementname: "",
                        elementId: 0,
                        id: 0,
                        copyType: COPY_TYPE_INVALID,
                        readonly: false,
                        type: CalendarItemType.Calendar,
                        temporaryMode: viggo.CalendarItemTemporaryMode.creating,
                    });
                    if (item) {
                        if (!this.addItem(item)) {
                            item = null;
                        }
                    }
                    this.repaint();
                    this.tbody.addEventListener('mousemove', move = (ev: MouseEvent) => {
                        ev.stopPropagation();
                        moved = true;
                        let td = viggo.dom.parent(<Element>ev.target, 'td');
                        if (td) {
                            let time = this.getTime(this.getTop(ev), td.cellIndex - 1);
                            let from = mousedownTime;
                            let to = time;
                            if (from > to) {
                                from = to;
                                to = mousedownTime;
                            }
                            if (item) {
                                item.setStart(from);
                                item.setLength(Math.max(to.getTime() - from.getTime(), AbstractCalendar.defaultTime));
                                item.update(this.filter(item));
                            }
                        }
                    }, false);
                } else if (!(event.ctrlKey || event.shiftKey || event.metaKey || event.altKey)) {
                    let dif = 0;
                    if (target && target.style.cursor) {
                        dif = item.start.getTime() + item.length - mousedownTime.getTime();
                        this.tbody.addEventListener('mousemove', move = (ev: MouseEvent) => {
                            ev.stopPropagation();
                            let td = <HTMLTableDataCellElement>viggo.dom.parent(<Element>ev.target, 'td');
                            if (td) {
                                let time = this.getTime(this.getTop(ev), td.cellIndex - 1);
                                let add = mousedownTime.getTime() - time.getTime();
                                if (item) {
                                    item.setLength(mousedownTime.getTime() - item.start.getTime() + dif - add);
                                    item.update(this.filter(item));
                                }
                                moved = moved || ev.clientX != event.clientX || ev.clientY != event.clientY;
                            }
                        }, false);
                    } else if (target) {
                        dif = item.start.getTime() - mousedownTime.getTime();
                        this.tbody.addEventListener('mousemove', move = (ev: MouseEvent) => {
                            ev.stopPropagation();
                            var td = <HTMLTableDataCellElement>viggo.dom.parent(<Element>ev.target, 'td');
                            if (td) {
                                let time = this.getTime(this.getTop(ev), td.cellIndex - 1);
                                let add = mousedownTime.getTime() - time.getTime();
                                time = mousedownTime;
                                time.setTime(time.getTime() + dif - add);
                                if (item) {
                                    item.setStart(time);
                                    item.update(this.filter(item));
                                }
                                moved = moved || ev.clientX != event.clientX || ev.clientY != event.clientY;
                            }
                        }, false);
                    }
                } else {
                    return;
                }

                /*
                let scrollToCursor = (ev: MouseEvent) => {
                    let top = this.table.getBoundingClientRect().top;
                    var y = window.pageYOffset + ev.clientY + viggo.getScrollTop(this.tbody.parentNode);
                    if (y < top) {
                        cal.tbody.scrollTop -= 10;
                    } else if (y > parentTop + cal.tbody.offsetHeight) {
                        cal.tbody.scrollTop += 10;
                    }
                    window.getSelection().removeAllRanges();
                };
                */

                let mouseup = () => {
                    this.tbody.removeEventListener('mousemove', move, false);
                    document.removeEventListener('mouseup', mouseup, false);
                    viggo.hint.unblock();
                    //document.removeEventListener('mousemove', scrollToCursor, true);
                    if (moved) {
                        this.sortItems();
                        this.repaint();
                        if (item) {
                            item.save();
                        }
                    } else if (item && !item.id) {
                        item.remove();
                    }
                };
                document.addEventListener('mouseup', mouseup, false);
                //document.addEventListener('mousemove', scrollToCursor, true);
            }, false);
        }
        public get name() {
            return 'Week';
        }
        protected repositionStartDate(startDate: Date) {
            let date = new Date(startDate);
            date.setDate(date.getDate() - ((date.getDay() + (7 - this.startDay)) % 7));
            date.setHours(0, 0, 0, 0);
            return date;
        }

        public get endDate() {
            let end = new Date(this.startDate);
            end.setDate(end.getDate() + this.weekLength);
            return end;
        }

        public copy(event: Event) {
            let result = super.copy(event);
            if (result && this.columns.length >= 5 && AbstractCalendar.copiedItems.size && this.hasTemplates('CopyToPeriodButton', 'CopyToPeriodModal', 'CopyToPeriodItem')) {
                // only show the "copy to weeks"-action if it's a weeks-calendar.
                if (this.copyWeekButton) {
                    this.copyWeekButton.remove();
                }
                let fragment = this.createView('CopyToPeriodButton', null)!;
                this.copyWeekButton = <HTMLElement>fragment.firstElementChild;
                let link = this.copyWeekButton.querySelector('a');
                if (!link) {
                    throw new Error("Need link in view CopyToPeriodButton");
                }
                let parent = document.querySelector('#events-details .toolbar .button-group');
                if (!parent) {
                    throw new Error("Unable to find parent for copy to multiple weeks.");
                }
                parent.insertBefore(this.copyWeekButton, parent.firstChild);
                link.addEventListener('click', (ev: MouseEvent) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    let modal = this.createView('CopyToPeriodModal', null)!;
                    let list = <HTMLElement>modal.querySelector('.items');
                    if (!list) {
                        throw new Error("Missing element .items in view CopyToPeriodModal");
                    }
                    let button = <HTMLButtonElement>modal.querySelector('button');
                    if (!button) {
                        throw new Error("Missing button in view CopyToPeriodModal");
                    }
                    button.style.display = 'none';
                    let savedDates: Date[] = [];
                    button.addEventListener('click', (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.pasteToDates(savedDates, this.copyData);
                        viggo.modal.close();
                    }, false);
                    let placeholder = <HTMLElement|undefined>modal.querySelector('.calendar-placeholder');
                    new viggo.modal({ element: modal });
                    viggo.createCalendar({
                        type: 'week',
                        input: placeholder,
                        date: new Date(this.startDate),
                        multiselect: true,
                        callback: (dates: Date | Date[]) => {
                            viggo.dom.empty(list);
                            let last = null;
                            let start: number[] | null = null;
                            if (!(dates instanceof Array)) {
                                dates = [dates];
                            }
                            savedDates = dates;
                            for (var i = 0; i < dates.length; i++) {
                                let d = dates[i].getWeekOfYear();
                                if (!start) {
                                    start = d;
                                }
                                if (last) {
                                    if (d[1] - 1 != last[1] || d[0] != last[0]) {
                                        let item = this.createView('CopyToPeriodItem', { week: start == last ? start[1] : start[1] + '-' + last[1], year: start[0] });
                                        if (item) {
                                            list.appendChild(item);
                                        }
                                        start = d;
                                    }
                                }
                                last = d;
                            }
                            if (last) {
                                let item = this.createView('CopyToPeriodItem', { week: start == last ? start[1] : start![1] + '-' + last[1], year: start![0] });
                                if (item) {
                                    list.appendChild(item);
                                }
                                button.style.display = '';
                            } else {
                                button.style.display = 'none';
                            }
                        }
                    });
                }, false);
            }
            return result;
        }

        public reset(startDate: Date) {
            super.reset(startDate);
            this.columns = [];
            this.loadColumns(true);
            this.narrowItems = [];
            this.resetPeriods();
        }

        public overlapsNarrowItemOnDay(date: Date) {
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
            let overlapping = false;
            for (let i = 0; i < this.narrowItems.length && !overlapping; i++) {
                overlapping = this.narrowItems[i].overlapsOnDay(date);
            }
            return overlapping;
        }

        //overlapFunctionName: 'overlaps',
        public repaint() {
            this.resetPeriods();
            let items = new WeekCalendarGroups(), narrowItems = new WeekCalendarGroups();
            this.items.forEach(item => item.alsoBookedBy = []);
            this.narrowItems = [];
            this.items.forEach((item, index) => {
                let type = (item.typeString||'calendar').toLowerCase();
                if (type in viggo.informationTypes && viggo.informationTypes[type] != 1) {
                    item.itemCount = 1;
                    item.update(this.filter(item));
                } else {
                    item.itemCount = 1;
                    if (viggo.informationTypes[type] == 1) {
                        this.narrowItems.push(item);
                        narrowItems.addItem(item);
                    } else {
                        items.addItem(item);
                    }
                    for (let i = index + 1; i < this.items.length; i++) {
                        item.checkDoubleBooking(this.items[i]);
                    }
                }
            });

            items.close();
            narrowItems.close();
            this.columns.forEach(column => {
                let elm = <HTMLDivElement>column.cell.firstChild;
                let min = AbstractCalendar.hourHeight * 24,
                    max = 0;
                for (let i = 0; i < elm.childNodes.length; i++) {
                    let item = elm.childNodes[i];
                    if (item.nodeType == 1) {
                        if ((<HTMLElement>item).classList.contains('calendar-item')) {
                            let top = parseFloat((<HTMLElement>item).style.getPropertyValue('--top'));
                            let height = parseFloat((<HTMLElement>item).style.getPropertyValue('--height'));
                            if (!isNaN(top) && !isNaN(height)) {
                                min = Math.min(min, top + height);
                                max = Math.max(max, top);
                            }
                        }
                    }
                }
                elm.style.setProperty('--min', min+'');
                elm.style.setProperty('--max', max+'');
            });
            this.showCurrentTimeLine();
        }
        protected timelineTimeout = 0;
        protected showCurrentTimeLine() {
            clearTimeout(this.timelineTimeout);
            if (this.timeLine) {
                this.timeLine.remove();
                delete this.timeLine;
            }
            if (document.body.contains(this.element)) {
                var date = new Date();
                date.setHours(0, 0, 0, 0);
                var column = this.getColumn(date);
                if (column) {
                    var offset = (Date.now() - date.getTime()) / (24 * 60 * 60 * 1000) * column.cell.offsetHeight;
                    let container = column.cell.firstChild;
                    if (container) {
                        this.timeLine = container.appendChild(
                            viggo.dom.tag('span', {
                                className: 'current-time-line',
                                style: {
                                    top: offset + 'px'
                                }
                            })
                        );
                        this.timelineTimeout = window.setTimeout(() => this.showCurrentTimeLine(), 10000);
                    }
                }
            }
        }
        public getColumn(date: Date): DayColumn|null {
            for (var i = 0; i < this.columns.length; i++) {
                var v = this.columns[i].compare(date);
                if (v == 0) {
                    return this.columns[i];
                } else if (v > 0) {
                    return null;
                }
            }
            return null;
        }
        protected loadColumns(createHeaders?: boolean) {
            let columns = [];
            let cells = this.thead.getElementsByTagName('tr')[0].cells;
            let tr = this.tbody.getElementsByTagName('tr')[0];
            let date = new Date(this.startDate);
            viggo.dom.empty(cells[0]);
            let template = this.createView('WeekNumber', {date: date});
            if (template) {
                cells[0].appendChild(template);
            }
            for (let i = 1; i < cells.length; i++) {
                columns.push(new DayColumn(tr.cells[i], new Date(date), i-1));
                if (createHeaders) {
                    let cell = cells[i];
                    viggo.dom.empty(cell);
                    cell.classList.remove('dst', 'today');
                    template = this.createView('Header', {date: date});
                    if (template) {
                        cell.appendChild(template);
                    }
                    if (date.isDstSwitchDay()) {
                        cell.classList.add('dst');
                    }
                    if (date.isToday()) {
                        cell.classList.add('today');
                    }
                }
                date.setDate(date.getDate() + 1);
            }
            this.columns = columns;
        }
        protected createHeadFootRow(type: string) {
            var row = viggo.dom.tag('tr', {
                className: 'calendar-' + type
            });
            for (var i = 0; i <= this.columns.length; i++) {
                if (!i) {
                    row.appendChild(viggo.dom.tag('th', null, this.createView('FooterLabel', {type: type})));
                } else {
                    row.appendChild(viggo.dom.tag('td', null, viggo.dom.tag('div', { className: 'container' })));
                }
            }
            return row;
        }
        public getHeaderRow(type: string) {
            var row = this.headerRows[type];
            if (!row) {
                row = this.thead.appendChild(this.createHeadFootRow(type));
                this.headerRows[type] = row;
            }
            return row;
        }
        public getFooterRow(type: string) {
            let row = this.footerRows[type];
            if (!row) {
                row = this.createHeadFootRow(type);
                if (type == CalendarItemType[CalendarItemType.Birthday].toLowerCase()) {
                    this.tfoot.appendChild(row);
                } else {
                    this.tfoot.insertBefore(row, this.tfoot.firstChild);
                }
                this.footerRows[type] = row;
            }
            return row;
        }
        public getDate(element: HTMLElement|null, event?: MouseEvent) {
            if (element) {
                element = element.closest('td');
                if (element && this.element.contains(element)) {
                    let date: Date;
                    if (event) {
                        date = this.getTime(this.getTop(event), (<HTMLTableDataCellElement>element).cellIndex - 1);
                    } else {
                        date = new Date(this.startDate);
                        date.setDate(date.getDate() + (<HTMLTableDataCellElement>element).cellIndex - 1);
                    }
                    return date;
                }
            }
            return null;
        }
    }

    export class WorkWeekCalendar extends WeekCalendar {
        public get name() {
            return 'WorkWeek';
        }
    }

    export class DayCalendar extends WeekCalendar {
        protected periodIncrement = 1;
        public get name() {
            return 'Day';
        }
        protected repositionStartDate(startDate: Date) {
            let date = new Date(startDate);
            date.setHours(0, 0, 0, 0);
            return date;
        }
    }

    interface CustomHeaderCalendarOptions extends CalendarOptions {
        days?: number;
        columnWidth?: number;
        orderHeaders?: boolean;
        headers: any[];
        element: HTMLTableElement;
    }

    export class CustomHeaderCalendar extends AbstractCalendar<viggo.CustomHeaderCalendarItem> {
        public element!: HTMLTableElement;
        public columns!: Map<number, Map<number, HTMLDivElement>>;
        public days!: number;
        public columnWidth!: number;
        protected orderHeaders!: boolean;
        protected thead!: HTMLTableSectionElement;
        protected tbody!: HTMLTableSectionElement[];
        protected itemClass: new (item: CalendarItemDTO) => viggo.CustomHeaderCalendarItem;
        public name = 'CustomHeader';

        constructor(options: CustomHeaderCalendarOptions) {
            super(options);
            this.itemClass = viggo.CustomHeaderCalendarItem;
        }

        protected initialize(options: CustomHeaderCalendarOptions) {
            super.initialize(options);
            this.days = options.days || 1;
            this.periodIncrement = this.days == 5 && this.startDate.getDay() == 1 ? 7 : this.days;
            this.orderHeaders = options.orderHeaders || false;

            let me = this;
            let scroll = function (this: HTMLTableSectionElement, event: MouseEvent) {
                let left = this.scrollLeft;
                for (let i = 0; i < me.tbody.length; i++) {
                    let e = me.tbody[i].scrollLeft = left;
                }
                me.thead.scrollLeft = left;
            }

            viggo.dom.empty(this.element);
            this.element.addEventListener('contextmenu', (event: MouseEvent) => {
                let td = (<HTMLElement>event.target).closest('td');
                if (td && this.allowPaste()) {
                    let date = this.getDate(td);
                    let headerId = parseInt(td.dataset.headerId||'0');
                    if (date && headerId) {
                        viggo.contextmenu.addItem({
                            title: __('Paste'),
                            click: () => {
                                let oldDate = AbstractCalendar.copyDate;
                                let firstDate = AbstractCalendar.getFirstCopiedDate();
                                AbstractCalendar.copyDate = firstDate;
                                date!.setHours(firstDate.getHours(), firstDate.getMinutes(), 0, 0);
                                this.pasteToDates([date!], [headerId]); // we don't use copyData here, because the header is the data.
                                AbstractCalendar.copyDate = oldDate;
                            }
                        });
                    }
                }
            }, false);
            let colgroup = viggo.dom.tag('colgroup');
            this.element.appendChild(colgroup);
            this.thead = this.element.appendChild(viggo.dom.tag('thead', { onscroll: scroll }));
            let tr = viggo.dom.tag('tr');
            this.thead.appendChild(tr);
            this.columns = new Map();
            let th = viggo.dom.tag('th', null, viggo.dom.tag('div', { className: 'container' }));
            tr.appendChild(th);
            colgroup.appendChild(viggo.dom.tag('col', { className: 'time' }));
            let createHeader = async (data: any) => {
                let map = new Map();
                colgroup.appendChild(viggo.dom.tag('col'));
                this.columns.set(data.id, map);
                tr.appendChild(viggo.dom.tag('th', { dataset: { headerId: data.id } }, this.createView('Header', data)));
            };
            let orderedHeaders = Array.from(options.headers);
            orderedHeaders.forEach(x => x.calendar = this);
            if (this.orderHeaders) {
                let orderBy = function (a: any, b: any) {
                    return a.orderBy < b.orderBy ? -1 : (a.orderBy > b.orderBy ? 1 : 0);
                };
                orderedHeaders.sort(orderBy);
            }
            for (let i = 0; i < orderedHeaders.length; i++) {
                createHeader(orderedHeaders[i]);
            }
            this.tbody = [];
            this.columnWidth = Math.max((this.element.clientWidth - th.offsetWidth) / this.columns.size, 5);
            let createDay = (tr: HTMLTableRowElement, day: number) => {
                let d = this.repositionStartDate(options.startDate);
                d.setDate(d.getDate() + day);
                tr.appendChild(viggo.dom.tag('th', null, this.createView('SideHeader', { date: d })));
            };
            for (let i = 0; i < this.days; i++) {
                let tbody = this.element.appendChild(viggo.dom.tag('tbody', { onscroll: scroll }));
                this.tbody.push(tbody);
                let tr = tbody.appendChild(viggo.dom.tag('tr'));
                createDay(tr, i);
                for (let j = 0; j < orderedHeaders.length; j++) {
                    let map = this.columns.get(orderedHeaders[j].id);
                    if (map) {
                        let td = tr.appendChild(viggo.dom.tag('td', { dataset: Object.assign(orderedHeaders[j].data || {}, { headerId: orderedHeaders[j].id }) }));
                        let view = this.createView('Container', orderedHeaders[j]);
                        if (view) {
                            let container = <HTMLDivElement>view.firstChild;
                            td.appendChild(container);
                            map.set(i, container);
                        }
                    }
                }
            }

            let section = document.querySelector('main>section');
            if (section && section.contains(this.element)) {
                section.scrollTop = 360;
            }
            /*
             * // is this nessacery?
            for (let i = 1; i < tr.childNodes.length; i++) {
                tr.childNodes[i].firstChild.style.width = this.columnWidth + 'px';
            }
            */
        }

        protected createInvalidArea(from: Date, to: Date) {
            // Method not implemented
        }

        public get endDate() {
            let result = new Date(this.startDate);
            result.setDate(result.getDate() + this.days);
            return result;
        }

        public getDate(td: HTMLTableDataCellElement, event?: MouseEvent) {
            let tbody = <Element | null>td.closest('tbody');
            let date: Date | null = new Date(this.startDate);
            if (tbody) {
                do {
                    tbody = tbody.previousElementSibling;
                    date.setDate(date.getDate() + 1);
                } while (tbody && tbody.tagName == 'TBODY');
                date.setDate(date.getDate() - 1);
            } else {
                date = null;
            }
            return date;
        }

        protected repositionStartDate(date: Date) {
            date = new Date(date);
            date.setHours(0, 0, 0, 0);
            return date;
        }

        private repaintSideHeader() {
            let date = new Date(this.startDate);
            for (let tbody of this.tbody) {
                let th = tbody.rows[0].cells[0];
                viggo.dom.empty(th);
                th.appendChild(this.createView('SideHeader', { date: date })!);
                date.setDate(date.getDate() + 1);
            }
        }

        public repaint() {
            this.repaintSideHeader();
            let map: Map<number, CustomHeaderCalendarItem[]> = new Map();
            this.items.forEach(item => {
                let type = (item.typeString || 'calendar').toLowerCase();
                if (type in viggo.informationTypes && viggo.informationTypes[type] != 1) {
                    item.itemCount = 1;
                    item.update(this.filter(item));
                } else {
                    item.itemCount = 1;
                    if (item.columnIds) {
                        item.columnIds.filter(x => this.columns.has(x)).forEach(columnId => {
                            let items = map.get(columnId);
                            if (!items) {
                                items = [];
                                map.set(columnId, items);
                            }
                            while (items.length && !item.overlaps(items[items.length - 1])) {
                                let i = items.pop();
                                if (i) {
                                    i.update(this.filter(item));
                                }
                            }
                            item.indent = items.length;
                            items.push(item);
                            items.forEach(x => x.itemCount = Math.max(items!.length, x.itemCount));
                        });
                    }
                }
            });
            map.forEach(items => {
                while (items.length) {
                    let item = items.pop();
                    if (item) {
                        item.update(this.filter(item));
                    }
                }
            });

            /*
            let previousParent = null;
            let samePlaceElements: HTMLDivElement[] = [];
            let lastElement = null;
            let minTop = -1, maxTop = -1;
            let repos = function () {
                let length = samePlaceElements.length;
                if (length > 1) {
                    while (samePlaceElements.length) {
                        let elm = <HTMLDivElement>samePlaceElements.pop();
                        let width = parseFloat(<string>elm.style.width) / length;
                        elm.style.width = width + 'px';
                        elm.style.left = width * samePlaceElements.length + 'px';
                    }
                } else {
                    samePlaceElements = [];
                }
            };
            for (let i = 0; i < elements.length; i++) {
                let element = elements[i],
                    parent = element.parentNode,
                    item = (<HTMLDivElement>element).viggoItem;
                if (previousParent != parent && item) {
                    previousParent = parent;
                    repos();
                    samePlaceElements.push(element);
                    minTop = item.start.getHours() * 60 * 60 * 1000 + item.start.getMinutes() * 60 * 1000 + item.start.getSeconds() * 1000 + item.start.getMilliseconds();
                    maxTop = item.length + minTop;
                } else if (item) {
                    var top = item.start.getHours() * 60 * 60 * 1000 + item.start.getMinutes() * 60 * 1000 + item.start.getSeconds() * 1000 + item.start.getMilliseconds();
                    if (top < maxTop && top >= minTop) {
                        samePlaceElements.push(element);
                        minTop = Math.min(minTop, top);
                        maxTop = Math.max(top + item.length, maxTop);
                    } else {
                        repos();
                        samePlaceElements.push(element);
                        minTop = top;
                        maxTop = top + item.length;
                    }
                }
                lastElement = element;
            }
            repos();
            */
        }
    }

    interface AbstractCustomRowCalendarOptions extends CalendarOptions {
        days: number;
        sections: CustomRowSection[];
    }

    interface CustomRowDayCalendarOptions extends AbstractCustomRowCalendarOptions {
    }

    interface CustomRowHeaderItem {
        Id: number;
        Title: string;
    }

    export interface CustomRowSection {
        Id: string;
        Title: string;
        Headers: { [id: string]: CustomRowHeaderItem };
    }

    export abstract class AbstractCustomRowCalendar<GenericItem extends CalendarItem, GenericSection extends CustomRowSection> extends AbstractCalendar<GenericItem> {
        protected _days!: number;
        private dayWidth = 0;
        protected groupMap: Map<string, HTMLTableRowElement>;
        protected sections: GenericSection[];
        // tableHeader is used for measuring the width of one day
        protected tableHeader!: HTMLTableHeaderCellElement;
        public constructor(options: AbstractCustomRowCalendarOptions) {
            super(options);
            this.sections = <GenericSection[]>options.sections;
            this.groupMap = new Map<string, HTMLTableRowElement>();
            this.element.classList.add('events-row-header');
            this._days = options.days || 7;
            let resize = (event: UIEvent) => {
                if (document.body.contains(this.element)) {
                    this.repaint();
                } else {
                    window.removeEventListener('resize', resize, false);
                }
            };
            let popstate = () => {
                if (!document.body.contains(this.element)) {
                    window.removeEventListener('resize', resize, false);
                    window.removeEventListener('popstate', popstate, false);
                }
            };
            window.addEventListener('resize', resize, false);
            window.addEventListener('popstate', popstate, false);
            this.element.addEventListener('wheel', this.scroll.bind(this), false);
        }

        public get days() {
            return this._days;
        }

        public getDayWidth() {
            if (!this.dayWidth) {
                this.dayWidth = this.tableHeader.getBoundingClientRect().width;
            }
            return this.dayWidth;
        }

        protected getUnixDay(date: Date) {
            date = new Date(date);
            date.setHours(0, 0, 0, 0);
            return Math.floor(date.getTime() / (1000 * 60 * 60 * 24));
        }

        protected getGroupId(element: HTMLElement) {
            let group = '';
            let container = <HTMLElement | null>element.closest('tr[data-header-id]');
            if (container) {
                group = container.dataset.headerId || '';
            }
            return group;
        }

        protected createTable() {
            viggo.dom.empty(this.element);
            this.createTableHead();
            this.createTableBodySections();
            this.createTableFoot();
        }

        protected createTableBodySections() {
            let collapsed = this.getJsonSetting('collapsedSections');
            if (!collapsed) {
                collapsed = {};
            }
            for (let section of this.sections) {
                let tbody = this.createTableBody(section);
                if (collapsed[section.Id]) {
                    tbody.classList.add('collapsed');
                }
                this.element.appendChild(tbody);
            }
        }

        protected toggleSection(id: string, tbody: HTMLTableSectionElement) {
            let isCollapsed = tbody.classList.toggle('collapsed');
            let json = this.getJsonSetting("collapsedSections");
            if (!json) {
                json = {};
            }
            if (isCollapsed) {
                json[id] = 1;
            } else {
                delete json[id];
            }
            this.setJsonSetting('collapsedSections', json);
        }

        protected createTableBody(section: GenericSection) {
            let tbody = viggo.dom.tag('tbody');
            let tr = viggo.dom.tag('tr');
            tbody.appendChild(tr);
            tr.appendChild(this.createSectionHeader(section, tbody));
            for (let id in section.Headers) {
                tr = tbody.appendChild(viggo.dom.tag('tr', { dataset: {headerId: id}}));
                this.groupMap.set(id, tr);
                tr.appendChild(viggo.dom.tag('th', null, this.createView('RowHeader', section.Headers[id])));
                this.createRow(tr);
            }
            return tbody;
        }

        protected addResizeListener(tbody: HTMLTableSectionElement) {
            tbody.addEventListener('mousemove', (event: MouseEvent) => {
                let target = viggo.dom.parent(<Element>event.target, 'div'),
                    item = target ? target.viggoItem : null;

                if (target && item && !item.readonly && target == item.elements[item.elements.length - 1]) {
                    if (event.clientX - viggo.dom.getPos(target, true).left - target.offsetWidth >= -10) {
                        target.style.cursor = 'e-resize';
                    } else {
                        target.style.cursor = '';
                    }
                }
            }, false);
        }

        protected addCreateAndMoveListener(tbody: HTMLTableSectionElement) {
            tbody.addEventListener('mousedown', (event) => {
                if (event.button != 0 || !this.links.Add.length) {
                    return;
                }
                let target: HTMLElement | null = <HTMLElement>event.target;
                let item: GenericItem | undefined;
                while (target && !item) {
                    item = (<any>target).viggoItem;
                    if (!item) {
                        target = <HTMLElement | null>target.parentNode;
                    }
                }
                if (item) {
                    item.pointerEvents = false;
                    target = <HTMLElement|null>document.elementsFromPoint(event.clientX, event.clientY).find(x => x.tagName == 'TD');
                } else {
                    target = <HTMLElement>event.target;
                }
                let group = this.getGroupId(<HTMLElement>event.target);
                let originalGroup = group;
                let moved = false;
                let move: (ev: MouseEvent) => void;
                let td = (<HTMLElement>target).closest('td');
                if (td) {
                    let mousedownTime = this.getTime(td, event.clientX);
                    if (!item) {
                        if (group) {
                            item = new this.itemClass({
                                calendar: this,
                                group: group,
                                start: mousedownTime,
                                length: AbstractCalendar.defaultTime,
                                title: "",
                                elementname: "",
                                elementId: 0,
                                id: 0,
                                copyType: COPY_TYPE_INVALID,
                                readonly: false,
                                type: CalendarItemType.Calendar,
                                temporaryMode: viggo.CalendarItemTemporaryMode.creating
                            });
                            this.addItem(item);
                            item.update(this.filter(item));

                            this.element.addEventListener('mousemove', move = (ev: MouseEvent) => {
                                ev.stopPropagation();
                                group = this.getGroupId(<HTMLElement>ev.target);
                                let td = <HTMLTableDataCellElement | null>(<HTMLElement>ev.target).closest('tbody td');
                                if (group && td) {
                                    moved = true;
                                    let time = this.getTime(td, ev.clientX);
                                    let from = mousedownTime;
                                    let to = time;
                                    if (from > to) {
                                        from = to;
                                        to = mousedownTime;
                                    }
                                    if (item) {
                                        item.setStart(from);
                                        item.setGroup(group);
                                        item.setLength(Math.max(to.getTime() - from.getTime(), AbstractCalendar.defaultTime));
                                        item.update(this.filter(item));
                                    }
                                }
                            }, false);
                        }
                    } else {
                        let dif = 0;
                        if (target && target.style.cursor) {
                            let end = item.end;
                            this.repositionEndDateForEditing(end);
                            if (end <= item.start) {
                                end.setDate(end.getDate() + 1);
                            }
                            try {
                                item.end = end;
                            } catch (e) {
                                console.log('end smaller than start');
                            }
                            // resize
                            dif = item.start.getTime() + item.length - mousedownTime.getTime();
                            this.element.addEventListener('mousemove', move = (ev: MouseEvent) => {
                                let td = <HTMLTableDataCellElement | null>(<HTMLElement>ev.target).closest('tbody td');
                                if (td) {
                                    ev.stopPropagation();
                                    let time = this.getTime(td, ev.clientX);
                                    let add = mousedownTime.getTime() - time.getTime();
                                    if (item) {
                                        item.setLength(mousedownTime.getTime() - item.start.getTime() + dif - add);
                                        item.update(this.filter(item));
                                    }
                                    moved = moved || ev.clientX != event.clientX || ev.clientY != event.clientY;
                                }
                            }, false);
                        } else if (target) {
                            // move
                            dif = item.start.getTime() - mousedownTime.getTime();
                            this.element.addEventListener('mousemove', move = (ev: MouseEvent) => {
                                let td = <HTMLTableDataCellElement | null>(<HTMLElement>ev.target).closest('tbody td');
                                if (td) {
                                    ev.stopPropagation();
                                    group = this.getGroupId(td);
                                    if (group) {
                                        let time = this.getTime(td, ev.clientX);
                                        let add = mousedownTime.getTime() - time.getTime();
                                        time = mousedownTime;
                                        time.setTime(time.getTime() + dif - add);
                                        if (item) {
                                            item.setStart(time);
                                            item.setGroup(group);
                                            item.update(this.filter(item));
                                        }
                                        moved = moved || ev.clientX != event.clientX || ev.clientY != event.clientY || group != originalGroup;
                                    }
                                }
                            }, false);
                        }
                    }
                }

                let mouseup = () => {
                    this.element.removeEventListener('mousemove', move, false);
                    document.removeEventListener('mouseup', mouseup, false);
                    viggo.hint.unblock();
                    //document.removeEventListener('mousemove', scrollToCursor, true);
                    if (moved) {
                        if (item) {
                            item.pointerEvents = true;
                        }
                        this.sortItems();
                        this.repaint();
                        if (item) {
                            item.save();
                        }
                    } else if (item && !item.id) {
                        item.remove();
                    }
                };
                document.addEventListener('mouseup', mouseup, false);
            }, false);
        }

        protected abstract repositionEndDateForEditing(end: Date): void;

        protected createSectionHeader(section: GenericSection, tbody: HTMLTableSectionElement) {
            return viggo.dom.tag('td', {
                colSpan: this.days + 1,
                onclick: () => {
                    this.toggleSection(section.Id, tbody);
                },
                onmousedown: (e: MouseEvent) => {
                    e.stopPropagation();
                }
            }, this.createView('SectionHeader', section));
        }

        protected createRow(tr: HTMLTableRowElement) {
            for (let i = 0; i < this.days; i++) {
                tr.appendChild(viggo.dom.tag('td'));
            }
        }

        protected repaint() {
            this.groupMap.clear();
            this.dayWidth = 0;
            this.createTable();
            this.items.forEach(item => {
                item.alsoBookedBy = [];
                item.indent = -1;
            });
            let map = new Map<string, GenericItem[]>();
            for (let item of this.items) {
                if (item.isInView()) {
                    if (!map.has(item.group)) {
                        map.set(item.group, [item]);
                        item.indent = 0;
                    } else {
                        let list = map.get(item.group);
                        if (list) {
                            list.forEach(x => item.checkDoubleBooking(x));
                            list.push(item);
                            item.indent = item.alsoBookedBy.length;
                        }
                    }
                } else {
                    item.removeAllElements();
                }
            }

            this.updateRowVariables(map);
            
            // this may seem stupid, but you need to add the containers before adding the content.
            // otherwise the width of the table may change, and this leeds to wrong positioning.
            this.items.forEach(item => item.update(this.filter(item)));
        }

        protected updateRowVariables(itemMap: Map<string, GenericItem[]>) {
            for (let entity of this.groupMap) {
                let list = itemMap.get(entity[0]);
                let num = list ? Math.max(...list.map(x => x.alsoBookedBy.length + 2)).toString() : '0';
                entity[1].style.setProperty('--max-items', num);
                entity[1].dataset.maxItems = num;
            }
        }

        public set days(value: number) {
            if (!isNaN(value)) {
                this._days = value;
                this.element.style.setProperty('--days', this.days + '');
                this.setSetting('days', value);
            }
        }

        public get endDate() {
            let end = new Date(this.startDate);
            end.setDate(end.getDate() + this.days);
            return end;
        }
        protected repositionStartDate(startDate: Date): Date {
            let date = new Date(startDate);
            date.setHours(0, 0, 0, 0);
            return date;
        }
        private scroll(event: WheelEvent) {
            let scroll = event.deltaX;
            if (!scroll && event.shiftKey) {
                scroll = event.deltaY;
            }

            if (scroll < 0) {
                event.preventDefault();
                this.previousPreiod();
            } else if (scroll > 0) {
                event.preventDefault();
                this.nextPeriod();
            } // if scroll == 0 do nothing
        }
        protected abstract createTableHead(): void;
        protected abstract createTableFoot(): void;
        protected abstract getTime(td: HTMLTableDataCellElement, x: number): Date;
    }

    export class CustomRowDayCalendar<GenericItem extends CustomRowDayCalendarItem, GenericSection extends CustomRowSection> extends AbstractCustomRowCalendar<GenericItem, GenericSection> {
        protected itemClass: new (item: CalendarItemDTO) => GenericItem;
        protected periodIncrement = 1;
        public get name() {
            return 'CustomRowDay';
        }
        public constructor(options: CustomRowDayCalendarOptions) {
            super(options);
            this.itemClass = <any>CustomRowDayCalendarItem;
        }
        protected repositionStartDate(startDate: Date) {
            startDate = new Date(startDate);
            startDate.setHours(0, 0, 0, 0);
            return startDate;
        }
        public getDate(element: HTMLElement | null) {
            throw new Error("Not implemented");
            return new Date();
        }
        protected createTableHead() {
            let thead = viggo.dom.tag('thead');
            let tr = thead.appendChild(viggo.dom.tag('tr'));
            tr.appendChild(this.createControls()); // controls
            let date = new Date(this.startDate);
            for (let i = 0; i < this.days; i++) {
                tr.appendChild(viggo.dom.tag('th', null, this.createView('Header', { date: date })));
                date.setDate(date.getDate() + 1);
            }
            this.tableHeader = <HTMLTableHeaderCellElement>tr.lastChild;
            this.element.appendChild(thead);
        }
        protected createControls() {
            let controls = viggo.dom.tag('th', { rowSpan: 2 }, this.createView('ViewControls', { days: this.days }));
            let control = <HTMLInputElement | HTMLSelectElement | null>controls.querySelector('.days-control');
            if (!control) {
                throw new Error('Element with class="days-control" not found.');
            }
            control.addEventListener('change', (ev: Event) => {
                let target = <HTMLInputElement | HTMLSelectElement>ev.target;
                let d = parseInt(target.value);
                if (!isNaN(d)) {
                    this.days = d;
                    this.repaint();
                }
            }, false);
            return controls;
        }

        protected createTableBody(section: GenericSection) {
            let tbody = super.createTableBody(section);
            this.addResizeListener(tbody);
            this.addCreateAndMoveListener(tbody);

            return tbody;
        }
        public getItemPosition(group: string, date: Date) {
            let calDay = this.getUnixDay(this.startDate);
            let startDay = this.getUnixDay(date);
            let index = startDay - calDay;

            // calculate starting index of calendar item
            if (index < 0) {
                index = 0;
            } else if (index > this.days) {
                index = this.days;
            }

            let row = this.groupMap.get(group);
            return row ? row.cells[index + 1] : null;
        }
        protected repositionEndDateForEditing(end: Date) {

        }
        public createTableFoot() { }

        protected getTime(td: HTMLTableDataCellElement, x: number) {
            let size = td.getBoundingClientRect();
            x = (x - size.left) / size.width;
            let cellIndex = td.cellIndex - 1;
            let days = cellIndex;

            let hours = (x % 1) * 24;

            let date = new Date(this.startDate);
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + days);
            date.setTime(Math.round((date.getTime() + hours * 60 * 60 * 1000) / AbstractCalendar.timeDetail) * AbstractCalendar.timeDetail);
            return date;
        }

        protected createInvalidArea(from: Date, to: Date) {
            // Method not implemented
        }
    }

    interface CustomRowDetailCalendarOptions extends AbstractCustomRowCalendarOptions {
        startHour: number;
        endHour: number;
    }

    export class CustomRowDetailCalendar extends AbstractCustomRowCalendar<CustomRowDetailCalendarItem, CustomRowSection> {
        protected itemClass: new (item: CalendarItemDTO) => CustomRowDetailCalendarItem;
        public get name() {
            return 'CustomRowDetail';
        }
        private _startHour!: number;
        private _endHour!: number;
        protected periodIncrement = 1;

        constructor(options: CustomRowDetailCalendarOptions) {
            super(options);
            let days = this.getNumberSetting('days');
            if (days === null) {
                days = options.days || 7;
            }
            this._days = days;
            let hour = this.getNumberSetting('startHour');
            if (hour !== null) {
                options.startHour = hour;
            }
            hour = this.getNumberSetting('endHour');
            if (hour !== null) {
                options.endHour = hour;
            }
            this.startHour = options.startHour;
            this.endHour = options.endHour;
            this.days = this._days; // update the css value.
            this.itemClass = CustomRowDetailCalendarItem;
        }

        public get startHour() {
            return this._startHour;
        }
        public get endHour() {
            return this._endHour;
        }

        public set startHour(value: number) {
            if (!isNaN(value)) {
                value = Math.min(Math.max(value, 0), 12);
                this._startHour = value;
                if (!isNaN(this.endHour)) {
                    this.element.style.setProperty('--hours', (this.endHour - this.startHour) + '');
                }
                this.setSetting('startHour', value);
            }
        }

        public set endHour(value: number) {
            if (!isNaN(value)) {
                value = Math.min(Math.max(value, 13), 24);
                this._endHour = value;
                if (!isNaN(this.startHour)) {
                    this.element.style.setProperty('--hours', (this.endHour - this.startHour) + '');
                }
                this.setSetting('endHour', value);
            }
        }

        public getDate(element: HTMLElement | null) {
            throw new Error("Not implemented");
            return new Date();
        }

        protected getTime(td: HTMLTableDataCellElement, x: number) {
            let size = td.getBoundingClientRect();
            x = (x - size.left) / size.width;
            let cellIndex = td.cellIndex - 1;
            let days = Math.floor(cellIndex / 2);

            let hours = (x % 1) * (this.endHour - this.startHour) + this.startHour;

            let date = new Date(this.startDate);
            date.setHours(0, 0, 0, 0);
            date.setDate(date.getDate() + days);
            if (cellIndex % 2 == 1) {
                date.setTime(Math.round((date.getTime() + hours * 60 * 60 * 1000) / AbstractCalendar.timeDetail) * AbstractCalendar.timeDetail);
            }
            return date;
        }

        protected createInvalidArea(from: Date, to: Date) {
            // Method not implemented
        }

        protected repositionEndDateForEditing(end: Date) {
            if (end.getHours() < this.startHour || (end.getHours() == this.startHour && end.getMinutes())) {
                end.setHours(this.startHour, 0, 0, 0);
            } else if (end.getHours() >= this.endHour) {
                end.setHours(this.endHour, 0, 0, 0);
            }
        }

        protected createControls() {
            let controls = viggo.dom.tag('th', { rowSpan: 2 }, this.createView('ViewControls', { days: this.days, startHour: this.startHour, endHour: this.endHour }));
            let control = <HTMLInputElement | HTMLSelectElement | null>controls.querySelector('.days-control');
            if (!control) {
                throw new Error('Element with class="days-control" not found.');
            }
            control.addEventListener('click', (ev: Event) => {
                let target = <HTMLElement|null>ev.target;
                target = target!.closest('[data-days]');
                if (target) {
                    let d = parseInt(target.dataset.days!);
                    if (!isNaN(d)) {
                        this.days = d;
                        this.repaint();
                    }
                }
            }, false);
            control = <HTMLInputElement | HTMLSelectElement | null>controls.querySelector('.start-hour-control');
            if (!control) {
                throw new Error('Element with class="start-hour-control" not found.');
            }
            control.addEventListener('change', (ev: Event) => {
                let target = <HTMLInputElement | HTMLSelectElement>ev.target;
                let d = parseInt(target.value);
                if (!isNaN(d)) {
                    this.startHour = d;
                    this.repaint();
                }
            }, false);
            control.addEventListener('input', (ev: Event) => {
                let target = <HTMLInputElement>ev.target;
                controls.querySelectorAll('.start-hour-value').forEach(x => x.textContent = target.value);
            }, false);
            control = <HTMLInputElement | HTMLSelectElement | null>controls.querySelector('.end-hour-control');
            if (!control) {
                throw new Error('Element with class="end-hour-control" not found.');
            }
            control.addEventListener('change', (ev: Event) => {
                let target = <HTMLInputElement | HTMLSelectElement>ev.target;
                let d = parseInt(target.value);
                if (!isNaN(d)) {
                    this.endHour = d;
                    this.repaint();
                }
            }, false);
            control.addEventListener('input', (ev: Event) => {
                let target = <HTMLInputElement>ev.target;
                controls.querySelectorAll('.end-hour-value').forEach(x => x.textContent = target.value);
            }, false);
            return controls;
        }

        protected createTableHead() {
            let colgroup = viggo.dom.tag('colgroup');

            let thead = viggo.dom.tag('thead');
            let trHead = thead.appendChild(viggo.dom.tag('tr'));
            trHead.appendChild(this.createControls());
            let hours = this.endHour - this.startHour;
            let trTime = thead.appendChild(viggo.dom.tag('tr'));

            let date = new Date(this.startDate);
            date.setHours(12, 0, 0, 0);
            colgroup.appendChild(viggo.dom.tag('col', { className: 'headers' }));

            for (let i = 0; i < this.days; i++) {
                colgroup.appendChild(viggo.dom.tag('col', { className: 'between-days' }));
                trHead.appendChild(viggo.dom.tag('th', { className: 'between-days' }));
                trTime.appendChild(viggo.dom.tag('td', { className: 'between-days' }));

                trHead.appendChild(viggo.dom.tag('th', { colSpan: hours }, this.createView('Header', { date: date })));

                for (let i = 0; i < hours; i++) {
                    trTime.appendChild(viggo.dom.tag('td', null, this.createView('TimeHeader', { hour: this.startHour + i })));
                    colgroup.appendChild(viggo.dom.tag('col'));
                }

                date.setDate(date.getDate() + 1);
            }

            colgroup.appendChild(viggo.dom.tag('col', { className: 'between-days' }));
            trHead.appendChild(viggo.dom.tag('th', { className: 'between-days' }));
            trTime.appendChild(viggo.dom.tag('td', { className: 'between-days' }));
            this.tableHeader = <HTMLTableHeaderCellElement>trHead.childNodes[2];
            this.element.appendChild(colgroup);
            this.element.appendChild(thead);
        }

        protected createRow(tr: HTMLTableRowElement) {
            let hours = this.endHour - this.startHour;
            for (let i = 0; i < this.days; i++) {
                tr.appendChild(viggo.dom.tag('td', { className: 'between-days' }));
                let td = viggo.dom.tag('td', { colSpan: hours });
                tr.appendChild(td);
            }
            tr.appendChild(viggo.dom.tag('td', { className: 'between-days' }));
        }

        protected createSectionHeader(section: CustomRowSection, tbody: HTMLTableSectionElement) {
            let td = super.createSectionHeader(section, tbody);
            td.colSpan = this.days * (this.endHour - this.startHour + 1) + 2;
            return td;
        }

        protected createTableBody(section: CustomRowSection) {
            let tbody = super.createTableBody(section);

            this.addResizeListener(tbody);

            this.addCreateAndMoveListener(tbody);

            return tbody;
        }

        protected createTableFoot() { }

        public getItemPosition(group: string, startDate: Date, endDate: Date) {
            let calDay = this.getUnixDay(this.startDate);
            let startDay = this.getUnixDay(startDate);
            let start = startDay - calDay;

            // calculate starting index of calendar item
            if (start < 0) {
                start = 0;
            } else if (start > this.days) {
                start = this.days * 2;
            } else {
                start = start * 2 + 1;
                if (startDate.getHours() < this.startHour) {
                    start--;
                } else if (startDate.getHours() >= this.endHour) {
                    start++;
                }
            }

            // calculate ending index of calendar item
            let endDay = this.getUnixDay(endDate);
            let end = endDay - calDay;
            if (endDay < 0) {
                endDay = 0;
            } else if (end > this.days) {
                end = this.days * 2;
            } else {
                end = end * 2 + 1;
                if (endDate.getHours() < this.startHour) {
                    end--;
                } else if (endDate.getHours() > this.endHour || (endDate.getHours() == this.endHour && endDate.getMinutes())) {
                    end++;
                }
            }
            let list = this.groupMap.get(group);
            if (list) {
                return {
                    parent: list.cells[start + 1],
                    start: start,
                    end: end
                }
            }
            return null;
        }
    }

    interface MultiweekCalendarOptions extends CalendarOptions {
        element: HTMLElement;
        maxItems?: number;
    }

    export class MultiweekCalendar extends AbstractCalendar<viggo.MultiweekCalendarItem> {
        public rows!: HTMLTableElement[];
        protected maxItems = 5;
        private draggedItem?: MultiweekCalendarItem;
        protected itemClass: new (item: CalendarItemDTO) => viggo.MultiweekCalendarItem;
        protected get weekCount() {
            return 4;
        }
        constructor(options: MultiweekCalendarOptions) {
            super(options);
            this.itemClass = viggo.MultiweekCalendarItem;
        }
        protected initialize(options: MultiweekCalendarOptions) {
            super.initialize(options);
            this.periodEnd.setHours(23, 59, 59, 999);
            this.maxItems = options.maxItems || 5;
            this.loadRows();
        }
        public get name() {
            return "Weeks";
        }

        protected createInvalidArea(from: Date, to: Date) {
            let slip = new Date(from.getTime() - this.slipDistance);
            let td: HTMLTableDataCellElement | null;
            if (from.getDate() == slip.getDate()) {
                td = this.getCell(from);
                if (td) {
                    td.classList.add('halfperiod');
                }
            }
            while (from < to) {
                td = this.getCell(from);
                if (td) {
                    td.classList.add('offperiod');
                } else {
                    break;
                }
                from.setDate(from.getDate() + 1);
                from.setHours(0, 0, 0, this.slipDistance);
            }
            slip = new Date(to.getTime() + this.slipDistance);
            if (to.getDate() == slip.getDate()) {
                td = this.getCell(to);
                if (td) {
                    td.classList.add('halfperiod');
                }
            }
        }
        public resetPeriods() {
            this.element.querySelectorAll('table:not(.cal-items) td:not(.out)').forEach(td => td.classList.remove('offperiod', 'halfperiod'));
            super.resetPeriods();
        }
        public get endDate() {
            let end = new Date(this.startDate);
            end.setDate(end.getDate() + 7 * this.weekCount);
            return end;
        }
        protected repositionStartDate(startDate: Date) {
            let date = new Date(startDate);
            date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
            date.setHours(0, 0, 0, 0);
            return date;
        }
        protected repaint() {
            this.loadRows();
            for (let i = 0; i < this.items.length; i++) {
                let item = this.items[i];
                item.update(this.filter(item));
            }
            for (let row of this.rows) {
                row.parentElement!.style.setProperty('--rows', row.rows.length+'');
            }
        }
        public getDate(td: HTMLTableDataCellElement) {
            let start = this.startDate;
            let row = td.closest('div.week-row');
            if (row) {
                let week = Array.prototype.indexOf.call(row.parentElement!.children, row);
                if (week != -1) {
                    let days = -1;
                    let first = true;
                    while (td != null) {
                        days += first ? 1 : td.colSpan;
                        first = false;
                        td = <HTMLTableDataCellElement>td.previousElementSibling;
                    }
                    return new Date(start.getFullYear(), start.getMonth(), start.getDate() + week * 7 + days, 0, 0, 0, 0);
                }
            }
            return null;
        }
        public getCellIndex(date: Date) {
            date = new Date(date);
            var end = new Date(this.startDate);
            var dst = end.dst();
            var add = 0;
            if (date.dst() != dst) {
                add = dst ? 1 : -1;
            }
            end.setDate(end.getDate() + 7 * this.rows.length);
            end.setHours(end.getHours() + add);
            date.setHours(end.getHours() + add);
            if (date < this.startDate || date >= end) {
                return -1;
            } else {
                return Math.floor((date.getTime() - this.startDate.getTime()) / (1000 * 60 * 60 * 24));
            }
        }
        protected loadRows() {
            viggo.dom.empty(this.element);

            var fragment = viggo.dom.fragment();
            let date = new Date(2013, 6, 15, 0, 0, 0, 0); // monday
            for (let i = 7; i--;) {
                fragment.appendChild(viggo.dom.tag('th', null, this.createView('Header', { date: date })));
                date.setDate(date.getDate() + 1);
            }
            this.element.appendChild(
                viggo.dom.tag('table', null,
                    viggo.dom.tag('thead', null,
                        viggo.dom.tag('tr', null, fragment)
                    )
                )
            );

            this.rows = [];
            let rows: HTMLTableElement[] = [];
            date = new Date(this.startDate);
            let mouseDownCell: HTMLTableDataCellElement|null = null;
            let mouseUpCell: HTMLTableDataCellElement|null = null;
            let setHighlightClass = (start: HTMLTableDataCellElement, end: HTMLTableDataCellElement, className: string) => {
                let tds = this.element.querySelectorAll('table.calBackground td');
                let found = false, td: Element;
                for (var i = 0; i < tds.length; i++) {
                    td = tds[i];
                    if (td == start) {
                        found = true;
                    }
                    if (found) {
                        td.classList.add('create-new');
                    } else {
                        td.classList.remove('create-new');
                    }

                    if (td == end) {
                        found = false;
                    }
                }
            }
            let highlightCells = function () {
                let start = mouseDownCell,
                    end = mouseUpCell;
                if (start && end) {
                    if (!(start.compareDocumentPosition(end) & 4)) {
                        let temp = start;
                        start = end;
                        end = temp;
                    }
                    setHighlightClass(start, end, 'create-new');
                }
            };
            let tableObject = {
                className: 'cal-background',
                onmousedown: (event: MouseEvent) => {
                    if (event.button) {
                        return;
                    }
                    mouseDownCell = viggo.dom.parent(<HTMLElement>event.target, 'td');
                    mouseUpCell = mouseDownCell;
                    highlightCells();
                    let mouseup = (event: MouseEvent) => {
                        if (mouseDownCell && mouseUpCell) {
                            if (!(mouseDownCell.compareDocumentPosition(mouseUpCell) & 4)) {
                                var temp = mouseDownCell;
                                mouseDownCell = mouseUpCell;
                                mouseUpCell = temp;
                            }
                            setHighlightClass(mouseDownCell, mouseUpCell, 'create-new');
                            let from = <Date>mouseDownCell.viggoDate;
                            let to = <Date>mouseUpCell.viggoDate;
                            let item = this.createItem({
                                calendar: this,
                                id: 0,
                                group: '',
                                title: "",
                                elementId: 0,
                                elementname: "",
                                copyType: COPY_TYPE_INVALID,
                                start: new Date(from),
                                length: to.getTime() - from.getTime() + 4 * 60 * 60 * 1000,
                                readonly: false,
                                type: CalendarItemType.Calendar
                            });
                            mouseDownCell = null;
                            mouseUpCell = null;
                            document.removeEventListener('mouseup', mouseup, true);
                            this.reset(this.originalDate, true);
                            this.repaint();
                            if (item) {
                                item.save();
                            }
                        }
                    };
                    document.addEventListener('mouseup', mouseup, true);
                },
                onmouseover: (event: MouseEvent) => {
                    if (mouseDownCell) {
                        var cell = viggo.dom.parent(<Element>event.target, 'td');
                        if (cell)
                            mouseUpCell = cell;
                        highlightCells();
                    }
                },
                ondragover: (event: DragEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                },
                ondragenter: (event: DragEvent) => {
                    let target = viggo.dom.parent(<Element>event.target, 'td');
                    if (target && this.draggedItem) {
                        let item = this.draggedItem;
                        var endDate = new Date(<Date>target.viggoDate);
                        endDate.setHours(item.start.getHours(), item.start.getMinutes(), item.start.getSeconds(), item.start.getMilliseconds() + item.length - 1);
                        var index = this.getCellIndex(endDate);
                        var cells = this.element.querySelectorAll('table.cal-background td');
                        if (index == -1) {
                            index = cells.length - 1;
                        }
                        setHighlightClass(target, <HTMLTableDataCellElement>cells[index], 'create-new');
                        event.stopPropagation();
                    }
                },
                ondragleave: (event: DragEvent) => {
                    event.stopPropagation();
                },
                ondrop: (event: DragEvent) => {
                    var target = viggo.dom.parent(<Element>event.target, 'td');
                    if (target && this.draggedItem) {
                        let item = this.draggedItem;
                        let oldDate = item.start;
                        let newDate = new Date(oldDate);
                        let cellDate = <Date>target.viggoDate;
                        newDate.setFullYear(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
                        if (newDate.getFullYear() != oldDate.getFullYear() || newDate.getMonth() != oldDate.getMonth() || newDate.getDate() != oldDate.getDate()) {
                            this.draggedItem.setStart(newDate);
                            this.draggedItem.save();
                            this.reset(this.originalDate, true);
                            this.repaint();
                        }
                        event.stopPropagation();
                        event.preventDefault();
                    }
                },
                oncontextmenu: (event: MouseEvent) => {
                    let td = (<HTMLElement>event.target).closest('td');
                    if (AbstractCalendar.copiedItems.size && td && this.allowPaste()) {
                        let date = this.getDate(td);
                        if (date) {
                            viggo.contextmenu.addItem({
                                title: __('Paste'),
                                click: () => {
                                    let oldDate = AbstractCalendar.copyDate;
                                    let firstDate = AbstractCalendar.getFirstCopiedDate();
                                    AbstractCalendar.copyDate = firstDate
                                    date!.setHours(firstDate.getHours(), firstDate.getMinutes(), 0, 0);
                                    this.pasteToDates([date!], this.copyData);
                                    AbstractCalendar.copyDate = oldDate;
                                }
                            });
                        }
                    }
                }
            };
            let createBackground = () => {
                let tr = viggo.dom.tag('tr');
                for (let i = 0; i < 7; i++) {
                    let view = this.createView('CalendarBackground', {date: date, currentMonth: this.originalDate.getMonth()});
                    if (!view) {
                        throw new Error('Missing view "CalendarBackground"');
                    }
                    let td = <HTMLTableDataCellElement | null>view.firstChild;
                    if (!td) {
                        throw new Error('<td> must be first in "CalendarBackground view"');
                    }
                    td.viggoDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8, 0, 0, 0);
                    tr.appendChild(td);
                    date.setDate(date.getDate() + 1);
                }
                return viggo.dom.tag('table', tableObject, viggo.dom.tag('tbody', null, tr));            };

            let createTable = () => {
                return viggo.dom.tag('table', { className: 'cal-items' }, viggo.dom.tag('tbody'));
            };

            let createRow = () => {
                let row = viggo.dom.tag('div', { className: 'week-row' });
                row.appendChild(createBackground());
                rows.push(row.appendChild(createTable()));
                return row;
            };

            let div = viggo.dom.tag('div', { className: 'week-rows' });

            let i = this.weekCount;
            while (i--) div.appendChild(createRow());
            this.element.appendChild(div);

            this.rows = rows;

            this.resetPeriods();
        }
        public getCell(date: Date) {
            let days = Math.floor((date.getTime() - this.startDate.getTime()) / (1000 * 60 * 60 * 24));
            if (days >= 0) {
                return <HTMLTableDataCellElement | null>this.element.querySelector(`.week-row:nth-child(${Math.floor(days / 7) + 1}) table.cal-background td:nth-child(${days % 7 + 1})`);
            }
            return null;
        }
        public reset(startDate: Date, keepItems?: boolean) {
            super.reset(startDate);
            if (!keepItems) {
                while (this.items.length) {
                    this.items.pop()!.remove();
                }
            }
            this.loadRows();
        }
        public dragstart(this: HTMLDivElement, event: DragEvent) {
            if (this.viggoItem) {
                let calendar = <MultiweekCalendar>this.viggoItem.calendar;
                calendar.draggedItem = <MultiweekCalendarItem>this.viggoItem;
                event.dataTransfer!.setData("application/x-viggo-calendar-item", this.viggoItem.id.toString());
                event.dataTransfer!.effectAllowed = 'move';
                viggo.hint.block();
            }
        }
        public dragend(this: HTMLDivElement, event: DragEvent) {
            viggo.hint.unblock();
            event.preventDefault();
        }
    }

    export class MonthCalendar extends MultiweekCalendar {
        protected get weekCount() {
            let from = new Date(this.startDate);
            let to = new Date(from);
            if (from.getDate() > 20) {
                to.setDate(to.getDate() + 20);
                to.setDate(1);
            }
            to.setMonth(to.getMonth() + 1);
            to.setDate(0);
            to = super.repositionStartDate(to);
            to.setDate(to.getDate()+7);
            let days = Math.round((to.getTime() - from.getTime()) / (24 * 60 * 60 * 1000));
            return Math.ceil(days / 7);
        }
        public get name() {
            return "Month";
        }
        protected repositionStartDate(startDate: Date) {
            let date = new Date(startDate);
            date.setDate(1);
            date = super.repositionStartDate(date);
            return date;
        }
        public nextPeriod() {
            this.originalDate.setDate(1);
            this.originalDate.setMonth(this.originalDate.getMonth() + 1);
            this.setPeriod(this.originalDate);
        }
        public previousPreiod() {
            this.originalDate.setDate(1);
            this.originalDate.setMonth(this.originalDate.getMonth() - 1);
            this.setPeriod(this.originalDate);
        }
    }

    interface PeriodMonthOptions extends MultiweekCalendarOptions, PeriodCalendarPartOptions {
    }

    interface MultimonthCalendarOptions extends CalendarOptions {
        element: HTMLTableElement;
        readonly?: boolean;
    }

    export class MultimonthCalendar extends AbstractCalendar<MultimonthCalendarItem> {
        public get name() {
            return "Multimonth";
        }
        public element!: HTMLTableElement;
        protected draggedItem?: MultimonthCalendarItem;
        protected tbody!: HTMLTableSectionElement;
        protected readonly!: boolean;
        private readonly maxColumns = 4;
        private _itemRows: number = 1;
        private _itemColumns: number = 2;

        protected itemClass: new (item: CalendarItemDTO) => MultimonthCalendarItem;
        protected get monthCount() {
            let months = (this.periodEnd.getFullYear() - this.periodStart.getFullYear()) * 12 + this.periodEnd.getMonth() - this.periodStart.getMonth() + 1;
            if (months > 13) {
                months = 6;
            }
            return months;
        }
        constructor(options: MultimonthCalendarOptions) {
            super(options);
            let num = this.getNumberSetting('itemRows');
            if (num === null) {
                num = 1;
            }
            this.itemRows = num;
            num = this.getNumberSetting('itemColumns');
            if (num === null) {
                num = 2;
            }
            this.itemColumns = num;
            this.itemClass = viggo.MultimonthCalendarItem;
        }
        protected initialize(options: MultimonthCalendarOptions) {
            super.initialize(options);
            this.periodEnd.setHours(23, 59, 59, 999);
            this.readonly = !!options.readonly;
        }

        public resetPeriods() {
            let parent = this.tbody;
            parent.querySelectorAll('table:not(.cal-items) td:not(.out)').forEach(td => td.classList.remove('offperiod', 'halfperiod'));
            super.resetPeriods();
        }

        protected createInvalidArea(from: Date, to: Date) {
            let date = new Date(from);
            while (date < to) {
                let cell = this.getCell(date);
                if (cell) {
                    cell.classList.add('offperiod');
                } else {
                    break;
                }
                date.setDate(date.getDate() + 1);
                date.setHours(0, 0, 0, this.slipDistance);
            }
            let slip = new Date(from.getTime() - this.slipDistance);
            if (from.getDate() == slip.getDate()) {
                let cell = this.getCell(from);
                if (cell) {
                    cell.classList.remove('offperiod');
                    cell.classList.add('halfperiod');
                }
            }
            slip = new Date(to.getTime() + this.slipDistance);
            if (to.getDate() == slip.getDate()) {
                let cell = this.getCell(to);
                if (cell) {
                    cell.classList.remove('offperiod');
                    cell.classList.add('halfperiod');
                }
            }
        }
        protected repositionStartDate(date: Date) {
            date = new Date(date);
            date.setHours(0, 0, 0, 0);
            date.setDate(1);
            return date;
        }
        public get endDate() {
            let end = new Date(this.startDate);
            end.setMonth(end.getMonth() + this.monthCount);
            return end;
        }
        public get itemRows() {
            return this._itemRows;
        }
        public set itemRows(value: number) {
            if (!isNaN(value)) {
                this._itemRows = value;
                this.element.style.setProperty('--item-rows', value + '');
                this.element.dataset.itemRows = value + '';
                this.setSetting('itemRows', value);
            }
        }
        public get itemColumns() {
            return this._itemColumns;
        }
        public set itemColumns(value: number) {
            if (!isNaN(value)) {
                this._itemColumns = value;
                this.element.style.setProperty('--item-columns', value + '');
                this.element.dataset.itemColumns = value + '';
                this.setSetting('itemColumns', value);
            }
        }
        public repaint() {
            this.tbody.querySelectorAll('span.show-more').forEach(span => span.remove());
            for (let item of this.items) {
                item.update(this.filter(item));
            }
            let max = this.itemRows * this.itemColumns;
            if (max != 0) {
                this.tbody.querySelectorAll('td > div').forEach(node => {
                    if (node.childNodes.length > max + 1) {
                        node.appendChild(viggo.dom.tag('span', {
                            className: 'show-more',
                            onmousedown: (event: MouseEvent) => {
                                event.preventDefault();
                                event.stopPropagation();
                            },
                            onclick: (event: MouseEvent) => {
                                event.preventDefault();
                                event.stopPropagation();
                                let td = viggo.dom.parent(<Element>event.target, 'td');
                                if (td) {
                                    td.classList.toggle('show-more');
                                    if (td.classList.contains('show-more')) {
                                        let removeShow = (ev: MouseEvent) => {
                                            if (ev.buttons & 1) {
                                                document.removeEventListener('mousedown', removeShow, false);
                                                if (td) {
                                                    td.classList.remove('show-more');
                                                }
                                            }
                                        };
                                        document.addEventListener('mousedown', removeShow, false);
                                    }
                                }
                            }
                        }, "+" + (node.childNodes.length - max - 1)));
                    }
                });
            }
        }
        public getCell(date: Date) {
            var month = (date.getFullYear() - this.startDate.getFullYear())*12 + date.getMonth() - this.startDate.getMonth();
            if (month < 0 || month > this.monthCount) {
                return null;
            }
            return this.element.rows[date.getDate()].cells[month];
        }
        public getDate(cell: HTMLTableDataCellElement) {
            let row = <HTMLTableRowElement>cell.parentElement;
            let date = new Date(this.startDate);
            date.setMonth(date.getMonth() + cell.cellIndex);
            let month = date.getMonth();
            date.setDate(row.rowIndex);
            date.setHours(0, 0, 0, 0);
            return month == date.getMonth() ? date : null;
        }
        public dragstart(this: HTMLDivElement, event: DragEvent) {
            if (this.viggoItem) {
                let calendar = <MultimonthCalendar>this.viggoItem.calendar;
                calendar.draggedItem = <MultimonthCalendarItem>this.viggoItem;
                event.dataTransfer!.setData("application/x-viggo-calendar-item", this.viggoItem.id.toString());
                event.dataTransfer!.effectAllowed = 'move';
                viggo.hint.block();
            }
        }
        public dragend(this: HTMLDivElement, event: DragEvent) {
            viggo.hint.unblock();
            event.preventDefault();
        }
        private repaintGrid(grid: Element) {
            grid.querySelectorAll('.x').forEach(x => x.classList.remove('x'));
            for (let y = 0; y < (this.itemRows || 1); y++) {
                for (let x = 0; x < this.itemColumns; x++) {
                    grid.children[y * this.maxColumns + x].classList.add('x');
                }
            }
        }
        private createControls() {
            let controls = this.createView('ViewControls', { rows: this.itemRows, columns: this.itemColumns });
            if (controls) {
                let grid = controls.querySelector('.grid-control');
                let checkbox = <HTMLInputElement|null>controls.querySelector('input.rows-auto');
                let originalRows = this.itemRows || 1;
                if (grid) {
                    grid.addEventListener('click', (event) => {
                        let index = Array.prototype.indexOf.call(grid!.children, event.target);
                        if (index != -1) {
                            this.itemColumns = (index % this.maxColumns) + 1;
                            if (!checkbox || !checkbox.checked) {
                                this.itemRows = Math.floor(index / this.maxColumns) + 1;
                                originalRows = this.itemRows || 1;
                            }
                            this.repaintGrid(grid!);
                            this.repaint();
                        }
                    }, false);
                    this.repaintGrid(grid);
                }
                if (checkbox) {
                    checkbox.addEventListener('change', () => {
                        if (checkbox!.checked) {
                            originalRows = this.itemRows;
                            this.itemRows = 0;
                            this.repaint();
                        } else {
                            this.itemRows = originalRows;
                            this.repaint();
                        }
                    });
                }
                let inputColumns = <HTMLInputElement | null>controls.querySelector('input.max-columns');
                if (inputColumns) {
                    inputColumns.addEventListener('change', () => {
                        this.itemColumns = inputColumns!.valueAsNumber;
                        this.repaint();
                    }, false);
                }
                let inputRows = <HTMLInputElement | null>controls.querySelector('input.max-rows');
                if (inputRows) {
                    inputRows.addEventListener('change', () => {
                        this.itemRows = inputRows!.valueAsNumber;
                        this.repaint();
                    }, false);
                }
            }
            return controls;
        }
        protected createTable() {
            let table: DocumentFragment, caption: HTMLTableCaptionElement, colgroup: HTMLTableColElement, thead: HTMLTableSectionElement, tbody: HTMLTableSectionElement, tr: HTMLTableRowElement;
            table = viggo.dom.fragment(
                caption = viggo.dom.tag('caption'),
                colgroup = viggo.dom.tag('colgroup'),
                thead = viggo.dom.tag('thead'),
                this.tbody = tbody = viggo.dom.tag('tbody', {
                    oncontextmenu: (event: PointerEvent) => {
                        let td = (<HTMLElement>event.target).closest('td');
                        if (td) {
                            let date = <Date>this.getDate(td!);
                            if (date && this.allowPaste()) {
                                viggo.contextmenu.addItem({
                                    title: __('Paste'),
                                    click: () => {
                                        let oldDate = AbstractCalendar.copyDate;
                                        let firstDate = AbstractCalendar.getFirstCopiedDate();
                                        AbstractCalendar.copyDate = firstDate
                                        date.setHours(firstDate.getHours(), firstDate.getMinutes(), 0, 0);
                                        this.pasteToDates([date], this.copyData);
                                        AbstractCalendar.copyDate = oldDate;
                                    }
                                });
                            }
                        }
                    }
                })
            );
            let controls = this.createControls();
            if (controls) {
                caption.appendChild(controls);
            } else {
                caption.remove();
            }
            thead.appendChild(tr = viggo.dom.tag('tr'));
            let d = new Date(this.startDate);
            d.setDate(1);
            let counter = this.monthCount;
            while (counter--) {
                tr.appendChild(viggo.dom.tag('th', null, this.createView('Header', { date: d })));
                let col = viggo.dom.tag('col');
                //if (i == 1) {
                //    col.className = 'days';
                //} else {
                //    d.setMonth(d.getMonth() + 1);
                //}
                d.setMonth(d.getMonth() + 1);
                colgroup.appendChild(col);
            }

            let year = this.startDate.getFullYear();
            let firstMonth = this.startDate.getMonth();

            for (let day = 1; day <= 31; day++) {
                tr = tbody.appendChild(viggo.dom.tag('tr'));
                for (let month = 0; month < this.monthCount; month++) {
                    let d = new Date(year, firstMonth + month, day);
                    let td: HTMLTableDataCellElement | undefined;
                    if (day != d.getDate()) {
                        td = viggo.dom.tag('td', { className: 'out' });
                    } else {
                        let view = this.createView('TableData', { date: d });
                        if (view) {
                            td = <HTMLTableDataCellElement>view.firstChild;
                        }
                    }
                    if (td) {
                        tr.appendChild(td);
                    }
                }
            }
            return table;
        }
        public reset(date: Date) {
            super.reset(date);
            viggo.dom.empty(this.element);
            this.element.appendChild(this.createTable());
            this.resetPeriods();
            if (!this.readonly) {
                let getDate = (cell: HTMLTableDataCellElement) => {
                    return cell ? new Date(this.startDate.getFullYear(), this.startDate.getMonth() + cell.cellIndex, (<HTMLTableRowElement>cell.parentNode).rowIndex, 0, 0, 0, 0) : null;
                };

                let highlightBetween = (start: HTMLTableDataCellElement, end: HTMLTableDataCellElement, func: (cell: HTMLTableDataCellElement) => void) => {
                    let startDate = getDate(start),
                        endDate = getDate(end),
                        temp;

                    if (startDate && endDate) {
                        if (endDate < startDate) {
                            temp = start;
                            start = end;
                            end = temp;
                        }
                        if (end == null) {
                            let d = new Date(this.startDate);
                            d.setMonth(d.getMonth() + this.monthCount);
                            end = this.element.rows[d.daysInMonth()].cells[this.monthCount - 1];
                        }
                        let startColumn = start.cellIndex,
                            endColumn = end.cellIndex,
                            startRow = (<HTMLTableRowElement>start.parentNode).rowIndex,
                            endRow = (<HTMLTableRowElement>end.parentNode).rowIndex;

                        let d = new Date(this.startDate);
                        d.setMonth(d.getMonth() + startColumn);
                        for (let col = startColumn; col <= endColumn; col++) {
                            let maxDays = d.daysInMonth();
                            for (let row = startRow; (row <= endRow && col == endColumn) || (col < endColumn && row <= maxDays); row++) {
                                func(this.element.rows[row].cells[col]);
                            }
                            startRow = 1;
                            d.setMonth(d.getMonth() + 1);
                        }
                    }
                };
                this.tbody.addEventListener('mousedown', (event: MouseEvent) => {
                    if (event.buttons == 1) {
                        event.stopPropagation();
                        let item = viggo.dom.parentClass(<Element>event.target, 'calendar-item');
                        if (item && this.element.contains(item)) {
                            return;
                        } else {
                            event.preventDefault(); // no drag/drop
                        }
                        let mousedownCell = viggo.dom.parent(<Element>event.target, 'td');
                        if (mousedownCell && !mousedownCell.classList.contains('out')) {
                            this.tbody.className = 'creating';
                            let startTime = getDate(mousedownCell);
                            let mouseupCell: HTMLTableDataCellElement | null = mousedownCell;
                            let mouseover = (ev: MouseEvent) => {
                                highlightBetween(mousedownCell!, mouseupCell!, td => td.classList.remove('create-new'));
                                mouseupCell = viggo.dom.parent(<Element>ev.target, 'td');
                                while (mouseupCell && mouseupCell.classList.contains('out')) {
                                    mouseupCell = this.tbody.rows[(<HTMLTableRowElement>mouseupCell.parentNode).rowIndex - 2].cells[mouseupCell.cellIndex];
                                }
                                highlightBetween(mousedownCell!, mouseupCell!, td => td.classList.add('create-new'));
                            };
                            let mouseup = () => {
                                this.tbody.removeEventListener('mouseover', mouseover, false);
                                document.removeEventListener('mouseup', mouseup, false);
                                highlightBetween(mousedownCell!, mouseupCell!, td => td.classList.remove('create-new'));
                                this.tbody.classList.remove('creating');
                                let endTime = getDate(mouseupCell!);
                                if (startTime && endTime) {
                                    if (startTime > endTime) {
                                        let temp = startTime;
                                        startTime = endTime;
                                        endTime = temp;
                                    }
                                    startTime.setHours(8, 0, 0, 0);
                                    endTime.setHours(12, 0, 0, 0);
                                    let item = new this.itemClass({
                                        calendar: this,
                                        start: startTime,
                                        length: endTime.getTime() - startTime.getTime(),
                                        title: "",
                                        id: 0,
                                        elementId: 0,
                                        group: '',
                                        columnIds: [],
                                        copyType: COPY_TYPE_INVALID,
                                        readonly: false,
                                        type: CalendarItemType.Calendar
                                    });
                                    if (this.addItem(item)) {
                                        this.repaint();
                                        item.save();
                                    } else {
                                        item.remove();
                                    }
                                }
                            };
                            this.tbody.addEventListener('mouseover', mouseover, false);
                            document.addEventListener('mouseup', mouseup, false);
                            mouseover(event);
                        }
                    }
                }, false);
                this.tbody.addEventListener('dragover', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                }, false);
                this.tbody.addEventListener('dragenter', (event: DragEvent) => {
                    let target = viggo.dom.parent(<Element>event.target, 'td');
                    if (target) {
                        let item = this.draggedItem;
                        let endDate = getDate(target);
                        if (item && endDate) {
                            endDate.setHours(item.start.getHours(), item.start.getMinutes(), item.start.getSeconds(), item.start.getMilliseconds() + item.length);
                            let endCell = this.getCell(endDate);
                            if (endCell) {
                                highlightBetween(target, endCell, td => td.classList.add('create-new'));
                            }
                        }
                        event.stopPropagation();
                    }
                }, false);
                this.tbody.addEventListener('dragleave', (event: DragEvent) => {
                    var target = viggo.dom.parent(<Element>event.target, 'td');
                    if (target) {
                        var item = this.draggedItem;
                        var endDate = getDate(target);
                        if (item && endDate) {
                            endDate.setHours(item.start.getHours(), item.start.getMinutes(), item.start.getSeconds(), item.start.getMilliseconds() + item.length);
                            let endCell = this.getCell(endDate);
                            if (endCell) {
                                highlightBetween(target, endCell, td => td.classList.remove('create-new'));
                            }
                        }
                        event.stopPropagation();
                    }
                }, false);
                this.tbody.addEventListener('drop', (event: DragEvent) => {
                    var target = viggo.dom.parent(<Element>event.target, 'td');
                    if (target) {
                        this.tbody.classList.remove('creating');
                        var item = this.draggedItem;
                        if (item) {
                            let oldDate = item.start;
                            let newDate = new Date(oldDate);
                            let cellDate = getDate(target);
                            if (cellDate && this.draggedItem) {
                                newDate.setFullYear(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate());
                                if (newDate.getFullYear() != oldDate.getFullYear() || newDate.getMonth() != oldDate.getMonth() || newDate.getDate() != oldDate.getDate()) {
                                    this.draggedItem.setStart(newDate);
                                    this.draggedItem.save();
                                    this.reset(this.startDate);
                                    this.repaint();
                                }
                            }
                        }
                        event.stopPropagation();
                        event.preventDefault();
                    }
                }, false);
            }
        }
        public nextPeriod() {
            this.originalDate.setDate(1);
            this.originalDate.setMonth(this.originalDate.getMonth() + this.monthCount);
            this.setPeriod(this.originalDate);
        }
        public previousPreiod() {
            this.originalDate.setDate(1);
            this.originalDate.setMonth(this.originalDate.getMonth() - this.monthCount);
            this.setPeriod(this.originalDate);
        }
    }

    export class SemesterCalendar extends MultimonthCalendar {
        public get name() {
            return "Semester";
        }
        protected repositionStartDate(date: Date) {
            date = super.repositionStartDate(date);
            date.setMonth(Math.floor(date.getMonth() / 6) * 6);
            return date;
        }
    }

    interface PeriodCalendarOptions extends MultimonthCalendarOptions, PeriodCalendarPartOptions {
    }

    interface HasDateCells {
        getCell(date: Date): HTMLTableDataCellElement | null;
    }

    abstract class PeriodCalendarPart implements HasDateCells {
        public originalDate!: Date;
        public startDate!: Date;
        public abstract makePeriodInvalid(from: Date, to: Date): void;
        public abstract getCell(date: Date): HTMLTableDataCellElement | null;
    }

    let lastCalendar: AbstractCalendar<CalendarItem>|null = null;

    let popState = function (event: PopStateEvent) {
        if (event.state && event.state.owner === 'Calendar') {
            var d = window.location.search.match(/date=(\d{4})-(\d\d)-(\d\d)/);
            if (d && lastCalendar) {
                let date = new Date(parseInt(d[1]), parseInt(d[2]) - 1, parseInt(d[3]), 0, 0, 0, 0);
                lastCalendar.setPeriod(date);
            }
        }
    };

    interface ScheduleDTO {
        Id: number;
        Name: string;
        InfoName: string;
        Framecolor: string;
        Bgcolor: string;
        Textcolor: string;
        Selected: boolean;
        InputName: string;
        ShowtimeOnScreen: number;
        ShowinViggo: number;
    }

    interface CalendarLoadData {
        date: string;
        type: string;
        [index: string]: number[]|Date|string|undefined;
    }

    interface CalendarConstructorDTO {
        Schedules: ScheduleDTO[];
        Links: CalendarLinksDTO;
        MaxItems: number;
        UserRoll: any[];
        Days?: number;
        StartHour?: number;
        EndHour?: number;
        ColumnWidth?: number;
        OrderHeaders: boolean;
        Headers: any[];
        CopyType: number;
        CopyData: number[] | null;
        RowHeader?: boolean;
        ExternalNavigation?: boolean;
        StartDay: number;
        WeekLength: number;
        PeriodStart?: Date;
        PeriodEnd?: Date;
        ValidPeriods?: DatePeriod[];
        InvalidPeriods?: DatePeriod[];
        Sections: CustomRowSection[];
        Templates: { [name: string]: string };
    }

    export function loadCalendar(element: HTMLElement | string | null, date: Date, type: string, options: CalendarConstructorDTO): any {
        if (typeof element == 'string') {
            element = <HTMLElement|null>document.querySelector(element);
        }
        if (!element) {
            throw new Error('Missing calender element. Needs to be HTMLElement or selector string.');
        }
        var calendars = options.Schedules;
        if (!calendars) {
            calendars = [];
        }
        date = new Date(date);
        let calendar: any,
            loadCalendars: CalendarLoadData = {
                date: date.format('yyyy-MM-dd'),
                type: type
            },
            inputDate = <HTMLInputElement|null>document.getElementById('selected-date');

        calendars.forEach(e => {
            if (e.Selected) {
                if (!loadCalendars[e.InputName]) {
                    loadCalendars[e.InputName] = [];
                }
                (<number[]>loadCalendars[e.InputName]).push(e.Id);
            }
        });

        let properties: CalendarOptions = {
            element: element,
            links: options.Links,
            startDate: date,
            templates: options.Templates,
            data: loadCalendars,
            copyType: options.CopyType,
            copyData: options.CopyData,
            periodEnd: options.PeriodEnd || null,
            periodStart: options.PeriodStart || null,
            validPeriods: (options.ValidPeriods||[]).map(x => { return { From: new Date(x.From), To: new Date(x.To) }; }),
            invalidPeriods: (options.InvalidPeriods||[]).map(x => { return { From: new Date(x.From), To: new Date(x.To) }; })
        };

        if (!options.ExternalNavigation) {
            Object.assign(properties, {
                inputElementDate: inputDate,
                clickElementToday: document.getElementById('date-today'),
                clickElementNext: document.getElementById('date-next'),
                clickElementPrevious: document.getElementById('date-prev'),
            });
        }

        switch (type) {
            case 'day':
                calendar = new DayCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element,
                    startDay: date.getDay(),
                    weekLength: 1
                }));
                break;
            case 'workweek':
                calendar = new WorkWeekCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element,
                    startDay: 1,
                    weekLength: 5
                }));
                break;
            case 'week':
                calendar = new WeekCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element,
                    startDay: options.StartDay,
                    weekLength: options.WeekLength
                }));
                break;
            case 'weeks':
            case 'month':
            case 'periodmonth':
                calendar = new MonthCalendar(Object.assign(properties, {
                    element: element,
                    maxItems: options.MaxItems
                }));
                break;
            case 'planning':
                calendar = new CustomHeaderCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element,
                    headers: options.Headers,
                    days: options.Days,
                    columnWidth: options.ColumnWidth,
                    orderHeaders: options.OrderHeaders
                }));
                break;
            case 'customrowdetail':
                calendar = new CustomRowDetailCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element,
                    days: options.Days || 7,
                    sections: options.Sections,
                    startHour: options.StartHour || 0,
                    endHour: options.EndHour || 24
                }));
                break;
            case 'customrowday':
                calendar = new CustomRowDayCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element,
                    days: options.Days || 30,
                    sections: options.Sections
                }));
                break;
            case 'semester':
                calendar = new SemesterCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element
                }));
                break;
            case 'period':
                calendar = new MultimonthCalendar(Object.assign(properties, {
                    element: <HTMLTableElement>element
                }));
                break;
            default:
                throw new Error('Unknown calendar type');
        }
       lastCalendar = calendar;
        
        window.addEventListener('popstate', popState, false);
        return calendar;
    };

    export let weekcalendar = {
        reload: function (element?: HTMLElement|string|null) {
            if (typeof element == 'string') {
                element = <HTMLElement|null>document.querySelector(element);
                if (!element) {
                    throw new Error('Element for calendar reload not found.');
                }
            }
            let calendar = lastCalendar;
            if (element) {
                calendar = (<any>element).viggoCalendar;
            }
            if (calendar) {
                calendar.reload();
            }
        },
        getLatestCalendar: function () {
            return lastCalendar;
        },
        getToolbarRelatedCalendar: function (reference: Element) {
            let result = reference.closest('.toolbar');
            if (result) {
                result = result.nextElementSibling;
            }
            return result ? (<any>result).viggoCalendar : null;
        }
    };
}