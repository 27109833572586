/*
Effects:
Example:
    viggo.effect({
        element: document.getElementById('test'),
        style: 'opacity',
        from: 1,
        to: 0,
        duration: 500,    // default: 1000
        type: 'sine',    // default: 'sine'
        complete: function() {    // default: function(){}
            var a = document.getElementById('test');
            a.parentNode.removeChild(a);
        }
    });

Object-properties:
    element: DOM-element or id
    style: CSS-style property
    from: Initial value
    to: Final value
    duration: Effect-duration in miliseconds,
    type: How the effect glides. Included is: easeinout, root, easein, easeout, linear, sine, flicker, wobble, pulse, spring
    complete: function to run after the effect has finished.
 */

interface EffectOptions {
    element: Element | Element[] | null,
    style: string | EffectStyleCalculator,
    from: number | string,
    to: number | string,
    duration?: number,
    removeStyle?: boolean,
    type?: string | EffectTypeCalculator,
    fps?: number,
    complete?: (this: viggo.effect) => void
}

type EffectTypeCalculator = (a: number, b?: number) => number;
type EffectStyleCalculator = (start: number | string, end: number | string, index: number) => string | void;

module viggo {
    export class effect implements EffectOptions {
        private static browserPrefix: { [index: string]: boolean } = { transform: true };
        private static prefixes = ['webkit', 'moz', 'ms', 'o'];
        public static durationFactor: number = 1;
        private stopped: boolean | number = false;

        element: Element | Element[] | null;
        style: string | EffectStyleCalculator;
        from: number | string;
        to: number | string;
        duration: number = 1000;
        removeStyle: boolean = false;
        type: EffectTypeCalculator = effect.types.linear;
        fps: number = 0;
        complete: () => void = (() => { });

        constructor(object: EffectOptions) {
            Object.assign(this, object);
            this.element = object.element;
            this.style = object.style;
            this.from = object.from;
            this.to = object.to;
            this.duration *= effect.durationFactor;

            let property = typeof object.style == 'string' ? effect.styleToProperty(object.style) : null;
            let start = Date.now();
            if (typeof object.element === 'string') {
                this.element = document.getElementById(object.element);
            }
            if (!this.element) {
                return;
            }
            if (object.type) {
                this.type = typeof object.type == 'string' ? effect.types[object.type] : object.type;
            }
            let callback = typeof object.style == 'function' ? object.style : effect.styles[object.style];
            let lastFrameTimestamp = 0;

            let animate = () => {
                let time = Date.now();
                if (!this.stopped) {
                    if (this.fps > 0) {
                        if (time - lastFrameTimestamp < 1000 / this.fps) {
                            requestAnimationFrame(animate);
                            return;
                        } else {
                            lastFrameTimestamp = time;
                        }
                    }
                    let index = this.duration <= 0 ? 1 : (time - start) / this.duration;
                    if (index >= 1) {
                        index = 1;
                    }
                    let style: string;
                    var f = function (e: HTMLElement) {
                        let prop: any = <string>property;
                        e.style[prop] = style;
                        if (effect.browserPrefix[prop]) {
                            let prefixProperty = (<string>property).replace(/^[a-z]/, a => a.toUpperCase());
                            effect.prefixes.forEach(x => {
                                prop = x + prefixProperty;
                                e.style[prop] = style;
                            });
                        }
                    };
                    if (typeof object.style == 'string') {
                        style = <string>callback(object.from, object.to, this.type(index));
                        if (object.element!.constructor == Array) {
                            (<HTMLElement[]>object.element).forEach(x => f.call(this, x));
                        } else {
                            f.call(this, <HTMLElement>object.element);
                        }
                    } else {
                        callback.call(this, object.from, object.to, this.type(index));
                    }
                    if (index < 1) {
                        requestAnimationFrame(animate);
                    } else {
                        if (object.removeStyle) {
                            style = '';
                            if (object.element!.constructor == Array) {
                                (<HTMLElement[]>object.element).forEach(x => f.call(this, x));
                            } else {
                                f.call(this, <HTMLElement>object.element);
                            }
                        }
                        this.complete.call(this);
                        effect.runningEffects = Math.max(effect.runningEffects - 1, 0);
                    }
                } else {
                    if (this.stopped === 1) {
                        this.complete.call(this);
                    } else if (this.stopped === 2) {
                        setTimeout(() => {
                            this.complete.call(this);
                        }, Math.max(this.duration - (time - start), 1));
                    }
                }
            }
            effect.runningEffects++;
            animate();
        }

        public stop(callComplete: boolean, waitDuration: boolean) {
            if (!this.stopped) {
                effect.runningEffects = Math.max(effect.runningEffects - 1, 0);
            }
            this.stopped = true;
            if (callComplete) {
                this.stopped = 1;
                if (waitDuration) {
                    this.stopped = 2;
                }
            }
        }

        public static isIdle = (function () {
            let lastIdleTime: number = 0;
            return function (delayTime: number = 0) {
                effect.runningEffects = Math.max(effect.runningEffects, 0);
                if (effect.runningEffects) {
                    lastIdleTime = 0;
                } else if (!lastIdleTime) {
                    lastIdleTime = Date.now();
                }
                return !effect.runningEffects && (Date.now() - lastIdleTime) >= delayTime;
            }
        }());

        public static styleCalc(style: string, from: number | string, to: number | string, index: number) {
            return effect.styles[style](from, to, index);
        };

        public static types: { [index: string]: EffectTypeCalculator } = {
            easeinout: (a: number, b: number = 3): number => {
                b = b - b % 2 + 1;
                return a < 0.5 ? Math.pow(a * 2, b) / 2 : Math.pow((a - 1) * 2, b) / 2 + 1;
            },
            root: (a: number, b: number = 2): number => {
                return Math.pow(a, 1 / b);
            },
            elastic: (x: number): number => {
                return (-Math.cos(x * Math.PI - Math.PI) + Math.pow(2 * x, 2) - 1) / 2;
                //return -Math.pow(x-1, 5)+2*x-1; // a bit slower
            },
            easeout: (a: number): number => {
                return Math.sqrt(1 - Math.pow(a - 1, 2));
            },
            easein: (a: number): number => {
                return 1 - Math.sqrt(1 - Math.pow(a, 2));
            },
            linear: (a: number): number => {
                return a;
            },
            sine: (a: number): number => {
                return -Math.cos(a * Math.PI) / 2 + 0.5;
            },
            flicker: (a: number): number => {
                a = -Math.cos(a * Math.PI) / 4 + 0.75 + Math.random() / 4;
                return a > 1 ? 1 : a;
            },
            wobble: (a: number): number => {
                return -Math.cos(a * Math.PI * (9 * a)) / 2 + 0.5;
            },
            pulse: (a: number, b: number = 5): number => {
                return Math.round(a % (1 / b) * b) == 0 ? a * b * 2 - Math.floor(a * b * 2) : 1 - (a * b * 2 - Math.floor(a * b * 2));
            },
            spring: (a: number): number => {
                return 1 - Math.cos(a * 4.5 * Math.PI) * Math.exp(-a * 6);
            }
        }

        private static runningEffects: number = 0;

        public static calc(start: number, end: number, index: number): number {
            return start - index * (start - end);
        }
        public static styleToProperty(style: string): string {
            if (style == 'float') {
                style = 'cssFloat';
            } else {
                style = style.replace(/-([a-z])/, function (a, l) {
                    return l.toUpperCase();
                });
            }
            return style;
        }
        private static styles: { [index: string]: EffectStyleCalculator } = Object.assign({},
            viggo.mapValues(('width height top right bottom left max-width min-width max-height min-height background-position-x background-position-y' +
                'margin margin-top margin-right margin-bottom margin-left ' +
                'padding padding-top padding-right padding-bottom padding-left ' +
                'border-width border-top-width border-right-width border-bottom-width border-left-width ' +
                'font-size letter-spacing word-spacing line-height text-indent vertical-align').split(' '), (start: number | string, end: number | string, index: number) => {
                    if (typeof start == 'string') {
                        return start.replace(/^-?(?:\d+.)?\d+/, (): string => {
                            return effect.calc(parseFloat(<string>start), parseFloat(<string>end), index) + '';
                        });
                    } else {
                        return effect.calc(start, parseFloat(<string>end), index) + 'px';
                    }
                }),
            viggo.mapValues(['box-shadow', 'text-shadow'], (from: string, to: string, index: number) => {
                var f = from.split(' ');
                var t = to.split(' ');
                for (var i = 0; i < f.length; i++) {
                    if (f[i].match(/^\d+/)) {
                        f[i] = <string>effect.styles.top(parseFloat(f[i]), t[i], index);
                    } else {
                        f[i] = <string>effect.styles.color(f[i], t[i], index);
                    }
                }
                return f.join(' ');
            }),
            viggo.mapValues(['transform'], (from: string, to: string, index: number) => {
                var i = 0;
                var fromValues: number[] = [], toValues: number[] = [];
                var regexFloats = /[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g;
                var regexBracket = /\([^\)]+\)/g;
                from = from.replace(regexBracket, function (s) {
                    return s.replace(regexFloats, function (x) {
                        fromValues.push(parseFloat(x));
                        return '{' + (i++) + '}';
                    });
                });
                to.replace(regexBracket, (s: string) => {
                    s.replace(regexFloats, (x: string) => {
                        toValues.push(parseFloat(x));
                        return x;
                    });
                    return s;
                });
                return from.replace(/\{([0-9]+)\}/g, function (all: string, num: number) {
                    return effect.calc(fromValues[num], toValues[num], index) + '';
                });
            }),
            viggo.mapValues(('background-color color outline-color' +
                'border-color border-top-color border-right-color border-bottom-color border-left-color').split(' '), (() => {
                    let colors: any = {
                        maroon: "#800000", red: "#ff0000", orange: "#ffA500", yellow: "#ffff00", olive: "#808000",
                        purple: "#800080", fuchsia: "#ff00ff", white: "#ffffff", lime: "#00ff00", green: "#008000",
                        navy: "#000080", blue: "#0000ff", aqua: "#00ffff", teal: "#008080",
                        black: "#000000", silver: "#c0c0c0", gray: "#808080"
                    };
                    let regex: RegExp[] = [
                        /^rgba\(([0-9]+),\s*([0-9]+),\s*([0-9]+),\s*([01]?(?:\.[0-9]+)?)\)$/,
                        /^rgb\(([0-9]+),\s*([0-9]+)\s*([0-9]+)\)$/,
                        /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i,
                        /^#([0-9a-f])([0-9a-f])([0-9a-f])$/i
                    ];
                    interface Color {
                        r: number,
                        g: number,
                        b: number,
                        a: number
                    }
                    let func = [
                        (all: string, r: string, g: string, b: string, a: string): Color => {
                            return { r: parseInt(r, 10), g: parseInt(g, 10), b: parseInt(b, 10), a: parseFloat(a) };
                        },
                        (all: string, r: string, g: string, b: string): Color => {
                            return { r: parseInt(r, 10), g: parseInt(g, 10), b: parseInt(b, 10), a: 1 };
                        },
                        (all: string, r: string, g: string, b: string): Color => {
                            return { r: parseInt(r, 16), g: parseInt(g, 16), b: parseInt(b, 16), a: 1 };
                        },
                        (all: string, r: string, g: string, b: string): Color => {
                            return { r: parseInt(r, 16) * 16, g: parseInt(g, 16) * 16, b: parseInt(b, 16) * 16, a: 1 };
                        }
                    ];

                    return (start: string, end: string, index: number): string => {
                        if (start in colors)
                            start = <string>colors[start];
                        if (end in colors)
                            end = <string>colors[end];
                        let c1: Color | null = null;
                        let c2: Color | null = null;

                        let i = 0;
                        for (let reg of regex) {
                            if (!c1) {
                                let m = start.match(reg);
                                if (m) {
                                    c1 = func[i].apply<null, string[], Color | null>(null, m);
                                }
                            }
                            if (!c2) {
                                let m = end.match(reg);
                                if (m) {
                                    c2 = func[i].apply<null, string[], Color>(null, m);
                                }
                            }
                            i++;
                        }
                        if (c1 && c2) {
                            return 'rgba(' +
                                Math.round(effect.calc(c1.r, c2.r, index)) + ',' +
                                Math.round(effect.calc(c1.g, c2.g, index)) + ',' +
                                Math.round(effect.calc(c1.b, c2.b, index)) + ',' +
                                effect.calc(c1.a, c2.a, index) +
                                ')';
                        } else {
                            return '';
                        }
                    };
                })()),
            viggo.mapValues(['background-position'], function (start: string, end: string, index: number): string {
                let s = start.split(' ');
                let e = end.split(' ');
                return effect.calc(parseFloat(s[0]), parseFloat(e[0]), index) + 'px ' + effect.calc(parseFloat(s[1]), parseFloat(e[1]), index) + 'px';
            }),
            viggo.mapValues(['opacity'], function (start: number | string, end: number | string, index: number) {
                return effect.calc(parseFloat(<string>start), parseFloat(<string>end), index).toString();
            })
        );

        public static show(element: HTMLElement) {
            return new viggo.effect({
                element: element,
                from: 0,
                to: 1,
                style: 'opacity',
                duration: 400
            });
        };

        public static graph(func: string | EffectTypeCalculator) {
            var canvas = <HTMLCanvasElement>viggo.dom.tag('canvas', {
                width: 100,
                height: 100,
                style: {
                    width: '100px',
                    height: '100px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    margin: '-50px 0 0 -50px',
                    boxShadow: '0 0 10px black',
                    zIndex: 2000
                },
                onclick: function () {
                    this.parentNode.removeChild(this);
                }
            });
            canvas.width = 100;
            canvas.height = 100;
            document.body.appendChild(canvas);
            if (typeof func == 'string') {
                func = effect.types[func];
            }
            let context = canvas.getContext('2d');
            if (context) {
                context.fillStyle = 'white';
                context.fillRect(0, 0, 100, 100);
                context.strokeStyle = 'black';
                context.beginPath();
                context.moveTo(0, 100);
                for (var x = 0; x <= 100; x++) {
                    context.lineTo(x, (1 - func(x / 100)) * 100);
                }
                context.stroke();
            }
            return canvas;
        }
    }

    export function toggleHeight(element: HTMLElement | string | null, hideClass?: string, minHeight: number = 0, hideLinkClass?: string) {
        if (typeof element === 'string') {
            element = <HTMLElement>document.getElementById(element) || document.querySelector(element);
        }

        if (!element) {
            return;
        }

        if (hideClass) {
            var classes = document.getElementsByClassName(hideClass);
            for (var i = 0; i < classes.length; i++) {
                var elm = <HTMLElement>classes[i];
                if (elm != element && elm.style.display != 'none') {
                    (function (ownElement) {
                        ownElement.style.overflow = 'hidden';
                        new viggo.effect({
                            element: ownElement,
                            from: ownElement.offsetHeight,
                            to: 0,
                            style: 'height',
                            type: 'easeinout',
                            duration: 200,
                            complete: function () {
                                ownElement.style.display = 'none';
                            }
                        });
                    }(elm));
                }
            }
        }

        if (hideLinkClass) {
            var classesLink = document.getElementsByClassName(hideLinkClass);
            for (var i = 0; i < classesLink.length; i++) {
                var elm2 = classesLink[i];
                elm2.className += ' hide';
            }
        }


        let visible = minHeight ? minHeight <= element.clientHeight : element.style.display != 'none';
        element.style.display = 'grid';
        element.style.overflow = 'hidden';
        element.style.height = '';
        let height = element.offsetHeight;
        new viggo.effect({
            element: element,
            from: visible ? height : minHeight,
            to: visible ? minHeight : height,
            style: 'height',
            type: 'easeinout',
            duration: 200,
            complete: () => {
                if (visible && element) {
                    if (!minHeight) {
                        (<HTMLElement>element).style.display = 'none';
                    }
                } else if (element) {
                    (<HTMLElement>element).style.height = '';
                    (<HTMLElement>element).style.overflow = '';
                    (<HTMLElement>element).style.display = '';
                }
            }
        });
    }

    /**
     * Expands the element to the highest 
     * @param element
     * @param hideClass
     * @param minHeight
     * @param hideLinkClass
     */
    export function expandHeight(element: HTMLElement | string | null, hideClass?: string, minHeight: number = 0, hideLinkClass?: string, displayType?: string) {
        if (typeof element === 'string') {
            element = <HTMLElement>document.getElementById(element) || document.querySelector(element);
        }

        if (!element) {
            return;
        }

        if (hideClass) {
            var classes = document.getElementsByClassName(hideClass);
            for (var i = 0; i < classes.length; i++) {
                var elm = <HTMLElement>classes[i];
                if (elm != element && elm.style.display != 'none') {
                    (function (ownElement) {
                        ownElement.style.overflow = 'hidden';
                        new viggo.effect({
                            element: ownElement,
                            from: ownElement.offsetHeight,
                            to: 0,
                            style: 'height',
                            type: 'easeinout',
                            duration: 200,
                            complete: function () {
                                ownElement.style.display = 'none';
                            }
                        });
                    }(elm));
                }
            }
        }

        if (hideLinkClass) {
            var classesLink = document.getElementsByClassName(hideLinkClass);
            for (var i = 0; i < classesLink.length; i++) {
                var elm2 = classesLink[i];
                elm2.className += ' hide';
            }
        }


        let visible = minHeight ? minHeight <= element.clientHeight : element.style.display != 'none';

        if (visible) {
            return;
        }

        element.style.display = 'grid';
        element.style.overflow = 'hidden';
        element.style.height = '';
        let height = element.offsetHeight;
        new viggo.effect({
            element: element,
            from: minHeight,
            to: height,
            style: 'height',
            type: 'easeinout',
            duration: 200,
            complete: () => {
                if (!visible && element) {
                    (<HTMLElement>element).style.height = '';
                    (<HTMLElement>element).style.overflow = '';
                    (<HTMLElement>element).style.display = displayType || '';
                }
            }
        });
    }

    export function collapseHeight(element: HTMLElement | string | null, hideClass?: string, minHeight: number = 0, hideLinkClass?: string) {
        if (typeof element === 'string') {
            element = <HTMLElement>document.getElementById(element) || document.querySelector(element);
        }

        if (!element) {
            return;
        }

        if (hideClass) {
            var classes = document.getElementsByClassName(hideClass);
            for (var i = 0; i < classes.length; i++) {
                var elm = <HTMLElement>classes[i];
                if (elm != element && elm.style.display != 'none') {
                    (function (ownElement) {
                        ownElement.style.overflow = 'hidden';
                        new viggo.effect({
                            element: ownElement,
                            from: ownElement.offsetHeight,
                            to: 0,
                            style: 'height',
                            type: 'easeinout',
                            duration: 200,
                            complete: function () {
                                ownElement.style.display = 'none';
                            }
                        });
                    }(elm));
                }
            }
        }

        if (hideLinkClass) {
            var classesLink = document.getElementsByClassName(hideLinkClass);
            for (var i = 0; i < classesLink.length; i++) {
                var elm2 = classesLink[i];
                elm2.className += ' hide';
            }
        }

        let visible = minHeight ? minHeight <= element.clientHeight : element.style.display != 'none';

        if (!visible) {
            return;
        }

        element.style.display = 'grid';
        element.style.overflow = 'hidden';
        element.style.height = '';
        let height = element.offsetHeight;
        new viggo.effect({
            element: element,
            from: height,
            to: minHeight,
            style: 'height',
            type: 'easeinout',
            duration: 200,
            complete: () => {
                if (visible && element) {
                    if (!minHeight) {
                        (<HTMLElement>element).style.display = 'none';
                    }
                }
            }
        });
    }
}
