module viggo {
    interface TemplateData {
        user?: AutoCompleteUser | null;
        data: DOMStringMap;
    }

    type TemplateCallback = (item: TemplateData) => string;

    export class hint {
        private static owner = viggo.dom.tag('div', {id: 'hint'});
        private static blocked = 0;
        private ajaxTimeout = 0;
        private static instance: hint | null;
        private static templates: { [index: string]: TemplateCallback };
        private hintOwner: Node | null = null;
        private hintParent: Node | null = null;
        public hintElement: Element | null = null;
        private beforeHintNode: Node | null = null;
        constructor(element: HTMLElement, customHint?: Element) {
            if (hint.blocked) {
                throw new Error('Hint is blocked');
            }
            if (!hint.blocked && (!hint.instance || element != hint.instance.hintOwner) && !viggo.isMobileDevice) {
                let found: Element | null | undefined = customHint;
                if (!found) {
                    found = element.nextElementSibling;
                    if (found && !found.classList.contains('hint')) {
                        found = null;
                    }
                }
                if (!found) {
                    found = element.querySelector('.hint');
                }
                if (found) {
                    this.initializeHint(found, element);
                } else {
                    this.createHintFromTemplate(element).then(div => {
                        if (div) {
                            this.initializeHint(div, element);
                        }
                    });
                }
            }
        }
        private initializeHint(found: Element, element: HTMLElement) {
            hint.remove();
            hint.instance = this;
            this.hintElement = found;
            let ajaxLink = <HTMLAnchorElement>this.hintElement.querySelector('a.ajax-hint');
            if (ajaxLink) {
                this.ajaxTimeout = window.setTimeout(() => {
                    hint.loadAjaxHint(ajaxLink);
                }, parseInt(ajaxLink.dataset.hintDelay || '500'));
            }
            this.hintOwner = element;
            this.hintParent = this.hintElement.parentNode;
            this.beforeHintNode = this.hintElement.nextSibling;
            clearTimeout(hint.hintTimeout);
            this.show();
            let f = (event: Event) => {
                if (!hint.isChildOf(<Element>event.target, <Element>this.hintOwner) && !hint.isChildOf(<Element>event.target, hint.owner)) {
                    hint.hide();
                    document.removeEventListener('mouseover', f, true);
                    document.removeEventListener('touchstart', f, true);
                }
            };
            document.addEventListener('mouseover', f, true);
            document.addEventListener('touchstart', f, true);
            this.followmouse();
        }
        private async createHintFromTemplate(target: HTMLElement) {
            let template = target.dataset.hint;
            if (template) {
                let data: TemplateData = {
                    data: target.dataset
                };
                if (target.dataset.userId) {
                    data.user = await viggo.autocomplete.getUser(parseInt(target.dataset.userId));
                    if (!data.user) {
                        return null;
                        //data.user = viggo.autocomplete.createFakeUser();
                    }
                }
                return viggo.func.createView(data, hint.templates[template]).querySelector('.hint');
            }
            return null;
        }
        private show() {
            hint.owner.appendChild(<Element>this.hintElement);
            document.body.appendChild(hint.owner);
        }
        private static isChildOf(element: Element, parent: Element) {
            let result = parent != null;
            let block = element.closest('.blockhint');
            if (block && result) {
                result = !parent.contains(block);
            }
            return result && parent.contains(element);
        }
        public static x: number = 0;
        public static y: number = 0;
        public remove() {
            if (this.hintParent) {
                this.hintParent.insertBefore(<Element>this.hintElement, this.beforeHintNode);
            } else if (this.hintElement && this.hintElement.parentNode) {
                this.hintElement.parentNode.removeChild(this.hintElement);
            } else if (this.hintElement) {
                viggo.dom.empty(this.hintElement);
            }
            clearTimeout(this.ajaxTimeout);
            document.removeEventListener('mousemove', hint.followListener, true);
            document.removeEventListener('touchmove', hint.followListener, true);
            document.removeEventListener('dragover', hint.followListener, true);
            hint.owner.remove();
            this.hintElement = this.hintParent = this.beforeHintNode = this.hintOwner = null;
        }
        private followmouse() {
            document.addEventListener('mousemove', hint.followListener, true);
            document.addEventListener('touchmove', hint.followListener, true);
            document.addEventListener('dragover', hint.followListener, true);
            hint.followListener();
        }
        private static followListener() {
            let offsetWidth = hint.owner.offsetWidth,
                offsetHeight = hint.owner.offsetHeight;
            let page = viggo.getTopScrollable();

            let maxX = page.offsetWidth - offsetWidth - 20,
                maxY = page.offsetHeight - offsetHeight - 30;

            if (maxY < hint.y && maxX < hint.x) {
                hint.x -= hint.owner.offsetWidth;
            }

            hint.owner.style.top = Math.min(hint.y, maxY) + 'px';
            hint.owner.style.left = Math.min(hint.x, maxX) + 'px';
        }

        public static loadAjaxHint(a: HTMLAnchorElement, complete?: ()=>void) {
            let parent = a.parentNode;
            new viggo.ajax({
                method: 'get',
                url: a.href,
                convert: 'html',
                complete: function (html) {
                    if (a.parentNode == parent && parent) {
                        parent.replaceChild(html, a);
                        if (complete) {
                            complete();
                        }
                        var ev = new CustomEvent('viggoHintLoad', {
                            bubbles: true,
                            cancelable: false
                        });
                        parent = viggo.dom.parentClass(<Element>parent, 'hint');
                        if (parent) {
                            parent.dispatchEvent(ev);
                        }
                    }
                }
            });
        }

        private static hintTimeout = 0;
        private static hide() {
            clearTimeout(hint.hintTimeout);
            hint.hintTimeout = window.setTimeout(()=>hint.remove(), 1);
        };

        public static remove() {
            if (hint.instance) {
                hint.instance.remove();
            }
        }

        public static getFirstHinted(target: Element|null) {
            let result = target && target.closest ? <HTMLElement|null>target.closest('.hinted,.blockhint') : null;
            return result && result.classList.contains('hinted') ? result : null;
        }

        public static block() {
            hint.blocked++;
            if (hint.instance) {
                hint.remove();
            }
        }
        public static unblock() {
            hint.blocked = Math.max(0, hint.blocked - 1);
        }
        public static get isBlocked() {
            return !!hint.blocked;
        }
        public static rehint(callback: () => void) {
            if (hint.instance) {
                let elm = hint.instance.hintOwner,
                    child = hint.instance.hintElement;
                hint.remove();
                callback();
                new hint(<HTMLElement>elm, <Element>child);
            }
        }
        private static defaultDelay = '200';
        public static getDelay(target: HTMLElement): number {
            var delay = target.dataset.hintDelay || hint.defaultDelay;
            return parseInt(delay);
        }
        public static loadTemplates() {
            this.templates = {};
            fetch('/Scripts/hint-templates.json').then(x => x.json()).then(json => {
                for (let key in json) {
                    this.templates[key] = viggo.func.createTemplate(json[key].html);
                }
            });
        }
    }

    document.addEventListener('statepushed', hint.remove, false);
    viggo.hint.loadTemplates();

    // show hints on mouseover with (.hinted .hint)
    // used when user is scrolling the page and the elementer under the cursor changes.
    let timeout = 0;
    document.addEventListener('touchstart', function (event) {
        clearTimeout(timeout);
        var target = viggo.hint.getFirstHinted(<Element>event.target);
        if (target) {
            try {
                new viggo.hint(target);
            } catch (e) {
                // hint is blocked
            }
        }
    }, false);
    document.addEventListener('mouseover', function (event) {
        clearTimeout(timeout);
        hint.x = event.pageX;
        hint.y = event.pageY;
        var target = viggo.hint.getFirstHinted(<Element>event.target);
        if (target) {
            var delay = viggo.hint.getDelay(<HTMLElement>target);
            var f = function () {
                try {
                    new viggo.hint(<HTMLElement>target);
                } catch (e) {
                    // hint is blocked
                }
            };
            if (delay) {
                timeout = window.setTimeout(f, delay);
            } else {
                f();
            }
        }
    }, false);
    document.addEventListener('touchmove', function (event) {
        hint.x = event.touches[0].pageX;
        hint.y = event.touches[0].pageY;
    }, false);
    document.addEventListener('mousemove', function (event) {
        hint.x = event.clientX;
        hint.y = event.clientY;
    }, true);
    document.addEventListener('dragover', function (event) {
        hint.x = event.clientX;
        hint.y = event.clientY;
    }, true);
    document.addEventListener('wheel', function (event) {
        clearTimeout(timeout);
        var target = viggo.hint.getFirstHinted(<Element>event.target);
        if (target) {
            var delay = viggo.hint.getDelay(<HTMLElement>target);
            var f = function () {
                target = viggo.hint.getFirstHinted(document.elementFromPoint(hint.x, hint.y));
                if (target) {
                    try {
                        new viggo.hint(target);
                    } catch (e) {
                        // hint is blocked
                    }
                }
            };
            if (delay) {
                timeout = window.setTimeout(f, delay);
            } else {
                f();
            }
        }
    }, false);
}