namespace viggo {
    export const version: string = "#build.number#";
    export let isReady: boolean = false;
    export let isMobileDevice: boolean = false; // this is set in the browser, by the server
    export let isMobileApp: boolean = false; // also set in the browser, by the server
    if (!viggo.hasOwnProperty('isMobileView')) {
        Object.defineProperty(viggo, 'isMobileView', {
            get: function () {
                return window.matchMedia("(max-width: 768px)").matches;
            }
        });
    }
    export const isDevelopment = !viggo.version.match(/\d/);
    export var screen: boolean = false;

    export function isFunction(param: any): boolean {
        return typeof param === 'function';
    }
    export function isNumber(param: any): boolean {
        return typeof param === 'number';
    }
    export function isObject(param: any): boolean {
        return typeof param === 'object' && param !== null;
    }
    // return a style for an element
    export function getStyle(element: Element, property: string): string {
        return window.getComputedStyle(element).getPropertyValue(property);
    }
    export function getTopScrollable(): HTMLElement {
        return <HTMLElement | null>(viggo.isMobileView ? document.querySelector('main') : document.getElementById('page')) || document.documentElement!;
    }
    export function getPosition(element: HTMLElement) {
        var top = 0,
            left = 0,
            fixed: HTMLElement | null = null;
        while (element && element.offsetParent) {
            if (!fixed && viggo.getStyle(element, 'position') == 'fixed') {
                fixed = element;
            }
            top += element.offsetTop;
            left += element.offsetLeft;
            element = <HTMLElement>element.offsetParent;
        }
        if (element && !fixed && viggo.getStyle(element, 'position') == 'fixed') {
            fixed = element;
        }
        return {
            top: top,
            left: left,
            fixed: fixed
        };
    }
    export function getScrollTop(element: HTMLElement): number {
        var top = 0;
        while (element && element.nodeType === 1) {
            top += element.scrollTop;
            element = <HTMLElement>element.parentNode;
        }
        return top;
    }
    // merge an all arguments into one object
    // Example: merge({a: 1},{b: 2, c:3},{d: 4}) => {a: 1, b: 2, c:3, d: 4}
    export function merge(...objects: any[]): any {
        var result: any = {};
        viggo.each(objects, function (param: any) {
            viggo.each(param, function (value: any, name: string) {
                if (!(name in result))
                    result[name] = value;
            });
        });
        return result;
    }
    export function extend(obj: any, properties: any): any {
        for (var i in properties) {
            obj[i] = properties[i];
        }
        return obj;
    }
    // Maps all elements of the object to value
    // Example: mapValues(['foo', 'bar'], 'foobar') => {foo: 'foobar', 'bar': 'foobar'}
    export function mapValues(object: any, value: any): any {
        var result: any = {};
        viggo.each(object, function (obj: any) {
            result[obj] = value;
        });
        return result;
    }
    // puts missing properties from defaults into object
    export function mapDefaults(object: any, defaults: any): any {
        viggo.each(defaults, function (data: any, name: string) {
            if (!(name in object)) {
                object[name] = data;
            }
        });
        return object;
    }
    // will intersect two objects using keys and use the values from first object
    export function intersect(a: any, b: any): any {
        var result: any = {};
        viggo.each(a, function (value: any, index: string) {
            if (index in b) {
                result[index] = value;
            }
        });
        return result;
    }
    // executes callback for each element in object.
    // @param object Object or Array
    // @param callback function
    export function each(object: any, callback: (obj: any, index?: any, object?: any) => void, thisObject?: any): void {
        var i: any = 0, obj: any;
        if (object && typeof object.length == 'number') {
            for (; i < object.length;) {
                obj = object[i];
                callback.call(thisObject, obj, i, object);
                if (obj === object[i]) {
                    i++;
                }
            }
        } else if (object) {
            for (i in object) {
                obj = object[i];
                callback.call(thisObject, obj, i, object);
            }
        } else {
            throw new Error("Unknown object in \"each\"");
        }
    }

    type CheckBrowserResultType = 'ok' | 'unknown' | 'old';
    interface CheckBrowserResult {
        result: CheckBrowserResultType;
        browser: string;
        version: string;
        minVersion: string;
    }

    export function checkBrowser(agent: string = navigator.userAgent): CheckBrowserResult {
        type VersionCheck = [RegExp, string];
        let defaultApple: VersionCheck = [/CPU (?:iPhone )?OS (\d+_\d+)/, '13_6'];
        let versions: {[index: string]: VersionCheck} = {
            Firefox: [/Firefox\/(\d+\.\d+)/, '82.0'],
            Chrome: [/Chrome\/(\d+\.\d+)/, '86.0'],
            GSA: defaultApple, // this is the Google Search App. The version number is iOS version.
            CriOS: defaultApple, // this is the Chrome Browser App for iPhone. For rendering and JS it still uses Safari.
            Safari: [/Version\/(\d+\.\d+)/, '14.0'], // Safari comes last, as i sometimes registeres as other versions
            AppleWebKit: defaultApple
        };
        let found: string | null = null;
        for (let name in versions) {
            if (agent.indexOf(name) != -1) {
                found = name;
                break;
            }
        }
        let getIntVersion = (version: string) => {
            return version.split(/\D/).map((value) => parseInt(value)).reduce((previousValue, currentValue, index, array) => previousValue + currentValue << (8*(array.length - index-1)), 0);
        }
        let result: CheckBrowserResultType;
        let foundVersion = 'unknown';
        let minVersion = 'unknown';
        if (found) {
            let version = versions[found];
            minVersion = version[1];
            let browserVersion = agent.match(version[0]);
            if (browserVersion) {
                foundVersion = browserVersion[1];
                if (getIntVersion(foundVersion) < getIntVersion(minVersion)) { // too old
                    result = 'old';
                } else {
                    result = 'ok';
                }
            } else {
                result = 'unknown'
            }
        } else {
            result = 'unknown';
        }
        return {
            result: result,
            browser: found || 'Unknown',
            version: foundVersion,
            minVersion: minVersion
        };
    }

    export const freezeScroll = (function () {
        interface ElementObject {
            element: HTMLElement,
            position: any,
            distanceTop: number,
            width: number
        }
        var elements: Array<ElementObject> = [];
        var eventHandler = function (event: Event) {
            var target = event.target;
            for (var i = elements.length - 1; i >= 0; i--) {
                var element = elements[i].element;
                if (document.body.contains(element)) {
                    if ((<Node>target).contains(element)) {
                        var offset = elements[i].position.top - elements[i].distanceTop;
                        if (viggo.getScrollTop(<HTMLElement>element.parentNode) >= offset) {
                            if (element.style.position != 'fixed') {
                                element.style.top = elements[i].distanceTop + 'px';
                                element.style.left = elements[i].position.left + 'px';
                                element.style.position = 'fixed';
                                element.style.width = elements[i].width + 'px';
                                element.classList.add('frozen');
                            }
                        } else if (element.style.position == 'fixed') {
                            element.style.position = '';
                            element.style.top = '';
                            element.style.left = '';
                            element.style.width = '';
                            element.classList.remove('frozen');
                        }
                    }
                } else {
                    elements.splice(i, 1);
                }
            }
        };
        var inCollection = function (element: HTMLElement) {
            var found = false;
            for (var i = 0; i < elements.length && !found; i++) {
                found = elements[i].element == element;
            }
            return found;
        };
        window.addEventListener('scroll', eventHandler, true);
        return function (element: HTMLElement, distanceTop: number): void {
            var position = viggo.getPosition(element);
            if (!inCollection(element)) {
                elements.push({
                    element: element,
                    position: position,
                    distanceTop: distanceTop,
                    width: element.clientWidth
                });
            }
        };
    }())
    // calls a function when the dom is ready
    export function ready(func: () => void) {
        if (viggo.isReady) {
            func.call(document);
        } else {
            var f = function () {
                viggo.isReady = true;
                document.removeEventListener("DOMContentLoaded", f, false);
                func.call(document);
            };
            document.addEventListener("DOMContentLoaded", f, false);
        }
    }
    export function isTablet() {
        return window.matchMedia("(min-width: 768px)").matches;
    }
    interface RegisteredUser {
        username: string;
        Fullname: string;
        image?: string;
    }
    export function registerUser(user: RegisteredUser) {
        localStorage.setItem('registeredUser', JSON.stringify(user));
    }
    export function getRegisteredUser() {
        let json = localStorage.getItem('registeredUser');
        if (json) {
            let user = <RegisteredUser>JSON.parse(json);
            return user;
        }
        return null;
    }
    export function isPwa() {
        return window.matchMedia("(display-mode: standalone)").matches;
    }
    export function wait(ms: number) {
        return new Promise(function (resolve) {
            setTimeout(resolve, ms);
        });
    }
    export function loadScript(src: string, complete: (event: Event) => void) {
        var s = document.createElement('script');
        s.addEventListener('load', complete, false);
        s.setAttribute('type', 'text/javascript');
        s.setAttribute('src', src);
        document.getElementsByTagName('head')[0].appendChild(s);
    }
    export function isLoggedIn(): boolean {
        return !!document.getElementById('nav');
    }
    export function mapQueryString(url: string): ObjectOfString {
        if (url) {
            var pos = url.indexOf('?');
            if (pos !== -1) {
                url = url.substring(pos + 1);
            }
        }
        var result: ObjectOfString = {};
        if (url) {
            let hashSplit = url.split('#');
            url = hashSplit[0];
            if (hashSplit[1]) {
                result['#'] = hashSplit[1];
            }
            let urlSplit = url.split('&');
            for (var i = 0; i < urlSplit.length; i++) {
                var s = urlSplit[i].split('=');
                result[s[0]] = s[1] ? s[1] : '';
            }
        }
        return result;
    }
    export function getQueryString(name: string, url = window.location.search) {
        var query = mapQueryString(url);
        return query[name] || null;
    }
    export function appendQueryString(url: string, originalUrl: string = window.location.search): string {
        let urlSplit = url.split('?');
        let hash: string[] | string;
        if (!urlSplit[1]) {
            hash = urlSplit[0].split('#');
            urlSplit[0] = hash[0];
            urlSplit.push('');
        } else {
            hash = urlSplit[1].split('#');
            urlSplit[1] = hash[0];
        }
        let search: any = viggo.mapQueryString(originalUrl);
        let querystring = viggo.mapQueryString(urlSplit[1]);
        Object.assign(search, querystring);
        querystring = search;
        search = [];
        for (var i in querystring) {
            search.push(i + '=' + querystring[i]);
        }
        urlSplit[1] = search.join('&');
        if (!urlSplit[1]) {
            urlSplit.pop();
        }
        hash = hash[1] ? '#' + hash[1] : '';
        return urlSplit.join('?') + hash;
    }
    // returns a new XMLHttpRequest
    export function xhr(): XMLHttpRequest {
        return new XMLHttpRequest();
    }
    export const animationCount = (function () {
        let counter = 0,
            map = new Map<Element, number>(),
            remove = (elm: Element) => {
                let timeout = map.get(elm);
                clearTimeout(timeout);
                map.delete(elm);
                return !!timeout;
            },
            start = (ev: Event) => {
                let elm = <Element>ev.target;
                if (elm.nodeType == 1) { // weird Edge bug.
                    let isTransition = ev.type == 'transitionrun';
                    if (isTransition || viggo.getStyle(elm, 'animation-iteration-count') != "infinite") {
                        if (!remove(elm)) {
                            counter++;
                        }
                        map.set(elm, window.setTimeout(() => {
                            map.delete(elm);
                            counter--;
                        }, 500));
                    }
                }
            },
            end = (ev: Event) => {
                if (remove(<Element>ev.target)) {
                    counter--;
                }
            };
        document.addEventListener('animationstart', start, true);
        document.addEventListener('animationcancel', end, true);
        document.addEventListener('animationend', end, true);

        document.addEventListener('transitionrun', start, true);
        document.addEventListener('transitioncancel', end, true);
        document.addEventListener('transitionend', end, true);
        return function animationCount() {
            return counter;
        };
    }());

    let frame = 0;
    let isAnimationExecuted = false;

    export function prepareAnimationFrame() {
        if (!frame) {
            isAnimationExecuted = false;
            frame = requestAnimationFrame(function () {
                isAnimationExecuted = true;
                frame = 0;
            });
        }
        return frame;
    }

    export function isAnimationFrameExecuted() {
        if (isAnimationExecuted) {
            frame = 0;
            isAnimationExecuted = false;
            return true;
        }
        return false;
    }

    export const disableCssAnimations = (function () {
        let disabled = false;
        return function disableCssAnimations() {
            if (!disabled) {
                let head = document.getElementsByTagName('head')[0];
                if (head) {
                    disabled = true;
                    let style = viggo.dom.tag('style');
                    style.setAttribute('type', 'text/css');
                    style.appendChild(viggo.dom.text('*{animation-duration: 0s !important; animation-delay: 0s !important; transition-duration: 0s !important; transition-delay: 0s !important;}'));
                    head.appendChild(style);
                }
                viggo.effect.durationFactor = 0;
            }
        }
    }());

    export function isIdle(delay: number = 0) {
        return viggo.animationCount() == 0
            && viggo.ajax.isIdle(delay)
            && (!viggo.modal || viggo.modal.isIdle(delay))
            && viggo.effect.isIdle(delay)
            && viggo.autocomplete.isIdle()
            && (<any>viggo).load // this is to prevent screen-typescript from being stupid
            && (<any>viggo).load.isIdle();
    }

    export function invalidate(listOrQuery?: Element[] | Element | string | null, parameters: DOMStringMap = {}) {
        if (typeof listOrQuery == 'string') {
            listOrQuery = Array.from(document.querySelectorAll(listOrQuery));
        }
        if (listOrQuery instanceof Element) {
            listOrQuery = [listOrQuery];
        }
        if (listOrQuery && listOrQuery.length) {
            let promises: Promise<void>[] = [];
            for (let i = 0; i < listOrQuery.length; i++) {
                let element = listOrQuery[i];
                let event = new CustomEvent('invalidate', { bubbles: true, cancelable: true });
                if (element.dispatchEvent(event)) {
                    let result = viggo.load.loadParent(element, 'invalidate', parameters);
                    if (result) {
                        promises.push(result);
                    }
                }
            }
            if (promises.length) {
                return Promise.all(promises);
            } else {
                viggo.history.reload();
            }
        } else {
            viggo.history.reload();
        }
    }
}

namespace viggo.func {
    export function searchableSelect(elm: HTMLSelectElement): void {
        if (elm && elm.options.length) {
            let ul: HTMLUListElement, selected: HTMLElement, filter = function (value: string) {
                value = value.toLowerCase();
                let foundLi = null;
                for (let i = 0; i < ul.childNodes.length; i++) {
                    let li = <HTMLLIElement>ul.childNodes[i], child = li.firstChild;
                    if (child && child.nodeValue && child.nodeValue.toLowerCase().indexOf(value) == -1) {
                        li.classList.add('hidden');
                    } else {
                        foundLi = foundLi || li;
                        li.classList.remove('hidden');
                    }
                }
                if (value && foundLi && foundLi.nodeType == 1) {
                    select(parseInt(foundLi.dataset.optionArrayIndex || '0'));
                }
            };
            let select = (index: number) => {
                index = Math.max(0, Math.min(elm.options.length - 1, index));
                elm.selectedIndex = index;
                let ev = new Event('change', { bubbles: true, cancelable: false });
                elm.dispatchEvent(ev);
            };
            let input: HTMLInputElement;
            let unfocus = function (event?: Event) {
                if (!event || !div.contains(<Node>event.target)) {
                    document.removeEventListener('click', unfocus, true);
                    document.removeEventListener('focus', unfocus, true);
                    div.classList.remove('focused');
                    input.value = '';
                    filter('');
                }
            };
            let focus = function (event: Event) {
                document.addEventListener('click', unfocus, true);
                document.addEventListener('focus', unfocus, true);
                if (div.classList.contains('focused') && div != event.target && (!div.contains(<Node>event.target) || (<HTMLElement>event.target).tagName != 'INPUT')) {
                    div.classList.remove('focused');
                    unfocus();
                } else {
                    div.classList.add('focused');
                }
            }
            let firstNode = elm.selectedOptions[0] || elm.options[0];
            var div = viggo.dom.tag('div', {
                className: 'input-select' + (elm.disabled ? ' disabled' : ''),
                onclick: focus
            },
                selected = viggo.dom.tag('span', { className: firstNode.className, dataset: Object.assign(firstNode.dataset, {}) }, firstNode.dataset.icon ? viggo.dom.tag('i', { className: firstNode.dataset.icon }) : null, firstNode.dataset.icon ? ' ' : null, viggo.func.createViewFromString(firstNode.innerHTML)),
                viggo.dom.tag('div', { className: '' }, input = <HTMLInputElement>viggo.dom.tag('input', {
                    placeholder: __('Search'),
                    type: 'text',
                    tabIndex: elm.tabIndex,
                    onfocus: focus,
                    onkeydown: function (event: KeyboardEvent) {
                        let index = elm.selectedIndex;
                        switch (event.key) {
                            case 'ArrowDown':
                                do {
                                    index++;
                                } while (index < elm.options.length && (<HTMLElement>ul.childNodes[index]).classList.contains('hidden'));
                                if (index >= elm.options.length || (<HTMLElement>ul.childNodes[index]).classList.contains('hidden')) {
                                    index = 0;
                                    while (index < elm.selectedIndex && (<HTMLElement>ul.childNodes[index]).classList.contains('hidden')) {
                                        index++;
                                    }
                                }
                                select(index);
                                break;
                            case 'ArrowUp':
                                do {
                                    index--
                                } while (index >= 0 && (<HTMLElement>ul.childNodes[index]).classList.contains('hidden'));
                                if (index < 0 || (<HTMLElement>ul.childNodes[index]).classList.contains('hidden')) {
                                    index = ul.childNodes.length;
                                    do {
                                        index--;
                                    } while (index > elm.selectedIndex && (<HTMLElement>ul.childNodes[index]).classList.contains('hidden'));
                                }
                                select(index);
                                break;
                            case 'Enter':
                                event.preventDefault();
                            case 'Tab':
                                unfocus();
                                break;
                        }
                    },
                    onkeyup: function (event: KeyboardEvent) {
                        switch (event.key) {
                            case 'ArrowUp':
                            case 'ArrowDown':
                            case 'ArrowLeft':
                            case 'ArrowRight':
                            case 'Shift':
                            case 'Control':
                            case 'Alt':
                            case 'Meta':
                                break;
                            case 'Enter':
                                select(elm.selectedIndex);
                            case 'Escape':
                                unfocus();
                                event.preventDefault();
                                event.stopPropagation();
                                break;
                            default:
                                filter(this.value);
                                break;
                        }
                    }
                })),
                ul = <HTMLUListElement>viggo.dom.tag('ul', {
                    onclick: function (event: MouseEvent) {
                        let li = (<HTMLElement>event.target).closest('li');
                        event.stopPropagation();
                        event.preventDefault();
                        if (li && !li.classList.contains('disabled')) {
                            select(parseInt(<string>li.dataset.optionArrayIndex));
                            unfocus();
                        }
                    }
                })
            );
            for (let i = 0; i < elm.options.length; i++) {
                let option = elm.options[i];
                let li = viggo.dom.tag('li', {
                    className: [option.selected ? 'selected' : '', option.className, option.disabled ? 'disabled' : ''].filter(x => x).join(' '),
                    dataset: Object.assign(option.dataset, { optionArrayIndex: i })
                }, option.dataset.icon ? (option.dataset.icon.indexOf('/') != -1 ? viggo.dom.tag('img', { src: option.dataset.icon }) : viggo.dom.tag('i', { className: option.dataset.icon })) : null, option.dataset.icon ? ' ' : null, viggo.func.createViewFromString(option.innerHTML));
                if (option.dataset.level) {
                    li.style.setProperty('--level', option.dataset.level);
                }
                ul.appendChild(li);
            }
            (<HTMLElement>elm.parentNode).insertBefore(div, elm);
            elm.addEventListener('change', (event: Event) => { // there might be changes outside of this contexts
                ul.querySelectorAll('li.selected').forEach(e => e.classList.remove('selected'));
                let index = elm.selectedIndex;
                if (index == -1) {
                    index = elm.selectedIndex = 0;
                }
                let option = elm.options[index];
                if (option) {
                    viggo.dom.empty(selected);
                    if (option.dataset.icon) {
                        selected.appendChild(viggo.dom.tag('i', { className: option.dataset.icon }));
                        selected.appendChild(viggo.dom.text(' '));
                    }
                    if (option.firstChild) {
                        selected.appendChild(option.firstChild.cloneNode(true));
                    }
                    (<HTMLLIElement>ul.childNodes[index]).classList.add('selected');
                    ul.scrollTop = (<HTMLLIElement>ul.childNodes[index]).offsetTop;
                }
            }, true);
            if (elm.disabled) {
                div.querySelector('div')!.remove();
                ul.remove();
                div.removeEventListener('click', focus, false);
            }
        }
    }

    export function createSearchableSelects(parent?: any): void {
        if (!parent) {
            parent = document;
        }
        let selects = parent.querySelectorAll('select');
        for (let i = 0; i < selects.length; i++) {
            let select = selects[i];
            let prev = select.previousSibling;
            if ((!prev || prev.nodeType != 1 || !prev.classList.contains('input-select')) && !select.classList.contains('simple') && select.options.length) {
                viggo.func.searchableSelect(select);
            }
        }
        return parent;
    }
    export function removeSearchableSelects(parent?: any): void {
        if (!parent) {
            parent = document;
        }
        let selects = parent.getElementsByTagName('select');
        for (let i = selects.length - 1; i >= 0; i--) {
            let select = selects[i];
            let prev = select.previousSibling;
            if (prev && prev.nodeType == 1 && prev.classList.contains('input-select')) {
                prev.remove();
            }
        }
    }
    export function updateSearchableSelects(parent?: any): void {
        viggo.func.removeSearchableSelects(parent);
        viggo.func.createSearchableSelects(parent);
    }
    export function createViewFromString(str: string) {
        let template = <HTMLTemplateElement>viggo.dom.tag('template');
        template.innerHTML = str;
        return template.content;
    }

    export function installPwa(parentElement: HTMLElement, button: HTMLElement, callback?: (success: boolean) => void, beforeinstall?: () => void) {
        let deferredPrompt: any = null;

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            deferredPrompt = e;
            if (parentElement) {
                parentElement.style.display = '';
            }
            if (beforeinstall) {
                beforeinstall();
            }
        });

        if (button) {
            button.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();

                if (deferredPrompt) {
                    deferredPrompt.prompt();
                    deferredPrompt.userChoice.then(function (choiceResult: any) {
                        if (callback) {
                            callback(choiceResult.outcome == 'accepted');
                        }
                        deferredPrompt = null;
                    });
                }
            }, false);
        }
    }
}

// Converts from degrees to radians.
Math.radians = function (degrees: number) {
    return degrees * Math.PI / 180;
}

// Converts from radians to degrees.
Math.degrees = function (radians: number) {
    return radians * 180 / Math.PI;
}

Array.prototype.unique = function () {
    return this.filter(function (value, index, self) {
        return self.indexOf(value) === index;
    });
}

Date.prototype.stdTimezoneOffset = function () {
    var jan = new Date(this.getFullYear(), 0, 1);
    var jul = new Date(this.getFullYear(), 6, 1);
    return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Date.prototype.dst = function (): boolean {
    return this.getTimezoneOffset() < this.stdTimezoneOffset();
};

Date.prototype.isDstSwitchDay = function (): boolean {
    var copy = new Date(this.getTime()),
        noon = new Date(this.getTime());
    copy.setHours(0, 0, 0, 0);
    noon.setHours(12, 0, 0, 0);
    return copy.getTimezoneOffset() != noon.getTimezoneOffset();
};

Date.fromViggoTime = function (input: (string | HTMLInputElement)) {
    if (typeof input == 'object') {
        input = input.value;
    }
    var m = input.match(/^(?:([0-3]\d)-([01]\d)-(\d{4}))?\s*(?:([012]\d):([0-5]\d)(?::([0-5]\d))?)?$/);
    var d: (Date | null) = null;
    if (m) {
        if (m[1] && m[4]) {
            d = new Date(parseInt(m[3]), parseInt(m[2]) - 1, parseInt(m[1]), parseInt(m[4]), parseInt(m[5]), parseInt(m[6]));
        } else if (m[1]) {
            d = new Date(parseInt(m[3]), parseInt(m[2]) - 1, parseInt(m[1]));
        } else if (m[4]) {
            d = new Date();
            d.setHours(parseInt(m[4]), parseInt(m[5]), parseInt(m[6]));
        }
    }
    return d;
};

Date.prototype.isToday = function (d = new Date()) {
    return this.getFullYear() == d.getFullYear() && this.getMonth() == d.getMonth() && this.getDate() == d.getDate();
};

Date.prototype.getWeekOfYear = function () {
    var d = new Date(this.getTime());
    d.setHours(0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getFullYear(), weekNo];
};

// Returns the monday of a given week in a year
Date.getDateOfWeek = function (week, year) {
    var simple = new Date(year, 0, 1 + (week - 1) * 7);
    var dow = simple.getDay();
    var weekStart = simple;
    if (dow <= 4)
        weekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        weekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return weekStart;
};

Date.prototype.daysInMonth = function () {
    let d = new Date(this);
    d.setDate(1);
    d.setMonth(d.getMonth() + 1);
    d.setDate(0);
    return d.getDate();
};

// implementation of .NET DateTime.ToString function
Date.prototype.format = function (format: string) {
    let date = this;
    let repeat = (s: string, num: number) => {
        return num && num > 0 ? new Array(num + 1).join(s) : '';
    };
    let zero = (a: number, count: number) => {
        let b = a.toString(10);
        return repeat('0', count - b.length) + b;
    };
    let nothingIfZero = (s: string) => {
        return s.match(/^0+$/) ? '' : s;
    };
    let inString: any = false;
    return format.replace(/([dfFghHKmMstyz])(?:\1*)|["']/g, (all: string): string => {
        if (inString == all) {
            inString = false;
            return '';
        }
        if (inString) {
            return all;
        }
        let t;
        switch (all) {
            case "'": inString = "'"; return '';
            case '"': inString = '"'; return '';
            case 'd': return date.getDate().toString();
            case 'dd': return zero(date.getDate(), 2);
            case 'ddd': return __('Sunday Monday Tuesday Wednesday Thursday Friday Saturday'.split(' ')[date.getDay()]).substring(0, 3);
            case 'dddd': return __('Sunday Monday Tuesday Wednesday Thursday Friday Saturday'.split(' ')[date.getDay()]);
            case 'f': return zero(date.getMilliseconds(), 3).substring(0, 1);
            case 'ff': return zero(date.getMilliseconds(), 3).substring(0, 2);
            case 'fff': return zero(date.getMilliseconds(), 3);
            case 'ffff': return zero(date.getMilliseconds(), 3) + '0';
            case 'fffff': return zero(date.getMilliseconds(), 3) + '00';
            case 'ffffff': return zero(date.getMilliseconds(), 3) + '000';
            case 'fffffff': return zero(date.getMilliseconds(), 3) + '0000';
            case 'F': return nothingIfZero(zero(date.getMilliseconds(), 3).substring(0, 1));
            case 'FF': return nothingIfZero(zero(date.getMilliseconds(), 3).substring(0, 2));
            case 'FFF': return nothingIfZero(zero(date.getMilliseconds(), 3));
            case 'FFFF': return nothingIfZero(zero(date.getMilliseconds(), 3) + '0');
            case 'FFFFF': return nothingIfZero(zero(date.getMilliseconds(), 3) + '00');
            case 'FFFFFF': return nothingIfZero(zero(date.getMilliseconds(), 3) + '000');
            case 'FFFFFFF': return nothingIfZero(zero(date.getMilliseconds(), 3) + '0000');
            case 'g': case 'gg': throw "Unimplemented";
            case 'h': return (date.getHours() % 12) + "";
            case 'hh': return zero(date.getHours() % 12, 2);
            case 'H': return date.getHours() + "";
            case 'HH': return zero(date.getHours(), 2);
            case 'K': throw "Unimplemented";
            case 'm': return date.getMinutes() + "";
            case 'mm': return zero(date.getMinutes(), 2);
            case 'M': return (date.getMonth() + 1) + "";
            case 'MM': return zero(date.getMonth() + 1, 2);
            case 'MMM': return __('January February March April May June July August September October November December'.split(' ')[date.getMonth()]).substring(0, 3);
            case 'MMMM': return __('January February March April May June July August September October November December'.split(' ')[date.getMonth()]);
            case 's': return date.getSeconds() + "";
            case 'ss': return zero(date.getSeconds(), 2);
            case 't': return date.getHours() < 12 ? 'A' : 'P';
            case 'tt': return date.getHours() < 12 ? 'AM' : 'PM';
            case 'y': return (date.getFullYear() % 100) + "";
            case 'yy': return zero(date.getFullYear() % 100, 2);
            case 'yyy': return zero(date.getFullYear(), 3);
            case 'yyyy': return date.getFullYear() + "";
            case 'yyyyy': return zero(date.getFullYear(), 5);
            case 'z': t = date.getTimezoneOffset(); return (t < 0 ? '+' : '-') + Math.floor(Math.abs(t) / 60);
            case 'zz': t = date.getTimezoneOffset(); return (t < 0 ? '+' : '-') + zero(Math.floor(Math.abs(t) / 60), 2);
            case 'zzz': t = date.getTimezoneOffset(); return (t < 0 ? '+' : '-') + zero(Math.floor(Math.abs(t) / 60), 2) + ':' + zero(Math.abs(t) % 60, 2);
            default: return all;
        }
    });
};

Date.prototype.formatISO8601 = function() {
    return this.format('dd-MM-yyyyTHH:mm:sszzz');
}

Date.prototype.toJSON = function toJSON() {
    return this.format('yyyy-MM-ddTHH:mm:sszzz');
};

// foldout navigation
viggo.ready(function () {
    viggo.isReady = true;
    var nav = document.getElementById('nav2');
    if (nav) {
        nav.addEventListener('click', function (event) {
            var a = viggo.dom.parent(<HTMLElement>event.target, 'a');
            if (a) {
                var ul = (<HTMLElement>a.parentNode).getElementsByTagName('ul')[0];
                if (ul) {
                    var height = ul.clientHeight;
                    var open = (<HTMLElement>ul.parentNode).className.match(/(?:^|\s)selected(?:\s|$)/);
                    new viggo.effect({
                        element: ul,
                        from: open ? 0 : height,
                        to: open ? ul.clientHeight : 0,
                        duration: 800,
                        style: 'height',
                        complete: function () {
                            ul.style.height = '';
                        }
                    });
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        }, false);
    }
});

document.addEventListener('change', function (event) {
    var target = <HTMLInputElement>event.target;
    var m;
    if (target.tagName == 'INPUT' && target.type == 'checkbox' && (m = target.className.match(/checkradio(\d+)/))) {
        var parentIndex = parseInt(m[1], 10);
        if (parentIndex) {
            var parentNode: Node | null = <Node>target;
            while (parentIndex-- && parentNode) {
                parentNode = parentNode.parentNode;
            }
            if (parentNode) {
                var checkboxes = <NodeListOf<HTMLInputElement>>(<HTMLElement>parentNode).querySelectorAll('[name="' + target.name + '"]');
                for (var i = 0; i < checkboxes.length; i++) {
                    checkboxes[i].checked = checkboxes[i] == target;
                }
            }
        }
    }
}, true);

window.addEventListener('keydown', function (event: KeyboardEvent) {
    var type: string[] = [];
    if (event.ctrlKey) {
        type.push('Ctrl');
    }
    if (event.shiftKey) {
        type.push('Shift');
    }
    if (event.altKey) {
        type.push('Alt');
    }
    if (event.metaKey) {
        type.push('Meta');
    }
    if (!type.length) {
        type.push('Shortcut');
    }
    let key = event.key;
    if (key && key != '"') {
        if (key.length == 1) {
            key = key.toUpperCase();
        }
        type.push(key);
    }
    let target = <HTMLElement | null>event.target;
    let shortcut = type.join('+');
    var ev = new CustomEvent(shortcut, { bubbles: true, cancelable: true });
    if (target && ((target.tagName != 'TEXTAREA' && target.tagName != 'INPUT') || (target.classList && target.classList.contains('allow-shortcuts')))) {
        if (!target.dispatchEvent(ev)) {
            event.preventDefault();
        } else if ((<any>target).querySelectorAll) {
            let modal = viggo.modal ? viggo.modal.getLatestModalElement() : null;
            if (modal) {
                target = modal;
            }
            let selector = `[data-shortcuts~="${shortcut}"]`;
            if (target!.matches(selector)) {
                target!.dispatchEvent(ev);
            } else {
                target!.querySelectorAll(selector).forEach(x => {
                    if (x.tagName == 'A' || x.tagName == 'BUTTON') {
                        (<HTMLAnchorElement>x).click();
                        if ((<HTMLElement>x).dataset.shortcutMode == 'prevent') {
                            event.preventDefault();
                        }
                    }
                    if (!x.dispatchEvent(ev)) {
                        event.preventDefault();
                    }
                });
            }
        }
    }
}, false);

viggo.ready(function () {
    var f = function (event?: Event) {
        let target = event ? event.target : undefined;
        viggo.func.createSearchableSelects(target);
    };

    var valueFunction = function (event: Event) {
        var target = <HTMLInputElement>event.target;
        let value: string | null = null;
        if (target.tagName == 'TEXTAREA' || (target.tagName == 'INPUT' && target.type in { text: 1, email: 1, phone: 1, password: 1 })) {
            value = target.value;
        } else if (target.contentEditable == "true") {
            value = target.innerHTML;
            let element = target;
            if (value.match(/^<p><br\s*\/?><\/p>$/im)) {
                value = '';
            }
            target = <HTMLInputElement>target.closest('.form-group');
            if (event.type == 'focus' && target) {
                let blur = function () {
                    element.removeEventListener('blur', blur, false);
                    target.classList.remove('focused');
                };
                target.classList.add('focused');
                element.addEventListener('blur', blur, false);
            }
        }
        if (value !== null && target) {
            if (value == "") {
                target.classList.add('empty-value');
                target.classList.remove('value-specified');
            } else {
                target.classList.remove('empty-value');
                target.classList.add('value-specified');
            }
        }
    };

    return function () {
        window.addEventListener('statepushed', f, false);
        if (viggo.modal) {
            viggo.modal.addEventListener('show', f);
            viggo.modal.addEventListener('load', f);
        }
        f();
        if (viggo.isMobileDevice) {
            document.documentElement!.classList.add('mobile');
        }
        window.addEventListener('keyup', valueFunction, true);
        window.addEventListener('focus', valueFunction, true);
    };
}());

(function (html) {
    let aniScroll: number, aniResize: number;
    let styleObject = html.style;
    html.addEventListener('mousemove', function (event) {
        window.cancelAnimationFrame(aniScroll);
        var x = event.x,
            y = event.y;
        aniScroll = window.requestAnimationFrame(function () {
            //styleObject.setProperty('--mouse-x', x+'px');
            //styleObject.setProperty('--mouse-y', y+'px');
        });
    }, false);
    var lastScroll = 0;
    window.addEventListener('scroll', function (event) {
        let target = <HTMLElement>event.target;
        if (target.tagName == 'MAIN') {
            var scroll = (<HTMLElement>event.target).scrollTop;
            var down = scroll > lastScroll && scroll > 0;
            window.cancelAnimationFrame(aniScroll);
            aniScroll = window.requestAnimationFrame(function () {
                styleObject.setProperty('--scroll-down', down ? "1" : "0");
            });
            lastScroll = scroll;
        }
        if (target.nodeType == 1 && target.classList.contains('scroll-var') || target.querySelector('.scroll-var')) {
            window.requestAnimationFrame(function () {
                if (target && target.style && target.style.setProperty) {
                    target.style.setProperty('--scroll', target.scrollTop + '');
                }
            });
        }
    }, true);
    let resize = function () {
        let page = <HTMLElement | null>document.querySelector(viggo.isMobileView ? 'main' : '#page');
        if (page) {
            styleObject.setProperty('--browser-width', page.offsetWidth + '');
            styleObject.setProperty('--browser-height', page.offsetHeight + '');
        }
    };
    window.addEventListener('resize', function (event) {
        cancelAnimationFrame(aniResize);
        aniResize = requestAnimationFrame(resize);
    });
    styleObject.setProperty('--scroll-down', "0");
    viggo.ready(resize);
}(document.documentElement!));


if ("serviceWorker" in navigator && !window.location.pathname.match(/^screen/i)) {
    let version = 2;
    let url = `/viggo.serviceworker.js?version=${version}`;
    if (navigator.serviceWorker.controller && navigator.serviceWorker.controller.scriptURL.indexOf(url) != -1) {
        console.log("Service worker found, no need to register");
    } else {
        // Register the service worker
        navigator.serviceWorker
            .register(url, {
                scope: "/"
            })
            .then(function (reg) {
                console.log("Service worker has been registered for scope: " + reg.scope);
            })
            .catch(function (err) {
                console.error(err);
            });

        const localStorageName = 'pwa-notifications';
        let storedTimestamp = localStorage.getItem(localStorageName);
        let date = new Date(0);
        if (storedTimestamp) {
            date = new Date(parseInt(storedTimestamp));
        }

        if ((true || viggo.isPwa()) && !sessionStorage.getItem(localStorageName) && (Date.now() - date.getTime() > 7*24*60*60*1000)) {
            navigator.serviceWorker.getRegistration()
                .then(reg => reg ? reg.pushManager.getSubscription() : null)
                .then(subscription => {
                    sessionStorage.setItem(localStorageName, "push");
                    if (!subscription && viggo.isLoggedIn()) {
                        let notice = viggo.notice(viggo.NoticeType.notice, '♫ ' + __('Want to receive notifications?'), 15000);
                        let text = notice.querySelector('p');
                        if (text) {
                            text.style.cursor = 'pointer';
                            text.addEventListener('click', function () {
                                viggo.modal.showAjax('/Shared/ProfileSettings/ActivateNotificationsForDevice');
                            }, false);
                        }
                        let close = notice.querySelector('.close');
                        if (close) {
                            close.addEventListener('click', function () {
                                localStorage.setItem(localStorageName, Date.now().toString());
                            }, false);
                        }
                    }
                }).catch(e => {
                    sessionStorage.setItem('pwa-notifications', "push");
                });
        }
    }
}