module viggo.classes {
    export class eventListener {
        private eventListeners: any = null;
        private static eventListeners: any = null;
        public addEventListener(event: string, listener: (data: any) => void) {
            if (!this.eventListeners) {
                this.eventListeners = {};
            }
            if (!this.eventListeners[event]) {
                this.eventListeners[event] = [];
            }
            var i = this.eventListeners[event].indexOf(listener);
            if (i === -1) {
                this.eventListeners[event].push(listener);
            }
        }
        public removeEventListener(event: string, listener: (data: any) => void) {
            if (this.eventListeners && this.eventListeners[event]) {
                var i = this.eventListeners[event].indexOf(listener);
                if (i !== -1) {
                    this.eventListeners[event].splice(i, 1);
                }
            }
        }
        public dispatchEvent(event: string, data?: any, singleListener?: (data: any) => void) {
            var i = 0, prevented = false;
            if (data === null || typeof data == 'undefined') {
                data = {};
            }
            data.preventDefault = function () {
                prevented = true;
            };
            if (singleListener) {
                singleListener.call(this, data);
            } else {
                while (this.eventListeners && this.eventListeners[event] && i < this.eventListeners[event].length) {
                    let listener = this.eventListeners[event][i];
                    listener.call(this, data);
                    if (listener === this.eventListeners[event][i]) {
                        i++;
                    }
                }
            }
            delete data.preventDefault;
            return !prevented;
        }

        public static addEventListener = eventListener.prototype.addEventListener;
        public static removeEventListener = eventListener.prototype.removeEventListener;
        public static dispatchEvent = eventListener.prototype.dispatchEvent;
    }
}