module viggo {
    export class progress {
        protected constructor() {
            this.domElement = viggo.dom.tag('div', { id: 'progress-load-page' });
            document.body.appendChild(this.domElement);
        }
        private endTime?: Date;
        private domElement?: HTMLElement;
        private effect: any;
        private remove(): void {
            if (this.domElement) {
                document.body.removeChild(this.domElement);
            }
            delete this.domElement;
            delete progress.instance;
        }
        protected static instance?: progress;
        protected static getInstance() {
            if (!this.instance) {
                this.instance = new progress();
            }
            return this.instance;
        }
        public static setProgress(zeroToOne: number|null) {
            this.getInstance().setProgress(zeroToOne);
        }
        public static setProgressTime(ms: number) {
            let p = this.getInstance();
            p.endTime = new Date(Date.now() + ms);
        }
        private setProgress(zeroToOne: number|null): void {
            if (this.effect) {
                this.effect.stop();
            }
            if (zeroToOne === null) {
                this.remove();
            } else if (this.domElement) {
                let from = parseFloat(this.domElement.style.getPropertyValue('--progress') || '');
                if (isNaN(from)) {
                    from = 0;
                }
                if (this.endTime && zeroToOne != 1) {
                    let x = zeroToOne;
                    x = -0.125 * x * x + 0.275 * x + 0.85;
                    zeroToOne = x;
                }
                this.effect = new viggo.effect({
                    from: from,
                    to: Math.pow(zeroToOne, 0.5),
                    duration: this.endTime && zeroToOne != 1 ? Math.max(this.endTime.getTime() - Date.now(), 200) : 200/Math.pow(zeroToOne, 1.5),
                    type: 'easeinout',
                    element: this.domElement,
                    style: (start: number|string, end: number|string, index: number) => {
                        if (this.domElement) {
                            this.domElement.style.setProperty('--progress', viggo.effect.calc(<number>start, <number>end, index)+'');
                        }
                    },
                    complete: () => {
                        if (zeroToOne == 1 && this.domElement) {
                            this.effect = new viggo.effect({
                                from: 1,
                                to: 0,
                                duration: 200,
                                type: 'sine',
                                element: this.domElement,
                                style: 'opacity',
                                complete: () => {
                                    this.remove();
                                }
                            });
                        }
                    }
                });
            }
        }
    }
}