module viggo {
    export const informationTypes: {[index: string]: number} = { // top = 0, center = 1, bottom = 2
        top: 0,
        birthday: 2,
        guard: 2,
        presence: 1,
        absence: 1
    };

    const itemStyles = ['backgroundColor', 'borderColor', 'color'];

    export enum CalendarItemTemporaryMode {
        pasting,
        creating,
        moving
    }

    interface CalendarItemLinks {
        dblclick?: string;
    }

    interface CalendarItemSubstitute {
        Fullname?: string;
        img?: string;
        initials?: string;
    }

    enum CalendarContextType {
        AjaxModal = 0,
        Execute = 1,
        ExecuteDelete = 2
    }

    enum UrlReplacement {
        None = 0,
        DataAttributes = 1,
        Callback = 2
    }

    interface CalendarContextItem {
        title: string;
        url: string;
        type: CalendarContextType;
        iconClassName?: string;
        invalidate?: boolean;
        replacement?: UrlReplacement;
    }

    export interface CalendarItemDTO {
        calendar?: CalendarInterface,
        id: number,
        group: string,
        title: string,
        elementname?: string,
        description?: string,
        hint?: string,
        premises?: string,
        premisesConflict?: boolean,
        hintClass?: string|null,
        start: Date,
        length: number,
        readonly: boolean,
        classes?: string,
        type: CalendarItemType,
        copyType: number,
        elementType?: number,
        elementId: number,
        links?: CalendarItemLinks,
        contextmenu?: CalendarContextItem[],
        data?: ObjectOfAny,
        columnIds?: number[],
        backgroundColor?: string,
        borderColor?: string,
        color?: string,
        temporaryMode?: CalendarItemTemporaryMode,
        style?: { [index: string]: string },
        initials?: string,
        selectedsubstitute?: CalendarItemSubstitute,
        [index: string]: any
    }

    export abstract class CalendarItem {
        public calendar: CalendarInterface;
        public id: number;
        public group: string;
        public title: string;
        public elementname: string;
        public description: string;
        public hint: string;
        public premises: string;
        public premisesConflict: boolean;
        public hintClass: string|null;
        public start: Date;
        public length: number;
        public readonly: boolean;
        public classes?: string;
        public type: CalendarItemType;
        public links: CalendarItemLinks;
        public data: ObjectOfAny;
        public columnIds?: number[];
        public elements: HTMLDivElement[];
        public indent: number;
        public itemCount: number;
        public elementType: number;
        public elementId: number;
        public initials?: string;
        protected contextmenu: CalendarContextItem[];
        public selectedsubstitute?: CalendarItemSubstitute;
        private overlappingItemsOnDay: CalendarItem[];
        private filter: FilterResult;
        public style: ObjectOfString;
        private copied: boolean;
        public alsoBookedBy: CalendarItem[];
        private selected: boolean = false;
        private changed: boolean = false;
        public temporaryMode?: CalendarItemTemporaryMode;
        public copyType: number;
        public overlapMode: number | boolean = false;
        public get end(): Date {
            let end = new Date(this.start.getTime() + this.length);
            let dif = this.start.getTimezoneOffset() - end.getTimezoneOffset();
            if (dif) {
                end.setMinutes(end.getMinutes() - dif);
            }
            return end;
        }
        public set end(value: Date) {
            let length = value.getTime() - this.start.getTime() - (value.getTimezoneOffset() - this.start.getTimezoneOffset()) * 60 * 1000;
            if (length <= 0) {
                throw new Error("End value must be later than start value.");
            }
            this.length = length;
        }
        public get typeString() {
            return CalendarItemType[this.type] || 'Calendar';
        }
        public get typeStringLowerCase() {
            return this.typeString.toLowerCase();
        }
        constructor(object: CalendarItemDTO) {
            if (!object.calendar) {
                throw new Error("Missing calender in CalenderItems");
            }
            this.calendar = object.calendar;
            this.id = object.id;
            this.group = object.group + '';
            this.title = object.title;
            this.elementname = object.elementname || '';
            this.description = object.description || '';
            this.filter = FilterResult.show;
            this.hint = object.hint || '';
            this.premises = object.premises || '';
            this.premisesConflict = object.premisesConflict || false;
            this.hintClass = object.hintClass || null;
            this.start = object.start;
            this.length = object.length;
            this.readonly = object.readonly;
            this.classes = object.classes;
            this.type = object.type;
            this.initials = object.initials || '';
            this.selectedsubstitute = object.selectedsubstitute || {};
            this.elementType = typeof object.elementType == 'undefined' ? -1 : object.elementType;
            this.elementId = object.elementId;
            this.links = object.links || {};
            this.data = object.data || {};
            this.columnIds = object.columnIds;
            this.elements = [];
            this.indent = 0;
            this.itemCount = 1;
            this.copyType = object.copyType;
            this.overlappingItemsOnDay = [];
            this.contextmenu = object.contextmenu || [];
            this.temporaryMode = object.temporaryMode;
            this.style = {};
            itemStyles.forEach((property: string) => {
                if (property in object && object[property]) {
                    this.style[property.replace('C', '-c')] = object[property];
                }
            });
            if (object.style) {
                for (let property in object.style) {
                    let lowercase = property.replace(/[A-Z]/, (a) => '-' + a.toLowerCase());
                    this.style[lowercase] = object.style[property];
                }
            }
            if (this.style['background-color'] && !this.style['color']) {
                this.style['color'] = this.getColorFromBackground(this.style['background-color']);
            }
            if (this.temporaryMode == CalendarItemTemporaryMode.creating) {
                this.style['pointer-events'] = 'none';
            }
            this.copied = AbstractCalendar.copiedItems.size > 0 && AbstractCalendar.copiedItems.has(<number>this.id);
            this.alsoBookedBy = [];
        }

        private getColorFromBackground(color: string) {
            const colors: number[] = [];
            color = this.colorNameToHex(color);
            for (let i = 1; i < color.length; i += 2) {
                colors.push(parseInt(color.substring(i, i + 2), 16) / 255);
            }
            const lightness = (Math.max(...colors) + Math.min(...colors)) / 2;

            return lightness < 0.45 ? 'white' : 'black';
        }

        private static colors: ObjectOfString = {
            "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
            "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
            "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
            "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
            "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
            "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
            "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
            "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
            "honeydew": "#f0fff0", "hotpink": "#ff69b4",
            "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
            "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
            "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
            "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
            "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
            "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
            "navajowhite": "#ffdead", "navy": "#000080",
            "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
            "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
            "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
            "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
            "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
            "violet": "#ee82ee",
            "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5",
            "yellow": "#ffff00", "yellowgreen": "#9acd32"
        };

        private colorNameToHex(color: string) {
            return CalendarItem.colors[color.toLowerCase()] || color;
        }
        public set pointerEvents(value: boolean) {
            if (value) {
                delete this.style['pointer-events'];
            } else {
                this.style['pointer-events'] = 'none';
            }
        }

        public get pointerEvents() {
            return this.style['pointer-events'] != 'none';
        }

        public createUrlFromItem(urlTemplate: string) {
            return viggo.func.createTemplate(urlTemplate)(this);
        }

        public createUrlFromElement(urlTemplate: string, element: Element) {
            return urlTemplate.replace(/\[([^\]]+)\]/, (all: string, attr: string) => {
                let parent = element.closest(all);
                return parent ? parent.getAttribute(attr) || '' : '';
            });
        }

        public createUrl(urlTemplate: string, element?: Element | null) {
            urlTemplate = this.createUrlFromItem(urlTemplate);
            if (element) {
                urlTemplate = this.createUrlFromElement(urlTemplate, element);
            }
            return urlTemplate;
        }

        public get isInPeriod() {
            return this.calendar.isInPeriod(this);
        }

        public setStart(start: Date) {
            if (!this.readonly) {
                if (start.getTime() != this.start.getTime()) {
                    this.start = start;
                    this.changed = true;
                }
            }
        }
        public setLength(length: number) {
            if (!this.readonly) {
                length = Math.max(length, AbstractCalendar.timeDetail);
                if (length != this.length) {
                    this.length = length;
                    this.changed = true;
                }
            }
        }
        public setGroup(group: string) {
            if (!this.readonly) {
                this.changed = this.changed || this.group != group;
                this.group = group;
            }
        }
        public getStyles() {
            let result: string[] = [];
            for (let property in this.style) {
                result.push(property + ": " + this.style[property]);
            }
            return result.join('; ');
        }
        public isInView() {
            return this.isBetween(this.calendar.startDate, this.calendar.endDate);
        }
        public save(complete?: (item: CalendarItem) => void) {
            let topElement = this.elements[this.elements.length - 1];
            if (this.id === 0 && !this.calendar.links.Execute) {
                let popup: HTMLElement;
                let close = (event: Event|false) => {
                    if (event && event.type == 'mousedown' && viggo.dom.parentId(<Element>event.target, 'events-create-popup')) {
                        return;
                    }
                    viggo.modal.removeEventListener('close', close);
                    document.removeEventListener('mousedown', close, true);
                    if (event !== false) {
                        this.remove();
                    }
                    if (popup) {
                        popup.remove();
                    }
                };
                if (!this.elements.length) {
                    // somehow we got something that shouldn't be saved.
                    return;
                }
                let pos = viggo.getPosition(topElement);
                pos.top -= viggo.getScrollTop(topElement);
                popup = viggo.dom.tag('div', {
                    id: 'events-create-popup',
                    style: {
                        left: pos.left + 'px',
                        top: pos.top + topElement.offsetHeight + 'px'
                    },
                    onmousedown: (event: Event) => {
                        event.stopPropagation();
                    }
                },
                    viggo.dom.tag('span', null, this.start.format('dd MMM HH:mm')),
                    viggo.dom.tag('span', null, (new Date(this.start.getTime() + this.length)).format('dd MMM HH:mm')));

                for (let add of this.calendar.links.Add) {
                    popup.appendChild(viggo.dom.tag('button', {
                        onclick: (event: Event) => {
                            close(false);
                            event.stopPropagation();
                            event.preventDefault();
                            viggo.modal.addEventListener('close', close);
                            let url = this.createUrl(add.Url, topElement);
                            let prefix = url.indexOf('?') == -1 ? '?' : '&';
                            url = `${url}${prefix}date=${encodeURIComponent(this.start.format('yyyy-MM-dd HH:mm:ss'))}&enddate=${encodeURIComponent(this.end.format('yyyy-MM-dd HH:mm:ss'))}`;
                            viggo.modal.showAjax(url, () => {
                                this.changed = false;
                                let input = <HTMLInputElement | null>document.getElementById('calendar_guard');
                                if (input && input.viggoPicker) {
                                    var d = new Date(this.start);
                                    d.setHours(0, 0, 0, 0);
                                    (<viggo.DatePickerInput>input.viggoPicker).selectedList = [d];
                                    (<viggo.DatePickerInput>input.viggoPicker).setDate(d);
                                }

                                let form = <HTMLFormElement | null>document.getElementById('calendarForm');
                                if (form) {
                                    let s: { [index: string]: string } = {
                                        startdate: 'dd-MM-yyyy',
                                        starttime: 'HH:mm',
                                        enddate: 'dd-MM-yyyy',
                                        endtime: 'HH:mm'
                                    };
                                    for (let i in s) {
                                        let item = <HTMLInputElement | null>form.elements.namedItem(i);
                                        if (item) {
                                            let date: Date = i.indexOf('end') === 0 ? this.end : this.start;
                                            item.value = date.format(s[i]);
                                        }
                                    }
                                }
                            });
                        }
                    }, add.Title));
                }

                let page = document.getElementById('page');
                if (page) {
                    page.appendChild(popup);
                    let rect = page.getBoundingClientRect();
                    popup.style.top = Math.min(pos.top + topElement.offsetHeight, rect.bottom - popup.offsetHeight - 32) + 'px';
                    popup.style.left = Math.min(pos.left, rect.right - popup.offsetWidth - 32) + 'px';
                }
                document.addEventListener('mousedown', close, true);
            } else if (this.id === 0 && this.calendar.links.Execute) {
                let data = Object.assign({}, this.data);
                data.start = this.start.format('dd-MM-yyyy HH:mm');
                data.end = this.end.format('dd-MM-yyyy HH:mm');

                new viggo.ajax({
                    method: 'post',
                    url: this.createUrl(this.calendar.links.Execute, topElement),
                    data: data,
                    convert: 'javascript',
                    complete: () => {
                        if (complete) {
                            complete(this);
                        }
                    }
                    //complete: function(data) {
                    //    if (data !== true) {
                    //        alert(__("Data not saved. Please reload the page."));
                    //    }
                    //}
                });
            } else if (this.changed && this.calendar.links.Move) {
                new viggo.ajax({
                    method: 'post',
                    url: this.createUrl(this.calendar.links.Move, topElement),
                    data: {
                        id: this.id,
                        start: this.start.format('dd-MM-yyyy HH:mm'),
                        end: (new Date(this.start.getTime() + this.length)).format('dd-MM-yyyy HH:mm')
                    },
                    convert: 'javascript',
                    complete: () => {
                        if (complete) {
                            complete(this);
                        }
                    }
                });
                this.changed = false;
            }
        }
        public removeAllElements() {
            while (this.elements.length) {
                var e = this.elements.pop();
                if (e) {
                    e.remove();
                }
            }
        }
        public remove(repaintLater?: boolean) {
            var removing = !!this.elements.length;
            this.removeAllElements();
            this.calendar.removeItem(this, repaintLater);
            return removing;
        }
        public abstract update(filter: FilterResult): void;
        private showItemHint(event: Event) {
            if (this.id) {
                let content: HTMLElement|null = null;
                let hint = this.calendar.createView('ItemHint', this);
                if (hint) {
                    event.stopPropagation();
                    try {
                        new viggo.hint(this.elements[0], <Element>hint.firstChild);
                    } catch (e) {
                        // hint is blocked
                    }
                }
            }
        }
        public addClassName(className: string) {
            for (var i = 0; i < this.elements.length; i++) {
                var e = this.elements[i];
                e.classList.add(className);
            }
        }
        public removeClassName(className: string) {
            for (var i = 0; i < this.elements.length; i++) {
                var e = this.elements[i];
                e.classList.remove(className);
            }
        }
        public highlightElements() {
            this.addClassName('hover');
            let elements: HTMLDivElement[] = [];
            this.alsoBookedBy.forEach(item => {
                if (item.overlapMode === true) {
                    elements = elements.concat(item.elements);
                }
            });
            new viggo.effect({
                element: elements,
                from: 'scale(1)',
                to: 'scale(1.05)',
                duration: 600,
                removeStyle: true,
                type: function (x) {
                    return viggo.effect.types.pulse(x, 3);
                },
                style: 'transform'
            });
        }
        public lowlightElements() {
            this.removeClassName('hover');
        }
        public select() {
            this.addClassName('selected');
            this.selected = true;
        }
        public deselect() {
            this.removeClassName('selected');
            this.selected = false;
        }
        public copyCut() {
            this.addClassName('cut');
            this.copied = true;
        }
        public clearCopyCut() {
            this.removeClassName('cut');
            this.copied = false;
        }
        public createElement(parent: Element, filter: FilterResult): HTMLDivElement {
            this.filter = filter;
            let element: DocumentFragment | HTMLDivElement | null = this.calendar.createView('Item', this);
            if (!element) {
                throw new Error('Missing template "Item" in calendar.');
            }
            element = <HTMLDivElement | null>element.firstChild;
            if (!element) {
                throw new Error('First element of template "Item" must be <div>');
            }
            switch (filter) {
                case FilterResult.hide:
                    return element;
                case FilterResult.show:
                    break;
                case FilterResult.dim:
                    break;
            }

            element.viggoItem = this;
            element.addEventListener('contextmenu', (event: Event) => {
                if (this.calendar.selectedItems.indexOf(this) === -1) {
                    this.calendar.clearSelection();
                    this.calendar.toggleSelect(this);
                }
                        
                if (this.calendar.selectedItems.length > 1) {
                    viggo.contextmenu.addItem({
                        title: __('Delete'),
                        className: 'calendar_delete ajaxDeleteExecute',
                        click: () => {
                            this.calendar.deleteSelected();
                        },
                        icon: 'flaticon-delete'
                    });
                } else {
                    for (let context of this.contextmenu) {
                        let url = context.url;
                        switch (context.replacement) {
                            case UrlReplacement.Callback:
                                url = this.createUrlFromItem(url);
                                break;
                            case UrlReplacement.DataAttributes:
                                url = this.createUrlFromElement(url, <HTMLElement>element);
                                break;
                        }
                        let item: ContextMenuItem = {
                            title: context.title,
                            icon: context.iconClassName
                        };

                        switch (context.type) {
                            case CalendarContextType.AjaxModal:
                                item.click = () => {
                                    let modal = viggo.modal.showAjax(url);
                                    if (context.invalidate) {
                                        modal.addEventListener('close', () => {
                                            viggo.invalidate(<HTMLElement>element);
                                        });
                                    }
                                };
                                break;
                            case CalendarContextType.Execute:
                            case CalendarContextType.ExecuteDelete:
                            default:
                                item.click = () => {
                                    let splitUrl = url.split('#');
                                    if (splitUrl[1]) {
                                        let parent = document.getElementById(splitUrl[1]);
                                        if (parent) {
                                            parent.classList.add('loading-spinner');
                                        }
                                    }
                                    new viggo.ajax({
                                        method: context.type == CalendarContextType.ExecuteDelete ? 'delete' : 'get',
                                        url: splitUrl[0],
                                        convert: true,
                                        complete: (result: any) => {
                                            if (splitUrl[1] && result && result.nodeType == 11) {
                                                let parent = document.getElementById(splitUrl[1]);
                                                if (parent) {
                                                    parent.classList.remove('loading-spinner');
                                                    viggo.dom.empty(parent);
                                                    parent.appendChild(result);
                                                }
                                            }
                                            if (context.invalidate) {
                                                viggo.invalidate(<HTMLElement>element);
                                            }
                                        }
                                    });
                                };
                                break;
                        }
                        viggo.contextmenu.addItem(item);
                    }
                }
                if (!this.readonly && this.calendar.links.Copy && this.copyType) {
                    viggo.contextmenu.addLine();
                    viggo.contextmenu.addItem({
                        title: __('Copy'),
                        className: 'calendar_copy',
                        icon: 'o-flaticon-copy',
                        click: () => {
                            this.calendar.copy();
                        }
                    });
                    viggo.contextmenu.addItem({
                        title: __('Cut'),
                        className: 'calendar_cut',
                        icon: 'o-flaticon-cut',
                        click: () => {
                            this.calendar.cut();
                        }
                    });
                }
            });

            element.addEventListener('mouseup', (event: MouseEvent) => {
                if (event.button === 0) {
                    if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
                        this.calendar.clearSelection();
                    }
                    this.calendar.toggleSelect(this);
                }
            }, false);
                    
            element.addEventListener('mouseover', (event: MouseEvent) => {
                this.showItemHint(event);
                this.highlightElements();
                event.stopPropagation();
            }, false);

            element.addEventListener('mouseout', (event: MouseEvent) => {
                this.lowlightElements();
                event.stopPropagation();
            }, false);

            element.addEventListener('dblclick', (event: MouseEvent) => {
                if (!viggo.isMobileDevice && this.links.dblclick) {
                    viggo.modal.showAjax(this.links.dblclick);
                }
            }, false);

            element.addEventListener('click', (event: MouseEvent) => {
                if (viggo.isMobileDevice && this.links.dblclick && !(viggo.contextmenu && viggo.contextmenu.isVisible())) {
                    viggo.modal.showAjax(this.links.dblclick);
                }
            }, false);
            parent.appendChild(element);
            this.elements.push(element);

            return element;
        }
        public checkDoubleBooking(item: CalendarItem) {
            let counter = 0;
            let overlaps = this.overlaps(item);
            if (overlaps && this.premises && this.premises == item.premises) {
                if (overlaps === true) {
                    this.overlapMode = item.overlapMode = true;
                } else {
                    if (!this.overlapMode)
                        this.overlapMode = overlaps;
                    if (!item.overlapMode) {
                        item.overlapMode = overlaps;
                    }
                }
                if (this.alsoBookedBy.indexOf(item) == -1) {
                    this.alsoBookedBy.push(item);
                    item.alsoBookedBy.push(this);
                    counter++;
                }
            }
            return counter;
        }
        public overlaps(item: CalendarItem): boolean | number {
            let t1 = this.typeStringLowerCase,
                t2 = item.typeStringLowerCase;
            let result = this.length > 0 && item.length > 0 && // this buggy software should not allow arrangements with a length of zero
                (t1 == t2 || !t1 || !t2 || (viggo.informationTypes[t1] == 1 && viggo.informationTypes[t2] == 1)) &&
                this.isBetween(item.start, item.end);
            return result;
        }
        public isBetween(start: Date, end: Date) {
            let x1 = this.start.getTime(),
                x2 = x1 + this.length,
                y1 = start.getTime(),
                y2 = end.getTime()

            let result =
                ((x1 >= y1 && x1 < y2) ||
                    (x1 >= y1 && x2 <= y2) ||
                    (x1 <= y1 && x2 >= y2) ||
                    (x2 > y1 && x2 <= y2));
            return result;
        }
        public overlapsOnDay(date: Date) {
            let end = new Date(date);
            end.setDate(end.getDate() + 1);
            return !!this.overlaps(<any>{
                start: date,
                length: 24 * 60 * 60 * 1000,
                type: this.type,
                typeString: this.typeString,
                typeStringLowerCase: this.typeStringLowerCase,
                end: end
            });
        }
        // only used for new positioning
        public overlapsDay(item: CalendarItem) {
            var h = (d: Date) => {
                d.setHours(0, 0, 0, 0);
                return d;
            };
            var x1 = new Date(this.start),
                x2 = new Date(x1.getTime() + this.length),
                y1 = new Date(item.start),
                y2 = new Date(y1.getTime() + item.length);

            x1 = h(x1);
            x2 = h(x2);
            y1 = h(y1);
            y2 = h(y2);

            var result =
                (x1 >= y1 && x1 < y2) ||
                (x1 >= y1 && x2 <= y2) ||
                (x1 <= y1 && x2 >= y2) ||
                (x2 > y1 && x2 <= y2);
            return result;
        }
        public compare(item: CalendarItem) {
            let dif = this.compareStart(item);
            if (dif == 0) {
                dif = this.compareBackgroundColor(item);
            }
            if (dif == 0) {
                dif = this.compareLength(item);
            }
            if (dif == 0) {
                dif = this.compareCreateingNew(item);
            }
            if (dif == 0) {
                dif = this.compareTitle(item);
            }
            if (dif == 0) {
                dif = this.compareId(item);
            }
            return dif;
        }
        private compareStart(item: CalendarItem) {
            return this.start.getTime() - item.start.getTime();
        }
        private compareLength(item: CalendarItem) {
            return item.length - this.length;
        }
        private compareCreateingNew(item: CalendarItem) {
            return this.id === null ? 1 : (item.id === null ? -1 : 0);
        }
        private compareBackgroundColor(item: CalendarItem) {
            const b1 = this.style['background-color'] || '';
            const b2 = item.style['background-color'] || '';
            return b1 < b2 ? -1 : (b1 > b2 ? 1 : 0);
        }
        private compareTitle(item: CalendarItem) {
            const t1 = (this.title || '').toLowerCase();
            const t2 = (item.title || '').toLowerCase();
            return t1 < t2 ? -1 : (t1 > t2 ? 1 : 0);
        }
        private compareId(item: CalendarItem) {
            return this.id - item.id;
        }
        public formatTime(time: Date) {
            return time.format('HH:mm');
        }
        public createText() {
            return this.formatTime(this.start) + ' - ' + this.formatTime(new Date(this.start.getTime() + this.length));
        }
    }

    export type CalendarItemConstructor = typeof CalendarItem;

    export class WeekCalendarItem extends CalendarItem {
        public calendar!: WeekCalendar;
        public update(filter: FilterResult) {
            let calendar = this.calendar;
            let getOffset = (date: Date) => {
                let time = ((date.getHours() * 60 + date.getMinutes()) * 60 + date.getSeconds()) * 1000 + date.getMilliseconds();
                var y = time - AbstractCalendar.calendarStart.getTime();
                y /= 60 * 60 * 1000;
                y *= AbstractCalendar.hourHeight;
                return Math.round(y);
            }

            let setElementTime = (element: HTMLElement, from: Date, to: Date) => {
                var top = getOffset(from),
                    height = (to.getTime() - from.getTime()) / 3600000 * AbstractCalendar.hourHeight;
                if (top < 0) {
                    height += top;
                    top = 0;
                }

                element.style.setProperty('--top', top + '');
                element.style.setProperty('--height', height + '');
                let width = 0;
                let left = 0;
                if (this.typeStringLowerCase in informationTypes) {
                    width = 0.1 / this.itemCount;
                    left = this.indent * width;
                } else if (calendar.overlapsNarrowItemOnDay(from)) {
                    width = 0.8 / this.itemCount;
                    left = this.indent * width + 0.1;
                } else {
                    width = 0.85 / this.itemCount;
                    left = this.indent * width;
                }
                element.style.width = 100 * width + '%';
                element.style.left = 100 * left + '%';
            }

            let createElement = (from: Date, to: Date, parent: HTMLTableCellElement) => {
                let element = null;
                if (from < to) {
                    if (this.typeStringLowerCase in informationTypes) {
                        switch (informationTypes[this.typeStringLowerCase]) {
                            case 0: // top
                                parent = calendar.getHeaderRow(this.typeStringLowerCase).cells[parent.cellIndex];
                                break;
                            case 2: // bottom
                                parent = calendar.getFooterRow(this.typeStringLowerCase).cells[parent.cellIndex];
                                break;
                        }
                    }

                    let container = <HTMLDivElement | null>parent.firstElementChild;
                    if (container) {
                        element = this.createElement(container, filter);
                        if (!(this.typeStringLowerCase in informationTypes) || informationTypes[this.typeStringLowerCase] == 1) {
                            setElementTime(element, from, to);
                        }
                    }
                }
                return element;
            }

            var from = new Date(this.start),
                to = this.end,
                startColumn = calendar.getColumn(from),
                endColumn = calendar.getColumn(to);

            this.removeAllElements();

            if (!startColumn && !endColumn) {
                if (from < calendar.startDate && to > calendar.startDate) {
                    var col;
                    for (var i = 0; i < calendar.columns.length; i++) {
                        col = calendar.columns[i];
                        createElement(new Date(col.date.getTime() + AbstractCalendar.calendarStart.getTime()), new Date(col.date.getTime() + AbstractCalendar.calendarEnd.getTime()), col.cell);
                    }
                }
                return;
            }

            var first = null,
                last = null;
            if (startColumn && startColumn == endColumn) {
                first = last = createElement.call(this, from, to, startColumn.cell);
            } else {
                var start, end;
                if (!startColumn) {
                    startColumn = calendar.columns[0];
                    start = new Date(startColumn.date.getTime() + AbstractCalendar.calendarStart.getTime());
                    end = new Date(startColumn.date.getTime() + AbstractCalendar.calendarEnd.getTime());
                    if (startColumn.date.isDstSwitchDay()) {
                        end.setHours(end.getHours() + (end.dst() ? -1 : 1));
                    }
                    if (startColumn == endColumn) {
                        last = createElement.call(this, start, to, startColumn.cell);
                    } else {
                        last = createElement.call(this, start, end, startColumn.cell);
                    }
                } else {
                    start = new Date(startColumn.date.getTime() + AbstractCalendar.calendarStart.getTime());
                    end = new Date(startColumn.date.getTime() + AbstractCalendar.calendarEnd.getTime());
                    if (startColumn.date.isDstSwitchDay()) {
                        end.setHours(end.getHours() + (end.dst() ? -1 : 1));
                    }
                    if (startColumn == endColumn) {
                        first = last = createElement.call(this, start, to, startColumn.cell);
                    } else {
                        first = last = createElement.call(this, from, end, startColumn.cell);
                    }
                }

                let startCell: HTMLTableDataCellElement | null = startColumn.cell;
                let endCell = endColumn ? endColumn.cell : null;

                startCell = <HTMLTableDataCellElement | null>startCell.nextElementSibling;

                while (startCell && startCell != endCell && (!endCell || startCell.cellIndex < endCell.cellIndex)) {
                    end.setDate(end.getDate() + 1);
                    start.setDate(start.getDate() + 1);
                    last = createElement.call(this, start, end, startCell);
                    startCell = <HTMLTableDataCellElement | null>startCell.nextElementSibling;
                }
                if (startCell) {
                    start.setDate(start.getDate() + 1);
                    last = createElement.call(this, start, to, startCell) || last;
                }
            }
            if (first)
                first.className += ' first';
            if (last)
                last.className += ' last';
        }
    }
    export class CustomHeaderCalendarItem extends CalendarItem {
        public calendar!: CustomHeaderCalendar;
        public update(filter: FilterResult) {
            let cal = this.calendar;
            let parentTop: number = 0;
            function getTop(ev: MouseEvent) {
                return ev.pageY - parentTop + viggo.getScrollTop(<HTMLElement>ev.target);
            }

            function getOffset(time: Date) {
                let y = ((time.getHours() * 60 + time.getMinutes()) * 60 + time.getSeconds()) * 1000 + time.getMilliseconds();
                y -= AbstractCalendar.calendarStart.getTime();
                y /= 60 * 60 * 1000;
                y *= AbstractCalendar.hourHeight;
                return Math.round(y);
            }

            function setElementTime(this: CustomHeaderCalendarItem, element: HTMLDivElement, from: Date, to: Date) {
                let top = getOffset(from),
                    height = (to.getTime() - from.getTime()) / 3600000 * AbstractCalendar.hourHeight;
                if (top < 0) {
                    height += top;
                    top = 0;
                } else {
                    element.classList.add('first');
                }
                if (height + top > 24 * AbstractCalendar.hourHeight) {
                    height = 24 * AbstractCalendar.hourHeight - top;
                } else {
                    element.classList.add('last');
                }

                element.style.top = top + 'px';
                element.style.height = height + 'px';
                let width: number;
                let left: number;
                if (this.typeStringLowerCase in informationTypes) {
                    width = this.calendar.columnWidth * 0.1 / this.itemCount;
                    left = this.indent * width;
                } else {
                    width = (this.calendar.columnWidth * 0.85) / this.itemCount;
                    left = this.indent * width;
                }
                element.style.width = width + 'px';
                element.style.left = left + 'px';
            }

            let getTime = function (y: number, dayIndex: number) {
                let t = Math.floor(((y / AbstractCalendar.hourHeight * 60 * 60 * 1000) + AbstractCalendar.calendarStart.getTime()) / CustomHeaderCalendar.timeDetail) * CustomHeaderCalendar.timeDetail;
                let time = new Date(cal.startDate);
                time.setDate(time.getDate() + dayIndex);
                time.setMilliseconds(t);
                return time;
            }

            let mousedown = function (this: HTMLDivElement, event: MouseEvent) {
                let parentRow = <HTMLTableRowElement>viggo.dom.parent(this, 'tr');
                let parentBody = <HTMLTableSectionElement>parentRow.parentNode;
                parentTop = viggo.dom.getPos(parentBody).top;
                let mousedownTime = getTime(getTop(event), parentRow.rowIndex - 1);
                let item = <CustomHeaderCalendarItem>this.viggoItem;

                let dif: number;
                let move: (ev: MouseEvent) => void;
                let moved: boolean;
                let mouseup: (ev: MouseEvent) => void;
                if (this.style.cursor == 's-resize') {
                    dif = item.start.getTime() + item.length - mousedownTime.getTime();
                    parentBody.addEventListener('mousemove', move = (ev: MouseEvent) => {
                        ev.stopPropagation();
                        var time = getTime(getTop(ev), parentRow.rowIndex - 1);
                        var add = mousedownTime.getTime() - time.getTime();
                        item.setLength(mousedownTime.getTime() - item.start.getTime() + dif - add);
                        item.update(FilterResult.show);
                        moved = moved || ev.clientX != event.clientX || ev.clientY != event.clientY;
                    }, true);
                } else {
                    dif = item.start.getTime() - mousedownTime.getTime();
                    parentBody.addEventListener('mousemove', move = (ev: MouseEvent) => {
                        ev.stopPropagation();
                        var time = getTime(getTop(ev), parentRow.rowIndex - 1);
                        var add = mousedownTime.getTime() - time.getTime();
                        time = mousedownTime;
                        time.setTime(time.getTime() + dif - add);
                        item.setStart(time);
                        item.update(FilterResult.show);
                        moved = moved || ev.clientX != event.clientX || ev.clientY != event.clientY;
                    }, true);
                }
                /*
                let scrollToCursor = function (ev: MouseEvent) {
                    let y = window.pageYOffset + ev.clientY + viggo.getScrollTop(cal.tbody.parentNode);
                    if (y < parentTop) {
                        cal.tbody.scrollTop -= 10;
                    } else if (y > parentTop + cal.tbody.offsetHeight) {
                        cal.tbody.scrollTop += 10;
                    }
                    window.getSelection().removeAllRanges();
                };
                document.addEventListener('mousemove', scrollToCursor, true);
                */
                document.addEventListener('mouseup', mouseup = () => {
                    parentBody.removeEventListener('mousemove', move, true);
                    document.removeEventListener('mouseup', mouseup, true);
                    // document.removeEventListener('mousemove', scrollToCursor, true);
                    if (moved) {
                        cal.sortItems();
                        cal.repaint();
                        item.save();
                    } else if (!item.id) {
                        item.remove();
                    }
                }, true);
            };
            let mousemove = function (this: HTMLDivElement, event: MouseEvent) {
                if (event.pageY - viggo.dom.getPos(this, true).top - this.offsetHeight >= -10) {
                    this.style.cursor = 's-resize';
                } else {
                    this.style.cursor = '';
                }
            };
            this.removeAllElements();
            if (this.columnIds) {
                let calendarStart = this.calendar.startDate;
                let calendarEnd = this.calendar.endDate;
                let firstDay = calendarStart.getDay();
                for (var i = 0; i < this.columnIds.length; i++) {
                    let id = this.columnIds[i];
                    let map = this.calendar.columns.get(id);
                    if (map) {
                        let date = new Date(this.start);
                        do {
                            let mapIndex = (date.getDay() - firstDay + 14) % 7;
                            let parent = map.get(mapIndex);
                            if (parent && date >= calendarStart && date < calendarEnd) {
                                let element = this.createElement(parent, filter);
                                setElementTime.call(this, element, date, this.end);
                                if (!this.readonly) {
                                    element.addEventListener('mousedown', mousedown, false);
                                    element.addEventListener('mousemove', mousemove, false);
                                }
                            }
                            date.setHours(0, 0, 0, 0);
                            date.setDate(date.getDate() + 1);
                        } while (date < this.end);
                    }
                }
            }
        }
    }
    export class CustomRowDayCalendarItem extends CalendarItem {
        public calendar!: CustomRowDayCalendar<CustomRowDayCalendarItem, CustomRowSection>;
        public update(filter: FilterResult) {
            this.removeAllElements();
            if (this.isInView()) {
                let dayWidth = this.calendar.getDayWidth();

                let parent = this.calendar.getItemPosition(this.group, this.start);
                if (parent) {
                    let calStart = new Date(this.calendar.startDate);
                    let calEnd = new Date(this.calendar.endDate);
                    let start = new Date(this.start);
                    let end = new Date(this.end);

                    if (calStart > start) {
                        start = new Date(calStart);
                    }
                    if (calEnd < end) {
                        end = new Date(calEnd);
                    }

                    let element = this.createElement(parent, filter);

                    let left = start.getHours() * 60 * 60 * 1000 + start.getMinutes() * 60 * 1000;
                    left = left / (24 * 60 * 60 * 1000);
                    left *= dayWidth;
                    element.style.left = left + 'px';

                    let width = end.getTime() - start.getTime();
                    width = width / (24 * 60 * 60 * 1000);
                    width *= dayWidth;
                    element.style.width = width + 'px';
                    if (this.indent >= 0) {
                        element.style.setProperty('--indent', this.indent.toString());
                    }
                }
            }
        }
        // disable highlighting
        public highlightElements() {}
    }
    export class CustomRowDetailCalendarItem extends CalendarItem {
        public calendar!: CustomRowDetailCalendar;
        public overlaps(item: CustomRowDetailCalendarItem): boolean | number {
            let x = this.calendar.getItemPosition(this.group, this.start, this.end);
            let y = this.calendar.getItemPosition(item.group, item.start, item.end);
            if (x && y) {
                let x1 = x.start,
                    x2 = x.end,
                    y1 = y.start,
                    y2 = y.end;
                let result = super.overlaps(item);
                if (!result) {
                    result = (x1 % 2 == 0 && (x1 == y1 || x1 == y2)) || (x2 % 2 == 0 && (x2 == y1 || x2 == y2)) ? 1 : false;
                }
                return result;
            }
            return super.overlaps(item);
        }
        public update(filter: FilterResult) {
            this.removeAllElements();
            if (this.isInView()) {
                let itemStart = new Date(this.start);
                let itemEnd = new Date(this.end);
                itemEnd.setTime(Math.min(itemEnd.getTime(), this.calendar.startDate.getTime() + this.calendar.days * 24 * 60 * 60 * 1000));
                let pos = this.calendar.getItemPosition(this.group, itemStart, itemEnd);
                if (pos && pos.parent) {
                    const dayWidth = this.calendar.getDayWidth();
                    const startHour = this.calendar.startHour;
                    const endHour = this.calendar.endHour;
                    const dayHours = endHour - startHour;
                    let calendarStart = new Date(Math.max(this.calendar.startDate.getTime(), itemStart.getTime()));
                    calendarStart.setHours(startHour, 0, 0, 0);

                    const element = this.createElement(pos.parent, filter);
                    const spacerWidth = 21;
                    let spaceMultiplier = 0;
                    if (pos.start % 2 == 0) { // we're starting in a seperating area
                        if (itemStart < calendarStart) {
                            itemStart = new Date(calendarStart);
                        } else if (itemStart.getHours() > endHour || (itemStart.getHours() == endHour && itemStart.getMinutes())) {
                            itemStart.setDate(itemStart.getDate() + 1);
                            calendarStart.setDate(itemStart.getDate());
                        }
                        itemStart.setHours(startHour, 0, 0, 0);
                        spaceMultiplier++;
                    }
                    if (pos.end % 2 == 0) { // we're ending in a seperating area
                        if (itemEnd.getHours() < startHour) {
                            itemEnd.setDate(itemEnd.getDate() - 1);
                        }
                        itemEnd.setHours(endHour, 0, 0, 0);
                        spaceMultiplier++;
                    }

                    let left = (itemStart.getTime() - calendarStart.getTime()) / (1000 * 60 * 60 * dayHours) * dayWidth;
                    let width = spacerWidth;

                    if (pos.start % 2 || pos.start != pos.end) {
                        let hiddenHours = 0;
                        if (pos.start % 2 == 0) {
                            if (pos.start == pos.end - 1) {
                                calendarStart.setHours(startHour, 0, 0, 0);
                            } else {
                                calendarStart.setHours(0, 0, 0, 0);
                            }
                        }
                        if (pos.end - pos.start > 1) {
                            while (pos.start < pos.end - 1) {
                                if (pos.start % 2) {
                                    hiddenHours += 24 - endHour;
                                    spaceMultiplier++;
                                } else {
                                    hiddenHours += startHour;
                                }
                                pos.start++;
                            }
                            if (pos.end % 2) {
                                hiddenHours += startHour;
                            }
                        }
                        width = (itemEnd.getTime() - calendarStart.getTime() - hiddenHours * 1000*60*60) / (1000 * 60 * 60 * dayHours) * dayWidth + spaceMultiplier * spacerWidth - left;
                    }
                    if (left < 0 || width < 0) {
                        console.log(this.id, left + 'px', width + 'px');
                    }
                    element.style.left = left + 'px';
                    element.style.width = width + 'px';
                    let count = this.alsoBookedBy.length + 1;
                    element.style.height = 100 / count + '%';
                    if (this.indent >= 0) {
                        element.style.top = 100 * this.indent / count + '%';
                    }
                }
            }
        }
    }
    export class MultiweekCalendarItem extends CalendarItem {
        public calendar!: MultiweekCalendar;
        public update(filter: FilterResult) {
            this.removeAllElements();
            let end = new Date(this.calendar.startDate.getTime() + this.calendar.rows.length * 7 * 24 * 60 * 60 * 1000);
            let dst = this.calendar.startDate.dst();
            let add = 0;
            if (end.dst() != dst) {
                add = dst ? 1 : -1;
                end.setHours(end.getHours() + add);
            }
            // check if the item ends before the calendar begins, or starts after the calender ends
            if (this.start.getTime() + this.length <= this.calendar.startDate.getTime() ||
                this.start.getTime() >= end.getTime()) {
                return;
            }

            let createRow = () => {
                let tr = viggo.dom.tag('tr');
                let i = 7;
                while (i--)
                    tr.appendChild(viggo.dom.tag('td'));
                return tr;
            };
            let getCell = (index: number) => {
                let rowIndex = Math.floor(index / 7);
                let cellIndex = index % 7;
                let table = this.calendar.rows[rowIndex];
                let foundCell = null;
                for (let y = 0; y < table.rows.length && !foundCell; y++) {
                    let row = table.rows[y];
                    let realIndex = 0;
                    for (let x = 0; x < row.cells.length && !foundCell; x++) {
                        if (realIndex == cellIndex && !row.cells[x].firstChild) {
                            foundCell = row.cells[x];
                        } else {
                            realIndex += row.cells[x].colSpan;
                        }
                    }
                }
                if (!foundCell && table.firstChild) {
                    let row = table.firstChild.appendChild(createRow());
                    foundCell = row.cells[cellIndex];
                }
                return foundCell;
            };

            var startCell = this.calendar.getCellIndex(this.start),
                endCell = this.calendar.getCellIndex(new Date(this.start.getTime() + this.length - 1)); // subtract one, to stay inside one day if end is at 00:00

            var noStart = startCell == -1,
                noEnd = endCell == -1;
            if (noStart) {
                startCell = 0;
            }
            if (noEnd) {
                endCell = this.calendar.rows.length * 7 - 1;
            }

            let first = null, last = null;

            while (startCell <= endCell) {
                var cell = getCell(startCell);
                var colSpan;
                if (Math.floor(endCell / 7) > Math.floor(startCell / 7)) {
                    colSpan = 7 - (startCell % 7);
                } else {
                    colSpan = endCell + 1 - startCell;
                }

                if (cell) {
                    cell.colSpan = colSpan;
                    while (--colSpan && cell.nextSibling && cell.parentNode) {
                        cell.parentNode.removeChild(cell.nextSibling);
                    }

                    startCell = startCell + cell.colSpan;

                    last = this.createElement(cell, filter);
                    if (!first)
                        first = last;
                    if (!this.readonly) {
                        last.draggable = true;
                        last.addEventListener('dragstart', this.calendar.dragstart, false);
                        last.addEventListener('dragend', this.calendar.dragend, false);
                    }
                }
            }
            if (!noStart && first) {
                first.className += ' first';
            }
            if (!noEnd && last) {
                last.className += ' last';
            }
        }
    }
    export class MultimonthCalendarItem extends CalendarItem {
        public calendar!: MultimonthCalendar;
        public update(filter: FilterResult) {
            this.removeAllElements();

            let start = new Date(this.start);
            start.setHours(0, 0, 0, 0);
            let end = new Date(this.end);
            if (end.format('HH:mm') == '00:00') {
                end.setDate(end.getDate() - 1);
            }
            end.setHours(12, 0, 0, 0);

            let cell: HTMLTableCellElement | null;
            let first: HTMLElement | null = null;
            let last: HTMLElement | null = null;
            do {
                cell = this.calendar.getCell(start);
                while (!cell && start <= end) {
                    start.setDate(start.getDate() + 1);
                    cell = this.calendar.getCell(start);
                }
                if (cell && cell.firstChild) {
                    let element = this.createElement(<Element>cell.firstChild, filter);
                    if (!first) {
                        first = element;
                    }
                    last = element;
                    if (!this.readonly) {
                        element.draggable = true;
                        element.addEventListener('dragstart', this.calendar.dragstart, false);
                        element.addEventListener('dragend', this.calendar.dragend, false);
                    }
                    start.setDate(start.getDate() + 1);
                }
            } while (start <= end && cell);
            if (first) {
                first.classList.add('first');
            }
            if (last) {
                last.classList.add('last');
            }
        }
    }
}