module viggo {

    interface DragDropData {
        files: boolean;
        functionId: number;
        area: string;
        data: { [index: string]: string };
    }

    export class dragdrop {
        private static instance: dragdrop;
        private data: DragDropData;
        private dragTime = 0;
        private element: HTMLElement | null = null;
        private constructor() {
            this.data = {
                files: false,
                functionId: -1,
                area: '',
                data: {}
            }
            document.addEventListener('dragenter', (event: DragEvent) => {
                if (!this.allowDrag(event)) {
                    return;
                }
                let target = this.getDropTarget(<HTMLElement>event.target);
                if (target && this.allowedType(target)) {
                    event.dataTransfer!.dropEffect = 'move';
                    target.classList.add('drag-over');
                    if (this.data.files) {
                        event.dataTransfer!.dropEffect = 'copy';
                    }
                } else {
                    event.dataTransfer!.dropEffect = 'none';
                }
                event.preventDefault();
            }, false);

            document.addEventListener('dragleave', (event: DragEvent) => {
                let target = this.getDropTarget(<HTMLElement>event.target);
                if (target && this.allowedType(target)) {
                    event.dataTransfer!.dropEffect = 'none';
                    target.classList.remove('drag-over');
                }
                document.documentElement!.classList.remove('drag-upload');
                document.documentElement!.classList.remove('drag-over');
                event.preventDefault();
            }, false);

            document.addEventListener('dragover', (event: DragEvent) => {
                if (!this.allowDrag(event)) {
                    return;
                }
                this.data.files = false;
                document.documentElement!.classList.add('drag-over');

                if (event.dataTransfer!.items[0].kind == "file") {
                    document.documentElement!.classList.add('drag-upload');
                    this.data.files = true;
                }
                let target = this.getDropTarget(<HTMLElement>event.target);
                if (target && this.allowedType(target)) {
                    event.dataTransfer!.dropEffect = this.data.files ? 'copy' : (event.dataTransfer!.dropEffect == 'none' ? 'move' : event.dataTransfer!.dropEffect);
                } else {
                    event.dataTransfer!.dropEffect = 'none';
                }
                event.preventDefault();
            }, false);

            document.addEventListener('dragstart', (event: DragEvent) => {
                this.dragTime = Date.now();
                document.documentElement!.classList.add('drag-over');
                let target = <HTMLElement|null>viggo.dom.parentDataset(<HTMLElement>event.target, 'dragId');
                if (target) {
                    if (!target.dataset.areaFunctionId) {
                        throw new Error("Missing data-area-function-id for drag");
                    }
                    let functionId = target.dataset.dragFunctionId;
                    if (!functionId) {
                        functionId = '-1';
                    }
                    event.dataTransfer!.effectAllowed = 'all';
                    this.data.area = target.dataset.areaFunctionId;
                    this.data.files = false;
                    this.data.functionId = parseInt(functionId);
                    this.data.data = {};

                    document.documentElement!.classList.add('drag-' + functionId);
                    event.dataTransfer!.setData('text', ''); // must be there, or the browser will not initiate the drag
                    for (var i in target.dataset) {
                        if (i.indexOf('drag') === 0) { // this will take any aditional drag data defined with data-drag-*
                            let attribute = i.substring(4);
                            attribute = attribute.substring(0, 1).toLowerCase() + attribute.substring(1);
                            this.data.data[attribute] = <string>target.dataset[i];
                        }
                    }
                    this.element = target;
                }
            }, false);

            document.addEventListener('dragend', (event: DragEvent) => {
                if (!this.allowDrag(event)) {
                    return;
                }
                this.reset();
                event.preventDefault();
            }, false);

            document.addEventListener('drop', (event: DragEvent) => {
                if (!this.allowDrag(event)) {
                    return;
                }
                this.reset();
                let target = this.getDropTarget(<HTMLElement>event.target);
                if (target && this.allowedType(target)) {
                    event.stopPropagation();
                    event.preventDefault();
                    let urlTarget = <HTMLElement|null>target.closest('[data-upload-url]');
                    if (this.data.files && urlTarget && event.dataTransfer!.files && event.dataTransfer!.files.length) {
                        let extensions = target.dataset.validExtensions;
                        let filetypes = extensions ? extensions.split(',').filter(x=>x) : [];
                        let maxSize = parseInt(target.dataset.maxSize || urlTarget.dataset.maxSize || '0');
                        if (isNaN(maxSize)) {
                            throw new Error("Max size not correctly set");
                        }

                        let url = urlTarget.dataset.uploadUrl!;
                        let resultUrl = url.split('#');
                        let resultElement: HTMLElement | null = null;
                        if (resultUrl.length > 1) {
                            resultElement = document.getElementById(resultUrl[1]);
                        }
                        url = resultUrl[0];

                        if (urlTarget) {
                            let uploader = new viggo.upload({
                                files: event.dataTransfer!.files,
                                filetypes: filetypes,
                                maxFilesize: maxSize,
                                completeElement: resultElement,
                                data: viggo.upload.getData(target),
                                target: urlTarget,
                                url: url
                            });
                            uploader.run(true);
                        } else {
                            throw new Error("Missing data-drop-url for file-upload");
                        }
                    } else {
                        let data: any = Object.assign({}, this.data.data);
                        data.parent = target.dataset.dropId;
                        data.parentFunctionId = target.dataset.dropFunctionId;
                        data.area = this.data.area;
                        data.functionId = this.data.functionId;

                        this.moveStuff(data, <HTMLElement|undefined>this.element, target);
                    }
                    this.data = {
                        area: '',
                        files: false,
                        functionId: -1,
                        data: {}
                    };
                }
            }, false);
        }
        public static getInstance() {
            if (!dragdrop.instance) {
                dragdrop.instance = new dragdrop();
            }
            return dragdrop.instance;
        }
        public moveStuff(data: any, movedTarget?: Element, newParent?: Element) {
            new viggo.ajax({
                method: 'post',
                url: '/Shared/Folder/ItemMove',
                data: data,
                complete: function () {
                    if (movedTarget) {
                        if (newParent) {
                            let link = movedTarget.querySelector(':scope>a');
                            let text = link ? (link.textContent || 'Element').trim().toLowerCase() : 'Element';
                            let list;
                            if (movedTarget.classList.contains('folder')) {
                                list = newParent.querySelectorAll(':scope>ul>li.folder');
                            } else {
                                list = newParent.querySelectorAll(':scope>ul>li:not(.folder)');
                            }
                            let bestTarget = null;
                            if (list.length) {
                                bestTarget = list[list.length - 1].nextSibling;
                            }
                            for (let i = 0; i < list.length; i++) {
                                let li = list[i];
                                link = li.querySelector(':scope>a');
                                let t = link ? (link.textContent || '').trim().toLowerCase() : '';
                                if (text < t) {
                                    bestTarget = li;
                                    break;
                                }
                            }
                            if (!bestTarget && movedTarget.classList.contains('folder')) {
                                bestTarget = newParent.firstChild;
                            }
                            let ul = newParent.querySelector(':scope>ul');
                            if (ul && bestTarget && ul.contains(bestTarget)) {
                                ul.insertBefore(movedTarget, bestTarget);
                            } else {
                                viggo.history.reload();
                            }
                        } else {
                            movedTarget.remove();
                        }
                    }
                }
            });
        }
        public static getData() {
            return dragdrop.getInstance().getData();
        }
        public getData() {
            return this.data.data;
        }
        private reset() {
            document.documentElement!.className = document.documentElement!.className.split(' ').filter(function (c) {
                return c !== '' && c.substring(0, 5) !== 'drag-';
            }).join(' ');
        }
        private getDropTarget(target: HTMLElement) {
            if (target.nodeType != 1) {
                target = target.parentElement!;
            }
            return target ? <HTMLElement|null>target.closest('[data-drop-id]') : null;
        }
        private allowedType(target: HTMLElement) {
            let result = false;
           
            if (target) {
                if (this.data.files) {
                    result = !!target.closest('[data-upload-url]');
                } else {
                    result = target.dataset.areaFunctionId == this.data.area &&
                        !!target.dataset.dropValidFunctionIds &&
                        target.dataset.dropValidFunctionIds.split(' ').indexOf(this.data.functionId + '') != -1 &&
                        this.element != target
                }
            }
            return result;
        }
        private allowDrag(event: DragEvent) {
            return Date.now() - this.dragTime > 200 && event.dataTransfer;
        }
    }

    dragdrop.getInstance();

    (function () {
        let buttons = document.getElementsByClassName('upload-disabled');
        let start = () => {
            [].forEach.call(buttons, (e: HTMLButtonElement) => {
                e.disabled = true;
            });
        };
        let stop = () => {
            [].forEach.call(buttons, (e: HTMLButtonElement) => {
                e.disabled = false;
            });
        };
        viggo.upload.addEventListener('upload', start);
        viggo.upload.addEventListener('finish', stop);
    }());
}