module viggo {
    export module imageeditor {
        interface ImageCropOptions {
            cropLeft: number;
            cropTop: number;
            cropRight: number;
            cropBottom: number;
            width: number;
            height: number;
            zoom: number;
        }
        interface IndexChanger {
            [index: number]: (x: number, y: number) => number;
        }
        class crop {
            public image: HTMLImageElement;
            public data: ImageCropOptions;
            private mouseisdown = false;
/*
 * mousearea:
 * _________
 * | 0 1 2 |
 * | 3 4 5 |
 * | 6 7 8 |
 * ‾‾‾‾‾‾‾‾‾
 */
            private mousearea = 0;
            private lastX = 0;
            private lastY = 0;
/**
 * @param image DOMImage
 * @param data Object
 * Parameter data should contain: cropLeft, cropTop, cropRight, cropBottom, width, height, zoom
 *   width and height are positive integers
 *   crop-paramters are flotingpoint numbers with values from [0;1[
 *   zoom is a flotingpoint number larger than zero.
 */
            public constructor(image: HTMLImageElement, data?: ImageCropOptions) {
                this.image = image;
                this.data = data || {
                    cropLeft: 0,
                    cropTop: 0,
                    cropRight: 0,
                    cropBottom: 0,
                    width: image.naturalWidth,
                    height: image.naturalHeight,
                    zoom: 1
                };
                image.classList.add('crop');
                image.style.width = image.width + 'px';
                image.style.height = image.height + 'px';
                let src = image.src;
                let original = image.dataset.originalSrc;
                image.src = '/Content/transparent.gif';
                image.style.background = `url("${original}") no-repeat`;
                this.refresh();
                image.addEventListener('mousemove', (event) => this.mousemoveCursor(event));
                image.addEventListener('mousedown', (event) => this.mousedown(event));
                image.addEventListener('wheel', (event) => this.wheel(event));
            }

		    protected refresh() {
			    let data = this.data;
			    this.image.style.width = (1 - data.cropLeft - data.cropRight) * data.zoom * data.width + 'px';
			    this.image.style.height = (1 - data.cropTop - data.cropBottom) * data.zoom * data.height + 'px';
			    this.image.style.backgroundPosition = (-data.cropLeft * data.width * data.zoom + 'px ') + (-data.cropTop * data.height * data.zoom + 'px');
			    this.image.style.backgroundSize =  (data.zoom * data.width + 'px ') + (data.zoom * data.height + 'px');
		    }
		    private mousemoveCursor (event: MouseEvent) {
			    if (!this.mouseisdown) {
				    let target = this.image,
					    pos = viggo.dom.getPos(target),
					    x = event.clientX - pos.left + window.pageXOffset,
					    y = event.clientY - pos.top + window.pageYOffset,
					    w = target.clientWidth,
					    h = target.clientHeight,
					    c = ['nw-resize', 'n-resize', 'ne-resize', 'w-resize', 'move', 'e-resize', 'sw-resize', 's-resize', 'se-resize', 'not-allowed'],
					    f = 5;

				    x = Math.floor(x / w * f);
				    y = Math.floor(y / h * f);

				    if (x == f - 1) {
					    x = 2;
				    } else if (x > 1) {
					    x = 1;
				    }

				    if (y == f - 1) {
					    y = 2;
				    } else if (y > 1) {
					    y = 1;
				    }

				    this.mousearea = y * 3 + x;
			        if (this.mousearea < 0 || this.mousearea > 8) {
			            this.mousearea = 9;
			        }

				    target.style.cursor = c[this.mousearea];
			    }
		    }
		    private changers: IndexChanger = {
			    0: function(this: crop, x: number, y: number): number { // nw
				    return this.changers[1].call(this, x, y) & this.changers[3].call(this, x, y);
			    },
			    1: function(this: crop, x: number, y: number): number { // n
				    var d = this.data;
				    d.cropTop = d.cropTop - y / d.height / d.zoom;
				    if (d.cropTop < 0) {
					    d.cropTop = 0;
					    return 1;
				    }
				    return 3;
			    },
			    2: function(this: crop, x: number, y: number): number { // ne
				    return 	this.changers[1].call(this, x, y) & this.changers[5].call(this, x, y);
			    },
			    3: function(this: crop, x: number, y: number): number { // w
				    var d = this.data;
				    d.cropLeft = d.cropLeft - x / d.width / d.zoom;
				    if (d.cropLeft < 0) {
					    d.cropLeft = 0;
					    return 2;
				    }
				    return 3;
			    },
                4: function (this: crop, x: number, y: number): number { // center
				    var d = this.data;
				    d.cropTop = d.cropTop + y / d.height / d.zoom;
				    d.cropLeft = d.cropLeft + x / d.width / d.zoom;
				    d.cropRight = d.cropRight - x / d.width / d.zoom;
				    d.cropBottom = d.cropBottom - y / d.height / d.zoom;

				    var result = 3;
				    if (d.cropTop < 0) {
					    d.cropBottom += d.cropTop;
					    d.cropTop = 0;
					    result = result & 1;
				    } else if (d.cropBottom < 0) {
					    d.cropTop += d.cropBottom;
					    d.cropBottom = 0;
					    result = result & 1;
				    }

				    if (d.cropLeft < 0) {
					    d.cropRight += d.cropLeft;
					    d.cropLeft = 0;
					    result = result & 2;
				    } else if (d.cropRight < 0) {
					    d.cropLeft += d.cropRight;
					    d.cropRight = 0;
					    result = result & 2;
				    }
				    return result;
			    },
                5: function (this: crop, x: number, y: number): number { // e
				    var d = this.data;
				    d.cropRight = d.cropRight + x / d.width / d.zoom;
				    if (d.cropRight < 0) {
					    d.cropRight = 0;
					    return 2;
				    }
				    return 3;
			    },
                6: function (this: crop, x: number, y: number): number { // sw
				    return this.changers[3].call(this, x, y) & this.changers[7].call(this, x, y);
			    },
                7: function (this: crop, x: number, y: number): number { // s
				    var d = this.data;
				    d.cropBottom = d.cropBottom + y / d.height / d.zoom;
				    if (d.cropBottom < 0) {
					    d.cropBottom = 0;
					    return 1;
				    }
				    return 3;
			    },
                8: function (this: crop, x: number, y: number): number { // se
				    return this.changers[5].call(this, x, y) & this.changers[7].call(this, x, y);
			    },
                9: function (this: crop, x: number, y: number): number {
			        return 0;
                }
		    }
		    private mousemoveChange(event: MouseEvent) {
			    var deltaX = this.lastX - event.clientX,
				    deltaY = this.lastY - event.clientY,
				    data = this.data;

			    var result = this.changers[this.mousearea].call(this, deltaX, deltaY);
			    if (result & 1) {
				    this.lastX = event.clientX;
			    }
			    if (result & 2) {
				    this.lastY = event.clientY;
			    }
			    this.refresh();
            }
            private mousedown(event: MouseEvent) {
			    this.mouseisdown = true;
			    this.lastX = event.clientX;
			    this.lastY = event.clientY;
			    event.preventDefault();
			    event.stopPropagation();
			    let move = (e: MouseEvent) => {
				    this.mousemoveChange(e);
			    }, up = (e: MouseEvent) => {
				    this.mouseisdown = false;
				    document.removeEventListener('mousemove', move, true);
				    document.removeEventListener('mouseup', up, true);
			    };
			    document.addEventListener('mousemove', move, true);
			    document.addEventListener('mouseup', up, true);
		    }
		    public autosize(event: MouseEvent) {
                let data = this.data;
                let parent = this.image.parentElement;
                if (parent) {
                    let fullWidth = parent.clientWidth;
                    data.zoom = fullWidth / data.width / (1 - data.cropLeft - data.cropRight);
                    this.refresh();
                }
            }
            private wheel(event: WheelEvent) {
			    var d = event.deltaY / Math.abs(event.deltaY);
			    if (isFinite(d)) {
				    event.preventDefault();
				    var data = this.data;
				    if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
					    data.zoom *= 1 - d / 50;
				    } else {
					    // placer øverste venstre hjørne og fokuser på musen ud fra hele billedet.
					    // beregning af øverste venstre hjørne samt bredde og højde er vigtigst.
					    var pos = viggo.dom.getPos(this.image);

					    var z = data.zoom;
					    var w = (1 - data.cropLeft - data.cropRight) * z * data.width;
					    var h = (1 - data.cropTop - data.cropBottom) * z * data.height;

				        var oldLeft = data.cropLeft,
				            oldRight = data.cropRight,
				            oldTop = data.cropTop,
				            oldBottom = data.cropBottom,
				            reset = false;

					    var x = (event.clientX - pos.left) / w;
					    var y = (event.clientY - pos.top) / h;

					    data.zoom = z * (1 - d / 50);

					    var nw = (1 - data.cropLeft - data.cropRight) * data.zoom * data.width;
					    var nh = (1 - data.cropTop - data.cropBottom) * data.zoom * data.height;

					    var difw = w - nw;
					    var difh = h - nh;

					    data.cropLeft -= x*difw/data.width/data.zoom;
					    data.cropRight -= (1-x)*difw/data.width/data.zoom;
					    data.cropTop -= y*difh/data.height/data.zoom;
					    data.cropBottom -= (1 - y) * difh / data.height / data.zoom;
                    
					    if (data.cropLeft < 0 && data.cropLeft + data.cropRight >= 0) {
                            data.cropRight += data.cropLeft;
                            data.cropLeft = 0;
                        } else if (data.cropRight < 0 && data.cropRight + data.cropLeft >= 0) {
                            data.cropLeft += data.cropRight;
					        data.cropRight = 0;
                        } else if (data.cropLeft + data.cropRight < 0) {
					        reset = true;
					    }

					    if (data.cropTop < 0 && data.cropTop + data.cropBottom >= 0) {
					        data.cropTop += data.cropBottom;
					        data.cropTop = 0;
					    } else if (data.cropBottom < 0 && data.cropBottom + data.cropTop >= 0) {
					        data.cropBottom += data.cropTop;
					        data.cropBottom = 0;
					    } else if (data.cropTop + data.cropBottom < 0) {
					        reset = true;
					    }
				    
                        if (reset) {
                            data.cropLeft = oldLeft;
                            data.cropRight = oldRight;
                            data.cropTop = oldTop;
                            data.cropBottom = oldBottom;
                            data.zoom = z;
                        }
				        /*
					    this.data.cropLeft += Math.max(z * x);
					    this.data.cropRight += Math.max(z * (1 - x));
					    this.data.cropTop += Math.max(z * y);
					    this.data.cropBottom += Math.max(z * (1 - y));
					    */
				    }
				    this.refresh();
			    }
		    }
        }

        interface ProfileEditorOptions {
            image: HTMLImageElement;
            zoomControl: HTMLInputElement;
            angleControl: HTMLInputElement;
            rotateElement: HTMLElement;
            width: number;
            height: number;
        }

        export class profile extends viggo.classes.eventListener {
            private angleAdd: number = 0;
            private ratioWidth: number;
            private ratioHeight: number;
            private image: HTMLImageElement;
            private zoomControl: HTMLInputElement;
            private angleControl: HTMLInputElement;
            private resultWidth: number;
            private resultHeight: number;
            public constructor(options: ProfileEditorOptions) {
                super();
                this.image = options.image;
                this.zoomControl = options.zoomControl;
                this.angleControl = options.angleControl;
                this.resultWidth = options.width;
                this.resultHeight = options.height;
                this.ratioWidth = 1;
                this.ratioHeight = 1;
                let oldZoom = this.zoom;
                this.zoomControl.addEventListener('input', () => {
                    let left = this.left;
                    let top = this.top;
                    let centerLeft = (this.resultWidth / 2 - left) / (this.resultWidth * oldZoom);
                    let centerTop = (this.resultHeight / 2 - top) / (this.resultHeight * oldZoom);

                    let zoom = this.zoom;
                    this.image.style.width = zoom * this.resultWidth * this.ratioWidth + 'px';
                    this.image.style.height = zoom * this.resultHeight * this.ratioHeight + 'px';

                    this.image.style.transformOrigin = `${centerLeft * this.resultWidth * zoom}px ${centerTop * this.resultHeight * zoom}px`;

                    this.left = this.resultWidth / 2 - centerLeft * this.resultWidth * zoom;
                    this.top = this.resultHeight / 2 - centerTop * this.resultHeight * zoom;

                    oldZoom = zoom;
                    this.notifyEventListener();
                }, false);
                this.angleControl.addEventListener('input', () => {
                    this.angle = this.angle;
                    this.notifyEventListener();
                }, false);
                this.image.addEventListener('mousedown', event => this.mousedown(event), false);
                options.rotateElement.addEventListener('click', () => {
                    this.angleAdd = (this.angleAdd + 90) % 360;
                    this.angle = this.angle;
                    this.notifyEventListener();
                }, false);
                let f = () => {
                    this.ratioWidth = this.image.naturalWidth / this.image.naturalHeight;
                    this.ratioHeight = this.image.naturalHeight / this.image.naturalWidth;
                    if (this.ratioWidth < this.ratioHeight) {
                        this.ratioWidth = 1;
                    } else {
                        this.ratioHeight = 1;
                    }
                    let zoom = this.zoom;
                    this.image.style.width = zoom * this.resultWidth * this.ratioWidth + 'px';
                    this.image.style.height = zoom * this.resultHeight * this.ratioHeight + 'px';
                    this.top = (200 - this.image.height) / 2;
                    this.left = (200 - this.image.width) / 2;
                    setTimeout(() => this.notifyEventListener(), 10);
                };
                if (this.image.height) {
                    f();
                } else {
                    this.image.addEventListener('load', f, false);
                }
            }
            public get angle() {
                return this.angleControl.valueAsNumber + this.angleAdd;
            }
            public set angle(value: number) {
                this.image.style.transform = `rotate(${this.angle}deg)`;
            }
            public get zoom() {
                return this.zoomControl.valueAsNumber;
            }
            public get width() {
                return this.image.width;
            }
            public get height() {
                return this.image.height;
            }
            public get top() {
                let value = parseFloat(this.image.style.top || '0');
                return isNaN(value) ? 0 : value;
            }
            public set top(value: number) {
                this.image.style.top = Math.max(Math.min(value, 0), this.resultHeight - this.height) + 'px';
            }
            public get left() {
                let value = parseFloat(this.image.style.left || '0');
                return isNaN(value) ? 0 : value;
            }
            public set left(value: number) {
                this.image.style.left = Math.max(Math.min(value, 0), this.resultWidth - this.width) + 'px';
            }
            private notifyEventListener() {
                this.dispatchEvent('change', {
                    left: this.left,
                    top: this.top,
                    zoom: this.zoom,
                    angle: this.angle
                });
            }
            private mousedown(event: MouseEvent) {
                event.preventDefault();
                let x = event.clientX,
                    y = event.clientY,
                    top = this.top,
                    left = this.left,
                    changed = false;

                let mousemove = (ev: MouseEvent) => {
                    ev.preventDefault();
                    let angle = Math.radians(this.angle);
                    let difX = ev.clientX - x;
                    let difY = ev.clientY - y;
                    let moveX = Math.cos(angle) * difX + Math.sin(angle) * difY;
                    let moveY = -Math.sin(angle) * difX + Math.cos(angle) * difY;
                    let zoom = this.zoom;
                    this.left = left + moveX;
                    this.top = top + moveY;
                    let centerLeft = (this.resultWidth / 2 - this.left) / (this.resultWidth * zoom);
                    let centerTop = (this.resultHeight / 2 - this.top) / (this.resultHeight * zoom);
                    this.image.style.transformOrigin = `${centerLeft * this.resultWidth * zoom}px ${centerTop * this.resultHeight * zoom}px`;
                    changed = true;
                };
                let mouseup = (ev: MouseEvent) => {
                    document.removeEventListener('mousemove', mousemove, false);
                    document.removeEventListener('mouseup', mouseup, false);
                    if (changed) {
                        this.notifyEventListener();
                    }
                };
                document.addEventListener('mousemove', mousemove, false);
                document.addEventListener('mouseup', mouseup, false);
            }
        }
    }
}