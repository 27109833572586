namespace viggo {
    interface ParsedHTML {
        html: string;
        scripts: string[];
    }

    interface Preloader {
        html: string;
    }

    interface PreloaderList {
        [index: string]: Preloader | undefined;
    }

    export type LoadCheckInitiator = "init" | "click" | "load" | "invalidate" | "click-no-clear";
    export class load {
        private url: string;
        private static loaders = 0;

        constructor(url: string) {
            this.url = url;
        }

        async html(signal: AbortSignal) {
            let response = await fetch(this.url, {signal: signal});
            let contentType = response.headers.get("content-type");
            if (contentType) {
                contentType = contentType.split(';')[0];
            }

            let content = await response.text();

            switch (contentType) {
                case 'text/html':
                    return this.parseHTML(content);
                case 'text/javascript':
                case 'application/javascript':
                    try {
                        let func = new Function(content);
                        func();
                    } catch (e) {}
                default:
                    return null;
            }
        }

        public static abortLoadingElements(parent: Element) {
            AreaLoader.abortLoadingElements(parent);
        }

        static async loadChildren(element: HTMLElement | Document = document, initiator: LoadCheckInitiator = 'load', parameters: DOMStringMap = {}) {
            let result: Promise<void>[] = [];
            (<NodeListOf<HTMLElement>>element.querySelectorAll('[data-load-url]')).forEach(x => {
                let loader = new AreaLoader(x, x.dataset.loadUrl!, initiator, x.dataset.loadPreloadTemplate, parameters);
                if (loader.isLoadable) {
                    AreaLoader.abortLoadingElements(x);
                    result.push(loader.load());
                }
            });
            this.loaders += result.length;
            await Promise.all(result);
            this.loaders -= result.length;
        }

        static loadParent(element: Element, initiator: LoadCheckInitiator = 'invalidate', parameters: DOMStringMap = {}) {
            let parent = <HTMLElement | null>element.closest('[data-load-url]');
            while (parent) {
                let loader = new AreaLoader(parent, parent.dataset.loadUrl!, initiator, parent.dataset.loadPreloadTemplate, parameters);
                if (loader.isLoadable) {
                    AreaLoader.abortLoadingElements(parent);
                    return loader.load();
                }
                parent = parent.parentElement;
                if (parent) {
                    parent = parent.closest('[data-load-url]');
                }
            }
            return null;
        }

        static isIdle() {
            return this.loaders == 0;
        }

        private parseHTML(html: string): DocumentFragment {
            let parsedResult = this.getScripts(html);
            let result = viggo.dom.parseHTML(parsedResult.html);
            for (let script of parsedResult.scripts) {
                let scriptNode = viggo.dom.tag('script');
                scriptNode.type = 'text/javascript';
                scriptNode.appendChild(viggo.dom.text(script));
                result.appendChild(scriptNode);
            }
            return result;
        }

        private getScripts(html: string): ParsedHTML {
            let scripts: string[] = [];
            html = html.replace(/<script (?:.*)?src="[^>]+>\s*<\/script>/g, ''); // ignore javascripts with src tags
            html = html.replace(/<script (?:.*)?type="text\/javascript"[^>]*>((?:.|\r|\n)*?)<\/script>/mg, function (all, script) {
                scripts.push(script);
                return "";
            });
            return {
                html: html.trim(),
                scripts: scripts
            };
        }
    }

    class AreaLoader {
        private url: string | null;
        private initiator: LoadCheckInitiator;
        private preloaderName?: string;
        private parameters: DOMStringMap;
        public element: HTMLElement;
        public static preloaders: PreloaderList = {};
        private static abortLoading: WeakMap<Element, AbortController> = new WeakMap<Element, AbortController>();

        // url parameter are specified in special format with curly brackets
        // url example: /Test/{id}/{subid?}?folder={folder?}&name={name=Stig}
        // In this example {id} must be present, and {subid} can be present.
        // {folder} can be present, but is removed if unavaliable
        // {name} can be present, but if not, "Stig" will be the value of name
        // {id} and {subid} are loaded from the /[0-9]+/[0-9]+ in the end of an url
        constructor(element: HTMLElement, url: string, initiator: LoadCheckInitiator, preloaderName: string | undefined, parameters: DOMStringMap) {
            this.element = element;
            this.parameters = parameters;
            this.url = this.parseUrl(url);
            this.initiator = initiator;
            this.preloaderName = preloaderName;
        }

        private parseUrl(url: string) {
            let query = viggo.mapQueryString(window.location.href);
            if (!query.id) {
                let idMatch = window.location.href.match(new RegExp("/(-?\\d+)(?:/(-?\\d+))?"));
                if (idMatch) {
                    query.id = idMatch[1];
                    if (idMatch[2] && !query.subid) {
                        query.subid = idMatch[2];
                    }
                }
            }
            let match = window.location.pathname.match(/^\/([A-Z][a-z]+)\/([A-Z][A-Za-z]+)(?:\/([A-Z][A-Za-z]+))?(?:\/([A-Z][A-Za-z]+))?/);
            if (match) {
                query.Area = match[1];
                if (match[4]) {
                    query.SubArea = match[2];
                    query.Controller = match[3];
                    query.Action = match[4];
                } else {
                    query.Controller = match[2];
                    query.Action = match[3] || 'Index';
                }
            }

            for (let param in this.parameters) {
                query[param] = this.parameters[param]!;
            }

            let validUrl = true;
            url = viggo.func.createTemplate(url, 'url')(query);
            url = url.replace(/(\/|&?\w+==?)\{([^\}]*)\}/g, (all: string) => {
                if (!validUrl) {
                    return all;
                }
                return all.replace(/(\/|&?\w+==?)\{([^\}]*)\}/, (a: string, slashParam: string, valueName: string) => {
                    let match = valueName.match(/^\w+==(.+)/);
                    let conditionList = match ? match[1].split('|') : null;
                    match = valueName.match(/^\w+=(.*)$/);
                    let defaultValue = !conditionList && match ? match[1] : null;
                    match = valueName.match(/^\w+\?$/);
                    let optional = !!match;
                    match = valueName.match(/\w+/);
                    valueName = match ? match[0] : '';

                    if (valueName in query) {
                        if (conditionList) {
                            validUrl = conditionList.indexOf(query[valueName]) != -1;
                            if (!validUrl) {
                                let defaultConditionValue = conditionList[conditionList.length - 1];
                                let prefix = 'default:';
                                if (defaultConditionValue && defaultConditionValue.startsWith(prefix)) {
                                    defaultConditionValue = defaultConditionValue.substring(prefix.length);
                                    validUrl = true;
                                    return slashParam + defaultConditionValue;
                                }
                            }
                        }
                        return slashParam + query[valueName];
                    } else if (optional) {
                        return '';
                    } else if (defaultValue !== null) {
                        return slashParam + defaultValue;
                    } else {
                        validUrl = false;
                        return '';
                    }
                });
            });
            return validUrl ? url : null;
        }

        private addAjax(url: string) {
            return url + (url.split('?')[1] ? '&' : (url.indexOf('?') == -1 ? '?' : '')) + 'ajax=1';
        }

        private preload(name: string) {
            let preloader = AreaLoader.preloaders[name];
            if (preloader) {
                let fragment = viggo.func.createViewFromString(preloader.html);
                this.element.appendChild(fragment);
                return true;
            }
            return false;
        }

        private preloadIfEmpty() {
            if (this.preloaderName && this.element.childNodes.length == 0) {
                return this.preload(this.preloaderName);
            }
            return false;
        }

        public static abortLoadingElements(parent: Element) {
            this.abortLoad(parent);
            parent.querySelectorAll('[data-load-url]').forEach(element => {
                this.abortLoad(element);
            });
        }

        private static abortLoad(element: Element) {
            let abort = this.abortLoading.get(element);
            if (abort) {
                abort.abort();
                this.abortLoading.delete(element);
            }
        }

        public async load() {
            if (this.url) {
                let loadingClassName = 'loading-spinner';
                if (this.preloadIfEmpty()) {
                    loadingClassName = 'loading-preload';
                }
                this.element.classList.add(loadingClassName);
                var abort = new AbortController();
                AreaLoader.abortLoading.set(this.element, abort);
                let loader = new load(this.addAjax(this.url));
                let fragment: DocumentFragment | null = null;
                try {
                    fragment = await loader.html(abort.signal);
                    this.element.classList.remove(loadingClassName);
                } catch (e) {
                    console.log(`${(<DOMException>e).message}: ${this.url}`);
                    this.element.classList.add('load-aborted');
                }
                AreaLoader.abortLoading.delete(this.element);

                if (fragment) {
                    while (this.element.lastChild) {
                        this.element.removeChild(this.element.lastChild);
                    }
                    this.element.classList.remove('loading-spinner', 'loading-preload', 'load-aborted');
                    this.element.appendChild(fragment);
                    if (this.initiator == "click") {
                        let clearSelector = this.element.dataset.loadClear;
                        if (clearSelector) {
                            document.querySelectorAll(clearSelector).forEach(e => {
                                while (e.lastChild) {
                                    e.removeChild(e.lastChild);
                                }
                            });
                        }
                    }
                    viggo.load.loadChildren(this.element, "load");
                }
            }
        }

        get isLoadable() {
            return !!this.url;
        }
    }
    fetch('/Scripts/preloader-templates.json').then(x => x.json()).then(x => AreaLoader.preloaders = x);

    viggo.ready(function() {
        viggo.load.loadChildren(document.body, "init");
    });
}
