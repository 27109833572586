module viggo {
    export class cookie {
        public static getCookie(name: string) {
            return this.toObject()[name];
        }
        private static toObject() {
            let result: ObjectOfString = {};
            document.cookie.replace(/(\w+)=([^;]*)(?:\;\s*|$)/g, (all: string, name: string, value: string) => {
                result[name] = value;
                return all;
            });
            return result;
        }
    }
}