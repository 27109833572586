module viggo {
    interface ErrorArchive {
        [filename: string]: number; // number is from Date.now()
    }

    interface TrackedEvent {
        type: string;
        target: string;
        time: Date;
    }

    interface ErrorEventError {
        columnNumber: number;
        fileName: string;
        lineNumber: number;
        message: string;
        stack: string;
    }

    const STARTUP_TIME = new Date();

    export class error {
        protected error: ErrorEventError;
        protected event: ErrorEvent;
        private static errorListener: (error: ErrorEvent) => void;
        private static eventListener: (event: Event) => void;
        private static trackedEvents: TrackedEvent[] = [];
        public constructor(event: ErrorEvent) {
            this.event = event;
            this.error = event.error;
        }
        public get filename() {
            let m = this.event.filename ? this.event.filename.match(new RegExp("^(?:https?://" + window.location.host.replace(".", "\\.") + ")?(.+)")) : null;
            if (m) {
                m[1] = m[1].replace(/([&?]\w+=|\/)[0-9\-,]+\b/g, "$1X").replace(/#modal[a-f0-9\-]+/, "#modal").replace(/\/$/, '');
            }
            return m ? m[1] : this.event.filename || 'Unknown';
        }
        public get lineno() {
            return this.event.lineno || 0;
        }
        public get colno() {
            return this.event.colno || 0;
        }
        public get message() {
            let msg = '';
            if (this.error && this.error.message) {
                msg = this.error.message;
            } else if (this.event && this.event.message) {
                msg = this.event.message;
            }
            return msg;
        }
        public get stack() {
            return this.error ? this.error.stack : null;
        }
        public get identifier() {
            return this.filename + ':' + this.lineno + ':' + this.colno;
        }
        public static analyze(err: any, script: string) {
            let lines = script.split(/\r\n/);
            let lineNumber = err.lineNumber || err.line || err.lineno;
            let columnNumber = err.columnNumber || err.column || err.colno;
            if (typeof lineNumber == 'undefined' && typeof err.stack == 'string') {
                let m = err.stack.match(/<anonymous>:(\d+):(\d+)/);
                if (m) {
                    lineNumber = parseInt(m[1]);
                    columnNumber = parseInt(m[2]);
                }
            }
            if (lineNumber && columnNumber < 256) {
                lines.splice(lineNumber - 2, 0, '-'.repeat(columnNumber - 1) + '^');
                lines = lines.slice(Math.max(0, lineNumber - 6), lineNumber + 3);
            }
            return {
                message: lines.join('\r\n'),
                lineNumber: lineNumber,
                columnNumber: columnNumber
            };
        }
        public static isAppleSpecificBug(err: any) {
            return err.message == "Refused to evaluate a string as JavaScript because 'unsafe-eval' is not an allowed source of script in the following Content Security Policy directive: \"default-src x-apple-ql-id: 'unsafe-inline'\".\n";
        }
        private static foreignErrors = ["Cannot read property '_avast_submit' of undefined"];
        private static isIgnorableError(err: any) {
            return this.foreignErrors.indexOf(err) != -1;
        }
        public report(message: string | null) {
            if (viggo.error.isIgnorableError(this.error)) {
                return;
            }
            let stack = this.stack||'';
            sourceMappedStackTrace.mapStackTrace(stack, (result) => {
                let s = result.join("\n");
                result = [];
                if (s || this.stack) {
                    result.push('Stacktrace: \n' + (s || this.stack || ''));
                }
                s = JSON.stringify(error.trackedEvents, null, 2);
                if (s.length) {
                    result.push('Events: ' + s);
                }
                result.push("Startup: " + STARTUP_TIME.format('HH:mm:ss dd-MM-yyyy'));
                result.push("Location: " + window.location.href);
                let modal = viggo.modal.getLatestModal();
                if (modal && modal.url) {
                    result.push("Modal: " + modal.url);
                }
                new viggo.ajax({
                    method: 'post',
                    url: '/Basic/Error/JavascriptError',
                    convert: 'javascript',
                    json: true,
                    data: {
                        file: this.identifier,
                        errorMessage: this.message.trim(),
                        userMessage: message,
                        userAgent: navigator.userAgent,
                        stackTrace: result.join("\n\n")
                    },
                    error: function () {
                        viggo.notice(viggo.NoticeType.notice, "Der kom et uventet resultat fra serveren. Forsøg venligst at logge ud, og ind igen, hvis problemet fortsætter.", 6000);
                        return true;
                    }
                });
            });
        }
        public isArchived() {
            return !!error.getArchive()[this.identifier];
        }

        public archive() {
            let archive = error.getArchive();
            archive[this.identifier] = Date.now();
            window.localStorage.setItem('error', JSON.stringify(archive));
        }

        public static clearArchive() {
            window.localStorage.removeItem("error");
        }

        public static cleanUp(days = 7) {
            let time = new Date();
            time.setDate(time.getDate() - days);
            let compare = time.getTime();
            let archive = this.getArchive();
            let counter = 0;
            for (let filename in archive) {
                if (archive[filename] < compare) {
                    delete archive[filename];
                    counter++;
                }
            }
            if (counter) {
                window.localStorage.setItem('error', JSON.stringify(archive));
            }
            return counter;
        }

        public static getArchive() {
            let json = <string | null>window.localStorage.getItem('error');
            let result: ErrorArchive;
            if (!json) {
                result = {};
            } else {
                result = JSON.parse(json);
            }
            return result;
        }

        private static getCssSelector(target: Element) {
            let path: string[] = [];
            if (target.nodeType != 1) {
                target = <Element>target.parentNode;
            }
            while (target && target.nodeType == 1) {
                let s = target.tagName.toLowerCase();
                if (target.id) {
                    s += '#' + target.id;
                }
                let className = target.className;
                if (className) {
                    if (className.trim) {
                        className = className.trim();
                    }
                    if (className && className.replace) {
                        s += '.' + className.replace(/\s+/g, '.');
                    }
                }
                path.unshift(s);
                if (target.id && typeof target.id == 'string' && !target.id.match(/\d/)) {
                    break;
                }
                target = <Element>target.parentNode;
            }
            return path.join(' > ');
        }

        private static addTrackedEvent(event: Event) {
            let result: TrackedEvent = {
                type: event.type,
                target: this.getCssSelector(<Element>event.target),
                time: new Date()
            };
            return result;
        }

        public static disable() {
            if (this.errorListener && this.eventListener) {
                window.removeEventListener('error', this.errorListener, true);
                document.removeEventListener('click', this.eventListener, true);
                document.removeEventListener('submit', this.eventListener, true);
                document.removeEventListener('change', this.eventListener, true);
            }
        }

        public static enable() {
            if (!this.errorListener) {
                this.errorListener = (event: ErrorEvent) => {
                    try {
                        if (event.error) {
                            if (this.isAppleSpecificBug(event)) {
                                window.location.reload();
                            } else {
                                let err = new error(event);
                                if (!err.isArchived()) {
                                    err.archive();

                                    viggo.notice(NoticeType.notice, "Client side error reported.", 3000);
                                    if (!viggo.isDevelopment) {
                                        let msg: string|null = null;
                                        if (!ajax.isIdle()) {
                                            msg = JSON.stringify(ajax.requestLog, null, 2);
                                        }
                                        err.report(msg);
                                    }
                                }
                                console.error(event.error);
                            }
                        }
                    } catch (e) {
                        console.error(e);
                    }
                };
            }

            if (!this.eventListener) {
                this.eventListener = (event: Event) => {
                    this.trackedEvents.push(this.addTrackedEvent(event));
                };
            }
            window.addEventListener('error', this.errorListener, true);

            document.addEventListener('click', this.eventListener, true);
            document.addEventListener('submit', this.eventListener, true);
            document.addEventListener('change', this.eventListener, true);
            viggo.ajax.addEventListener('idle', () => {
                this.trackedEvents = [];
            });
        }
    }

    error.enable();
    viggo.error.cleanUp();
}