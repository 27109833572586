namespace viggo {
    export interface ViggoAjaxRequest {
        method: string,
        url: string,
        data?: any,
        tag?: string,
        important?: boolean | null,
        async?: boolean,
        testing?: boolean,
        convert?: boolean | string,
        json?: boolean,
        multisender?: boolean,
        contentType?: string | boolean,
        error?: (xhr: XMLHttpRequest, response: string, exception: Error, contentType: string) => void,
        timestamp?: Date,
        progress?: boolean | ((zeroToOne: number | null) => void) | number,
        complete?: (result: any) => void
    }

    export interface ViggoAjaxMappedRequest extends ViggoAjaxRequest {
        method: string,
        data: any,
        tag: string,
        important: boolean,
        async: boolean,
        testing: boolean,
        convert: boolean,
        json: boolean,
        multisender: boolean,
        contentType: string,
        error?: (xhr: XMLHttpRequest, response: string, exception?: Error, contentType?: string) => boolean | undefined,
        complete: (result: any) => void,
        xhr: XMLHttpRequest
    }

    export interface ViggoAjaxRequestLog {
        method: string;
        url: string;
        referer: string;
        convert: string | boolean;
        multisender: boolean;
        timestamp: string;
    }

    export class ajax extends viggo.classes.eventListener {
        protected encodeDataString(data: {[index: string]: any} | HTMLElement): string | null {
            if (data && typeof data == 'object') {
                let result: string[] = [];
                if (data instanceof HTMLElement) {
                    data.querySelectorAll('input,textarea,select').forEach(e => {
                        let value = ajax.getElementValue(<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>e);
                        if (value) {
                            result.push(value);
                        }
                    });
                } else {
                    let encode = encodeURIComponent;
                    for (let name in data) {
                        let value = data[name];
                        if (value instanceof Array) {
                            value.forEach(e => {
                                if (e instanceof Date) {
                                    e = e.format('yyyy-MM-dd HH:mm:ss');
                                }
                                result.push(encode(name) + "=" + encode(e));
                            });
                        } else {
                            if (value instanceof Date) {
                                value = value.format('yyyy-MM-dd HH:mm:ss');
                            }
                            result.push(encode(name) + "=" + encode(value));
                        }
                    }
                }
                return result.join("&");
            }
            return null;
        }
        private aborted = false;
        public abort() {
            if (this.xhr.readyState != 4) {
                this.aborted = true;
                this.xhr.abort();
                viggo.progress.setProgress(null);
                ajax.removeRequest(this);
            }
        };
        public xhr: XMLHttpRequest;
        public tag: string;
        public important: boolean;
        private static requests: any = {};
        public progress?: (zeroToOne: number|null) => void;
        private callProgress(zeroToOne: number|null): void {
            if (typeof this.progress == 'function') {
                this.progress(zeroToOne);
            } else {
                viggo.progress.setProgress(zeroToOne);
            }
        }

        private getLoadtimes() {
            let json = localStorage.getItem('loadtime');
            return json ? JSON.parse(json) : {};
        }
        private getProgressTime(method: string, url: string) {
            url = url.replace(/^https?:\/\/[^\/]+/, '').replace(/\d+/g, '0');
            let obj = this.getLoadtimes();
            let row = obj['[' + method + ']' + url];
            return row ? <number>row.avg || 0 : 0;
        }
        private setProgressTime(method: string, url: string, time: number) {
            url = url.replace(/^https?:\/\/[^\/]+/, '').replace(/\d+/g, '0');
            let obj = this.getLoadtimes();
            let row = obj['[' + method + ']' + url];
            if (!row) {
                row = { count: 1, avg: time };
            } else {
                row.count++;
                row.avg = (row.avg/(row.count/(row.count-1))) + time / row.count;
            }
            obj['[' + method + ']' + url] = row;

            localStorage.setItem('loadtime', JSON.stringify(obj));
        }
        public static requestLog: ViggoAjaxRequestLog[] = [];
        private static latestUrl: string = "";
        static wasLastRequest(method: string, url: string) {
            return this.latestUrl == `${method.toUpperCase()}:${url}`;
        }
        constructor(object: ViggoAjaxRequest|HTMLFormElement) {
            super();
            let obj = <ViggoAjaxRequest>object;
            let form = <HTMLFormElement>object;
            let xhr = this.xhr = viggo.xhr();
            if (object instanceof HTMLFormElement) {
                obj = ajax.formToObject(form);
            } else if (obj.json) {
                obj.contentType = 'application/json';
                obj.data = JSON.stringify(obj.data);
            } else if (typeof obj.data !== 'string') {
                obj.data = this.encodeDataString(obj.data);
            }
            let map = <ViggoAjaxMappedRequest>viggo.mapDefaults(obj, this.defaults);
            if (map.method.toUpperCase() == 'GET') {
                if (map.data) {
                    map.url += map.url.indexOf('?') == -1 ? '?' : '&';
                    map.url += map.data;
                }
                map.data = null;
            }
            ajax.latestUrl = map.method.toUpperCase() + ':' + map.url;

            this.tag = map.tag;
            this.important = map.important;
            if (this.important === null) {
                this.important = map.method.toLowerCase() != 'get';
            }
    
            //if (lastRequestSent &&
            //    lastRequestSent.method.toLowerCase() == map.method.toLowerCase() &&
            //    lastRequestSent.url == map.url &&
            //    lastRequestSent.data == map.data &&
            //    !lastRequestSent.multisender &&
            //    Date.now() - (<Date>lastRequestSent.timestamp).getTime() < 500 && // 0.5 second delay
            //    !confirm('Du forsøger at sende den samme forespørgsel til serveren igen.\nEr det virkelig det du ønsker?')) {
            //    return;
            //}

            let start = 0;
            if (typeof map.progress === 'function') {
                this.progress = map.progress;
            } else if (map.progress === false) {
                this.progress = () => { };
            } else if (typeof map.progress == 'number') {
                viggo.progress.setProgressTime(map.progress);
            } else {
                let time = this.getProgressTime(map.method, map.url);
                start = Date.now();
                if (time) {
                    time += 100;
                    viggo.progress.setProgressTime(time);
                }
            }

            this.callProgress(0.2);
            xhr.open(
                map.method.toUpperCase(),
                map.url,
                map.async
            );
            var me = this;
            xhr.onreadystatechange = () => {
                this.callProgress((xhr.readyState + 1) / 5);
    
                if (xhr.readyState == 4) {
                    let exception = null;
                    if (!this.aborted) {
                        let contentType = xhr.getResponseHeader('Content-Type') || "";
                        contentType = contentType.split(';')[0];
                        switch (xhr.status) {
                            case 0:
                                // ajax was aborted - no worries (maybe)
                                break;
                            case 200: // OK
                                if (start) {
                                    this.setProgressTime(map.method, map.url, Date.now() - start);
                                }
                                try {
                                    var response = map.convert ? ajax.textToData(contentType, xhr.responseText, document, map.convert) : xhr.responseText;
                                    if (typeof map.complete == 'function') {
                                        map.complete.call(xhr, response);
                                    }
                                    break;
                                } catch (error) {
                                    exception = error;
                                    // continue
                                }
                            case 403: // Forbidden
                            case 404: // Not found
                            case 423: // Locked
                            case 500: // Internal Server Error
                            case 501: // Not Implemented
                            case 502: // Bad Gateway
                            case 503: // Service Unavailable
                            case 504: // Gateway Timeout
                            case 505: // HTTP Version Not Supported
                                if (typeof map.error == 'function') {
                                    map.error(xhr, xhr.responseText, exception, contentType);
                                } else if (xhr.status != 200) {
                                    try {
                                        ajax.textToData(contentType, xhr.responseText, document, 'javascript');
                                    } catch (e) {
                                        if (exception) {
                                            e.innerException = exception;
                                        }
                                        exception = e;
                                    }
                                }
                                if (exception) {
                                    if (viggo.error.isAppleSpecificBug(exception)) {
                                        window.location.reload();
                                    } else {
                                        console.error('Error in url: ' + map.url);
                                        console.error(exception);
                                        if (!viggo.isDevelopment) {
                                            let info: { message: string, lineNumber: number, columnNumber: number } | null = null;
                                            if (['text/javascript', 'application/javascript', 'x-application/javascript'].indexOf(contentType) != -1) {
                                                info = viggo.error.analyze(exception, xhr.responseText);
                                            } else {
                                                info = {
                                                    message: exception.message,
                                                    lineNumber: exception.lineNumber || exception.lineno || exception.line || 0,
                                                    columnNumber: exception.columnNumber || exception.colno || exception.column || 0
                                                };
                                            }
                                            let err = new viggo.error(new ErrorEvent("error", {
                                                colno: info.columnNumber,
                                                error: exception,
                                                filename: map.url,
                                                lineno: info.lineNumber,
                                                message: info.message
                                            }));
                                            err.report(`Status: ${xhr.status}\nRequest log: ${JSON.stringify(ajax.requestLog, null, 2)}` + (exception.innerException ? `\nInner exception: ${JSON.stringify(exception.innerException, null, 2)}` : '') + `\nData: ${map.data}`);
                                        }
                                    }
                                }
                                break;
                            default:
                                if (!this.aborted && (typeof map.error != 'function' || !map.error(xhr, xhr.responseText))) {
                                    alert("Something went wrong with the request.\nSee console for errors. Status " + xhr.status);
                                    console.log(xhr);
                                }
                                break;
                        }
                    }
                    ajax.removeRequest(this);
                    if (ajax.isIdle()) {
                        ajax.dispatchEvent('idle');
                        ajax.requestLog = [];
                    }
                    if (exception && navigator.webdriver) {
                        throw exception;
                    }
                }
            };
            ajax.requestLog.push({
                method: map.method,
                url: map.url,
                referer: window.location.href,
                convert: map.convert,
                multisender: map.multisender,
                timestamp: new Date().format("yyyy-MM-dd HH:mm:ss.fff")
            });
            if (map.testing) {
                console.log('Ajax sending: ' + map.url);
                console.log(map.data);
                this.callProgress(1);
            } else {
                if (!ajax.requests[this.tag]) {
                    ajax.requests[this.tag] = [];
                }
                ajax.requests[this.tag].push(this);
                if (map.contentType) {
                    xhr.setRequestHeader('Content-type', map.contentType);
                }
                xhr.setRequestHeader('X-Request-By', 'ViggoAjax');
                xhr.send(map.data);
                if (viggo.ping) {
                    viggo.ping.reset();
                }
            }
        }
        private defaults: ViggoAjaxRequest = {
            method: 'get',
            url: "",
            data: null,
            tag: "",
            important: null,
            async: true,
            testing: false,
            convert: true,
            json: false,
            multisender: false,
            contentType: "application/x-www-form-urlencoded"
        };
    
        public setFavicon(url: string) {
            let newLink = viggo.dom.tag('link', {
                type: url.match(/\.ico/) ? 'image/x-icon' : 'image/gif',
                rel: 'shortcut icon',
                href: url
            });
            let oldLink = document.querySelector('link[rel~="icon"]');
            if (oldLink) {
                (<HTMLElement>oldLink.parentNode).replaceChild(newLink, oldLink);
            } else {
                document.getElementsByTagName('head')[0].appendChild(newLink);
            }
        }
    
    
        private static removeRequest(xhr: ajax) {
            if (ajax.requests[xhr.tag]) {
                let index = ajax.requests[xhr.tag].indexOf(xhr);
                if (index != -1) {
                    ajax.requests[xhr.tag].splice(index, 1);
                }
            }
        };

        private static getElementValue(element: HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement) {
            let encode = encodeURIComponent;
            let result: string|null = null;
            switch (element.tagName) {
                case 'INPUT':
                    switch (element.type) {
                        case 'button':
                        case 'submit':
                        case 'image':
                            break;
                        case 'checkbox':
                        case 'radio':
                            if (!(<HTMLInputElement>element).checked) {
                                break;
                            }
                        case 'text':
                            if (element.name && element.classList.contains('date') && element.classList.contains('submit-date')) {
                                result = encode(element.name) + '=' + encode(element.value.split('-').reverse().join('-'));
                                break;
                            }
                        case 'hidden':
                        case 'password':
                        case 'file':
                        case 'textarea':
                        default: // new types (datetime, range, etc.)
                            if (element.name && element.className.indexOf('autocomplete') == -1) {
                                result = encode(element.name) + '=' + encode(element.value);
                            }
                            break;
                    }
                    break;
                case 'TEXTAREA':
                    if (element.name) {
                        result = encode(element.name) + '=' + encode(element.value);
                    }
                    break;
                case 'SELECT':
                    let name = encode(element.name);
                    if (name) {
                        if ((<HTMLSelectElement>element).multiple) {
                            result = '';
                            Array.from((<HTMLSelectElement>element).options).forEach(function(e) {
                                if (e.selected) {
                                    result += name + '=' + encode(e.value);
                                }
                            });
                            if (result === '')
                                result = null;
                        } else if ((<HTMLSelectElement>element).selectedIndex !== -1) {
                            result = name + '=' + encode((<HTMLSelectElement>element).options[(<HTMLSelectElement>element).selectedIndex].value);
                        }
                    }
                    break;
                case 'BUTTON':
                    break;
            }
            return result;
        }

        private static getElementTuple(element: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement): [string, string | boolean | number] | null {
            let value: string | boolean | number | null = element.value;
            let name = element.name;
            switch (element.tagName) {
                case 'INPUT':
                    switch (element.type) {
                        case 'button':
                        case 'submit':
                        case 'image':
                            break;
                        case 'checkbox':
                        case 'radio':
                            if (!(<HTMLInputElement>element).checked) {
                                break;
                            }
                        case 'text':
                        case 'hidden':
                        case 'password':
                        case 'file':
                        case 'textarea':
                        default: // new types (datetime, range, etc.)
                            if (element.name && element.className.indexOf('autocomplete') == -1) {
                                switch (element.dataset.jsonType) {
                                    case 'bool':
                                    case 'boolean':
                                        value = parseInt(value);
                                        if (isNaN(value)) {
                                            throw new Error(`Could not convert input "${name}" to boolean`);
                                        }
                                        value = !!value;
                                        break;
                                    case 'int':
                                    case 'integer':
                                        value = parseInt(value);
                                        if (isNaN(value)) {
                                            throw new Error(`Could not convert input "${name}" to integer`);
                                        }
                                        break;
                                    case 'float':
                                    case 'double':
                                    case 'decimal':
                                        value = parseFloat(value);
                                        if (isNaN(value)) {
                                            throw new Error(`Could not convert input "${name}" to float`);
                                        }
                                        break;
                                }
                            }
                            break;
                    }
                    break;
                case 'TEXTAREA':
                    break;
                case 'SELECT':
                    break;
                case 'BUTTON':
                    value = null;
                    break;
            }
            return name && value !== null ? [name, value] : null;
        }

        public static formToObject(form: HTMLFormElement) {
            let object: ViggoAjaxRequest = {
                method: form.getAttribute('method') || 'get',
                url: form.getAttribute('action') || '',
                convert: true
            };
            let type = (form.className.match(/append(?!-query-string)|prepend|replace(?:Owner)?|before|after|ajaxModal/) || ['replace'])[0];
            let target: string = <string>(form.className.match(new RegExp(type + '_([A-Za-z][A-Za-z0-9_\\-]*)')) || ['', null])[1];
            let resetButtons = function (xhr?: XMLHttpRequest) {
                (<NodeListOf<HTMLButtonElement | HTMLInputElement>>form.querySelectorAll('button[type="submit"],input[type="submit"]')).forEach(e => {
                    if (!xhr || xhr.status == 200) {
                        e.disabled = false;
                        e.classList.remove('loading');
                        e.querySelectorAll('span').forEach(x => x.remove());
                    }
                });
            };
            object.error = resetButtons;
            object.complete = function (x: DocumentFragment) {
                resetButtons();
                if ((target || type == 'ajaxModal') && x && x.nodeType == 11) {
                    let element = target ? document.getElementById(target) : null;
                    let init = element;
                    if (element) {
                        switch (type) {
                            case 'replace':
                            case 'ajaxModal':
                                viggo.dom.empty(element);
                                // don't break
                            case 'append':
                                element.appendChild(x);
                            break;
                            case 'prepend':
                                element.insertBefore(x, element.firstChild);
                                break;
                            case 'before':
                                (<Node>element.parentNode).insertBefore(x, element);
                                break;
                            case 'after':
                                (<Node>element.parentNode).insertBefore(x, element.nextSibling);
                                break;
                            case 'replaceOwner':
                                init = <HTMLElement>element.parentNode;
                                let modal = viggo.modal.getLatestModal();
                                if (modal) {
                                    let close = x.querySelector('.close');
                                    if (close) {
                                        close.addEventListener('click', () => {
                                            if (modal) {
                                                modal.close();
                                            }
                                            close!.remove();
                                        });
                                    }
                                }
                                let children = Array.from(x.children);
                                init.replaceChild(x, element);
                                if ((<any>viggo).richeditor) {
                                    children.forEach((node) => {
                                        (<any>viggo).richeditor.initialize(node);
                                    });
                                };
                                break;
                        }
                    }
                    if (type == 'ajaxModal') {
                        if (element) {
                            viggo.modal.show(target);
                        } else {
                            viggo.modal.showElement(x);
                        }
                    }
                    viggo.autocomplete.initialize(<HTMLElement|undefined>init);
                    let aria: Element|null = viggo.dom.parentClass(form, 'dropdown-menu');
                    if (aria) {
                        aria = aria.previousElementSibling;
                        if (aria && aria.getAttribute('aria-expanded')) {
                            aria.setAttribute('aria-expanded', 'false');
                        }
                    }
                }
            };
            let queryString: string[] = [];

            let collectData = (callback: (e: Element) => void) => {
                Array.from(form.elements).forEach(callback);
                if (form.dataset.extraFieldsSelector) {
                    document.querySelectorAll(form.dataset.extraFieldsSelector).forEach(callback);
                }
            };

            if (form.classList.contains('json')) {
                let data: ObjectOfAny = {};
                let callback = (e: Element) => {
                    let tuple = this.getElementTuple(<any>e);
                    if (tuple) {
                        data[tuple[0]] = tuple[1];
                    }
                };
                collectData(callback);
                object.contentType = 'application/json';
                object.data = JSON.stringify(data);
            } else if (form.enctype == 'multipart/form-data') {
                object.data = new FormData(form);
                object.contentType = <any>null;
            } else if (form.classList.contains('ajax-upload')) {
                object.data = new FormData(form);
                object.contentType = false;
            } else {
                let appendQueryString = form.classList.contains('append-query-string');
                let appendAll = !form.querySelector('.append-query-string');
                let data: string[] = [];
                let callback = (e: Element) => {
                    let value = this.getElementValue(<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>e);
                    if (value) {
                        data.push(value);
                        if (appendQueryString && (appendAll || e.classList.contains('append-query-string'))) {
                            queryString.push(value);
                        }
                    }
                };
                collectData(callback);
                object.data = data.join('&');
                if ((<string>object.method).toUpperCase() == 'GET') {
                    if (object.data.length > 0) {
                        object.url += object.url.indexOf('?') === -1 ? '?' : '&';
                        object.url += object.data;
                    }
                    object.data = null;
                }
                if (appendQueryString && queryString.length) {
                    let url = viggo.appendQueryString('?' + queryString.join('&'));
                    url = url.replace(/([&?])ajax=1&?/, '$1');
                    viggo.history.pushState(url, viggo.history.getBestTitle(form));
                }
            }
            return object;
        }

        public static textToData(contentType: string, data: string, ownerDocument?: Document, expect?: string|boolean): any {
            if (!ownerDocument) {
                ownerDocument = document;
            }
            if (contentType) {
                let pos = contentType.indexOf(';');
                if (pos > 1) {
                    contentType = contentType.substring(0, pos);
                }
            }
            if (!expect) {
                expect = true;
            }
            switch (contentType) {
                case 'text/html':
                    if (expect === true || expect == 'html') {
                        let range = ownerDocument.createRange();
                        let m;
                        if (m = data.trim().match(/^<(t[rdh])[\s>]/)) {
                            range.selectNode(document.querySelector(m[1]) || document.body);
                        } else {
                            range.selectNode(document.body);
                        }
                        return range.createContextualFragment(data);
                    }
                    break;
                case 'text/javascript':
                case 'application/javascript':
                case 'application/x-javascript':
                    if (expect === true || expect == 'javascript') {
                        // Don't throw errors. We will handle them elsewhere.
                        let func = new Function(data);
                        return func.call(null);
                    }
                    break;
                case 'application/json':
                    if (expect === true || expect == 'json') {
                        return JSON.parse(data);
                    }
                    break;
                default:
                    if (typeof expect == 'string') {
                        break;
                    } else {
                        return data;
                    }
            }
            throw new TypeError('Unexpected contenttype from ajax. Expected "' + expect + '" but got "' + contentType + '".');
        }

        public static parseResponse = ajax.textToData;

        private static ajaxLoads: any = {};
        public static cancelRequests(tag: string): void {
            if (ajax.requests[tag]) {
                let i = ajax.requests[tag].length;
                while (i--) {
                    let req = ajax.requests[tag][i];
                    if (!req.important) {
                        ajax.requests[tag][i].abort();
                    }
                }
                delete ajax.requests[tag];
            }
        };
        public static cancelAllRequests(): void {
            for (let i in ajax.requests) {
                ajax.cancelRequests(i);
            }
        };
        public static isIdle = (function () {
            let lastIdleTime: number = 0;
            return function isIdle(delayTime: number = 0) {
                let length = 0;
                for (let tag in ajax.requests) {
                    length += ajax.requests[tag].length;
                }
                if (length) {
                    lastIdleTime = 0;
                } else if (!lastIdleTime) {
                    lastIdleTime = Date.now();
                }
                return length == 0 && (Date.now() - lastIdleTime) >= delayTime;
            }
        }());
        public static loadHtml(url: string, id: string, object?: ViggoAjaxRequest, params?: any) {
            if (!viggo.isReady) {
                viggo.ready(function () {
                    ajax.loadHtml(url, id, object);
                });
                return;
            }
            let elm = document.getElementById(id);
            let request = null;
            if (elm) {
                if (ajax.ajaxLoads[id]) {
                    ajax.ajaxLoads[id].abort();
                    delete ajax.ajaxLoads[id];
                }
                if (!object) {
                    object = {
                        method: 'get',
                        url: url
                    };
                }
                object.url = url;
                object.convert = 'html';
                object.progress = false;
                object.error = function (err) {
                    (<HTMLElement>elm).classList.add('load-failed');
                    if (viggo.isDevelopment) {
                        let iframe = viggo.dom.tag('iframe', { style: { width: '100%', height: err.status == 403 ? '150px' : '300px'} });
                        (<HTMLElement>elm).appendChild(viggo.dom.tag('h5', {title: err.responseURL}, `${err.status} ${err.statusText}`));
                        (<HTMLElement>elm).appendChild(iframe);
                        let doc = iframe.contentWindow!.document;
                        doc.open();
                        doc.write(err.responseText);
                        doc.close();
                    }
                    delete ajax.ajaxLoads[id];
                };
                let complete = object.complete;
                object.complete = function (html: DocumentFragment) {
                    delete ajax.ajaxLoads[id];
                    (<HTMLElement>elm).classList.remove('loading-spinner');
                    if (!params) {
                        params = {
                            type: 'replace'
                        };
                    }
                    switch (params.type) {
                        case 'replaceOwner':
                            let parent = (<HTMLElement>elm).parentNode;
                            if (parent) {
                                parent.replaceChild(html, <HTMLElement>elm);
                                elm = <HTMLElement>parent;
                            }
                            break;
                        case 'append':
                            let appendNode = (<HTMLElement>elm);
                            if (appendNode) {
                                appendNode.appendChild(html);
                                elm = <HTMLElement>appendNode;
                            }
                            break;
                        case 'replace':
                        default:
                            viggo.dom.empty(<HTMLElement>elm);
                            (<HTMLElement>elm).appendChild(html);
                            break;
                    }
                    viggo.autocomplete.initialize(<HTMLElement | undefined>elm);
                    if (complete) {
                        complete.call(this, elm);
                    }
                };
                elm.classList.add('loading-spinner');
                request = new ajax(object);
                ajax.ajaxLoads[id] = request;
            }
            return request;
        };
        private static win: Window|null = window;
        public static openExternalPopup(url: string, notice: string, noBar: boolean) {
            if (ajax.win && ajax.win.opener && !ajax.win.opener.closed) return ajax.win.focus();
            let h = window.screen.height / 1.5;
            let w = window.screen.width / 1.5;
            let options = noBar ? 'width=' + w + ',height=' + h : '';
            ajax.win = window.open(url, '_blank', options);
            if (ajax.win === null || typeof ajax.win === 'undefined') {
                return viggo.modal.showAjax('/Basic/FileSharing/PopupWarning');
            } else {
                ajax.win.focus();
                return new Function(notice)();
            }
        };
        public static openWindow(e: Event, url: string) {
            e.preventDefault();
            let hash = url.split("#");
            let el = document.getElementById(hash[1]) || null;
            if (el === null) return viggo.notice(NoticeType.error, 'Wrong syntax');
    
            el.style.animation = "ajaxload 1s alternate infinite";
    
            var win = window.open('/Basic/FileSharing/WaitingForService', '_blank');
    
            win ? win.onload = function() {
                new viggo.ajax({
                    method: 'post',
                    convert: false,
                    url: url,
                    complete: function (data: string) {
                        viggo.history.load(window.location.href);
                        var resp = JSON.parse(data);
    
                        if (resp.Error) {
                            viggo.notice(NoticeType.error, resp.Inner_Error, 10000);
                            if (win) {
                                win.close();
                            }
                            return;
                        } else {
                            new viggo.ajax({
                                method: 'get',
                                url: '/Shared/Ember/OpenFiles',
                                convert: 'html',
                                complete: function (html: DocumentFragment) {
                                    var div = document.getElementById('ember-open-files');
                                    if (div) {
                                        viggo.dom.empty(div);
                                        div.appendChild(html);
                                    }
                                }
                            });
                        }
    
                        if (resp.Type === 'url') {
                            if (win) {
                                win.location.href = resp.Message;
                                win.focus();
                            }
                            return;
                        }
    
                        if (resp.Type === 'notification') {
                            viggo.notice(NoticeType.notice, resp.Message, 10000);
                            if (win) {
                                win.close();
                            }
                            return;
                        }
    
                        if (win) {
                            win.close();
                        }
                    },
                    error: function () {
                        viggo.notice(NoticeType.notice, 'Error', 10000);
                        viggo.history.load(window.location.href);
                        if (el) {
                            el.style.animation = "";
                        }
                        if (win) {
                            win.close();
                        }
                    }
                });
            }:null;
        };

        public static async stream(url: string, init?: RequestInit, onchunk?: (result: ResponseResult) => void) {
            let boundary: string | undefined;
            const response = await fetch(url, init);
            const contentType = response.headers.get('content-type');
            if (contentType) {
                boundary = (contentType.match(/^multipart\/byteranges;\s*boundary=(.*)$/)||[])[1];
            }
            if (boundary && response.body) {
                let decoder = new TextDecoder('utf-8');
                let partial = new MultipartReader(boundary);
                const reader = response.body.getReader();
                let done = false;
                do {
                    let data = await reader.read();
                    done = data.done;
                    if (!done) {
                        let text = decoder.decode(data.value);
                        let results = partial.add(text);
                        for (let chunk of results) {
                            let result = this.getResponse(chunk);
                            if (onchunk) {
                                onchunk(result);
                            }
                        }
                    }
                } while (!done);
            } else {
                throw new Error("Unable to stream content.");
            }
        }

        private static getResponse(chunk: MultipartResponse): ResponseResult {
            let contentType = chunk.header('content-type') || '';
            let result = viggo.ajax.parseResponse(contentType, chunk.body);
            let responseType: ResponseType = "unknown";
            switch (contentType) {
                case 'text/html':
                    responseType = "html";
                    break;
                case 'text/javascript':
                case 'application/javascript':
                case 'application/x-javascript':
                    responseType = "javascript";
                    break;
                case 'application/json':
                    responseType = "json";
                    break;
            }
            return {
                type: responseType,
                value: result,
                response: chunk
            };
        }
    }

    interface ResponseResultBase {
        response: MultipartResponse;
    }
    type ResponseType = "json" | "html" | "javascript" | "unknown";

    interface JsonResponseResult extends ResponseResultBase {
        type: "json",
        value: any;
    }

    interface HtmlResponseResult extends ResponseResultBase {
        type: "html";
        value: DocumentFragment;
    }

    interface JavascriptResponseResult extends ResponseResultBase {
        type: "javascript";
        value: any;
    }

    interface UnknownResponseResult extends ResponseResultBase {
        type: "unknown";
        value: string;
    }

    type ResponseResult = JsonResponseResult | HtmlResponseResult | JavascriptResponseResult | UnknownResponseResult;

    class MultipartResponse {
        private headers: Map<string, string>;
        private content: string;
        constructor(data: string) {
            this.headers = new Map<string, string>();
            const headerContentBoundary = '\r\n\r\n';
            let index = data.indexOf(headerContentBoundary);
            if (index != -1) {
                let headers = data.substring(0, index).split('\r\n');
                headers.forEach(header => {
                    let [name, value] = header.split(/:\s+/);
                    this.headers.set(name.toLowerCase(), value);
                });
                this.content = data.substring(index + headerContentBoundary.length);
                let length = this.header('content-length');
                if (length && parseInt(length) != this.content.length) {
                    throw new Error('Content-Length and body length differs.');
                }
            } else {
                this.content = '';
            }
        }
        public header(name: string) {
            name = name.toLowerCase();
            return this.headers.get(name);
        }
        public get body() {
            return this.content;
        }
    }
    enum ReaderState {
        invalid,
        awaitingBoundary,
        awaitingContent,
        readingChunk,
        chunkFinished,
        finished
    }
    class MultipartReader {
        private buffer: string;
        private boundary: string;
        private state: ReaderState;
        constructor(boundary: string) {
            this.boundary = boundary;
            this.buffer = "";
            this.state = ReaderState.awaitingBoundary;
        }
        public add(buffer: string) {
            this.buffer += buffer;
            let results: MultipartResponse[] = [];
            let result = this.readBuffer();
            while (result != null) {
                results.push(new MultipartResponse(result));
                result = this.readBuffer();
            }
            return results;
        }
        private get dashBoundary() {
            return '--' + this.boundary;
        }
        private readBuffer() {
            let chunks: string[] = [];
            let result: string | null;
            let i = 0;
            do {
                switch (this.state) {
                    case ReaderState.awaitingBoundary:
                        result = this.readUntilBoundary();
                        if (result !== null) {
                            this.removeBoundary();
                            chunks.push(result);
                            this.readAfterBoundary();
                        }
                        break;
                    case ReaderState.awaitingContent:
                    case ReaderState.readingChunk:
                        result = this.readUntilBoundary();
                        if (result !== null) {
                            result = result.substring(0, result.length - 2);
                            chunks.push(result);
                            this.state = ReaderState.chunkFinished;
                        } else {
                            this.state = ReaderState.awaitingContent;
                        }
                        break;
                    case ReaderState.chunkFinished:
                    case ReaderState.finished:
                        break;
                    case ReaderState.invalid:
                        throw new Error("Response state was invalid");
                        break;
                }
                if (i++ > 10) {
                    throw new Error("Too many readings.");
                }
            } while (!this.stateFinished());

            if (this.state == ReaderState.chunkFinished) {
                this.state = ReaderState.awaitingBoundary;
                this.removeUntilBoundary();
                return chunks.join('');
            } else {
                return null;
            }
        }
        private stateFinished() {
            let breakingStates = [ReaderState.finished, ReaderState.awaitingBoundary, ReaderState.awaitingContent, ReaderState.chunkFinished, ReaderState.invalid];
            return breakingStates.indexOf(this.state) != -1;
        }
        private readUntilBoundary() {
            let index = this.buffer.indexOf(this.dashBoundary);
            return index != -1 ? this.buffer.substring(0, index) : null;
        }
        private removeUntilBoundary() {
            let index = this.buffer.indexOf(this.dashBoundary);
            if (index != -1) {
                this.buffer = this.buffer.substring(index);
            }
        }
        private removeBoundary() {
            let index = this.buffer.indexOf(this.dashBoundary);
            if (index != -1) {
                this.buffer = this.buffer.substring(index + this.dashBoundary.length);
            }
        }
        private readAfterBoundary() {
            let nextChars = this.buffer.substring(0, 2);
            let found = false;
            switch (nextChars) {
                case '\r\n':
                    this.state = ReaderState.readingChunk;
                    found = true;
                    break;
                case '--':
                    this.state = ReaderState.finished;
                    found = true;
                    break;
                case '':
                    this.state = ReaderState.awaitingContent;
                    break;
                default:
                    this.state = ReaderState.invalid;
                    break;
            }
            if (found) {
                this.buffer = this.buffer.substring(2);
            }
        }
    }
}
