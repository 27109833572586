module viggo {
    export class editportal {
        private dragging: HTMLElement|null = null;
        private deltaX: number = 0;
        private deltaY: number = 0;
        private positionElement: HTMLElement|null = null;
        private mouseUpListener?: any;
        private mouseDownListener?: any;
        private mouseMoveListener?: any;
        private clickListener?: any;
        private main!: HTMLElement;
        private movedId?: string;
        private static keyDownListener: any;

        private static instance: editportal | null = null;
        public static get isOn() {
            return !!this.instance;
        }
        private constructor() {
            this.reset();
        }
        private static getInstance() {
            return this.instance ? this.instance : this.instance = new editportal();
        }
        private reset() {
            let main = document.querySelector('main');
            if (!main) {
                throw new Error('Missing main');
            }
            this.main = main;
            this.main.addEventListener('mousedown', this.mouseDownListener = this.mousedown.bind(this), false);
            this.main.addEventListener('click', this.clickListener = (event: MouseEvent) => {
                if ((<HTMLElement>event.target).closest('section')) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }, false);
            viggo.dom.removeNonTags(this.main);
        }
        private move(event: MouseEvent) {
            if (event.clientY < 50) {
                // scroll up
            } else if (event.clientY > document.documentElement!.offsetHeight - 50) {
                // scroll down
            }
            let x = event.pageX,
                y = event.pageY;
            if (this.dragging) {
                this.dragging.style.left = x - this.deltaX + 'px';
                this.dragging.style.top = y - this.deltaY + 'px';
            }
            let element = <Element | HTMLElement | null>document.elementFromPoint(x, y);
            let section: Element | null = null;
            let parent: Element | null = null;
            if (element) {
                section = element.closest('section');
                if (section) {
                    parent = section.parentElement;
                } else {
                    parent = element.closest('main>div[class*="column-"]');
                }
            }

            if (parent && this.positionElement && section != this.positionElement) {
                if (section) {
                    if (section.previousElementSibling == this.positionElement) {
                        section = this.positionElement.nextElementSibling;
                        if (section) {
                            section = section.nextElementSibling;
                        }
                    }
                }
                parent.insertBefore(this.positionElement, section);
            }
        }
        private mouseup(event: MouseEvent) {
            if (this.positionElement && this.dragging) {
                this.dragging.remove();
                this.positionElement.style.opacity = '';
                let columns = [
                    Array.from(document.querySelectorAll('main>.column-1>section')).map(section => section.id),
                    Array.from(document.querySelectorAll('main>.column-2>section')).map(section => section.id)
                ];
                if (this.movedId && columns[0].indexOf(this.movedId) == -1 && columns[1].indexOf(this.movedId) == -1) {
                    new viggo.ajax({
                        method: 'post',
                        url: '/Shared/Portal/SaveRemove',
                        data: {
                            MovedItem: this.movedId
                        }
                    });
                } else {
                    new viggo.ajax({
                        method: 'post',
                        url: '/Shared/Portal/SavePortal',
                        json: true,
                        data: columns
                    });
                }
            }
            this.dragging = null;
            this.removeEventListeners();
            this.reset();
        }
        private scroll() {
            setTimeout(() => {
                if (this.positionElement) {
                    let next = this.positionElement.nextSibling;
                    let parent = this.positionElement.parentNode;
                    if (parent) {
                        parent.removeChild(this.positionElement);
                        parent.insertBefore(this.positionElement, next);
                    }
                }
            }, 200);
        }
        private mousedown(event: MouseEvent) {
            let target = viggo.dom.parent(<Element>event.target, 'section');
            if (target) {
                event.preventDefault();
                event.stopPropagation();
                let pos = target.getBoundingClientRect();
                this.deltaX = event.pageX - pos.left;
                this.deltaY = event.pageY - pos.top;
                this.positionElement = target;

                let next = target.nextSibling, parent = target.parentNode;
                if (parent) {
                    this.dragging = <HTMLElement>target.cloneNode(true);
                    parent.insertBefore(this.dragging, this.positionElement);
                    this.positionElement.style.opacity = '0.5';
                    this.dragging.style.pointerEvents = 'none';
                    this.dragging.style.width = viggo.getStyle(this.dragging, 'width');
                    this.dragging.style.position = 'fixed';
                    this.dragging.style.zIndex = '20';
                    this.dragging.style.opacity = '0.5';
                    this.removeEventListeners();
                    this.main.addEventListener('mousemove', this.mouseMoveListener = this.move.bind(this), false);
                    document.addEventListener('mouseup', this.mouseUpListener = this.mouseup.bind(this), false);
                    this.movedId = target.id;
                    this.move(event);
                }
            }
        }
        private removeEventListeners() {
            this.movedId = undefined;
            if (this.mouseMoveListener) {
                this.main.removeEventListener('mousemove', this.mouseMoveListener, false);
                this.mouseMoveListener = null;
            }
            if (this.mouseUpListener) {
                document.removeEventListener('mouseup', this.mouseUpListener, false);
                this.mouseUpListener = null
            }
            if (this.mouseDownListener) {
                this.main.removeEventListener('mousedown', this.mouseDownListener, false);
                this.mouseDownListener = null;
            }
            if (this.clickListener) {
                this.main.removeEventListener('click', this.clickListener, false);
                this.clickListener = null;
            }
        }
        private turnOn() {
            var span = {
                className: 'remove btn',
                onmousedown: (event: MouseEvent) => {
                    event.stopPropagation();
                    event.preventDefault();
                },
                onclick: (event: MouseEvent) => {
                    event.stopPropagation();
                    event.preventDefault();
                    let box = viggo.dom.parent(<Element>event.target, 'section');
                    if (box) {
                        new viggo.ajax({
                            method: 'post',
                            url: '/Shared/Portal/SaveRemove',
                            data: {
                                MovedItem: box.id
                            }
                        });
                        let boxPos = box.getBoundingClientRect();
                        let column = document.querySelector('main>.column-deleted');
                        if (column) {
                            let delPos = column.getBoundingClientRect();
                            var x = delPos.left - boxPos.left;
                            var y = delPos.top - boxPos.top;
                            new viggo.effect({
                                from: 'translate(0px, 0px) scale(1)',
                                to: 'translate(' + x + 'px, ' + y + 'px) scale(0.2)',
                                type: 'easeinout',
                                removeStyle: true,
                                duration: 300,
                                element: box,
                                style: 'transform',
                                complete: () => {
                                    if (column && box) {
                                        column.insertBefore(box, column.firstChild);
                                    }
                                }
                            });
                        }
                    }
                }
            };

            document.querySelectorAll('main>[class^="column-"]>section>.head').forEach(head => head.appendChild(viggo.dom.tag('span', span, viggo.dom.tag('i', {className: 'flaticon-cross'}))));

            let main = document.querySelector('main');
            if (main) {
                main.classList.add('edit-portal');
                new viggo.ajax({
                    method: 'get',
                    url: '/Shared/Portal/GetDeleted',
                    convert: 'html',
                    complete: (html: DocumentFragment) => {
                        if (main) {
                            html.querySelectorAll('section>.head').forEach(head => head.appendChild(viggo.dom.tag('span', span, viggo.dom.tag('i', { className: 'flaticon-cross' }))));
                            main.appendChild(html);
                        }
                    }
                });
            }
        }
        private turnOff() {
            this.removeEventListeners();
            editportal.instance = null;
        }
        public static turnOff() {
            document.querySelectorAll('main > [class^="column-"] > section > .head > .remove, main > .column-deleted').forEach(x => x.remove());
            let main = document.querySelector('main');
            if (main) {
                main.classList.remove('edit-portal');
            }
            this.getInstance().turnOff();
            document.removeEventListener('keydown', this.keyDownListener, false);
        }
        public static turnOn() {
            if (viggo.isMobileDevice)
                return;
            if (!viggo.ajax.isIdle()) {
                var f = function () {
                    viggo.ajax.removeEventListener('idle', f);
                    viggo.editportal.turnOn();
                };
                viggo.ajax.addEventListener('idle', f);
                return;
            } else if (document.querySelector('#main>.column>.loading-spinner')) {
                return alert('Vent med at redigere forsiden til alle sider elementer er hentet.');
            }
            if (this.isOn) {
                this.turnOff();
                if (confirm('Forsiden kunne ikke redigeres. Vil du prøve igen?')) {
                    this.turnOn();
                }
                return;
            }

            this.getInstance().turnOn();
            document.addEventListener('keydown', this.keyDownListener = (event: KeyboardEvent) => {
                if (event.key == 'Escape') {
                    this.turnOff();
                }
            }, false);
        }
        public static toggle() {
            if (this.isOn) {
                this.turnOff();
            } else {
                this.turnOn();
            }
        }
    }
}