
/// <reference path="viggo.ts" />
/// <reference path="viggo.dom.ts" />
/// <reference path="viggo.ajax.ts" />


(function () {
    function disableButtons(form: HTMLFormElement) {
        (<NodeListOf<HTMLInputElement | HTMLButtonElement>>form.querySelectorAll('button[type="submit"],input[type="submit"]')).forEach(e => {
            e.disabled = true;
            e.classList.add('loading');
            e.appendChild(viggo.dom.tag('span'));
        });
    }
    function enableButtons(form: HTMLFormElement) {
        (<NodeListOf<HTMLInputElement | HTMLButtonElement>>form.querySelectorAll('button[type="submit"],input[type="submit"]')).forEach(e => {
            e.disabled = false;
            e.classList.remove('loading');
            e.querySelectorAll('span').forEach(x => x.remove());
        });
    }
    // submit forms by ajax
    document.addEventListener('submit', function (event) {
        let form = (<HTMLElement>event.target).closest('form');
        if (form && form.classList.contains('ajax')) {
            disableButtons(form);
            new viggo.ajax(form);
            event.preventDefault();
        }
    }, false);

    document.addEventListener('submit', function (event) {
        let form = (<HTMLElement>event.target).closest('form');
        if (form && form.classList.contains('ajax-stream')) {
            disableButtons(form);
            let formData = new FormData(form);
            let type = (form.className.match(/append|prepend|replace/) || ['append'])[0];
            let selectorId: string = <string>(form.className.match(new RegExp(type + '_([A-Za-z][A-Za-z0-9_\\-]*)')) || ['', null])[1];

            let messageElement = selectorId ? document.getElementById(selectorId) : null;
            if (messageElement) {
                viggo.dom.empty(messageElement);
            }
            viggo.ajax.stream(form.action, {
                method: form.method,
                body: formData
            }, (response) => {
                if (messageElement) {
                    switch (response.type) {
                        case 'html':
                            switch (type) {
                                case 'replace':
                                    viggo.dom.empty(messageElement);
                                case 'append':
                                    messageElement.appendChild(response.value);
                                    break;
                                case 'prepend':
                                    messageElement.insertBefore(response.value, messageElement.firstChild);
                                    break;
                            }
                            break;
                        case 'json':
                            switch (response.response.header('x-json-type')) {
                                case 'ProgressElement':
                                    let json = response.value;
                                    let element = document.getElementById(json.Id);
                                    let created = false;
                                    if (!element) {
                                        element = viggo.dom.tag('div', { id: json.Id, className: 'progress-element' }, viggo.dom.tag('div', {className: 'name'}, json.Name), viggo.dom.tag('progress'));
                                        messageElement.appendChild(element);
                                        created = true;
                                    }
                                    let progress = element.querySelector('progress')!;
                                    progress.max = json.Total;
                                    if (!created) {
                                        element.querySelector('.progress-element .name')!.textContent = `${json.Name} (${json.Counter}/${json.Total})`;
                                        progress.value = json.Counter;
                                    }
                                    break;
                            }
                            break;
                    }
                }
            }).then(x => {
                if (messageElement) {
                    messageElement.querySelectorAll('progress').forEach(p => p.value = p.max);
                }
                if (form) {
                    enableButtons(form);
                }
            });
            event.preventDefault();
        }
    });

    document.addEventListener('submit', function (event) {
        //console.log(event);
        var form = (<HTMLElement>event.target).closest('form');
        //console.log(form);
        if (form && form.classList.contains('copy-schoolyear-signalr')) {
            disableButtons(form);
            event.preventDefault();
            var formData = Object.fromEntries(new FormData(form));
            console.log(formData);
            var connection = new signalR.HubConnectionBuilder().withUrl("/copySchoolYearHub", {skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets}).build();

            connection.on("CreateProgress", function(id: string, name: string, subProgress: string) {
                var subProgressClass = subProgress.toLowerCase() === "true" ? " subprocess" : "";
                var element = viggo.dom.tag('div', { id: "copy-" + id, className: 'progress-element' + subProgressClass }, viggo.dom.tag('div', {className: 'name'}, name), viggo.dom.tag('progress'));
                document.getElementById("loading-message")?.appendChild(element);
            });

            connection.on("UpdateProgress", function(id: string, name: string, index: number, total: number) {
                var element = document.getElementById("copy-" + id);

                if (element) {
                    let progress = element.querySelector('progress')!;
                    progress.max = total;

                    element.querySelector('.progress-element .name')!.textContent = `${name} (${index}/${total})`;
                    progress.value = index;
                }
            });

            connection.on("RawJavascript", function(command: string) {
                eval(command);
                (<HTMLElement>document.querySelector(".loading-bars")).style.display = "none";

                var e = <HTMLButtonElement>document.querySelector("button[type='submit']");
                e.disabled = false;
                e.classList.remove('loading');
                e.querySelectorAll('span').forEach(x => x.remove());

                connection.close();
            });

            connection.on("OnException", function(ex: string) {
                viggo.notice(viggo.NoticeType.error, ex , 4000);
            });

            connection.start().then(function() {
                connection.invoke("CopySchoolYear", formData);
            });
        }
    }, false); 

}());

// selectable lists
document.addEventListener('click', function (event) {
    if (event.button !== 0) {
        return;
    }
    let target = <HTMLElement>event.target;
    let link = target.closest('a');
    if (link) {
        if ((<HTMLElement>link.parentNode).tagName == 'LI' && viggo.dom.parentClass(<Element>link.parentNode, 'selectable')) {
            let selectItems = function (from: Element, to: Element | null) {
                let i = 0;
                while (from && from !== to) {
                    from = <Element>from.parentNode;
                    from.classList.add('selected');
                    if (i++) {
                        from.classList.add('subselected');
                    } else {
                        from.classList.remove('subselected');
                    }
                    from = <Element>from.parentNode;
                }
            };

            let topList = link.closest(':not(li) > ul');

            let unselectAll = function () {
                // clear all selected classes
                let keepSelected = (<HTMLElement>topList).dataset.selectableClick == 'keep';
                topList?.querySelectorAll('.selected').forEach(e => {
                    if (keepSelected) {
                        e.classList.add('subselected');
                    } else {
                        e.classList.remove('selected');
                        e.classList.remove('subselected');
                    }
                });
            };
            // get the top most list
            if (target.classList.contains('folder-expand')) {
                let li = target.closest('li');
                if (li) {
                    li.classList.toggle('selected');
                    if (li.classList.contains('selected')) {
                        li.classList.add('subselected');
                    } else {
                        li.classList.remove('subselected');
                    }
                    event.stopPropagation();
                    event.preventDefault();
                }
            } else {
                unselectAll();
                // set class selected on li until we hit the top list
                selectItems(link, topList);
            }
        }
    }
}, false);

// ajax-toggle
document.addEventListener('click', function (event) {
    if (event.button !== 0) {
        return;
    }
    let link = <HTMLAnchorElement | null>(<HTMLElement>event.target).closest('a.ajax-toggle');
    if (link) {
        event.preventDefault();
        event.stopPropagation();
        let classes = (link.dataset.toggleClasses || 'off on').split(' ');
        let isOn = link.classList.contains(classes[1]);

        let reset = function (value: boolean) {
            link!.classList.remove(value ? classes[0] : classes[1]);
            link!.classList.add(value ? classes[1] : classes[0]);
        };
        reset(!isOn);
        if (isOn && link.matches('a[href*="settings=Notification"]')) {
            link.parentElement!.querySelectorAll('a.ajax-toggle').forEach(x => {
                x.classList.remove(classes[1]);
                x.classList.add(classes[0]);
            });
        }

        new viggo.ajax({
            method: 'put',
            url: (<HTMLAnchorElement>link).href.replace('%s', isOn ? "0" : "1"),
            convert: true,
            complete: function (data) {
                if (!data && data !== '' && data !== undefined) {
                    reset(isOn);
                }
            },
            error: function () {
                reset(isOn);
            }
        });
    }
}, false);

// ajaxExecute
document.addEventListener('click', function (event) {
    if (event.button !== 0) {
        return;
    }
    let link = (<HTMLElement>event.target).closest('a');
    if (link) {
        let m = link.className.match(/ajax(Get|Post|Put|Delete)?Execute/);
        if (m) {
            event.preventDefault();
            event.stopPropagation();
            new viggo.ajax({
                method: m[1] || 'get',
                url: (<HTMLAnchorElement>link).href,
                convert: 'javascript',
                complete: () => {
                    let event = new CustomEvent('viggoAjaxExecute', { detail: (m![1]||'get').toLowerCase(), bubbles: true, cancelable: false });
                    link!.dispatchEvent(event);
                    let selector = link?.dataset.removeParent;
                    if (selector) {
                        link!.closest(selector)?.remove();
                    }
                }
            });
        }
    }
}, false);

// ajaxToggleForm
document.addEventListener('click', function (event) {
    if (event.button !== 0) {
        return;
    }
    let target = <HTMLElement>event.target;
    let input = <HTMLInputElement|null>target.closest('input[type="checkbox"]');
    if (input && input.form && input.form.classList.contains('ajaxToggleForm')) {
        let inputs = <NodeListOf<HTMLInputElement>>input.form.querySelectorAll('input[type="checkbox"]');
        let checked = false;
        let value: any = null;
        for (let i = inputs.length - 1; i >= 0; i--) {
            if (input == inputs[i]) {
                checked = true;
                if (input.checked) {
                    value = input.value;
                } else {
                    value = true;
                }
            } else {
                if (value === true) {
                    value = inputs[i].value;
                }
                inputs[i].checked = checked;
            }
        }
        if (value === true) {
            value = parseInt(input.value) - 1;
        }
        new viggo.ajax({
            method: 'put',
            url: input.form.action,
            convert: true,
            data: {
                editrights: value
            }
        });
    }
}, false);

// aria-expanded
document.addEventListener('click', function (event) {
    if (event.button !== 0) {
        return;
    }
    let target = <HTMLElement>event.target;
    let link = <HTMLAnchorElement | HTMLInputElement | HTMLButtonElement | null>target.closest('[aria-expanded]');

    if (link) {
        let expanded = link.getAttribute('aria-expanded');
        if (expanded && expanded == "false") {
            link.setAttribute('aria-expanded', "true");
            let f = (ev: Event) => {
                let sibling = (<HTMLElement>link).nextElementSibling;
                let target = <Element>ev.target;
                if (link!.contains(target) || !sibling || !sibling.contains(target) || target.closest('button[type="submit"]')) {
                    (<HTMLElement>link).setAttribute('aria-expanded', "false");
                    document.removeEventListener('click', f, false);
                }
            };
            document.addEventListener('click', f, false);
        }
    }
}, false);

// handle # references
document.addEventListener('click', function (event) {
    let target = (<HTMLElement>event.target).closest('a');
    if (target) {
        let href = target.getAttribute('href');
        if (href) {
            let match = href.match(/^#(.+)/);
            if (match) {
                event.preventDefault();
                event.stopPropagation();
                let id = match[1];
                let element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "start"
                    });
                    const classname = 'focused-target';
                    element.classList.add(classname);
                    setTimeout(function () {
                        if (element) {
                            element.classList.remove(classname);
                        }
                    }, 2500);
                }
            }
        }
    }
}, false);