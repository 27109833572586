module viggo {
    interface DynamicListOptions {
        element: HTMLElement;
        itemHeight: number;
        itemCount: number;
        stickyClass?: string;
    }
    export class dynamiclistview {
        private parent: HTMLElement;
        private items: HTMLElement[];
        private defaultHeight: number;
        private heights: number[];
        private fillTop: HTMLElement;
        private fillBottom: HTMLElement;
        private oldIndex: number;
        private count: number;
        private stickyClass?: string;

        public constructor(options: DynamicListOptions) {
            this.parent = options.element;
            this.defaultHeight = options.itemHeight;
            this.stickyClass = options.stickyClass;
            this.count = options.itemCount;
            this.fillTop = viggo.dom.tag('li', { style: {padding: '0'} });
            this.fillBottom = viggo.dom.tag('li', { style: { padding: '0'} });
            this.oldIndex = 0;
            this.items = [];
            this.heights = [];
            this.parent.addEventListener('scroll', this.scroll.bind(this), false);
        }

        private scroll() {
            let index = this.getIndex(this.parent.scrollTop);
            let distance = Math.floor(this.count / 6);
            if (Math.abs(index - this.oldIndex) > distance || (this.oldIndex <= distance && index <= distance)) {
                this.moveWindow(index);
                this.oldIndex = index;
            }
        }

        private getIndex(top: number) {
            let sum = 0;
            let index = -1;
            while (top > sum && index < this.heights.length) {
                index++;
                sum += this.heights[index] || this.defaultHeight;
            }
            index -= Math.floor(this.count / 3);
            return Math.min(Math.max(0, index), this.heights.length - Math.floor(this.count / 3));
        }

        private moveWindow(index: number) {
            if (this.items.length) {
                let end = Math.min(this.items.length, index + this.count);
                let child;
                while (child = this.parent.firstChild) {
                    this.parent.removeChild(child);
                }
                this.updateFill(index);
                this.parent.appendChild(this.fillTop);
                for (let i = 0; i < end; i++) {
                    let item = this.items[i];
                    if (i >= index || (this.stickyClass && item.classList.contains(this.stickyClass))) {
                        this.parent.appendChild(item);
                        if (!this.heights[i]) {
                            this.heights[i] = item.offsetHeight;
                        }
                    }
                }
                this.parent.appendChild(this.fillBottom);
            }
        }

        private updateFill(index: number) {
            let top = 0;
            let bottom = 0;
            for (let i = 0; i < index; i++) {
                top += this.heights[i] || this.defaultHeight;
            }
            for (let i = index + this.count; i < this.items.length; i++) {
                bottom += this.heights[i] || this.defaultHeight;
            }
            this.fillTop.style.height = top + 'px';
            this.fillBottom.style.height = bottom + 'px';
        }

        public appendChild(item: HTMLElement) {
            this.items.push(item);
            this.heights.push(0);
        }

        public render() {
            this.moveWindow(0);
        }
    }
}
