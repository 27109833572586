module viggo.dom {
    const specialAttrs = { style: 1, dataset: 1 };
    export function tag<K extends keyof HTMLElementTagNameMap>(name: K, attributes?: any, ...children: any[]): HTMLElementTagNameMap[K] {
        var tag = document.createElement(name);
        if (attributes) {
            for (var attr in attributes) {
                var value = attributes[attr];
                if (attr in specialAttrs) {
                    for (var property in value) {
                        (<any>tag)[attr][property] = value[property];
                    }
                } else if (attr.substring(0, 2) == 'on') {
                    tag.addEventListener(attr.substring(2), value, false);
                } else {
                    (<any>tag)[attr] = value;
                }
            }
        }
        for (var i = 0; i < children.length; i++) {
            let child = children[i];
            if (typeof child == 'string' || typeof child == 'number')
                child = viggo.dom.text(child);
            if (child) {
                tag.appendChild(<Node>child);
            }
        }
        return tag;
    }
    export function svg<K extends keyof SVGElementTagNameMap>(name: K, attributes?: any, ...children: any[]): SVGElementTagNameMap[K] {
        var tag = document.createElementNS('http://www.w3.org/2000/svg', name);
        if (attributes) {
            for (var attr in attributes) {
                tag.setAttribute(attr, attributes[attr]);
            }
        }
        for (var i = 0; i < children.length; i++) {
            let child = children[i];
            if (typeof child == 'string' || typeof child == 'number') {
                tag.textContent = child.toString();
            } else {
                tag.appendChild(<Node>child);
            }
        }
        return tag;
    }
    export function fragment(...elements: (Node|null)[]): DocumentFragment {
        var fragment = document.createDocumentFragment();
        for (var i = 0; i < elements.length; i++) {
            if (elements[i]) {
                fragment.appendChild(<Node>elements[i]);
            }
        }
        return fragment;
    }
    export function text(text: string|number): Text {
        return document.createTextNode(<string>text);
    }
    export function parseHTML(html: string): DocumentFragment {
        return viggo.ajax.parseResponse('text/html', html, document, 'html');
    }
    export function betweenTags(text: string, tag: string): string {
        let startPos = text.indexOf('<' + tag);
        if (startPos != -1) {
            startPos = text.indexOf('>', startPos + tag.length);
            if (startPos != -1) {
                let endPos = text.indexOf('</' + tag, startPos);
                if (endPos != -1) {
                    return text.substring(startPos + 1, endPos);
                }
            }
        }
        return "";
    }
    export function getPos(element: HTMLElement, includeScroll?: boolean) {
        let left = 0, top = 0;
        let scroll = element;
        do {
            left += element.offsetLeft;
            top += element.offsetTop;
        } while (element = <HTMLElement>element.offsetParent);
        if (includeScroll) {
            do {
                left -= scroll.scrollLeft;
                top -= scroll.scrollTop;
            } while ((scroll = <HTMLElement>scroll.parentNode) && scroll.nodeType == 1);
        }
        return { left: left, top: top };
    }
    export function empty(elm: Node): DocumentFragment {
        var fragment = viggo.dom.fragment();
        while (elm && elm.lastChild) {
            fragment.appendChild(elm.lastChild);
        }
        return fragment;
    }
    export function removeNonTags(node: Element): void {
        for (var i = node.childNodes.length - 1; i >= 0; i--) {
            if (node.childNodes[i].nodeType != 1) {
                node.removeChild(node.childNodes[i]);
            }
        }
    }
    export function remove(...nodes: any[]): void {
        for (var i = 0; i < nodes.length; i++) {
            var id = nodes[i];
            var elm = typeof id == 'string' ? document.getElementById(id) : id;
            if (elm) {
                elm.parentNode.removeChild(elm);
            }
        }
    }
    // takes a node and a filter. When the filter returns true, that node is returned
    export function parentFilter(child: Element, filter: (child: Element) => boolean): Element|null {
        if (child && child.nodeType != 1 && child.parentNode) {
            child = <Element>child.parentNode;
        }
        while (child && child.nodeType == 1) {
            if (filter.call(child, child)) {
                return child;
            } else {
                child = <Element>child.parentNode;
            }
        }
        return null;
    }
    export function parent<S extends keyof HTMLElementTagNameMap>(child: Element, parentTag: S): HTMLElementTagNameMap[S] | null {
        return child ? <HTMLElementTagNameMap[S]>viggo.dom.closest(child, parentTag) : null;
    }
    export function parentDataset(child: HTMLElement, name: string, value?: string) {
        var callback = typeof value == 'undefined' ? (elm: Element) => {
            return name in (<HTMLElement>elm).dataset;
        } : (elm: Element) => {
            return name in (<HTMLElement>elm).dataset && (<HTMLElement>elm).dataset[name] == value;
        }
        return viggo.dom.parentFilter(child, callback);
    }
    export function parentClass(child: Element, className: string) {
        return viggo.dom.closest(child, '.' + className);
    }
    export function parentId(child: Element, id: string) {
        return viggo.dom.closest(child, '#' + id);
    }
    export function parentOf(child: Element, parent: Element) {
        return parent.contains(child);
    }
    export function closest(child: Element, selector: string) {
        if (child.nodeType != 1) {
            child = <Element>child.parentNode;
        }
        return child ? child.closest(selector) : null;
    }
};