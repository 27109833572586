module viggo {
    export class ping {
        protected delay: number = 10 * 60 * 1000;
        protected timeout: number = 0;
        protected static instance: ping;
        private static logId = viggo.cookie.getCookie('LogId');
        protected constructor() { }
        private isLoggedIn() {
            return viggo.isLoggedIn() && !document.getElementById('login-window');
        }
        protected ping() {
            if (this.isLoggedIn() && window.navigator.onLine) {
                if (ping.logId != viggo.cookie.getCookie('LogId')) {
                    window.location.href = "/";
                    return;
                }
                let err = () => {
                    if (!viggo.isMobileApp) {
                        viggo.notice(NoticeType.warning, __('You have been logged out for inactivity'), -1);
                        var tag = document.body.appendChild(viggo.dom.tag('div', { id: 'login-window' }));
                        new viggo.ajax({
                            method: 'get',
                            url: '/Basic/Account/LoginWindow',
                            complete: (html: DocumentFragment) => tag.appendChild(html)
                        });
                    }
                };
                new viggo.ajax({
                    method: 'get',
                    url: '/Basic/Ping',
                    progress: false,
                    convert: false,
                    error: err,
                    complete: (data: string) => {
                        if (data != "pung" && this.isLoggedIn()) {
                            err();
                        }
                    }
                });
            } else {
                this.reset();
                // TODO: set eventlistener for window.navigator.onLine
            }
        }
        protected static getInstance(): ping {
            if (!this.instance) {
                this.instance = new ping();
            }
            return this.instance;
        }
        protected reset() {
            clearTimeout(this.timeout);
            this.timeout = window.setTimeout(() => {
                this.ping();
            }, this.delay);
        }
        public static ping() {
            this.getInstance().ping();
        }
        public static reset() {
            this.getInstance().reset();
        }
        public static removeLoginWindow() {
            let e = document.getElementById('login-window');
            if (e) {
                e.remove();
                ping.ping();
            }
        }
    }
    ping.reset();
}
window.addEventListener('visibilitychange', function () {
    if (document.visibilityState == 'visible') {
        viggo.ping.ping();
    }
}, false);
window.addEventListener('online', function () {
    viggo.ping.ping();
}, false);