module viggo {
    let check = () => {
        let targets = document.querySelectorAll('.pagination li.next>a,.pagination tr.next[data-pagination-url]');
        let bottom = window.pageYOffset + document.documentElement!.offsetHeight;
        let distance = 700;
        targets.forEach((e) =>  {
            let rect = e.getBoundingClientRect();
            let height = Math.max(document.documentElement!.clientHeight, window.innerHeight);
            if ((rect.top || rect.height || rect.left || rect.width) && rect.top - height <= distance) {
                let href: string;
                let parent: Node|null = e;
                if (e.tagName == 'A') {
                    href = (<HTMLAnchorElement>e).href;
                    parent = parent.parentNode;
                    e.remove();
                } else {
                    href = (<HTMLElement>e).dataset.paginationUrl || '';
                    delete (<HTMLElement>e).dataset.paginationUrl;
                }
                new viggo.ajax({
                    method: 'get',
                    url: href,
                    convert: 'html',
                    complete: function (html: DocumentFragment) {
                        if (parent && parent.parentNode) {
                            parent.parentNode.replaceChild(html, parent);
                        }
                        check();
                    }
                });
            }
        });
    };

    //document.addEventListener('wheel', check, true);
    document.addEventListener('scroll', check, true);
    window.addEventListener('resize', check, true);
    document.addEventListener('statepushed', check, false);
    viggo.ready(check);
}