module viggo {
    interface ModalOptions {
        element?: Element|DocumentFragment|null;
        url?: string;
        complete?: (() => void);
        pushState?: boolean;
        sender?: Element;
    }

    interface ModalUrlNavigation {
        next?: string;
        previous?: string;
        target?: HTMLElement;
    }

    export class modal extends viggo.classes.eventListener {
        private static modalList: modal[] = [];
        private static allClosed = true;
        public element: HTMLElement | null = null;
        public url?: string;
        private sender?: Element;
        constructor(object?: ModalOptions) {
            super();
            modal.modalList.push(this);
            if (object) {
                this.sender = object.sender;
                if (object.element) {
                    this.show(object.element);
                } else if (object.url) {
                    this.url = object.url;
                    this.showAjax(object.url, object.complete, object.pushState);
                }
            }
        }

        public show(element?: Node) {
            this.createModal(element);
            modal.allClosed = false;
            document.documentElement!.classList.add('modal');

            if (element) {
                this.focusFirst();
                var close = this.element!.getElementsByClassName('close')[0] || this.element!.appendChild(viggo.dom.tag('div', { className: 'close' }));
                close.addEventListener('click', () => {
                    this.close();
                    close.remove();
                }, false);
            }

            this.dispatchEvent('show', { target: this.element, modal: this });
            viggo.modal.dispatchEvent('show', { target: this.element, modal: this });
        }

        private nextModal() {
            let url = this.getNavigation().next;
            if (url) {
                this.url = url;
                this.reload();
            }
        }

        private previousModal() {
            let url = this.getNavigation().previous;
            if (url) {
                this.url = url;
                this.reload();
            }
        }

        public createModal(child?: Node) {
            let me = this;
            let isCaptureClick = false;
            this.element = viggo.dom.tag('div', {
                className: 'modal',
                onkeydown: function (event: KeyboardEvent) {
                    if (event.type == 'keydown' && event.keyCode == 27) {
                        me.close(event);
                        event.stopPropagation();
                    }
                },
                onclick: function (event: MouseEvent) {
                    if (event.target == this && isCaptureClick) {
                        me.close(event);
                    }
                },
                onscroll: function (event: MouseEvent) {
                    var ev = {
                        target: this,
                        event: event,
                        modal: me
                    };
                    me.dispatchEvent('scroll', ev);
                    viggo.modal.dispatchEvent('scroll', ev);
                }
            }, child);

            this.element.addEventListener('mousedown', function (event) {
                isCaptureClick = event.target == this;
            }, true);
            this.element.addEventListener('keypress', (event) => {
                if (event.key == 'Enter' && event.ctrlKey) {
                    if (this.element) {
                        let button = <HTMLButtonElement|null>this.element.querySelector('button[type="submit"]');
                        if (button) {
                            button.click();
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    }
                }
            }, true);

            return document.body.appendChild(this.element);
        }
        public focusFirst() {
            if (this.element) {
                let first = <HTMLInputElement>this.element.getElementsByClassName('cursor-selected')[0];
                if (first && first.focus) {
                    first.focus();
                }
            }
        }
        public alwaysOnTop(value: boolean = true) {
            var func: string = value ? 'add' : 'remove';
            value ? this.element!.classList.add('alwaysOnTop') : this.element!.classList.remove('alwaysOnTop');
        }

        public showAjax(url: string, complete?: () => void, pushState?: boolean) {
            this.show();
            let xhr = new viggo.ajax(<ViggoAjaxRequest>{
                method: 'get',
                url: url,
                convert: false,
                error: (xhr: XMLHttpRequest, response: string, exception: Error, contentType: string) => {
                    try {
                        viggo.ajax.textToData(contentType, response, document, 'javascript');
                    } catch (e) {
                        if (viggo.error.isAppleSpecificBug(e)) {
                            window.location.reload();
                        } else {
                            console.log(e);
                            console.log(response, exception);
                            if (!viggo.isDevelopment) {
                                let err = new viggo.error(new ErrorEvent("error", {
                                    colno: e.columnNumber || e.colno || 0,
                                    error: e,
                                    filename: url,
                                    lineno: e.lineNumber || e.lineno || 0,
                                    message: exception + ""
                                }));
                                err.report(`Method: get\nUrl: ${url}\nConvert: false\nStatus: ${xhr.status}`);
                            }
                        }
                    }
                    this.forceClose();
                },
                complete: (html: string) => {
                    if (this.element) {
                        var scripts: string[] = [];
                        html = html.replace(/<script(?:.*)?src="[^>]+>\s*<\/script>/g, '');
                        html = html.replace(/<script\s+type="text\/javascript"[^>]*>((?:.|\r|\n)*?)<\/script>/mg, function(all, script) {
                            scripts.push(script);
                            return "";
                        });
                        let fragment = <DocumentFragment>viggo.ajax.parseResponse('text/html', html);
                        viggo.dom.empty(this.element);
                        this.element.appendChild(fragment);
                        this.setupNavigation();
                        viggo.modal.setupClose(this.element);
                        let s: string = '';
                        let pre = /^\(\s*function\s*\(\s*\)\s*\{/,
                            post = /\}\s*(?:\)\s*\(\s*\)|\(\s*\)\s*\))\s*;?$/;
                        try {
                            while (scripts.length && this.element) {
                                s = <string>scripts.shift();
                                s = s.trim();
                                if (s.match(pre) && s.match(post)) {
                                    s = s.replace(pre, '').replace(post, '');
                                    s = s.trim();
                                }
                                let f = new Function(s);
                                f.call(null);
                            }
                        } catch (err) {
                            if (viggo.error.isAppleSpecificBug(err)) {
                                this.forceClose();
                                window.location.reload();
                                return;
                            } else {
                                alert("Der skete en fejl ved åbning. Prøv eventuelt at genindlæse siden.\nFejlen sendes til Viggo, og vi fikser den hurtigst muligt.");
                                console.error(err);
                                let info = viggo.error.analyze(err, s);
                                if (viggo.isDevelopment) {
                                    console.log(info.message);
                                } else {
                                    let error = new viggo.error(new ErrorEvent("error", {
                                        colno: info.columnNumber,
                                        error: err,
                                        filename: url,
                                        lineno: info.lineNumber,
                                        message: err.message
                                    }));
                                    error.report(info.message);
                                }
                                this.forceClose();
                                return;
                            }
                        }
                        if (this.element) {
                            this.focusFirst();
                            if (complete) {
                                complete.call(this);
                            } else if (pushState) {
                                window.history.replaceState({ owner: 'ViggoModal', url: window.location.href.replace(/#.*$/, ''), modalUrl: url }, viggo.history.getBestTitle(this.element), '#modal');
                            }
                            this.dispatchEvent('load', { target: this.element, modal: this });
                            viggo.modal.dispatchEvent('load', { target: this.element, modal: this });
                        }
                    }
                }
            });
        }
        public hasChanges() {
            var
                element = this.element,
                changes = false,
                texts = <NodeListOf<HTMLInputElement>>element!.querySelectorAll('input[type="text"],textarea');

            for (var i = 0; i < texts.length && !changes; i++) {
                changes = texts[i].value !== texts[i].defaultValue;
            }

            return changes;
        }
        private setupNavigation() {
            let nav = this.getNavigation();
            if (nav.target) {
                if (nav.previous) {
                    nav.target.insertBefore(viggo.dom.tag('a', {
                        href: '#',
                        className: 'previousModal',
                        onclick: this.previousModal.bind(this),
                        dataset: {
                            shortcuts: 'Ctrl+ArrowLeft Meta+ArrowLeft'
                        }
                    }, viggo.dom.tag('i', { className: 'flaticon-arrow-left-simple'})), nav.target.firstChild);
                }
                if (nav.next) {
                    nav.target.appendChild(viggo.dom.tag('a', {
                        href: '#',
                        className: 'nextModal',
                        onclick: this.nextModal.bind(this),
                        dataset: {
                            shortcuts: 'Ctrl+ArrowRight Meta+ArrowRight'
                        }
                    }, viggo.dom.tag('i', { className: 'flaticon-arrow-right-simple' })));
                }
            }
        }
        private getNavigation(): ModalUrlNavigation {
            if (this.url && this.element) {
                let selectorElement = <HTMLElement | null>this.element.querySelector('[data-navigation-selector]');
                if (selectorElement) {
                    let selector = selectorElement.dataset.navigationSelector!;
                    let urls = Array.from(document.querySelectorAll(selector)).map(x => (<HTMLAnchorElement>x).href).filter(x => x).map(x => (<string>x).replace(/#.*/, ''));
                    let index = urls.indexOf(this.url);
                    if (index != -1) {
                        return {
                            next: urls[index + 1],
                            previous: urls[index - 1],
                            target: selectorElement
                        };
                    }
                }
            }
            return {};
        }
        public forceClose() {
            var element = this.element;
            if (element) {
                // Disable form submit when closing.
                (<NodeListOf<HTMLButtonElement | HTMLInputElement>>element.querySelectorAll('button[type="submit"],input[type="submit"]')).forEach(e => e.disabled = true);
                this.element = null;
                new viggo.effect({
                    element: element,
                    style: 'opacity',
                    from: 1,
                    to: 0,
                    removeStyle: true,
                    duration: 100,
                    complete: () => {
                        if (element) {
                            element.remove();
                        }
                        if (!modal.modalList.length) {
                            modal.allClosed = true;
                            document.documentElement!.classList.remove('modal');
                        }
                        this.dispatchEvent('closed');
                        viggo.modal.isIdle();
                    }
                });
            }
            var index = modal.modalList.indexOf(this);
            if (index != -1) {
                modal.modalList.splice(index, 1);
            }
        }
        public reload() {
            if (this.url && this.element) {
                this.element.remove();
                this.element = null;
                this.showAjax(this.url);
            }
        }

        public finish(data: any) {
            this.close();
            this.dispatchEvent('finish', data);
        }

        public close(event?: Event) {
            if (!this.element) {
                return;
            }
            var
                me = this,
                element = me.element;

            var f = function () {
                if (!me.dispatchEvent('close', {target: element, modal: me}) || !viggo.modal.dispatchEvent('close', {target: element, modal: me})) {
                    return;
                }
                me.forceClose();
            };

            if (event && (event.type == 'click' || event.type == 'keydown' && (<KeyboardEvent>event).keyCode == 27)) {
                if (this.hasChanges()) {
                    viggo.undo(NoticeType.notsaved, __("ModalSaveChanges"), [{
                        description: __("Yes, leave this page"),
                        className: 'confirm',
                        callback: f
                    }, {
                        description: __('No, continue'),
                        className: 'deconfirm',
                        callback: function () { }
                    }]);
                } else {
                    f();
                }
            } else if (!event) {
                f();
            }
        }

        public updateSender() {
            viggo.invalidate(this.sender);
        }

        public static setupClose(element: Element) {
            let close = element.querySelector('.dialog>.close');
            if (close) {
                let modalElement = element.closest('.modal');
                if (modalElement) {
                    let m = viggo.modal.modalList.find(modal => modal.element == modalElement);
                    if (m) {
                        close.addEventListener('click', function (this: HTMLElement) {
                            m!.close();
                            this.remove();
                        }, false);
                    }
                }
            }
        }
        public static close(event?: Event) {
            if (event && event.type == 'keydown' && (<KeyboardEvent>event).keyCode == 27) {
                modal.closeTop(event);
            } else if (!event) {
                for (var i = modal.modalList.length - 1; i >= 0; i--) {
                    modal.modalList[i].close(event);
                }
            }
        }
        public static reloadAll() {
            for (let m of modal.modalList) {
                m.reload();
            }
        }

        public static closeAll = modal.close;
        public static closeTop(event?: Event) {
            if (modal.modalList.length) {
                modal.modalList[modal.modalList.length - 1].close(event);
            }
        }

        public static finish(data: any) {
            if (modal.modalList.length) {
                modal.modalList[modal.modalList.length - 1].finish(data);
            }
        }

        public static updateSender() {
            if (modal.modalList.length) {
                modal.modalList[modal.modalList.length - 1].updateSender();
            }
        }

        public static show(id: string): modal {
            return new modal({ element: document.getElementById(id) });
        }

        public static showElement(element: Element | DocumentFragment): modal {
            return new modal({ element: element });
        };

        public static showAjax(url: string, complete?: ()=> void): modal {
            return new modal({ url: url, complete: complete, pushState: true });
        };

        public static showAjaxWindow(url: string) {
            return window.open(url, "ViggoModal", 'resizable,scrollbars,dependent,width=800,height=450');
        }

        public static isActive(): boolean {
            return !modal.isIdle();
        }

        private static lastIdleTime: number = 0;
        public static isIdle(delayTime: number = 0): boolean {
            if (!modal.allClosed) {
                modal.lastIdleTime = 0;
            } else if (!modal.lastIdleTime) {
                modal.lastIdleTime = Date.now();
            }
            return !modal.lastIdleTime || (Date.now() - modal.lastIdleTime) >= delayTime;
        }

        public static getLatestModal(): modal|null {
            return modal.modalList.length ? modal.modalList[modal.modalList.length - 1] : null;
        };

        public static getLatestModalElement(): HTMLElement|null {
            var m = modal.getLatestModal();
            return m ? m.element : m;
        };
    }
}

(function () {
    document.addEventListener('click', function (event) {
        if (event.button === 0 && event.target) {
            var target = viggo.dom.parent(<HTMLElement>event.target, 'a');
            var id, pos;
            if (target && target.classList.contains('ajaxModal')) {
                var url = target.href;
                pos = url.indexOf("#");
                if (pos !== -1) {
                    id = url.substring(pos + 1);
                    url = url.substring(0, pos);
                    if (target.classList.contains('append-query-string')) {
                        url = viggo.appendQueryString(url);
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    return new viggo.modal({ url: url, pushState: true, sender: target });
                }
            }
        }
    }, false);

    document.addEventListener('keydown', viggo.modal.close, false);

    var start = function () {
        setTimeout(function () {
            window.addEventListener('popstate', function (event) {
                if (event.state && event.state.owner === 'ViggoModal') {
                    var m = viggo.modal.getLatestModal();
                    if (m) {
                        m.close();
                    }
                }
            }, false);
        }, 1000);
        window.removeEventListener('load', start, false);
    };
    window.addEventListener('load', start, false);
}());