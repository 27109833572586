module viggo {
    interface NavigationTemplates<T> {
        Navigation: T;
        Item: T;
        List: T;
        [index: string]: T;
    }

    interface TemplateItem {
        current: any;
        list: any[];
        index: number;
    }

    interface NavigationOptions<T> {
        items: T[];
        parent: HTMLElement;
        index?: number;
        templates: NavigationTemplates<string>;
    }

    type TemplateFunction = (item: TemplateItem) => string;

    abstract class AbstractNavigation<T> {
        protected items: T[];
        protected parent: HTMLElement;
        protected templates: NavigationTemplates<TemplateFunction>;
        protected index: number;
        protected listVisible = false;
        constructor(options: NavigationOptions<T>) {
            this.items = options.items;
            this.parent = options.parent;
            this.index = options.index || 0;
            this.templates = <NavigationTemplates<TemplateFunction>>{};
            for (let name in options.templates) {
                this.templates[name] = <TemplateFunction>viggo.func.createTemplate(options.templates[name]);
            }
            this.initialize();
            this.repaint();
        }
        protected abstract initialize(): void;
        public next() {
            this.index++;
            if (this.index >= this.items.length) {
                this.index = 0;
            }
            this.repaint();
        }
        public previous() {
            this.index--;
            if (this.index < 0) {
                this.index = this.items.length - 1;
            }
            this.repaint();
        }
        public selectItem(item: T, event: Event) {
            event.preventDefault();
            let index = this.items.indexOf(item);
            if (index != -1) {
                this.index = index;
                this.repaint();
            }
        }
        public showList() {
            let template = viggo.func.createView<TemplateItem>({
                current: this.items[this.index],
                list: this.items,
                index: this.index
            }, this.templates.List);
            let list = template.querySelector('.list');
            let selected: HTMLElement | null = null;
            if (list) {
                let info: TemplateItem = {
                    current: this.items[this.index],
                    index: this.index,
                    list: this.items
                };
                for (let item of this.items) {
                    let elm = viggo.func.createView<any>({
                        item: item,
                        list: info
                    }, this.templates.Item).firstElementChild;
                    if (elm) {
                        elm.addEventListener('click', event => this.selectItem(item, event), false);
                        if (item == this.items[this.index]) {
                            selected = <HTMLElement>elm;
                            elm.classList.add('selected');
                        }
                        list.appendChild(elm);
                    }
                }
            }
            this.parent.appendChild(template);
            if (list && selected) {
                list.scrollTop = selected.offsetTop;
            }
            if (!this.listVisible) {
                let click = (event: MouseEvent) => {
                    document.removeEventListener('click', click, false);
                    this.listVisible = false;
                    this.repaint();
                };
                setTimeout(function () {
                    document.addEventListener('click', click, false);
                }, 10);
                this.listVisible = true;
            }
        }
        protected repaint() {
            viggo.dom.empty(this.parent);
            let template = viggo.func.createView<TemplateItem>({
                current: this.items[this.index],
                list: this.items,
                index: this.index
            }, this.templates.Navigation);
            let elm = <HTMLElement|null>template.querySelector('.previous');
            if (elm && !elm.classList.contains('disabled')) {
                elm.addEventListener('click', (event: MouseEvent) => {
                    //event.preventDefault();
                    //event.stopPropagation();
                    this.previous();
                }, false);
            }
            elm = <HTMLElement|null>template.querySelector('.next');
            if (elm && !elm.classList.contains('disabled')) {
                elm.addEventListener('click', (event: MouseEvent) => {
                    //event.preventDefault();
                    //event.stopPropagation();
                    this.next();
                });
            }
            elm = <HTMLElement | null>template.querySelector('.current');
            if (elm) {
                elm.addEventListener('click', (event: MouseEvent) => {
                    //event.preventDefault();
                    //event.stopPropagation();
                    if (!this.listVisible) {
                        this.showList();
                    }
                });
            }
            this.parent.appendChild(template);
            if (this.listVisible) {
                this.showList();
            }
        }
    }

    export class ListNavigation extends AbstractNavigation<any> {
        protected initialize() {

        }
    }
}