module viggo {
    let list: { [index: string]: notification } = {};

    export class notification {
        private id: string;
        private click: (event: MouseEvent) => void;
        private element: HTMLElement;
        constructor(url: string, id: string, element: HTMLElement) {
            this.id = id;
            this.element = element;
            this.click = (event: MouseEvent) => {
                if (!this.element.contains(<Node>event.target) && !viggo.modal.getLatestModal()) {
                    this.hide();
                }
            };
            document.addEventListener('click', this.click, true);
            new viggo.ajax({
                method: 'get',
                url: url,
                convert: 'html',
                complete: (html) => {
                    viggo.dom.empty(this.element);
                    this.element.style.display = '';
                    this.element.appendChild(html);
                }
            });
        }
        hide() {
            viggo.dom.empty(this.element);
            this.element.style.display = 'none';
            document.removeEventListener('click', this.click, true);
            delete list[this.id];
        }
    }

    export class notifications {
        public static show(url: string, id: string, event: Event) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            let elm = document.getElementById(id);
            if (elm) {
                list[id] = new notification(url, id, elm);
            }
        }
        public static hide(id: string) {
            let n = list[id];
            if (n) {
                n.hide();
            }
        }
    }
}