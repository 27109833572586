module viggo {
    interface PromptOptions {
        contents: Node|string;
        buttons?: boolean;
    }
    export class prompt {
        private element?: HTMLDivElement;
        private resolve?: (node: Node | null) => void;
        private escapeListener?: (this: Window, event: KeyboardEvent) => any;
        public constructor(options: PromptOptions) {
            this.element = <HTMLDivElement>viggo.func.createViewFromString(
`<div class="prompt">
    <div class="dialog">
        <div class="close"><i class="flaticon-cross"></i></div>
        <div class="content"></div>
        <div class="buttons"><button type="button" class="ok">${__('OK')}</button><button type="button" class="cancel">${__('Cancel')}</button></div>
    </div>
</div`
            ).firstElementChild;

            this.element.addEventListener('click', (ev) => {
                if (ev.target == this.element) {
                    this.cancel();
                }
            }, false);

            if (options.buttons === false) {
                this.element.querySelectorAll('button').forEach(x => x.remove());
            } else {
                this.element.querySelector('button.ok')!.addEventListener('click', this.ok.bind(this), false);
                this.element.querySelector('button.cancel')!.addEventListener('click', this.cancel.bind(this), false);
                this.element.querySelector('.close')!.addEventListener('click', this.cancel.bind(this), false);
            }
            let content = this.element.querySelector('.content')!;
            if (typeof options.contents == 'string') {
                content.innerHTML = options.contents;
            } else {
                content.appendChild(options.contents);
            }
        }

        public show() {
            if (this.element) {
                document.body.appendChild(this.element);
                this.escapeListener = (ev: KeyboardEvent) => {
                    if (ev.key == 'Escape') {
                        ev.stopImmediatePropagation();
                        this.cancel();
                    }
                };
                window.addEventListener('keydown', this.escapeListener, true);
                return new Promise((resolve) => {
                    this.resolve = resolve;
                });
            }
        }

        public ok() {
            if (this.resolve && this.element) {
                let content = this.element.querySelector('.content');
                if (content) {
                    this.resolve(viggo.dom.empty(content));
                } else {
                    this.resolve(null);
                }
            }
            this.remove();
        }
        public cancel() {
            if (this.resolve) {
                this.resolve(null);
            }
            this.remove();
        }
        public remove() {
            if (this.element) {
                this.element.remove();
                delete this.element;
            }
            window.removeEventListener('keydown', <any>this.escapeListener, true);
        }
    }
}