module viggo {
    var SpeechRecognition = <new () => SpeechRecognition>(<any>window).SpeechRecognition || (<any>window).webkitSpeechRecognition;
    var SpeechGrammarList = <new () => SpeechGrammarList>(<any>window).SpeechGrammarList || (<any>window).webkitSpeechGrammarList;
    export class speech {
        private recognition: SpeechRecognition;
        private grammer: SpeechGrammarList;
        public constructor() {
            this.recognition = new SpeechRecognition();
            this.recognition.continuous = false
            this.grammer = new SpeechGrammarList();
            let jsgf = `#JSGF V1.0 UTF-8 da;`;
            this.grammer.addFromString(jsgf);
            this.recognition.grammars = this.grammer;
            this.recognition.lang = "da";
            this.recognition.interimResults = false;
            this.recognition.maxAlternatives = 1;
            this.recognition.addEventListener('result', this.eventResult.bind(this), false);
            this.recognition.addEventListener('speechend', this.eventEnd.bind(this), false);
            this.recognition.addEventListener('nomatch', this.eventNomatch.bind(this), false);
            this.recognition.addEventListener('error', this.eventError.bind(this), false);
            this.recognition.start();
        }
        private eventResult(event: SpeechRecognitionEvent) {
            console.log(event);
        }
        private eventEnd(event: Event) {
            console.log(event);
            this.recognition.stop();
        }
        private eventNomatch(event: SpeechRecognitionEvent) {
            console.log(event);
        }
        private eventError(event: Event) {
            console.log(event);
        }
    }
}