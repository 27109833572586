module viggo {
	export interface ContextMenuItem {
		script?: string,
		ajax?: ViggoAjaxRequest,
		ajaxModal?: {
			url: string,
			complete?: () => void
		}
		click?: (event: MouseEvent) => void,
		className?: string,
        image?: string,
        icon?: string,
		title: string,
		children?: ContextMenuItem[]
	}
	export class contextmenu {
		private items: ContextMenuItem[] = [];
		private domNode: HTMLDivElement;
		private static instance: contextmenu|null = null;
		private constructor() {
			if (contextmenu.instance) {
				contextmenu.instance.hide();
			}
			contextmenu.instance = this;
			this.domNode = viggo.dom.tag('div', { id: 'contextmenu' });
		}
		public static getInstance() {
			if (!contextmenu.instance) {
				contextmenu.instance = new contextmenu();
			}
			return contextmenu.instance;
		}
		public static isVisible(): boolean {
		    return !!contextmenu.instance;
		}
		private createItem(parent: Node, item: ContextMenuItem) {
			let click;
			if (item.script) {
				click = new Function('event', item.script);
			} else if (item.ajax) {
			    click = () => {
					if (item.ajax) {
						new viggo.ajax(item.ajax);
					}
			    };
			} else if (item.ajaxModal) {
			    let m = item.ajaxModal;
			    click = () => {
                    viggo.modal.showAjax(m.url, m.complete);
			    };
			} else if (item.click) {
			    click = item.click;
			} else {
				click = function (event: MouseEvent) {
					event.stopPropagation();
				};
			}
			let li = viggo.dom.tag('li', {
				onclick: click
			});
		    if (item.className) {
		        li.className = item.className
		    }
			if (item.image) {
				li.appendChild(viggo.dom.tag('img', {src: item.image}));
            }
            if (item.icon) {
                li.appendChild(viggo.dom.tag('i', { className: item.icon }));
            }
			li.appendChild(viggo.dom.tag('span', null, viggo.dom.text(item.title)));
			if (item.children) {
			    var ul = viggo.dom.tag('ul');
			    for (var i = 0; i < item.children.length; i++) {
			        this.createItem(ul, item.children[i]);
			    }
			    if (ul.childNodes.length) {
			        li.appendChild(ul);
			    }
            }
			parent.appendChild(li);
		}
		/*
		private createUrl(parent: Node, url: string) {
			var li = viggo.dom.tag('li', { className: 'contextLoading' });
			li.appendChild(viggo.dom.tag('span', null, viggo.dom.text('Loading...')));
			parent.appendChild(li);
			new viggo.ajax({
				url: url,
				method: 'get',
				convert: 'json',
				complete: (data) => {
					var frag = viggo.dom.fragment();
					for (var i = 0; i < data.length; i++) {
						this.createItem(frag, data[i]);
					}
					parent.replaceChild(frag, li);
				}
			});
		}
		*/
		private createLine(parent: Node) {
			parent.appendChild(viggo.dom.tag('li', {className: 'line'}));
		}
		public showDom(element: HTMLElement, event: MouseEvent|TouchEvent|PointerEvent) {
		    var parent = <HTMLElement>element.parentNode;
		    var next = element.nextSibling;
		    let click = (ev: MouseEvent|TouchEvent) => {
		        document.removeEventListener('click', click, false);
                document.removeEventListener('touchstart', click, false);
		        var hide = () => {
		            parent.insertBefore(element, next);
		            this.hide();
		        };
		        if (ev.type == 'click') {
		            hide();
		        } else {
		            setTimeout(hide, 2000);
		        }
		    };
		    this.domNode.appendChild(element);
		    document.addEventListener('click', click, false);
		    document.addEventListener('touchstart', click, false);
			document.body.appendChild(this.domNode);
			let x: number = 0, y: number = 0;
			if (event.type == 'click' || event.type == 'contextmenu') {
				x = (<MouseEvent>event).clientX;
				y = (<MouseEvent>event).clientY;
			} else if (event.type == 'touchstart') {
				x = (<TouchEvent>event).touches[0].clientX;
				y = (<TouchEvent>event).touches[0].clientY;
			}
		    this.reposition(x, y);
		    viggo.hint.block();
		}
		public static addItem(item: ContextMenuItem) {
			contextmenu.getInstance().addItem(item);
        }
        public static addLine() {
            contextmenu.addItem({className: 'line', title: ''});
        }
        public static hide() {
            if (contextmenu.instance) {
                contextmenu.instance.hide();
            }
        }
		public addItem(item: ContextMenuItem) {
			this.items.push(item);
		}
        public hide() {
			this.domNode.remove();
			contextmenu.instance = null;
			viggo.hint.unblock();
		}
		public show(event: MouseEvent) {
            if (this.items.length) {
                let ul = this.domNode.appendChild(viggo.dom.tag('ul', {className: 'dropdown-menu'}));
				this.items.forEach((e => {
					this.createItem(ul, e);
				}));
                let click = (ev: MouseEvent) => {
                    if (ev.button == 0) { // Firefox on Mac calls click on right-click, so we make sure it's the right button
                        document.removeEventListener('click', click, false);
                        this.hide();
                    }
				};
				document.addEventListener('click', click, false);
			    // removed this part, because touchstart is triggered before click, and the menu is removed when touched
			    //document.addEventListener('touchstart', hide, false);
				document.body.appendChild(this.domNode);
				this.reposition(event.clientX, event.clientY);
				viggo.hint.block();
				return true;
			} else {
				return false;
			}
		}
		private reposition(x: number, y: number) {
            if (this.domNode) {
                let page = viggo.getTopScrollable();
		        let maxY = page.offsetHeight - this.domNode.offsetHeight - 10;
		        let maxX = page.offsetWidth - this.domNode.offsetWidth - 10;
		        y = Math.min(y, maxY);
		        x = Math.min(x, maxX);
		        this.domNode.style.top = y + 'px';
		        this.domNode.style.left = x + 'px';
		    }
		}
	}

	document.addEventListener('contextmenu', (event: MouseEvent) => {
        let target;
	    if (contextmenu.getInstance().show(event)) {
			event.preventDefault();
        } else if (target = (<Element>event.target).closest('.contextmenu,.block-contextmenu')) {
            if (target.classList.contains('contextmenu')) {
                let menu = target.querySelector('.context-dropdown-menu') || target.querySelector('.dropdown-menu');
                if (menu) {
                    contextmenu.getInstance().showDom(<HTMLElement>menu, event);
                    event.preventDefault();
                }
            }
		}
	}, false);

    document.addEventListener('contextmenu', (event: MouseEvent) => {
        contextmenu.hide();
    }, true);

	let touchtimer: number, cancelTimer = () => {
	    clearTimeout(touchtimer);
	};
    document.addEventListener('touchstart', (event: TouchEvent) => {
        clearTimeout(touchtimer);
        touchtimer = window.setTimeout(function () {
            if (!event.touches || event.touches.length > 1) {
                return;
            }
            let data = {
                view: event.view,
                bubbles: true,
                cancelable: true,
                clientX: event.touches[0].clientX,
                clientY: event.touches[0].clientY
            };
            let ev: any;
            if (typeof PointerEvent == "undefined") { // iPhone 5 doesn't support PointerEvent
                ev = new MouseEvent('contextmenu', data);
            } else {
                ev = new PointerEvent('contextmenu', data);
            }
			/*
			// not accepted by TypeScript
            ev.clientX = event.touches[0].clientX; // needs to be here for iPads.
			ev.clientY = event.touches[0].clientY;
			*/
            if (!event.target!.dispatchEvent(ev)) {
                event.preventDefault();
            }
        }, 500);
    }, false);

    document.addEventListener('touchend', cancelTimer, false);
    document.addEventListener('touchmove', cancelTimer, false);
    document.addEventListener('touchcancel', cancelTimer, false);
}