/*
Get emoji list from https://unicode.org/emoji/charts/full-emoji-list.html

// this function does the job of categorizing
(function(){
	let rows = document.querySelector('table').rows;
	let result = {};
	let header;
	for (let row of rows) {
		if (row.cells[0].classList.contains('bighead')) {
			header = row.textContent;
			result[header] = [];
		} else {
			let cell = row.cells[2];
			if (cell && cell.classList.contains('chars')) {
				if (!result[header]) {
					console.log(header, cell);
					throw new Error();
				}
				result[header].push(cell.textContent);
			}
		}
	}
	console.log(JSON.stringify(result));
}());
*/
namespace viggo {
    const Text = Quill.import('blots/text');
    const Module = Quill.import('core/module');
    const Keyboard = Quill.import('modules/keyboard');

    class EmojiBlot extends Text { }

    class ToolbarEmoji extends Module {
        private palette: HTMLElement | null = null;
        private quill: Quill;
        private toolbar: any;
        private closeListener: (() => void) | null = null;
        private ignoreInput = false;
        static emojiList: { [name: string]: string[] };

        constructor(quill: Quill, options: any) {
            super(quill, options);

            this.quill = quill;
            this.toolbar = quill.getModule('toolbar');
            if (this.toolbar) {
                this.toolbar.addHandler('emoji', this.togglePalette.bind(this));
            }

            if (!ToolbarEmoji.emojiList) {
                fetch("/Scripts/emoji-list.json").then(x => x.json()).then(json => {
                    ToolbarEmoji.emojiList = json;
                });
            }

            document.querySelectorAll('.ql-emoji').forEach(btn => {
                btn.innerHTML = options.buttonIcon;
            });

            this.quill.on('text-change', (delta: Delta, oldDelta: Delta, source: string) => {
                if (source === 'user' && !this.ignoreInput) {
                    this.close();
                }
            });
        }

        private togglePalette() {
            if (this.palette) {
                this.close();
            } else {
                this.showPalette();
            }
        }

        public showPalette() {
            let range = this.quill.getSelection();
            const atSignBounds = this.quill.getBounds(range!.index);

            this.palette = viggo.dom.tag('div', {
                className: 'emoji-palette',
                style: {
                    top: 10 + atSignBounds.top + atSignBounds.height + 'px',
                    left: (atSignBounds.left + 250 > (<any>this.quill).container.offsetWidth ? atSignBounds.left - 250 : atSignBounds.left) + 'px'
                },
                onclick: function (ev: MouseEvent) {
                    ev.stopPropagation();
                }
            });
            (<any>this.quill).container.appendChild(this.palette);

            let tabToolbar = viggo.dom.tag('div', {
                className: 'emoji-toolbar'
            });
            this.palette.appendChild(tabToolbar);

            let rangeIndex = range!.index;

            //panel
            let panel = viggo.dom.tag('div', {
                className: 'emoji-panel',
                onclick: (event: MouseEvent) => {
                    let target = <HTMLElement>event.target;
                    if (target.tagName == 'SPAN') {
                        this.ignoreInput = true;
                        let emoji = target.textContent || '';
                        this.quill.insertText(rangeIndex, emoji, (<any>Quill).sources.USER);
                        setTimeout(() => {
                            rangeIndex += emoji.length;
                            this.quill.setSelection(rangeIndex, 0);
                            this.ignoreInput = false;
                        }, 0);
                    }
                }
            });
            this.palette.appendChild(panel);
            this.closeListener = this.close.bind(this);
            setTimeout(() => {
                document.addEventListener('click', this.closeListener!, false);
            }, 0);

            let tabElementHolder = document.createElement('ul');
            tabToolbar.appendChild(tabElementHolder);

            let i = 0;
            for (let name in ToolbarEmoji.emojiList) {
                //add tab bar
                let tab = viggo.dom.tag('li', {
                    className: 'tab-' + name.split(' ')[0].toLowerCase() + (i++ ? '' : ' selected'),
                    onclick: () => {
                        for (let item of tabElementHolder.children) {
                            item.classList.remove('selected');
                        }
                        tab.classList.add('selected');
                        this.updatePanel(name, panel);
                    }
                });
                tabElementHolder.appendChild(tab);
            }

            this.updatePanel(Object.keys(ToolbarEmoji.emojiList)[0], panel);
        }

        private updatePanel(category: string, panel: HTMLElement) {
            viggo.dom.empty(panel);
            this.quill.focus();

            ToolbarEmoji.emojiList[category].forEach((emoji) => {
                let span = viggo.dom.tag('span');
                let t = document.createTextNode(emoji);
                span.appendChild(t);
                panel.appendChild(span);
            });
        }

        public close() {
            if (this.palette) {
                this.palette.remove();
                this.palette = null;
            }
            if (this.closeListener) {
                document.removeEventListener('click', this.closeListener, false);
                this.closeListener = null;
            }
        }
    }

    ToolbarEmoji.DEFAULTS = {
        buttonIcon: '<svg viewbox="0 0 18 18"><circle class="ql-fill" cx="7" cy="7" r="1"></circle><circle class="ql-fill" cx="11" cy="7" r="1"></circle><path class="ql-stroke" d="M7,10a2,2,0,0,0,4,0H7Z"></path><circle class="ql-stroke" cx="9" cy="9" r="6"></circle></svg>'
    };

    class AutoLinks {
        private quill: Quill;
        private static urlPattern = /(?:(https?|ftp):\/\/|[Ww]{3}\.)[A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff][A-Za-z0-9\-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]*(?:\.[A-Za-z0-9\-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff]+)*\.[A-Za-z0-9]+(?:\:\d+)?(?:\/[\w\?\-\._=%@&/~#;:\{\}\[\],\(\)\+]+)?/;
        constructor(quill: Quill) {
            this.quill = quill;
            this.registerTypeListener();
            this.registerPasteListener();
        }

        private registerTypeListener() {
            this.registerSpaceHandler();
            this.registerEnterHandler();
        }

        private registerSpaceHandler() {
            this.quill.keyboard.addBinding({
                key: ' ',
                collapsed: true,
                prefix: AutoLinks.urlPattern,
                handler: (range: RangeStatic, context: any) => {
                    const prefixMatch = context.prefix.match(AutoLinks.urlPattern);
                    if (prefixMatch) {
                        if (context.format.link) {
                            this.quill.format('link', false);
                            return true;
                        }
                        const prefixLength = prefixMatch[0].length;
                        const prefixStart = range.index - prefixLength;
                        const url = AutoLinks.prefixProtocol(this.quill.getText(prefixStart, prefixLength));
                        this.quill.formatText(prefixStart, prefixLength, { link: url }, 'user');
                        this.quill.setSelection(range, 'user');
                        this.quill.format('link', false);
                    }
                    return true;
                }
            });
        }
        private registerEnterHandler() {
            this.quill.keyboard.addBinding({
                key: 13,
                prefix: AutoLinks.urlPattern,
                collapsed: true,
                format: { link: false },
                handler: function (this: KeyboardStatic, range: RangeStatic, context: any) {
                    const prefixMatch = context.prefix.match(AutoLinks.urlPattern);
                    if (prefixMatch != null) {
                        const prefixLength = prefixMatch[0].length;
                        const prefixStart = range.index - prefixLength;
                        const url = AutoLinks.prefixProtocol(this.quill.getText(prefixStart, prefixLength));

                        this.quill.formatText(prefixStart, prefixLength, { link: url }, 'user');
                        this.quill.setSelection(range, 'user');
                        this.quill.format('link', false);
                    }
                    return true;
                }
            });

            // this is a hack, to make sure the enter event is handled.
            // otherwise Quill will handle internal events for enter, and ignore the others
            this.quill.keyboard.bindings[13].unshift(this.quill.keyboard.bindings[13].pop());
        }

        private registerPasteListener() {
            const globalPattern = new RegExp(AutoLinks.urlPattern.source, 'g');
            this.quill.clipboard.addMatcher(Node.TEXT_NODE, (node: Text, delta) => {
                const ops: DeltaOperation[] = [];
                const str = node.data;
                let lastMatchEndIndex = 0;
                let match = globalPattern.exec(str);
                while (match) {
                    if (match.index > lastMatchEndIndex) {
                        ops.push({ insert: str.slice(lastMatchEndIndex, match.index) });
                    }
                    const url = AutoLinks.prefixProtocol(match[0]);
                    ops.push({ insert: match[0], attributes: { link: url } });
                    lastMatchEndIndex = match.index + match[0].length;

                    match = globalPattern.exec(str);
                }

                if (lastMatchEndIndex < str.length) {
                    ops.push({ insert: str.slice(lastMatchEndIndex) });
                }

                delta.ops = ops;
                return delta;
            });
        }

        private static prefixProtocol(url: string) {
            if (url.substring(0, 4).toLowerCase() == 'www.') {
                url = 'http://' + url;
            }
            return url;
        }
    }

    // emoji list based on https://en.wikipedia.org/wiki/List_of_emoticons
    class AutoEmoji {
        private quill: Quill;
        private static emojiPattern = /(?:[:8xX=;]'?-?|<[\\\/]?)[)3}D(oOpP\/\\*|]$/;
        private static emojiSet: ObjectOf<string[]> = {
            "🙂": [":-)", ":)"], // Smiley or happy face.
            "😊": [":-3", ":3", ":-}", ":}"],
            "😃": [":-D", ":D"], // Laughing, big grin, laugh with glasses, or wide-eyed surprisea
            "😄": ["8-D", "8D"],
            "😆": ["x-D", "xD", "X-D", "XD"],
            "😍": ["=D", "=3"],
            "🙁": [":-(", ":("], // Frown, sad, angry, pouting 
            "😢": [":'-(", ":'("], // Crying
            "😂": [":'-)", ":')"], // Tears of happiness
            "😮": [":-O", ":O", ":‑o", ":o"], // Surprise, shock, yawn
            "😚": [":-*", ":*"], // Kiss
            "😉": [";-)", ";)"], // Wink, smirk
            "😛": [":-P", ":P", ":-p", ":p"], // Tongue sticking out, cheeky/playful, blowing a raspberry
            "😕": [":-/", ":/", ":\\"], // Skeptical, annoyed, undecided, uneasy, hesitant
            "😐": [":-|", ":|"], // Straight face[5] no expression, indecision
            // Embarrassed, blushing
            // Sealed lips or wearing braces, tongue-tied
            // Angel, saint, innocent
            // Evil, devilish
            // Cool, bored/yawning
            // Tongue-in-cheek
            // Partied all night
            // Drunk, confused
            // Being sick
            // Dumb, dunce-like
            // Scepticism, disbelief, or disapproval
            "💔": ["</3", "<\\3"],
            "❤️": ["<3"]
        };
        constructor(quill: Quill) {
            this.quill = quill;
            this.registerAutoEmojiHandler();
        }

        private registerAutoEmojiHandler() {
            this.quill.keyboard.addBinding({
                key: ' ',
                collapsed: true,
                prefix: AutoEmoji.emojiPattern,
                handler: (range: RangeStatic, context: any) => {
                    const prefixMatch = context.prefix.match(AutoEmoji.emojiPattern);
                    if (prefixMatch) {
                        const ascii = prefixMatch[0];
                        const emoji = this.getEmoji(ascii);
                        if (emoji) {
                            const prefixLength = ascii.length;
                            const prefixStart = range.index - prefixLength;
                            this.quill.deleteText(prefixStart, prefixLength, "user");
                            this.quill.insertText(prefixStart, emoji, "user");
                            this.quill.setSelection(range, 'user');
                        }
                    }
                    return true;
                }
            });
        }

        private getEmoji(ascii: string) {
            for (let emoji in AutoEmoji.emojiSet) {
                const list = AutoEmoji.emojiSet[emoji];
                if (list.includes(ascii)) {
                    return emoji;
                }
            }
            return null;
        }
    }

    Quill.register({
        'formats/emoji': EmojiBlot,
        'modules/emoji-toolbar': ToolbarEmoji,
        'modules/autoLinks': AutoLinks,
        'modules/autoEmoji': AutoEmoji
    });

    // script is superscript/subscript
    type RichEditorTool = "background" | "bold" | "color" | "font" | "code" | "italic" | "link" | "size" | "strike" | "script" | "underline" | "blockquote" | "header" | "indent" | "list" | "align" | "direction" | "code-block" | "formular" | "image" | "video";
    interface RichEditorOptions {
        element: HTMLTextAreaElement;
        tools: any;
    }
    export class richeditor {
        private textarea: HTMLTextAreaElement;
        private editor: HTMLElement;
        private quill: Quill;
        private openGraphContainer: HTMLElement | null;
        private openGraphUrl: string | null;
        private allowedTools: string[];

        constructor(options: RichEditorOptions) {
            this.textarea = options.element;
            this.editor = viggo.dom.tag('div', {
                className: this.textarea.className,
                innerHTML: this.textarea.value,
                dataset: this.textarea.dataset,
            });

            this.openGraphUrl = null;
            this.openGraphContainer = null;
            this.initializeOpenGraph(this.textarea.dataset.richeditorLinkContainer, this.textarea.dataset.richeditorLink);
            let parent = this.textarea.parentElement;
            if (parent) {
                parent.insertBefore(this.editor, this.textarea);
            }
            this.quill = new Quill(this.editor, {
                modules: {
                    autoLinks: true,
                    autoEmoji: true,
                    toolbar: {
                        container: options.tools,
                        handlers: {
                            emoji: function () {}
                        }
                    },
                    "emoji-toolbar": options.tools.some((x: string | string[]) => Array.isArray(x) ? x.some(y => y == 'emoji') : x == 'emoji'),
                    mention: this.textarea.classList.contains('autocomplete')
                },
                theme: 'snow'
            });
            this.allowedTools = ['link'];
            this.quill.on("text-change", this.onTextChange.bind(this));
            this.quill.clipboard.addMatcher(Node.ELEMENT_NODE, this.onPaste.bind(this));
            this.quill.root.tabIndex = this.textarea.tabIndex;
            this.initializeAllowedTools(options.tools);
            this.allowedTools = this.allowedTools.unique();
        }

        private onPaste(node: any, delta: Delta) {
            for (let op of delta.ops) {
                this.filterOperation(op);
            }
            return delta;
        }

        private onTextChange(delta: Delta, oldContents: Delta, source: Sources) {
            if (source !== "user") {
                return;
            }
            if (this.openGraphContainer && !this.openGraphUrl) {
                let link: string | null | undefined;
                for (let i = 0; i < delta.ops.length && !link; i++) {
                    let op = delta.ops[i];
                    link = <string | null | undefined>(op.attributes ? op.attributes!.link : null);
                    if (link && !link.match(/^https?:\/\//)) {
                        link = null;
                    }
                }
                if (link && link.indexOf(location.origin) != 0) {
                    this.previewLink(link);
                }
            }
            this.textarea.value = this.quill.root.innerHTML;
        }

        private filterOperation(op: Op) {
            let found = false;
            if (op.attributes) {
                let list = Object.keys(op.attributes);
                let counter = 0;
                for (let format of list) {
                    if (this.allowedTools.indexOf(format) == -1) { // format is now allowed
                        delete op.attributes[format];
                        counter++;
                        found = true;
                    }
                }
                if (counter == list.length) { // all formats was removed
                    delete op.attributes;
                }
            }
            return found;
        }

        private initializeAllowedTools(tools: any) {
            for (let tool of tools) {
                if (Array.isArray(tool)) {
                    this.initializeAllowedTools(tool);
                } else {
                    if (typeof tool == 'string') {
                        this.allowedTools.push(tool);
                    } else if (typeof tool == 'object') {
                        this.initializeAllowedTools(Object.keys(tool));
                    }
                }
            }
        }

        private previewLink(url: string) {
            this.openGraphUrl = url;
            viggo.dom.empty(this.openGraphContainer!);
            this.openGraphContainer!.classList.add('loading');
            new viggo.ajax({
                method: 'post',
                url: '/Shared/OpenGraph/GetView',
                json: true,
                convert: 'html',
                data: url,
                complete: (html) => {
                    let close = html.querySelector('.close');
                    if (close) {
                        close.addEventListener('click', () => {
                            viggo.dom.empty(this.openGraphContainer!);
                            this.openGraphUrl = null;
                        });
                    }
                    this.openGraphContainer!.classList.remove('loading');
                    this.openGraphContainer!.appendChild(html);
                }
            });
        }

        private initializeOpenGraph(container?: string, link?: string) {
            if (container) {
                this.openGraphContainer = document.getElementById(container);
            }
            if (this.openGraphContainer && link) {
                this.previewLink(link);
            }
        }

        public static initialize(element: Element | Document) {
            (<NodeListOf<HTMLTextAreaElement>>element.querySelectorAll('textarea.richeditor')).forEach(textarea => {
                let s = textarea.dataset.richeditorTools;
                let tools: any[] = [];
                if (!s) {
                    tools = ["bold", "italic", "underline", "emoji", { list: 'ordered' }, { list: 'bullet' }];
                } else if (s == 'all') {
                    tools = [
                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                        ['blockquote', 'code-block'],
                        ['emoji'],

                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                         // text direction

                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                        [{ 'font': [] }],
                        [{ 'align': [] }],

                        ['clean']                                         // remove formatting button
                    ];
                } else if (s == 'none') {
                    tools = [];
                } else {
                    tools = JSON.parse(s);
                }
                new viggo.richeditor({
                    element: textarea,
                    tools: tools
                });
            });
        }
    }
}

viggo.modal.addEventListener('load', function (data) {
    viggo.richeditor.initialize(data.target);
});

document.addEventListener('statepushed', async function (event) {
    viggo.richeditor.initialize(<Element | Document>event.target);
}, false);

viggo.modal.addEventListener('show', function (data) {
    viggo.richeditor.initialize(data.target);
});

viggo.ready(function () {
    viggo.richeditor.initialize(document);
});