module viggo {
    function timeout(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function beep(interval: number | number[]) {
        const audio = new AudioContext();
        const osc = audio.createOscillator();
        const gain = audio.createGain();
        gain.gain.setValueAtTime(1, audio.currentTime);
        osc.connect(gain);
        gain.connect(audio.destination);
        osc.frequency.setValueAtTime(450, audio.currentTime);
        osc.start();
        if (typeof interval == "number") {
            interval = [interval];
        }

        for (const [index, value] of interval.entries()) {
            if (index % 2 == 0) {
                gain.gain.setValueAtTime(1, audio.currentTime);
            }
            await timeout(value);
            if (index % 2 == 0) {
                gain.gain.setValueAtTime(0, audio.currentTime);
            }
        }
        osc.stop();
    }

    export function vibrate(interval: number | number[]) {
        if (viggo.isMobileDevice) {
            navigator.vibrate(interval);
        } else if (viggo.isDevelopment) {
            beep(interval);
        }
    }
}
