module viggo {
    export class confidential {
        private static instance: confidential | null = null;
        private styleTag: HTMLStyleElement;
        private confidentialIds: string[] = [];
        constructor() {
            if (confidential.instance) {
                confidential.unlock();
                confidential.instance = null;
            }
            this.styleTag = viggo.dom.tag('style', { type: 'text/css' });
        }
        public static getInstance() {
            if (!confidential.instance) {
                confidential.instance = new confidential();
            }
            return confidential.instance;
        }
        private static getConfidential(tag: Element): Element | null {
            return viggo.dom.parentFilter(tag, (element: Element) => {
                return !!((<HTMLElement>element).dataset && (<HTMLElement>element).dataset.confidential);
            });
        }
        private static clickListener(event: MouseEvent) {
            var tag = <HTMLElement | null>confidential.getConfidential(<HTMLElement>event.target);
            if (tag) {
                event.preventDefault();
                event.stopPropagation();
                let id = tag.dataset.confidential;
                if (id) {
                    let instance = confidential.getInstance();
                    let index = instance.confidentialIds.indexOf(id);
                    if (index === -1) {
                        instance.confidentialIds.push(id);
                    } else {
                        instance.confidentialIds.splice(index, 1);
                    }
                    confidential.setConfidential(id, index === -1);
                    confidential.startSelection(instance.confidentialIds);
                }
            }
        }
        private static exitListener(event: Event) {
            if (event.type != 'keydown' || (<KeyboardEvent>event).keyCode == 27) {
                viggo.confidential.endSelection();
            }
        }
        public static startSelection(ids: string[]) {
            this.unlock();
            let instance = confidential.getInstance();
            document.documentElement!.classList.add('confidential-selection');
            instance.setStyle(`[data-confidential] {
            }
            div.item:not([data-confidential]) {
                opacity: 0.3;
            }
            [data-confidential="${ids.join('"],[data-confidential="')}"] {
                background-color: #f2afaf !important
            }`);
            instance.confidentialIds = ids;
            viggo.notice(NoticeType.complete, __("Press [Esc] to finish"));
            document.addEventListener('click', confidential.clickListener, true);
            window.addEventListener('keydown', confidential.exitListener, true);
            document.addEventListener('statepushed', confidential.exitListener, true);
        }
        private setStyle(style: string) {
            viggo.dom.empty(this.styleTag);
            this.styleTag.appendChild(viggo.dom.text(style));
            document.getElementsByTagName('head')[0].appendChild(this.styleTag);
        }
        public static endSelection() {
            this.unlock();
            document.removeEventListener('click', confidential.clickListener, true);
            window.removeEventListener('keydown', confidential.exitListener, true);
            document.removeEventListener('statepushed', confidential.exitListener, true);
        }
        public static hasConfidentialElements() {
            return !!document.querySelector('[data-confidential]');
        }
        public static setConfidential(id: string, lock: boolean) {
            new viggo.ajax({
                method: 'post',
                url: '/Shared/Visibility/SaveConfidential',
                json: true,
                data: {
                    id: id,
                    locked: lock
                }
            });
        }
        public static lock(ids: string[]) {
            confidential.unlock();
            let instance = confidential.getInstance();
            instance.setStyle(`[data-confidential="${ids.join('"],[data-confidential="')}"] {
                display: none !important;
            }`);
        }
        public static unlock() {
            document.documentElement!.classList.remove('confidential-selection');
            if (confidential.instance) {
                confidential.instance.styleTag.remove();
            }
        }
    }
}