module viggo {
    let picker: HTMLElement | null = null;
    let result: HTMLElement | null = null;
    let input: HTMLInputElement | null = null;
    let click = function (event: MouseEvent) {
        let target = <HTMLElement>event.target;
        if (result && input) {
            let ev = new Event('change', { bubbles: true });
            if (target.dataset.img) {
                result.style.backgroundImage = 'url(' + (<HTMLImageElement>target).src + ')';
                input.value = target.dataset.img;
                input.dispatchEvent(ev);
            } else if (typeof target.dataset.color == 'string') {
                result.style.backgroundColor = target.dataset.color;
                input.value = target.dataset.color;
                input.dispatchEvent(ev);
            }
        }
    };

    document.addEventListener('click', function (event) {
        if (event.button !== 0 || !event.target || !(<HTMLElement>event.target).dataset) {
            return;
        }
        if (picker) {
            viggo.dom.empty(picker);
            picker.style.display = 'none';
            picker = null;
        }
        let target = <HTMLElement>event.target,
            id = target.dataset.pickerGuid,
            type = target.dataset.pickerType;

        if (id && type) {
            event.preventDefault();
            event.stopPropagation();
            var hidden = 't-' + id;
            new viggo.ajax({
                method: 'get',
                url: '/Shared/Picker/' + type,
                convert: 'html',
                complete: function (html) {
                    picker = document.getElementById(id!);
                    if (picker) {
                        viggo.dom.empty(picker);
                        picker.appendChild(html);
                        picker.style.display = '';
                        picker.addEventListener('click', click, false);
                        result = document.querySelector('[data-picker-guid="' + id + '"]');
                        input = <HTMLInputElement|null>document.getElementById(hidden);
                    }
                }
            });
        }
    }, false);
}