/*
Undo and notices
Definitions:
    viggo.undo(type, text, actions);
    viggo.notice(type, text);

Examples:
    viggo.undo('delete', 'Pages was deleted.', [{
        description: "Fortryd",
        className: "rollback",
        callback: function(){viggo.ajax({method: 'post', url: '/UndoUrl'});}
    }]);
    viggo.notice('complete', 'Filen blev uploadet.')
*/
module viggo {
    let div: HTMLElement|null, hideTimeout = 0;
    function remove() {
        clearTimeout(hideTimeout);
        if (div) {
            div.remove();
        }
        div = null;
    }
    export enum NoticeType {
        notice = 'notice', // 0
        complete = 'complete', // 1
        warning = 'warning', // 2
        error = 'error', // 3
        success = 'success', // 4
        notsaved = 'notsaved', // 5
        delete = 'delete' // 100
    };
    interface NoticeAction {
        description: string,
        className?: string,
        url?: string,
        callback?: string | NoticeCallback
    }
    type NoticeCallback = (event: Event) => void|boolean;

    function createDom(type: NoticeType, text: string, actions?: NoticeAction|NoticeAction[], hideDelay: number = 4000) {
        remove();
        let fragment = viggo.dom.fragment();
        text.split("\n").forEach((x, index) => {
            if (index) {
                fragment.appendChild(viggo.dom.tag('br'));
            }
            fragment.appendChild(viggo.dom.text(x));
        });

        div = viggo.dom.tag('div', {
            id: 'notice',
            className: type
        },
            viggo.dom.tag('i', { className: 'flaticon-' + type }),
            viggo.dom.tag('p', null, fragment),
            viggo.dom.tag('a', {
                className: 'close',
                onclick: (event: MouseEvent) => {
                    event.preventDefault();
                    event.stopPropagation();
                    hide(true);
                }
            })
        );
        if (actions) {
            let createAction = function (action: NoticeAction) {
                let a = viggo.dom.tag('a', {
                    href: '#',
                    className: action.className || '',
                    onclick: function (event: MouseEvent) {
                        event.preventDefault();
                        event.stopPropagation();
                        viggo.history.removeLastRequest();
                        if (action.callback) {
                            var callback: string | NoticeCallback = action.callback;
                            if (typeof callback == 'string') {
                                callback = <NoticeCallback>new Function("event", callback);
                            }
                            if (callback.call(a, event) !== false) {
                                hide(true);
                            }
                        } else if (action.url) {
                            new viggo.ajax({
                                method: 'post',
                                url: action.url
                            });
                            hide(true);
                        }
                    }
                }, action.description || '');
                div!.appendChild(a);
            };

            if (actions) {
                if (actions.constructor == Array) {
                    (<NoticeAction[]>actions).forEach(x => createAction(x));
                } else {
                    createAction(<NoticeAction>actions);
                }
            }
        }
        if (hideDelay >= 0) {
            let f = function () {
                document.removeEventListener('click', f, false);
                if (div) {
                    hide(true);
                }
            };
            switch (type) {
                case NoticeType.notice:
                case NoticeType.complete:
                    if (!actions) {
                        hideTimeout = window.setTimeout(f, hideDelay);
                        break;
                    } // don't break
                default:
                    hideTimeout = window.setTimeout(function () {
                        document.addEventListener('click', f, false);
                    }, hideDelay);
                    break;
            }
        }
        return document.body.appendChild(div);
        //viggo.vibrate([100, 200, 100, 200, 100]);
    }
    function hide(fast?: boolean) {
        clearTimeout(hideTimeout);
        new viggo.effect({
            element: div,
            style: 'opacity',
            from: 0.95,
            to: 0,
            duration: fast ? 100 : 200,
            complete: remove
        });
    }
    /*
        TODO:
        Evt. ændre parametrerne til noget mere action-specifikt.
    */
    export function undo(type: NoticeType, text: string, actions?: NoticeAction|NoticeAction[], hideDelay?: number) {
        return createDom(type, text, actions, hideDelay);
    }
    export function notice(type: NoticeType, text: string, hideDelay?: number) {
        return viggo.undo(type, text, undefined, hideDelay);
    }
}