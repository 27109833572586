module viggo {
    interface UserRole {
        Id: number,
        TypeNameExt: string,
        TypeName: string,
        Color: string
        Selected: boolean,
        ShowForAdmin: boolean,
        Orderby: number,
        TimeStart: Date,
        TimeEnd: Date
        UserRoleId?: number,
        CountUsers: number,

        NameLowerCase: string
    }
    interface UserProfile {
        ViggoFunction: number,
        Usertype: UserRole,
        Value: string
    }
    export interface AutoCompleteItem {
        // set by server
        Id: number,
        Name: string,
        SearchText: string,

        // set by javascript
        type: string,
        NameLowerCase: string,
        Search: string[],
        IsSelected: boolean,
        score: number
    }
    export interface AutoCompleteUser extends AutoCompleteItem {
        Image: string,
        Initials: string,
        Grade: string,
        OtherInfo: string,
        StudentId?: number,
        UserRolls: UserRole[],
        HouseRoom: string,
        SearchText: string,

        // set by javascript
        MainUserRoll: UserRole | null,
        Relations?: { [name: number]: AutoCompleteUserRelation },
    }
    interface AutoCompleteUserRelation extends Array<AutoCompleteUser> {
        Relation: AutoCompleteRelation
    }
    export interface AutoCompleteGroup extends AutoCompleteItem {
        // set by server
        Users: AutoCompleteUser[],
        Teachers: AutoCompleteUser[],
        UsersIds: number[],
        UsersIdsString: string,
        ContractTeacherIds: number[],
        ContractTeacherString: string,
        ContractTeacher: boolean,
        OriginalName: string,
        Categories: any,
        FolderId: number,
        FolderName: string,
        Visible: boolean,
        CountMemberInGroup: number,
        Description: string,
        UpdatedBy: number,
        UpdatedByUser: AutoCompleteUser,
        TimeStamp: Date,
        Selected: boolean,
        SyncId: string,
        Remove: boolean,
        GroupType: number,
        NameFirstPriority: string,
        Locked: boolean,
        LockedSync: boolean,
        AccessGroups: AutoCompleteGroup[],

        // set by javascript
        HasRelations: boolean,
    }

    interface AutoCompleteRelation {
        // set by server
        Id: number
        Name: string,
        Description: string,
        TimeStamp: Date,
        OrderBy: number,

        // set by javascript
        checked: boolean
    }

    interface AutoCompleteServerResponse {
        Users: AutoCompleteUser[],
        Groups: AutoCompleteGroup[],
        RelationNames?: AutoCompleteRelation[],
        Relations?: AutoCompleteRelationReference[],
        Templates: {[name: string]: string},
        Translations?: {[name: string]: string}
    }

    interface AutoCompleteRelationReference {
        MasterUserId: number,
        UserId: number,
        RelationsId: number
    }

    interface AutoCompleteRelationHeader {
        Name: string
    }
    interface HeaderItem {
        Title: string;
    }
    interface AutocompleteQuery {
        queryList: string[],
        wordList: string[]
    }

    type UserTemplate = (item: AutoCompleteUser) => string;
    type GroupTemplate = (item: AutoCompleteGroup) => string;
    type HeaderTemplate = (item: HeaderItem) => string;
    interface AutoCompleteTemplates {
        User?: UserTemplate,
        Group?: GroupTemplate,
        RelationUser?: UserTemplate,
        RelationSearchMasterUser?: UserTemplate,
        RelationSearchChildHeader?: (item: AutoCompleteUserRelation) => string,
        RelationSearchChildUser?: UserTemplate,
        RelationSearchRelationCheckboxes?: (item: AutoCompleteRelation[]) => string,
        RelationSearchAddAll?: (item: any) => string,
        RelationSearchHeadlineUser?: UserTemplate,
        RelationSearchHeadlineGroup?: GroupTemplate,
        UserSelected?: UserTemplate,
        GroupSelected?: GroupTemplate,
        Header?: HeaderTemplate,
        HeaderGroup?: HeaderTemplate,
        RemoveAllSelected?: (item: any) => string,
        SuperSearchUser?: UserTemplate,
        SuperSearchGroup?: GroupTemplate,
        [index: string]: Function|undefined
    }
    type AutoCompleteRelationNames = {[index: number] : AutoCompleteRelation};
    type AutoCompleteValueList = AutoCompleteItem[];
    type AutoCompleteSelectCallback = (element: AutoCompleteItem, event: Event) => void;
    type AutoCompleteUserMap = { [index: number]: AutoCompleteUser|null };
    type AutoCompleteGroupMap = { [index: number]: AutoCompleteGroup | null };

    enum AutoCompleteFlags {
        Users = 1,
        Groups = 2,
        StayOpen = 4,
        AllowDuplicates = 8,
        SingleItem = 16
    }

    interface AutoCompleteOptions {
        element: HTMLElement;
        flags: string | number;
        results: string;
    }

    export abstract class autocomplete extends viggo.classes.eventListener {
        private static defaultUrl: string = '/Shared/Search/GetautocompleteData';
        private static values: { [name: string]: AutoCompleteValueList } = {};
        private static urls: { [name: string]: string } = {
            "": autocomplete.defaultUrl,
            "Crmcustomers": '/Crm/Lists/GetCustomerAutoCompleteData/',
            "Crmlist": '/Crm/Lists/GetListAutoCompleteData/',
            //"Substitutefor": '/Crm/Lists/GetListAutoCompleteData/'
        }
        private static idMap: { [name: string]: AutoCompleteUserMap } = {}
        private static groupMap: { [name: string]: AutoCompleteGroupMap } = {}
        protected static relationNames: { [name: string]: AutoCompleteRelationNames } = {}
        private static templates: { [name: string]: AutoCompleteTemplates } = {}
        private static translations: { [name: string]: { [index: string]: string } } = {}
        private static scroller: Document | Element | null = null;
        public static loaded: { [name: string]: number } = {}
        public static cacheVersion: string|null = null;
        private static shortcutInfo: string|null = null;

        private static domRelation: HTMLElement | null;
        public static domParent: HTMLElement | null;
        protected static dynamiclist: viggo.dynamiclistview | null = null;
        public static modals: viggo.modal[] = [];
        public static scrollAdd: number = 0;

        private static lastSelectedItem: Element | null = null;

        protected lastSelectionIndex: number = -1;

        protected filter: AutoCompleteFlags = 0;
        protected element: HTMLElement;
        protected destination!: HTMLInputElement;
        protected guiDestination!: Element;
        protected templates: AutoCompleteTemplates;
        protected idMap: AutoCompleteUserMap;
        protected groupMap: AutoCompleteGroupMap;
        protected values: AutoCompleteValueList;
        protected translations: { [name: string]: string };
        private removeAllButton: HTMLElement | null = null;
        public defaultValue: string;

        constructor(options: AutoCompleteOptions) {
            super();
            if (options.flags) {
                if (typeof options.flags == 'string') {
                    for (let flag of options.flags.split(' ')) {
                        if (flag) {
                            this.filter |= AutoCompleteFlags[<keyof typeof AutoCompleteFlags>flag];
                        }
                    }
                } else if (typeof options.flags == 'number') {
                    this.filter = options.flags;
                }
            }
            this.element = options.element;

            if (!autocomplete.urls[this.name]) {
                autocomplete.urls[this.name] = this.url;
            }
            this.initializeDestination(options.results);

            this.values = autocomplete.values[this.name];
            this.idMap = autocomplete.idMap[this.name];
            this.groupMap = autocomplete.groupMap[this.name];
            this.templates = autocomplete.templates[this.name];
            this.translations = autocomplete.translations[this.name];

            this.element.addEventListener('selectstart', (ev: Event) => autocomplete.remove(ev), false);
            this.defaultValue = this.destination.value;
        }

        protected initializeDestination(destinationName: string) {
            let elm = <HTMLInputElement | HTMLTextAreaElement>this.element;
            let dest = elm.form ? <Element | null>elm.form.elements.namedItem(destinationName) : document.querySelector(`input[type="hidden"][name="${destinationName}"]`);

            let gui: HTMLElement | null = null;
            if (dest && (<HTMLInputElement>dest).tagName == 'INPUT') {
                this.destination = <HTMLInputElement>dest;
                gui = <HTMLElement | null>this.destination.previousElementSibling;
            } else {
                throw new Error('Missing destination for autocomplete');
            }
            if (gui && gui.classList.contains('choosen-choices')) {
                this.guiDestination = <HTMLElement>gui;
            } else {
                this.guiDestination = document.createElement('ul');
                this.guiDestination.classList.add('choosen-choices');
                this.destination.parentNode!.insertBefore(this.guiDestination, this.destination);
            }

        }

        public get url() {
            return this.element.dataset.autocompleteUrl || autocomplete.defaultUrl;
        }

        public set url(value: string) {
            this.element.dataset.autocompleteUrl = value;
        }

        public get name() {
            return this.element.dataset.autocompleteName || '';
        }

        public set name(value: string) {
            this.element.dataset.autocompleteName = value;
        }

        public static modalScroll(e: Event) {
            autocomplete.getScroll(<Element|Document>e.target);
        }

        public static setUrl(name: string, url: string) {
            if (autocomplete.urls[name] != url) {
                autocomplete.urls[name] = url;
                if (name === "") {
                    autocomplete.defaultUrl = url;
                }
                autocomplete.clear(name);
            }
        }
    
        private static getScroll(scrolledElement: Element | Document) {
            if (scrolledElement != autocomplete.domParent) {
                if (autocomplete.domRelation && viggo.dom.parentOf(autocomplete.domRelation, <Element>scrolledElement)) {
                    autocomplete.scrollAdd = viggo.getScrollTop(<HTMLElement>scrolledElement);
                } else if (scrolledElement == document) {
                    autocomplete.scrollAdd = document.documentElement!.scrollTop;
                } else {
                    autocomplete.scrollAdd = 0;
                }
                if (autocomplete.domParent) {
                    autocomplete.domParent.style.marginTop = -autocomplete.scrollAdd + 'px';
                }
            }
            return autocomplete.scrollAdd;
        };

        protected delaySearch: (() => void) | null = null;

        public hasFlags(flags: AutoCompleteFlags) {
            return (this.filter & flags) == flags;
        }

        public get value() {
            return this.destination.value;
        }

        protected createUserFragment(element: AutoCompleteUser, callback: AutoCompleteSelectCallback) {
            if (!this.templates.User) {
                throw new Error('Missing template "User"');
            }
            let view = viggo.func.createView(element, this.templates.User);
            if (view.firstChild) {
                (<HTMLElement>view.firstChild).addEventListener('click', (event: MouseEvent) => {
                    callback(element, event);
                }, false);
            }
            return view;
        }

        protected createGroupFragment(element: AutoCompleteGroup) {
            if (!this.templates.Group) {
                throw new Error('Missing template "Group"');
            }
            return viggo.func.createView(element, this.templates.Group);
        }

        private createSelectedUserFragment(element: AutoCompleteUser) {
            if (!this.templates.UserSelected) {
                throw new Error('Missing template "UserSelected"');
            }
            return viggo.func.createView(element, this.templates.UserSelected);
        }

        private createSelectedGroupFragment(element: AutoCompleteGroup) {
            if (!this.templates.GroupSelected) {
                throw new Error('Missing template "GroupSelected"');
            }
            return viggo.func.createView(element, this.templates.GroupSelected);
        }

        private createHeaderFragment(title: string) {
            if (!this.templates.Header) {
                throw new Error('Missing template "Header"');
            }
            return viggo.func.createView({ Title: title }, this.templates.Header);
        }

        private createGroupHeaderFragment(title: string) {
            if (!this.templates.HeaderGroup) {
                throw new Error('Missing template "HeaderGroup"');
            }
            return viggo.func.createView({ Title: title }, this.templates.HeaderGroup);
        }

        public static remove(ev?: Event) {
            if (!ev || !(<HTMLInputElement>ev.target).viggoAutoComplete) {
                if (autocomplete.domParent) {
                    document.removeEventListener('click', autocomplete.remove, false);
                    autocomplete.domParent.parentNode!.removeChild(autocomplete.domParent);
                    autocomplete.domParent = null;
                    autocomplete.lastSelectedItem = null;
                }
                if (autocomplete.scroller) {
                    (<Document>autocomplete.scroller).removeEventListener('scroll', autocomplete.modalScroll, false);
                    autocomplete.scroller = null;
                }
                autocomplete.domRelation = null;
                autocomplete.dynamiclist = null;
            }
        }

        protected userRoleDictionary(users: AutoCompleteUser[]) {
            let rollTypes: { [index: string]: AutoCompleteUser[] } = {};
            for (let element of users) {
                for (let roll of element.UserRolls) {
                    let name: string = roll.TypeName || (<any>roll).Name;
                    if (!rollTypes[name]) {
                        rollTypes[name] = [];
                    }
                    rollTypes[name].push(element);
                }
            }
            return rollTypes;
        }

        protected createHeaders() {

        }

        protected createUsers(users: AutoCompleteUser[], callback: AutoCompleteSelectCallback, typeHeader?: boolean) {
            var element, div, me = this, lastType = null;
            if (users.length && !typeHeader && autocomplete.dynamiclist) {
                autocomplete.dynamiclist.appendChild(<HTMLElement>this.createHeaderFragment(this.translations.users).firstElementChild);
            }
            this.createHeaders();
            let rollTypes = typeHeader ? this.userRoleDictionary(users) : { '': users.slice() };
            Object.values(rollTypes).forEach(list => {
                list.sort(autocomplete.sortCallback);
            });
            if (autocomplete.dynamiclist) {
                for (let type in rollTypes) {
                    let list = rollTypes[type];
                    if (typeHeader) {
                        autocomplete.dynamiclist.appendChild(<HTMLElement>this.createHeaderFragment(type).firstElementChild);
                    }
                    for (let user of list) {
                        div = this.createUserFragment(user, callback);
                        autocomplete.dynamiclist.appendChild(<HTMLElement>div.firstElementChild);
                    }
                }
            }
        }

        private createGroups(groups: AutoCompleteGroup[], callback: AutoCompleteSelectCallback, typeHeader?: boolean) {
            var i = 0, element, div, me = this, lastType = null;
            if (groups.length && autocomplete.dynamiclist) {
                autocomplete.dynamiclist.appendChild(<HTMLElement>this.createHeaderFragment(this.translations.groups).firstElementChild);
                while (i < groups.length) {
                    element = groups[i++];
                    if (typeHeader) {
                        if (lastType !== element.FolderName) {
                            lastType = element.FolderName;
                            autocomplete.dynamiclist.appendChild(<HTMLElement>this.createGroupHeaderFragment(lastType).firstElementChild);
                        }
                    }
                    div = <HTMLElement|null>this.createGroupFragment(element).firstElementChild;
                    ((obj) => {
                        if (div) {
                            (<HTMLElement>div).addEventListener('click', (event: MouseEvent) => {
                                callback(obj, event);
                            }, false);
                        }
                    })(element);
                    autocomplete.dynamiclist.appendChild(<HTMLElement>div);
                }
            }
        }

        private createDomParent(relation: HTMLElement, top?: number, left?: number, width?: number) {
            if (autocomplete.scroller) {
                (<Document>autocomplete.scroller).removeEventListener('scroll', autocomplete.modalScroll, true);
                autocomplete.scroller = null;
            }
            autocomplete.scroller = document;
            if (autocomplete.scroller) {
                autocomplete.scroller.addEventListener('scroll', autocomplete.modalScroll, true);
                autocomplete.scrollAdd = autocomplete.getScroll(relation);
            }
            autocomplete.scrollAdd += window.pageYOffset;
            if (top === undefined || left === undefined || width == undefined) {
                let pos = relation.getBoundingClientRect(),
                    parentPos = pos;
                if (relation.parentNode) {
                    parentPos = (<Element>relation.parentNode).getBoundingClientRect();
                }
                top = pos.bottom;
                left = parentPos.left;
                width = parentPos.width;
            }
            autocomplete.domRelation = relation;
            autocomplete.domParent = viggo.dom.tag('ul', {
                id: 'autocomplete',
                onmousemove: (event: Event) => this.mousemove(event),
                className: viggo.autocomplete.loaded[this.name] == 2 ? '' : 'loading',
                style: {
                    top: top + window.pageYOffset + 'px',
                    left: left + 'px',
                    width: width + 'px',
                    marginTop: -autocomplete.scrollAdd + 'px'
                }
            });
            autocomplete.dynamiclist = new viggo.dynamiclistview({
                element: autocomplete.domParent,
                itemCount: 60,
                itemHeight: 55,
                stickyClass: 'h5'
            });
        }

        protected mousemove(event: Event) {
            autocomplete.lastSelectedItem = <HTMLElement>event.target;
        }

        public show(users: AutoCompleteUser[], groups: AutoCompleteGroup[], callback: AutoCompleteSelectCallback, relation: HTMLElement, top?: number, left?: number, width?: number) {
            autocomplete.remove();
            this.createDomParent(relation, top, left, width);
            if (this.hasFlags(AutoCompleteFlags.Users)) {
                this.createUsers(users, callback);
            }
            if (this.hasFlags(AutoCompleteFlags.Groups)) {
                this.createGroups(groups, callback);
            }
            if (autocomplete.domParent) {
                document.body.appendChild(autocomplete.domParent);
                if (autocomplete.dynamiclist) {
                    autocomplete.dynamiclist.render();
                }
                autocomplete.lastSelectedItem = <Element>autocomplete.domParent.firstElementChild;
                while (autocomplete.lastSelectedItem && !this.isListItem(autocomplete.lastSelectedItem)) {
                    autocomplete.lastSelectedItem = <Element>autocomplete.lastSelectedItem.nextElementSibling;
                }
                if (autocomplete.lastSelectedItem) {
                    autocomplete.lastSelectedItem.classList.add('autocomplete-selected');
                }
            }
            document.addEventListener('click', autocomplete.remove, false);
        }

        protected dispatchAllChanged() {
            let value = this.value;
            let ids = value ? value.split(',').map(x => parseInt(x)) : [];

            let ev = new CustomEvent('viggoAutocompletedAll', {
                bubbles: true,
                cancelable: false,
                detail: ids
            });
            this.element.dispatchEvent(ev);
        }

        public showAll(users: AutoCompleteUser[], groups: AutoCompleteGroup[], callback: AutoCompleteSelectCallback, relation: HTMLElement) {
            autocomplete.remove();
            this.createDomParent(relation);
            if (this.hasFlags(AutoCompleteFlags.Users)) {
                this.createUsers(users, callback, true);
            }
            if (this.hasFlags(AutoCompleteFlags.Groups)) {
                this.createGroups(groups, callback, true);
            }
            if (autocomplete.domParent) {
                document.body.appendChild(autocomplete.domParent);
                if (autocomplete.dynamiclist) {
                    autocomplete.dynamiclist.render();
                }
            }
            let search = "";
            let timeout: number = 0;
            let keydown = this.keydown.bind(this);
            let k = function (event: KeyboardEvent) {
                window.clearTimeout(timeout);
                timeout = window.setTimeout(function () {
                    search = "";
                }, 1000);
                event.preventDefault();
                switch (event.keyCode) {
                    case 8: // backspace
                        search = search.substring(0, search.length - 1);
                        break;
                    default:
                        search += String.fromCharCode(event.charCode).toLowerCase();
                        break;
                }
                if (autocomplete.domParent) {
                    var node = <HTMLElement|null>document.evaluate('div[starts-with(translate(., "ABCDEFGHIJKLMNOPQRSTUVWXYZ", "abcdefghijklmnopqrstuvwxyz"),"' + search + '")]', autocomplete.domParent, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
                    if (node && autocomplete.domParent) {
                        autocomplete.domParent.scrollTop = node.offsetTop;
                        if (autocomplete.lastSelectedItem) {
                            autocomplete.lastSelectedItem.classList.remove('autocomplete-selected');
                            autocomplete.lastSelectedItem = null;
                        }
                        autocomplete.lastSelectedItem = <Element>node;
                        node.classList.add('autocomplete-selected');
                    }
                }
            }, b = function (event: KeyboardEvent) {
                if (event.keyCode == 8) {
                    event.preventDefault();
                }
            }
                , c =  () => {
                    document.removeEventListener('keypress', k, false);
                    document.removeEventListener('keydown', b, false);
                    document.removeEventListener('keydown', keydown, false);
                    document.removeEventListener('click', c, false);
                    autocomplete.remove();
                };
            document.addEventListener('keydown', keydown, false);
            document.addEventListener('keydown', b, false);
            document.addEventListener('keypress', k, false);
            document.addEventListener('click', c, false);
        }

        protected isListItem(item: Element) {
            return !(item.className == '' || item.classList.contains('h4') || item.classList.contains('h5'));
        }

        public moveUp() {
            let item: HTMLElement | null = null;
            if (autocomplete.lastSelectedItem) {
                item = <HTMLElement | null>autocomplete.lastSelectedItem.previousElementSibling;
                while (item && !this.isListItem(item)) {
                    item = <HTMLElement | null>item.previousElementSibling;
                }
            }
            if (item) {
                this.scrollIntoView(item);
            }
            return !!item;
        }

        public moveDown() {
            let item: HTMLElement | null = null;
            if (autocomplete.domParent) {
                if (!autocomplete.lastSelectedItem) {
                    item = <HTMLElement>autocomplete.domParent.firstChild;
                } else {
                    item = <HTMLElement>autocomplete.lastSelectedItem.nextElementSibling;
                }
                while (item && !this.isListItem(item)) {
                    item = <HTMLElement>item.nextElementSibling;
                }
                if (item) {
                    this.scrollIntoView(item);
                }
            }
            return !!item;
        }

        private scrollIntoView(item: HTMLElement) {
            if (autocomplete.lastSelectedItem) {
                autocomplete.lastSelectedItem.classList.remove('autocomplete-selected');
            }
            autocomplete.lastSelectedItem = item;
            item.classList.add('autocomplete-selected');
            if (autocomplete.domParent) {
                let top = autocomplete.domParent.scrollTop;
                top = Math.min(top, item.offsetTop);
                top = Math.max(top, item.offsetTop - autocomplete.domParent.offsetHeight + item.offsetHeight);
                autocomplete.domParent.scrollTop = top;
            }
        }

        public clickSelected() {
            if (autocomplete.lastSelectedItem) {
                var evt = document.createEvent('MouseEvents');
                evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                autocomplete.lastSelectedItem.dispatchEvent(evt);
                return true;
            }
            return false;
        }

        protected keydown(event: KeyboardEvent) {
            let key = event.key;
            if (autocomplete.domParent) {
                switch (key) {
                    case 'ArrowUp':
                        if (this.moveUp()) {
                            event.preventDefault();
                        }
                        break;
                    case 'ArrowDown':
                        if (this.moveDown()) {
                            event.preventDefault();
                        }
                        break;
                    case 'Enter':
                    case 'Tab':
                        this.clickSelected();
                        if (key == 'Enter') { // ignore tab
                            event.preventDefault();
                        }
                        break;
                    default:
                        if (autocomplete.lastSelectedItem && !event.ctrlKey && !event.metaKey) {
                            autocomplete.lastSelectedItem.classList.remove('autocomplete-selected');
                            autocomplete.lastSelectedItem = null;
                        }
                        break;
                }
            }
        }

        private getPosition() {
            return viggo.getPosition(this.element);
        }

        private createGuiUser(object: AutoCompleteItem) {
            let elm: HTMLElement|DocumentFragment|null = null;
            switch (object.type) {
                case 'group':
                    elm = this.createSelectedGroupFragment(<AutoCompleteGroup>object);
                    break;
                case 'user':
                default:
                    elm = this.createSelectedUserFragment(<AutoCompleteUser>object);
                    break;
            }
            if (elm) {
                let child = <HTMLElement|null>elm.firstChild;
                if (child) {
                    child.dataset.autocompleteId = object.Id.toString();
                    let remove = elm.querySelector('.remove');
                    if (remove) {
                        remove.addEventListener('click', (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            this.removeElementFromSelected(object.Id);
                            this.dispatchAllChanged();
                        }, false);
                    }
                }
            }
            return elm;
        }
    
        public removeElementFromSelected(id: number | string) {
            id = id.toString();
            let result = true;
            let ids = this.destination.value.split(',');
            let index = ids.indexOf(id);
            if (index !== -1) {
                ids.splice(index, 1);
            }
            var ev = new CustomEvent('viggoAutocompleted', {
                bubbles: true,
                cancelable: true,
                detail: ids
            });
            result = this.element.dispatchEvent(ev);
            if (result) {
                this.destination.value = ids.join(',');
            }
            var elm = this.guiDestination.querySelector('[data-autocomplete-id="' + id + '"]');
            if (elm) {
                this.guiDestination.removeChild(elm);
            }
            if (this.destination.value == '' && this.removeAllButton) {
                this.removeAllButton.remove();
                this.removeAllButton = null;
            }
            return result;
        }

        private clearSelectedElements() {
            var elm = this.guiDestination.querySelectorAll('[data-autocomplete-id]').forEach(elm => this.guiDestination.removeChild(elm));
            if (this.destination.value == '' && this.removeAllButton) {
                this.removeAllButton.remove();
                this.removeAllButton = null;
            }
        }
    
        public async addElementToSelected(obj: AutoCompleteItem, removeTarget?: HTMLElement, addOnly = false, dispatchAllEvent = true) {
            await this.load();
            let addUser = (id: number, target?: HTMLElement) => {
                if (!this.templates.RemoveAllSelected) {
                    throw new Error('Missing template "RemoveAllSelected"');
                }
                if (this.hasFlags(AutoCompleteFlags.SingleItem)) {
                    viggo.dom.empty(this.guiDestination);
                    this.destination.value = '';
                }
                var value = this.destination.value.split(',');
                if (value.length && value[0] === '') {
                    value = [];
                }
                if (this.hasFlags(AutoCompleteFlags.AllowDuplicates) || value.indexOf(id.toString()) == -1) {
                    let arg: AutoCompleteItem | null = this.hasFlags(AutoCompleteFlags.Users) ? this.idMap[id] : this.groupMap[id];
                    if (arg) {
                        value.push(id.toString());
                        var ev = new CustomEvent('viggoAutocompleted', {
                            bubbles: true,
                            cancelable: true,
                            detail: value
                        });
                        if (this.element.dispatchEvent(ev)) {
                            this.destination.value = value.join(',');
                            this.guiDestination.appendChild(this.createGuiUser(arg));
                            arg.IsSelected = true;
                            if (!this.removeAllButton) {
                                let fragment = viggo.func.createView({}, this.templates.RemoveAllSelected);
                                if (fragment.firstChild instanceof HTMLElement) {
                                    this.removeAllButton = fragment.firstChild;
                                    let btn = <HTMLElement|null>this.removeAllButton.querySelector('.remove-all');
                                    if (btn) {
                                        btn.addEventListener('click', (ev: MouseEvent) => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            this.destination.value.split(',').forEach(id => this.removeElementFromSelected(id));
                                            this.dispatchAllChanged();
                                            this.removeAllButton = null;
                                        }, false);
                                    }
                                    this.guiDestination.insertBefore(this.removeAllButton, this.guiDestination.firstChild);
                                }
                            }
                        }
                    } else {
                        return false;
                    }
                }

                if (target) {
                    target = <HTMLElement|undefined>viggo.dom.parentFilter(target, function (elm) {
                        return elm.classList.contains('autocomplete_user') || elm.classList.contains('autocomplete_group');
                    });
                    if (target) {
                        if (target == autocomplete.lastSelectedItem) {
                            do {
                                autocomplete.lastSelectedItem = <Element|null>autocomplete.lastSelectedItem!.nextSibling;
                            } while (autocomplete.lastSelectedItem && autocomplete.lastSelectedItem.tagName !== 'UL');
    
                            if (!autocomplete.lastSelectedItem) {
                                autocomplete.lastSelectedItem = target;
                                do {
                                    autocomplete.lastSelectedItem = <Element|null>autocomplete.lastSelectedItem!.previousSibling;
                                } while (autocomplete.lastSelectedItem && autocomplete.lastSelectedItem.tagName !== 'UL');
                            }
                            if (autocomplete.lastSelectedItem) {
                                autocomplete.lastSelectedItem.classList.add('autocomplete-selected');
                            }
                        }
                        target.remove();
                    }
                }
            };
            var errors = 0;
            if (this.hasFlags(AutoCompleteFlags.Users | AutoCompleteFlags.Groups) && obj.type == 'group') {
                let ids = (<AutoCompleteGroup>obj).UsersIds.slice(0);
                ids.sort((a, b) => {
                    let result = 0;
                    let userA = this.idMap[a],
                        userB = this.idMap[b];
                    if (userA && userB) {
                        result = userA.NameLowerCase < userB.NameLowerCase ? -1 : 1;
                    }
                    return result;
                });
                for (let id of ids) {
                    if (addUser(id) === false) {
                        errors++;
                    }
                }
            } else if (this.inSelectedElements(obj) && !addOnly) {
                this.removeElementFromSelected(obj.Id);
            } else {
                addUser(obj.Id, removeTarget);
            }
            if (errors) {
                viggo.notice(NoticeType.warning, __("{num} users were not added, due to access restrictions", {num: errors}));
            }
            if (dispatchAllEvent) {
                this.dispatchAllChanged();
            }
        }
    
        private inSelectedElements(object: AutoCompleteItem) {
            if (object.type != 'user')
                return false;
            var ids = this.destination.value.split(',');
            var index = ids.indexOf(object.Id.toString());
            return index !== -1;
        }

        protected abstract filterValues(): AutoCompleteItem[];

        public async search(string: string) {
            let users: AutoCompleteUser[] = [],
                groups: AutoCompleteGroup[] = [],
                valueIndex = 0,
                element;

            let query = viggo.autocomplete.queryToLists(string);
    
            await this.load();
            let valueSet = this.filterValues();

            while (valueIndex < valueSet.length) {
                element = valueSet[valueIndex++];
                element.IsSelected = this.inSelectedElements && this.inSelectedElements(element);
                /*
                if (!this.allowDuplicates && this.inSelectedElements && this.inSelectedElements(element) && !this.relationSearch && this.relationSearch.type == 'group')) {
                    continue;
                }
                */
                if (viggo.autocomplete.searchElement(query, element)) {
                    element.score = autocomplete.scoreElement(query, element);
                    switch (element.type) {
                    case 'user':
                        users.push(<AutoCompleteUser>element);
                        break;
                    case 'group':
                        groups.push(<AutoCompleteGroup>element);
                        break;
                    }
                }
            }
    
            return { users: users.sort(autocomplete.sortCallback), groups: groups.sort(autocomplete.sortCallback) };
        }
    
        private async showAllClick(relation?: HTMLElement) {
            let data: {[index: string]: AutoCompleteItem[]} = {
                user: [],
                group: []
            };
    
            await this.load();
    
            for (var i = 0; i < this.values.length; i++) {
                if (!this.inSelectedElements(this.values[i])) {
                    data[this.values[i].type].push(this.values[i]);
                }
            }
            data.user = (<AutoCompleteUser[]>data.user).sort(function (a, b) {
                var rollA = a.UserRolls[0],
                    rollB = b.UserRolls[0];
                if (rollA.Orderby != rollB.Orderby) {
                    return rollA.Orderby - rollB.Orderby;
                } else if (rollA.NameLowerCase != rollB.NameLowerCase) {
                    return rollA.NameLowerCase < rollB.NameLowerCase ? -1 : 1;
                } else if (a.NameLowerCase != b.NameLowerCase) {
                    return a.NameLowerCase < b.NameLowerCase ? -1 : 1;
                } else {
                    return a.Id - b.Id;
                }
            });
            this.showAll(<AutoCompleteUser[]>data.user, <AutoCompleteGroup[]>data.group, (obj: AutoCompleteItem, event: Event) => {
                this.addElementToSelected(obj, <HTMLElement>event.target);
                if (this.hasFlags(AutoCompleteFlags.StayOpen) || (<KeyboardEvent>event).ctrlKey || (<KeyboardEvent>event).metaKey) {
                    event.stopPropagation();
                }
            }, relation || this.element);
        };

        private showShortcutsClick(formGroup: Element) {
            let showInfo = () => {
                if (document.body.contains(formGroup)) {
                    let tag = viggo.dom.parseHTML(<string>autocomplete.shortcutInfo).firstElementChild;
                    if (tag) {
                        let remove = (event: Event) => {
                            (<Element>tag).remove();
                            document.removeEventListener('click', remove, true);
                        };
                        document.addEventListener('click', remove, true);
                        formGroup.appendChild(tag);
                    }
                }
            }

            if (autocomplete.shortcutInfo) {
                showInfo();
            } else {
                fetch(new Request('/Shared/Search/ShortcutInfo', {
                    method: 'GET',
                    credentials: 'same-origin'
                })).then(x => x.text())
                    .then((text) => {
                        autocomplete.shortcutInfo = text;
                    }).then(showInfo);
            }
        }

        private async load() {
            this.element.classList.add('loading');
            await autocomplete.load(true, this.name);
            this.element.classList.remove('loading');
            this.values = autocomplete.values[this.name];
            this.idMap = autocomplete.idMap[this.name];
            this.groupMap = autocomplete.groupMap[this.name];
            this.templates = autocomplete.templates[this.name];
            this.translations = autocomplete.translations[this.name];
        }

        public async reload() {
            autocomplete.urls[this.name] = this.url;
            await this.load();
        }

        public async repaint(dispatchChanged: boolean) {
            await this.load();
            let oldValue = this.destination.value;
            let ids = oldValue.split(',');
            this.destination.value = '';
            this.clearSelectedElements();
            if (ids.length && ids[0] !== '') {
                let isUser = this.hasFlags(AutoCompleteFlags.Users);
                let errors = [];
                let list: (AutoCompleteUser | AutoCompleteGroup)[] = [];
                for (let id of ids) {
                    let data;
                    if (isUser) {
                        data = this.idMap[parseInt(id)];
                    } else {
                        data = this.groupMap[parseInt(id)];
                    }
                    if (data) {
                        list.push(data);
                    } else {
                        errors.push(id);
                    }
                }
                list.sort((a, b) => {
                    return a.NameLowerCase.localeCompare(b.NameLowerCase);
                });
                for (let x of list) {
                    await this.addElementToSelected(x, undefined, false, false);
                }
                if (dispatchChanged && this.destination.value) {
                    this.dispatchAllChanged();
                }

                if (errors.length) {
                    console.log("Missing users in hidden value: " + errors.join(', '));
                }
            }
        }

        public static isReady(name?: string) {
            name = name || '';
            return name in autocomplete.loaded && autocomplete.loaded[name] == 2;
        }

        public static isIdle() {
            let result = true;
            for (let name in autocomplete.loaded) {
                result = result && autocomplete.loaded[name] == 2;
            }
            return result;
        }

        private static statepushed(event: Event) {
            autocomplete.initialize(<HTMLElement>event.target);
        }

        private static modalloaded(event: Event) {
            autocomplete.initialize(<HTMLElement>event.target);
        }

        private static promises: { [name: string]: Promise<void> } = {};

        private static isCacheValid() {
            let localVersion = localStorage.getItem('cache.version');
            if (this.cacheVersion == null) {
                this.cacheVersion = localVersion;
                if (!this.cacheVersion) {
                    this.cacheVersion = Date.now().toString()
                    localStorage.setItem('cache.version', this.cacheVersion);
                }
                return true;
            }
            if (this.cacheVersion != localVersion) {
                this.cacheVersion = null;
            }
            return this.cacheVersion == localVersion;
        }

        public static load(sync?: boolean, name?: string) {
            name = name || '';
            let url = autocomplete.urls[name];
            if (!this.isCacheValid()) {
                autocomplete.reload(true);
            }
            if (!(name in autocomplete.loaded) && url) {
                autocomplete.loaded[name] = 0;
                autocomplete.clear(name);
                autocomplete.loaded[name] = 1;
                this.promises[name] = fetch(new Request(url, {
                    method: 'GET',
                    credentials: 'same-origin'
                })).then(x => x.json())
                    .then((data) => {
                        autocomplete.loaded[<string>name] = 2;
                        autocomplete.setList(data, name, sync);
                        delete this.promises[<string>name];
                        autocomplete.dispatchEvent('load' + name);
                        window.addEventListener('statepushed', autocomplete.statepushed, false);
                        if (viggo.modal) {
                            viggo.modal.addEventListener('load', autocomplete.modalloaded);
                        }
                    });
            }
            if (this.promises[name]) {
                return this.promises[name];
            } else {
                return new Promise<void>((resolve) => { resolve(); });
            }
        }
    
        public static reload(resetInstances: boolean = false) {
            autocomplete.loaded = {};
            autocomplete.values = {};
            autocomplete.idMap = {};
            autocomplete.groupMap = {};
            autocomplete.initialize(document, resetInstances);
        };

        private static clear(name: string) {
            name = name || '';
            delete autocomplete.loaded[name];
            autocomplete.values[name] = [];
            autocomplete.idMap[name] = {};
            autocomplete.groupMap[name] = {};
            autocomplete.templates[name] = {};
            autocomplete.translations[name] = {};
            autocomplete.relationNames[name] = {};
            autocomplete.translations[name] = {
                users: __('Users'),
                groups: __('Groups')
            };
        }

        public static createFakeUser(): AutoCompleteUser {
            return {
                Id: -1,
                Name: 'Fake user',
                SearchText: '',
                type: 'fake',
                NameLowerCase: 'fake user',
                Search: [],
                IsSelected: false,
                score: -1,
                Image: 'https://viggopublic.azureedge.net/skolernesithus/logo/clientlogo.png',
                Initials: 'UU',
                Grade: 'Fake grade',
                OtherInfo: '',
                StudentId: -1,
                UserRolls: [],
                MainUserRoll: null,
                HouseRoom: 'Fake house, Fake room',
                Relations: {}
            };
        }

        public static setList(list: AutoCompleteServerResponse, name: string = "", silent?: boolean) {
            name = name || '';
            let templates: {[name: string]: string} = {
                User: '<li>${item.Name}</li>',
                Group: '<li>${item.Name}</li>',
                UserSelected: '<div>${item.Name}</div>',
                GroupSelected: '<div>${item.Name}</div>',
                RelationSearchHeadlineUser: '<div>${item.Name}</div>',
                RelationSearchHeadlineGroup: '<div>${item.Name}</div>',
                RelationSearchMasterUser: '<li><h4>${item.Name}</h5><ul class="relations"></ul></li>',
                RelationSearchChildHeader: '<li><h5>${item.Relation.Name}</h6></li>',
                RelationSearchChildUser: '<li><h6>${item.Name}</h6></li>',
                RelationSearchRelationCheckboxes: '<li>${Object.values(item).map(rel => `<label><input type="checkbox" value="${rel.Id}"/> ${rel.Name}</label>`).join("")}</li>',
                RelationSearchAddAll: '<li><button class="add-all" type="button">Tilføj alle</button></li>',
                RemoveAllSelected: '<li><button class="remove-all" type="button">Fjern alle</button></li>',
                Header: '<li><h2>${item.Title}</h2></li>',
                HeaderGroup: '<li><h3 class="groupHeader">${item.Title}</h3></li>',
                SuperSearchUser: '<li>${item.Name}</li>',
                SuperSearchGroup: '<li>${item.Name}<li>'
            };
            let users = list.Users || (<any>list).users, missingUsers = [], missingGroups = [], missingReferences = [], regSplit = /[ ().\-_[\]/\\{}]/g;
            for (let user of users) {
                if (!user.Name) {
                    missingUsers.push(user.Id);
                }
                user.type = 'user';
                user.NameLowerCase = user.Name.toLowerCase();
                user.Search = [];
                regSplit.lastIndex = 0;
                let match: RegExpExecArray | null;
                let spaceIndex = 0;
                let charIndex = 0;
                while (match = regSplit.exec(user.SearchText)) {
                    if (match[0] == ' ') {
                        if (spaceIndex != charIndex) {
                            user.Search.push(user.SearchText.substring(spaceIndex, regSplit.lastIndex - 1));
                        }
                        user.Search.push(user.SearchText.substring(charIndex, regSplit.lastIndex - 1));
                        spaceIndex = charIndex = regSplit.lastIndex;
                    } else {
                        user.Search.push(user.SearchText.substring(charIndex, regSplit.lastIndex - 1));
                        charIndex = regSplit.lastIndex;
                    }
                }
                if (charIndex != spaceIndex) {
                    user.Search.push(user.SearchText.substring(spaceIndex, user.SearchText.length));
                }
                user.Search.push(user.SearchText.substring(charIndex, user.SearchText.length));

                let mainRoll: UserRole|null = null;
                for (let roll of user.UserRolls) {
                    roll.NameLowerCase = (roll.TypeName||(<any>roll).Name).toLowerCase();
                    if (!mainRoll || mainRoll.Orderby > roll.Orderby) {
                        mainRoll = roll;
                    }
                }
                user.MainUserRoll = mainRoll;
                autocomplete.idMap[name][user.Id] = user;
                autocomplete.values[name].push(user);
            }
    
            if (list.RelationNames) {
                for (let relation of list.RelationNames) {
                    autocomplete.relationNames[name][relation.Id] = relation;
                    relation.checked = true;
                }
            }
            if (list.Relations) {
                for (let i = 0; i < list.Relations.length; i++) {
                    let relation: AutoCompleteRelationReference|AutoCompleteRelation = list.Relations[i];
                    let master = autocomplete.idMap[name][relation.MasterUserId];
                    let user = autocomplete.idMap[name][relation.UserId];
                    let relationId = relation.RelationsId;
                    relation = autocomplete.relationNames[name][relationId];
                    if (master && user && relation) {
                        if (!master.Relations) {
                            master.Relations = {};
                        }
                        if (!master.Relations[relationId]) {
                            let rel: AutoCompleteUser[] = [];
                            (<AutoCompleteUserRelation>rel).Relation = relation;
                            master.Relations[relationId] = <AutoCompleteUserRelation>rel;
                        }
                        master.Relations[relationId].push(user);
                        if (!user.Relations) {
                            user.Relations = {};
                        }
                        if (!user.Relations[relationId]) {
                            let rel: AutoCompleteUser[] = [];
                            (<AutoCompleteUserRelation>rel).Relation = relation;
                            user.Relations[relationId] = <AutoCompleteUserRelation>rel;
                        }
                        user.Relations[relationId].push(master);
                    }
                }
            }
    
            let groups = list.Groups;
            if (groups) {
                for (let group of groups) {
                    if (!group.Name) {
                        missingGroups.push(group.Id);
                    }
                    group.type = 'group';
                    group.Search = group.SearchText.split(regSplit);
                    group.NameLowerCase = group.Name.toLowerCase();
                    group.HasRelations = group.UsersIds.some((x: number) => {
                        let result = autocomplete.idMap[name][x];
                        return result ? !!result.Relations : false;
                    });
                    for (var j = 0; j < group.UsersIds.length; j++) {
                        if (!autocomplete.idMap[name][group.UsersIds[j]]) {
                            missingReferences.push(group.Id + ':' + group.UsersIds[j]);
                        }
                    }
                    autocomplete.values[name].push(group);
                    autocomplete.groupMap[name][group.Id] = group;
                }
            }

            let serverTemplates = list.Templates || (<any>list).templates;
            if (serverTemplates) {
                for (let key in serverTemplates) {
                    templates[key] = list.Templates[key];
                }
            }

            for (let i in templates) {
                var e = templates[i];
                if (typeof e == 'string') {
                    try {
                        autocomplete.templates[name][i] = viggo.func.createTemplate(e);
                    } catch (err) {
                        console.log(`Error in template "${i}".`, e);
                        console.error(err);
                    }
                }
            }

            if (list.Translations) {
                autocomplete.translations[name].users = list.Translations.Users;
                autocomplete.translations[name].groups = list.Translations.Groups;
            }
    
            if (missingUsers.length || missingGroups.length || missingReferences.length) {
                console.warn(`Missing users: ${missingUsers.join(", ")}
    Missing groups: ${missingGroups.join(", ")}
    Missing references: ${missingReferences.join(", ")}`);
            }
            if (!silent) {
                autocomplete.initialize();
            }
        };
    
        public static createView(element: any, name: string, autocompleteName?: string) {
            autocompleteName = autocompleteName || '';
            let func = autocomplete.templates[autocompleteName][name];
            let result = null;
            if (func) {
                result = viggo.func.createView(element, func);
            }
            return result;
        }

        public static async iterate(callback: (item: AutoCompleteItem, index: number, array: AutoCompleteItem[]) => void, sort?: (a: AutoCompleteItem, b: AutoCompleteItem) => number, name = "") {
            name = name || '';
            await autocomplete.load(true, name);
            let array = autocomplete.values[name];
            if (sort) {
                array = array.slice();
                array.sort(sort);
            }
            array.forEach(callback);
        }

        public static sortCallback(a: AutoCompleteItem, b: AutoCompleteItem) {
            let result = 0;

            if (a.type == 'user' && b.type == 'user') {
                let rollA = (<AutoCompleteUser>a).MainUserRoll,
                    rollB = (<AutoCompleteUser>b).MainUserRoll;
                if (rollA && rollB) {
                    result = rollA.Orderby - rollB.Orderby;
                }
            }
            if (!result) {
                result = b.score - a.score;
            }
            if (!result) {
                if (a.NameLowerCase == b.NameLowerCase) {
                    result = a.Id - b.Id;
                } else {
                    result = a.NameLowerCase > b.NameLowerCase ? 1 : -1;
                }
            }
            return result;
        }

        public static searchElement(query: AutocompleteQuery, element: AutoCompleteItem) {
            var found, searchIndex, substring, elementSearchIndex, elementSearch, wordFind = 0;
    
            for (var i = 0; i < query.wordList.length && wordFind == i; i++) {
                if (element.Search.indexOf(query.wordList[i]) != -1) {
                    wordFind++;
                }
            }
    
            var foundAt = [];
            if (wordFind == query.wordList.length) {
                found = false;
                searchIndex = 0;
                while (!found && searchIndex < query.queryList.length) {
                    substring = query.queryList[searchIndex++];
                    elementSearchIndex = 0;
                    while (!found && elementSearchIndex < element.Search.length) {
                        while (foundAt.indexOf(elementSearchIndex) !== -1) {
                            elementSearchIndex++;
                        }
                        if (elementSearchIndex < element.Search.length) {
                            elementSearch = element.Search[elementSearchIndex++];
                            found = substring === elementSearch.substring(0, substring.length);
                        }
                    }
                    if (found) {
                        foundAt.push(elementSearchIndex - 1);
                        found = false;
                    } else {
                        found = true;
                    }
                }
            }
            return foundAt.length == query.queryList.length && wordFind == query.wordList.length;
        }
    
    /*
    "pe":
    Pernille Pedersen
    Per Andersen
    Peter Kristensen
    Pernille Nielsen
    Knud Pedersen
    
    "per":
    Per Andersen
    Pernille Nielsen
    Pernille Pedersen
    Anders Persson
    */
        public static scoreElement(query: AutocompleteQuery, element: AutoCompleteItem) {
            let finalScore = query.wordList.length * 100;
    
            for (let i = 0; i < query.queryList.length; i++) {
                let q = query.queryList[i];
                for (let j = 0; j < element.Search.length; j++) {
                    let index = element.Search[j];
                    if (q == index) {
                        finalScore += 100;
                    } else if (index.substring(0, q.length) == q) {
                        finalScore += element.Search.length - j;
                        finalScore += query.queryList.length - i;
                        if (i == j) {
                            finalScore += 10;
                        }
                    }
                }
            }
            finalScore = finalScore / element.Search.length;
            return finalScore;
        }
    
        public static queryToLists(q: string): AutocompleteQuery {
            var fullWords: string[] = [];
            q = q.replace(/(^|\s+)([A-ZÆØÅ]{2,}|#[0-9]*|@@?[A-Za-zÆØÅæøå\-0-9]*)(\s+|$)/, function (a, start, word, end) {
                fullWords.push(word.toLowerCase());
                return start + end;
            });
            fullWords = fullWords.filter(function (word) {
                return word != '';
            });
    
            q = q.replace(/^\s+|"|\s+$/g, '');
            let query = q.split(/\s+/);
    
            query = query.filter(function (word) {
                return word != '';
            }).map(function (a) {
                return a.toLowerCase();
            });
    
            return {
                queryList: query,
                wordList: fullWords
            };
        }
    
        public static async getUser(id: number, name = "") {
            name = name || '';
            await autocomplete.load(true, name);
            return autocomplete.idMap[name][id];
        }
    
        public static async getGroup(id: number, name = "") {
            name = name || '';
            await autocomplete.load(true, name);
            return autocomplete.groupMap[name][id];
        }
    
        public static async getUsersFromGroups(ids: number[], name = "") {
            name = name || '';
            await autocomplete.load(true, name);
            let result: number[] = [];
            for (var i = 0; i < ids.length; i++) {
                let group = autocomplete.groupMap[name][ids[i]];
                if (group) {
                    result = result.concat(group.UsersIds);
                }
            }
            return result.unique();
        }

        public static async initializeInput(input: HTMLInputElement|HTMLTextAreaElement) {
            if (input && input.classList.contains('autocomplete') && input.dataset.autocompleteFlags && input.dataset.autocompleteResults) {
                if (input.viggoAutoComplete) {
                    (<autocomplete>input.viggoAutoComplete).repaint(false);
                } else {
                    let autoCompleter: autocomplete | null = null;
                    let options: AutoCompleteOptions = {
                        element: input,
                        flags: input.dataset.autocompleteFlags,
                        results: input.dataset.autocompleteResults
                    };
                    switch (input.tagName) {
                        case 'INPUT':
                            autoCompleter = new autocompleteInput(<AutoCompleteInputOptions>options);
                            break;
                    }
                    (<any>input).viggoAutoComplete = autoCompleter;
                    if (autoCompleter) {
                        autoCompleter.repaint(true);
                        if (!autoCompleter.destination.viggoAutoComplete) {
                            autoCompleter.destination.viggoAutoComplete = true;
                        }
                    }
                }
            }
        }

        public static initialize(parent?: HTMLElement | Document, resetInstances = false) {
            if (!parent) {
                parent = document;
            }
            parent.querySelectorAll('input.autocomplete').forEach(async function (x) {
                if (resetInstances) {
                    delete (<any>x).viggoAutoComplete;
                } else {
                    await autocomplete.initializeInput(<HTMLInputElement | HTMLTextAreaElement>x);
                }
            });
        }

        public static showAll(event: Event, input: string|HTMLInputElement|HTMLTextAreaElement|null) {
            event.stopPropagation();
            event.preventDefault();
            if (typeof input === 'string') {
                input = <HTMLInputElement | HTMLTextAreaElement | null>document.getElementById(input);
            }
            if (input) {
                autocomplete.initializeInput(input);
                if (input.viggoAutoComplete) {
                    (<autocomplete>input.viggoAutoComplete).showAllClick();
                }
            }
        }

        public static showShortcuts(event: Event, input: string|HTMLInputElement|HTMLTextAreaElement|null) {
            event.stopPropagation();
            event.preventDefault();
            if (typeof input === 'string') {
                input = <HTMLInputElement | HTMLTextAreaElement | null>document.getElementById(input);
            }
            if (input) {
                autocomplete.initializeInput(input);
                if (input.viggoAutoComplete) {
                    let target = (<Element>event.target).closest('.form-group');
                    if (target) {
                        (<autocomplete>input.viggoAutoComplete).showShortcutsClick(target);
                    }
                }
            }
        }
    }

    interface AutoCompleteInputOptions extends AutoCompleteOptions {
        element: HTMLInputElement;
    }

    interface AutoCompleteTextareaOptions extends AutoCompleteOptions {
        element: HTMLTextAreaElement;
    }

    class autocompleteInput extends autocomplete {
        protected element!: HTMLInputElement;
        protected relationSearch: AutoCompleteUser | AutoCompleteGroup | null = null;
        public constructor(options: AutoCompleteInputOptions) {
            super(options);
            this.element.addEventListener('keydown', (event: KeyboardEvent) => this.keydown(event), false);
            this.element.addEventListener('keyup', (event: KeyboardEvent) => this.keyup(event), false);

            this.element.addEventListener('keydown', (event: KeyboardEvent) => {
                if (this.element.value == "" && this.relationSearch && event.key in { Backspace: 1, Escape: 1 }) {
                    event.stopPropagation();
                    this.setRelationSearch(null);
                }
            }, false);
            this.element.addEventListener('focus', async (event: FocusEvent) => {
                if (this.element.value || this.relationSearch) {
                    event.preventDefault();
                    event.stopPropagation();
                    var result = await this.search(this.element.value);
                    this.show(result.users, result.groups, (obj: AutoCompleteItem, ev: Event) => {
                        this.addElementToSelected(obj, this.hasFlags(AutoCompleteFlags.StayOpen) ? <HTMLElement>ev.target : undefined);
                        ev.stopPropagation();
                        this.element.focus();
                    }, this.element);
                }
            }, false)
        }

        protected createHeaders() {
            if (this.relationSearch && autocomplete.dynamiclist) {
                autocomplete.dynamiclist.appendChild(<HTMLElement>this.createRelationSearchAddAllFragment().firstElementChild);
                if (this.relationSearch.type == 'group') {
                    autocomplete.dynamiclist.appendChild(<HTMLElement>this.createRelationSearchRelationCheckboxesFragment().firstElementChild);
                }
            }
        }

        protected filterValues() {
            let valueSet = this.values;
            if (this.relationSearch) {
                switch (this.relationSearch.type) {
                    case 'user':
                        valueSet = Array.prototype.concat.apply([], Object.values(<any>(<AutoCompleteUser>this.relationSearch).Relations)).unique();
                        break;
                    case 'group':
                        valueSet = <AutoCompleteItem[]>(<AutoCompleteGroup>this.relationSearch).UsersIds.map(x => this.idMap[x]).filter(x => !!x);
                        break;
                }
            }
            return valueSet;
        }

        protected createUserFragment(element: AutoCompleteUser, callback: AutoCompleteSelectCallback) {
            let view: DocumentFragment;
            if (this.relationSearch && this.relationSearch.type == 'group') {
                view = this.createRelationSearchedGroupFragment(element, callback);
            } else {
                if (this.relationSearch) {
                    view = this.createRelationUserFragment(element, callback);
                } else {
                    view = super.createUserFragment(element, callback);
                }
            }
            let btn = <HTMLElement | null>view.querySelector('.relation-search');
            if (btn) {
                if (this.relationSearch || !element.Relations) {
                    btn.remove();
                } else {
                    btn.addEventListener('click', (event: MouseEvent) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.setRelationSearch(element);
                    }, false);
                }
            }
            return view;
        }

        protected createGroupFragment(element: AutoCompleteGroup) {
            let view = super.createGroupFragment(element);
            let btn = <HTMLElement | null>view.querySelector('.relation-search');
            if (btn) {
                if (this.relationSearch || !element.HasRelations || !this.hasFlags(AutoCompleteFlags.Users)) {
                    if (btn.parentNode) {
                        btn.parentNode.removeChild(btn);
                    }
                } else {
                    btn.addEventListener('click', (event: MouseEvent) => {
                        event.stopPropagation();
                        event.preventDefault();
                        this.setRelationSearch(element);
                    });
                }
            }
            return view;
        }

        private async keyup(event: KeyboardEvent) {
            let
                input = <HTMLInputElement>event.target,
                value = input.value,
                index = input.selectionStart,
                results;
            if (this.delaySearch) {
                viggo.autocomplete.removeEventListener('load' + this.name, this.delaySearch);
                this.delaySearch = null;
            }
            if (this.lastSelectionIndex !== index) {
                this.lastSelectionIndex = index || 0;
                if (value.match(/\S/) || this.relationSearch) {
                    value = value.replace(/^\s+|\s+$/, '').replace(/\s+/, ' ');
                    if (!autocomplete.loaded[this.name] || viggo.autocomplete.loaded[this.name] < 2) {
                        event = <KeyboardEvent>{ target: event.target };
                        var me = this;
                        this.delaySearch = () => {
                            this.lastSelectionIndex = -1;
                            this.keyup(event);
                        };
                        viggo.autocomplete.addEventListener('load' + this.name, this.delaySearch);
                    }
                    results = await this.search(value);
                    this.show(results.users, results.groups, (obj: AutoCompleteItem, ev: Event) => {
                        this.addElementToSelected(obj, this.hasFlags(AutoCompleteFlags.StayOpen) ? <HTMLElement>ev.target : undefined);
                        ev.stopPropagation();
                        if (!this.hasFlags(AutoCompleteFlags.StayOpen) && !(<KeyboardEvent>ev).ctrlKey && !(<KeyboardEvent>ev).metaKey) {
                            input.value = '';
                            input.focus();
                            autocomplete.remove();
                        }
                    }, input);
                } else {
                    autocomplete.remove();
                }
            }
        }

        protected async setRelationSearch(element: AutoCompleteGroup | AutoCompleteUser | null) {
            let gui: DocumentFragment | Element | null;
            if (this.relationSearch) {
                gui = this.element.previousElementSibling;
                if (gui) {
                    gui.remove();
                }
            }
            this.relationSearch = element;
            if (element) {
                gui = element.type == 'user' ? this.createRelationSearchUserFragment(<AutoCompleteUser>element) : this.createRelationSearchGroupFragment(<AutoCompleteGroup>element);
                if (gui) {
                    let close = gui.querySelector('.close');
                    if (close) {
                        close.addEventListener('click', (ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            this.setRelationSearch(null);
                        }, false);
                    }
                    this.element.parentNode!.insertBefore(gui, this.element);
                }
                this.element.value = '';
                var result = await this.search('');
                this.element.focus();
                this.show(result.users, result.groups, (obj: AutoCompleteItem, ev: Event) => {
                    this.addElementToSelected(obj, this.hasFlags(AutoCompleteFlags.StayOpen) ? <HTMLElement>ev.target : undefined);
                    ev.stopPropagation();
                    this.element.focus();
                }, this.element);
            } else {
                autocomplete.remove();
            }
        };
        private createRelationSearchedGroupFragment(element: AutoCompleteUser, callback: AutoCompleteSelectCallback) {
            if (!this.templates.RelationSearchMasterUser) {
                throw new Error('Missing template "RelationSearchMasterUser"');
            }
            if (!this.templates.RelationSearchChildHeader) {
                throw new Error('Missing template "RelationSearchChildHeader"');
            }
            if (!this.templates.RelationSearchChildUser) {
                throw new Error('Missing template "RelationSearchChildUser"');
            }
            var view = viggo.func.createView(element, this.templates.RelationSearchMasterUser);
            var relations = view.querySelector('.relations');

            if (element.Relations) {
                for (var relationId in element.Relations) {
                    if (!autocomplete.relationNames[this.name][relationId].checked) {
                        continue;
                    }
                    var rel = element.Relations[relationId];
                    var child = viggo.func.createView(rel, this.templates.RelationSearchChildHeader);
                    if (relations) {
                        relations.appendChild(child);
                        for (var i = 0; i < rel.length; i++) {
                            child = viggo.func.createView(rel[i], this.templates.RelationSearchChildUser);
                            if (child.firstChild) {
                                (<HTMLElement>child.firstChild).addEventListener('click', ((obj: AutoCompleteUser) => {
                                    return (event: MouseEvent) => {
                                        callback(obj, event);
                                        event.stopPropagation();
                                    };
                                })(rel[i]));
                                relations.appendChild(child);
                            }
                        }
                    }
                }
            }
            return view;
        }

        private createRelationSearchUserFragment(element: AutoCompleteUser) {
            if (!this.templates.RelationSearchHeadlineUser) {
                throw new Error('Missing template "RelationSearchHeadlineUser"');
            }
            return viggo.func.createView(element, this.templates.RelationSearchHeadlineUser);
        };

        private createRelationSearchGroupFragment(element: AutoCompleteGroup) {
            if (!this.templates.RelationSearchHeadlineGroup) {
                throw new Error('Missing template "RelationSearchHeadlineGroup"');
            }
            return viggo.func.createView(element, this.templates.RelationSearchHeadlineGroup);
        }
        private createRelationUserFragment(element: AutoCompleteUser, callback: AutoCompleteSelectCallback) {
            if (!this.templates.RelationUser) {
                throw new Error('Missing template "RelationUser"');
            }
            (<any>element).relationUser = this.relationSearch;
            let result = viggo.func.createView(element, this.templates.RelationUser);
            if (result.firstChild) {
                (<HTMLElement>result.firstChild).addEventListener('click', (event: MouseEvent) => {
                    callback(element, event);
                }, false);
            }
            delete (<any>element).relationUser;
            return result;
        }
        private createRelationSearchAddAllFragment() {
            if (!this.templates.RelationSearchAddAll) {
                throw new Error('Missing template "RelationSearchAddAll"');
            }
            var view = viggo.func.createView({}, this.templates.RelationSearchAddAll);
            var btn = view.querySelector('.add-all');
            if (btn) {
                btn.addEventListener('click', async (ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    var result = await this.search(this.element.value);
                    if (this.relationSearch) {
                        switch (this.relationSearch.type) {
                            case 'user':
                                for (let x of result.users) {
                                    await this.addElementToSelected(x, undefined, true, false);
                                }
                                break;
                            case 'group':
                                for (let user of result.users) {
                                    if (user.Relations) {
                                        for (let rel of Object.values(user.Relations)) {
                                            if (autocomplete.relationNames[this.name][rel.Relation.Id].checked) {
                                                for (let x of rel) {
                                                    await this.addElementToSelected(x, undefined, true, false);
                                                }
                                            }
                                        }
                                    }
                                }
                                break;
                        }
                        this.dispatchAllChanged();
                    }
                    this.setRelationSearch(null);
                }, false);
            }
            let updateCheckboxes = (value: boolean) => {
                if (autocomplete.domParent) {
                    (<NodeListOf<HTMLInputElement>>autocomplete.domParent.querySelectorAll('input[type="checkbox"][value]')).forEach(input => {
                        input.checked = value;
                        autocomplete.relationNames[this.name][parseInt(input.value)].checked = value;
                    });
                    let input = autocomplete.domParent.querySelector('input[type="checkbox"][value]');
                    if (input) {
                        input.dispatchEvent(new Event('change'));
                    }
                }
            };
            btn = view.querySelector('.check-all');
            if (btn) {
                if (this.relationSearch && this.relationSearch.type == 'group') {
                    btn.addEventListener('click', (ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        updateCheckboxes(true);
                    }, false);
                } else {
                    btn.remove();
                }
            }
            btn = view.querySelector('.uncheck-all');
            if (btn) {
                if (this.relationSearch && this.relationSearch.type == 'group') {
                    btn.addEventListener('click', (ev) => {
                        ev.preventDefault();
                        ev.stopPropagation();
                        updateCheckboxes(false);
                    }, false);
                } else {
                    btn.remove();
                }
            }
            return view;
        }
        private createRelationSearchRelationCheckboxesFragment() {
            if (!this.templates.RelationSearchRelationCheckboxes) {
                throw new Error('Missing template "RelationSearchRelationCheckboxes"');
            }
            var view = viggo.func.createView<AutoCompleteRelationNames>(autocomplete.relationNames[this.name], this.templates.RelationSearchRelationCheckboxes);
            if (view.firstChild) {
                view.firstChild.addEventListener('click', event => event.stopPropagation(), false);
                let change = async (event: Event) => {
                    let target = <HTMLInputElement>event.target;
                    autocomplete.relationNames[this.name][parseInt(target.value)].checked = target.checked;
                    var result = await this.search(this.element.value);
                    this.show(result.users, result.groups, (obj: AutoCompleteItem, ev: Event) => {
                        this.addElementToSelected(obj, this.hasFlags(AutoCompleteFlags.StayOpen) ? <HTMLElement>ev.target : undefined);
                        ev.stopPropagation();
                        this.element.focus();
                    }, this.element);
                };
                let checkboxes = <NodeListOf<HTMLInputElement>>view.querySelectorAll('input[type="checkbox"][value]');
                checkboxes.forEach((input) => {
                    input.checked = autocomplete.relationNames[this.name][parseInt(input.value)].checked;
                    input.addEventListener('change', change, false);
                });
            }
            return view;
        }
    }

    const Inline = Quill.import('blots/inline');

    // autocomplete in richeditor is called "mention"
    class AutocompleteBlot extends Inline {
        public static blotName = "mention";
        public static tagName = "A";
        public static className = "hinted";
        protected domNode!: HTMLAnchorElement;
        public static create(userId: string) {
            let node: HTMLAnchorElement = super.create();
            node.dataset.hint = 'user';
            node.setAttribute('href', `/Basic/Users/Details/${userId}`);
            node.dataset.userId = userId + '';
            return node;
        }

        public static formats(node: HTMLElement) {
            return node.dataset.userId;
        }
        public format(name: string, value: string) {
            if (name === "mention" && value) {
                this.domNode.dataset.userId = value;
            } else {
                super.format(name, value);
            }
        }

        public formats() {
            const formats = super.formats();
            formats["mention"] = AutocompleteBlot.formats(this.domNode);
            return formats;
        }
    }

    Quill.register({ "formats/mention": AutocompleteBlot });

    class QuillAutocomplete extends autocomplete {
        private quill: Quill;
        constructor(options: AutoCompleteOptions, quill: Quill) {
            super(options);
            this.quill = quill;
        }
        protected createUserFragment(element: AutoCompleteUser, callback: AutoCompleteSelectCallback) {
            let view = super.createUserFragment(element, callback);
            let btn = <HTMLElement | null>view.querySelector('.relation-search');
            if (btn) {
                btn.remove();
            }
            return view;
        }

        protected createGroupFragment(element: AutoCompleteGroup) {
            let view = super.createGroupFragment(element);
            if (!this.templates.Group) {
                throw new Error('Missing template "Group"');
            }
            let btn = <HTMLElement | null>view.querySelector('.relation-search');
            if (btn) {
                btn.remove();
            }
            return view;
        }

        public removeElementFromSelected(id: string | number) {
            let result = super.removeElementFromSelected(id);
            if (result) {
                this.quill.root.querySelectorAll(`a[data-user-id="${id}"]`).forEach(elm => elm.replaceWith(viggo.dom.empty(elm)));
            }
            return result;
        }

        protected filterValues(): AutoCompleteItem[] {
            return this.values;
        }
    }

    class AutocompleteMention {
        protected quill: Quill;
        protected autocomplete: QuillAutocomplete;
        private static userRegex = /[A-Z\u00c0-\u00d6\u00d8-\u00dd][A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\-]*(?: | [A-Z\u00c0-\u00d6\u00d8-\u00dd][A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\-]*)*$/;
        private static atRegex = /@([A-Za-z\u00c0-\u00d6\u00d8-\u00f6\u00f8-\u00ff\- ]*)/;
        private selectedUsers: string[] | null;
        constructor(quill: Quill) {
            this.quill = quill;
            this.autocomplete = new QuillAutocomplete({
                element: quill.container,
                flags: quill.container.dataset.autocompleteFlags || '',
                results: quill.container.dataset.autocompleteResults || ''
            }, quill);

            this.selectedUsers = null;

            quill.keyboard.addBinding({
                key: 50,
                shiftKey: true,
            }, this.onAtKey.bind(this));

            quill.keyboard.addBinding({ // up
                key: 38,
                collapsed: true
            }, this.onArrowUp.bind(this));

            quill.keyboard.addBinding({ // down
                key: 40,
                collapsed: true
            }, this.onArrowDown.bind(this));

            quill.keyboard.addBinding({
                key: 27,
                collapsed: true
            }, this.onEsc.bind(this));

            quill.keyboard.addBinding({ // enter
                key: 13,
                collapsed: true
            }, this.onEnter.bind(this));

            quill.keyboard.addBinding({ // tab
                key: 9,
                collapsed: true
            }, this.onEnter.bind(this));

            // tab and enter must be moved up before the default Quill handlers, or they will not run
            quill.keyboard.bindings[13].unshift(quill.keyboard.bindings[13].pop());
            quill.keyboard.bindings[9].unshift(quill.keyboard.bindings[9].pop());
            quill.on('text-change', this.textChange.bind(this));
            quill.on('selection-change', this.selectionChange.bind(this));

            quill.root.addEventListener('keydown', this.keydown.bind(this), false);
        }

        private keydown(event: KeyboardEvent) {
            if (event.key.length == 1) {
                let format = this.quill.getFormat();
                if (format.mention) {
                    this.quill.format('mention', false, 'user');
                }
            }
        }

        private onAtKey() {
            this.autocomplete.search("").then((result) => {
                let pos = this.getAutocompletePosition();
                this.autocomplete.show(result.users, result.groups, this.callbackReplace(this.quill.getSelection(true).index, '@'), this.quill.container, pos.top, pos.left, pos.width);
            });
            return true;
        }

        private getAutocompletePosition() {
            let bounds = this.quill.getBounds(this.quill.getSelection(true).index);
            let position = viggo.dom.getPos(this.quill.root);

            return {
                top: bounds.top + position.top + bounds.height,
                left: bounds.left + position.left,
                width: this.quill.container.clientWidth / 2
            };
        }

        private onArrowUp() {
            return !this.autocomplete.moveUp();
        }

        private onArrowDown() {
            return !this.autocomplete.moveDown();
        }

        private onEsc() {
            autocomplete.remove();
            return true;
        }

        private onEnter() {
            return !this.autocomplete.clickSelected();
        }

        private callbackReplace(quillIndex: number, replacedText: string) {
            return (elm: AutoCompleteItem) => {
                this.quill.focus();
                this.quill.deleteText(quillIndex, replacedText.length, "user");
                let insertText = elm.Name;
                if (replacedText == (<AutoCompleteUser>elm).Initials) {
                    insertText = (<AutoCompleteUser>elm).Initials;
                }
                this.quill.insertText(quillIndex, insertText, "mention", elm.Id, "user");
                this.quill.setSelection(quillIndex + insertText.length, 0, "user");
                this.autocomplete.addElementToSelected(elm, undefined, true, true);
            }
        }

        private async checkContentForUsers() {
            if (this.selectedUsers == null) {
                this.selectedUsers = Array.from(<NodeListOf<HTMLAnchorElement>>this.quill.root.querySelectorAll('a[data-user-id]')).map(x => x.dataset.userId!);
            }
            let dict: ObjectOf<boolean> = {};
            (<NodeListOf<HTMLElement>>this.quill.root.querySelectorAll('a[data-user-id]')).forEach(x => {
                dict[x.dataset.userId!] = true;
            });
            let repaint = false;
            for (let i = this.selectedUsers.length - 1; i >= 0; i--) {
                let id = this.selectedUsers[i];
                if (!dict[id]) {
                    this.autocomplete.removeElementFromSelected(id);
                    this.selectedUsers.splice(i, 1);
                    repaint = true;
                }
            }
            for (let id in dict) {
                if (this.selectedUsers.indexOf(id) == -1) {
                    let user = await autocomplete.getUser(parseInt(id));
                    if (user) {
                        this.selectedUsers.push(user.Id + '');
                        await this.autocomplete.addElementToSelected(user, undefined, true, false);
                    }
                }
            }
            if (repaint) {
                await this.autocomplete.repaint(true);
            }
        }

        private selectionChange(range: RangeStatic, oldRange: RangeStatic, source: Sources) {

        }

        private log(msg: any) {
            let label = this.quill.root.closest('.form-group')?.querySelector('label');
            if (label) {
                label.textContent = msg;
            }
        }

        private async textChange(delta: Delta, oldContents: Delta, source: Sources) {
            let range = this.quill.getSelection();
            let found = false;
            if (range) {
                let [leaf, offset] = this.quill.getLeaf(range.index);
                if (leaf && (<any>leaf).text && !this.quill.getFormat(range).mention) {
                    let text: string = (<any>leaf).text.substring(0, offset);
                    let match = text.match(AutocompleteMention.atRegex);
                    let result;
                    let quillIndex = range.index - offset;
                    if (match) {
                        result = await this.autocomplete.search(match[1]);
                        quillIndex += match.index || 0;
                    } else if (match = text.match(AutocompleteMention.userRegex)) {
                        text = match[0];
                        quillIndex += match.index || 0;
                        do {
                            result = await this.autocomplete.search(text);
                            if (!result.users.length) {
                                let index = text.indexOf(' ');
                                if (index == -1) {
                                    text = '';
                                } else {
                                    quillIndex += index;
                                    text = text.substring(index + 1).trim();
                                }
                            }
                        } while (!result.users.length && text);
                    }
                    if (result && result.users.length) {
                        found = true;
                        let pos = this.getAutocompletePosition();
                        this.autocomplete.show(result.users, [], this.callbackReplace(quillIndex, text), this.quill.container, pos.top, pos.left, pos.width);
                        autocomplete.modalScroll(<any>{target: this.quill.container});
                    }
                }
            }
            if (!found) {
                autocomplete.remove();
            }
            await this.checkContentForUsers();
        }
    }

    Quill.register("modules/mention", AutocompleteMention);

    viggo.modal.addEventListener('load', function (data) {
        autocomplete.initialize(data.target);
    });

    document.addEventListener('statepushed', async function(event) {
        autocomplete.initialize(<HTMLElement|Document>event.target);
    }, false);

    viggo.modal.addEventListener('show', function (data) {
        viggo.autocomplete.initialize(data.target);
        autocomplete.modals.push(data.modal);
        autocomplete.scrollAdd = 0;
        data.modal.addEventListener('scroll', autocomplete.modalScroll);
        if (autocomplete.domParent) {
            autocomplete.domParent.style.marginTop = -autocomplete.scrollAdd + 'px';
        }
    });
    viggo.modal.addEventListener('close', function(data) {
        data.modal.removeEventListener('scroll', autocomplete.modalScroll);
        var index = autocomplete.modals.indexOf(data.modal);
        if (index !== -1) {
            autocomplete.modals.splice(index, 1);
        }
        let m = autocomplete.modals[autocomplete.modals.length - 1];
        while (autocomplete.modals.length && m.element && !document.body.contains(m.element)) {
            autocomplete.modals.pop();
        }
        if (autocomplete.modals.length) {
            m = autocomplete.modals[autocomplete.modals.length - 1];
            if (m.element) {
                autocomplete.scrollAdd = m.element.scrollTop;
            }
        } else {
            autocomplete.scrollAdd = 0;
        }
        if (autocomplete.domParent) {
            autocomplete.domParent.style.marginTop = -autocomplete.scrollAdd + 'px';
        }
    });

    autocomplete.addEventListener = async function (event, listener) {
        viggo.classes.eventListener.addEventListener.call(this, event, listener);
        var m = event.match(/^load(.+)$/);
        if (m) {
            await autocomplete.load(false, m[1]);
            if (autocomplete.loaded[m[1]] == 2) {
                autocomplete.dispatchEvent(event, null, listener);
            }
        }
    };
}

document.addEventListener('focus', async function (event) {
    var target = <HTMLInputElement|HTMLTextAreaElement>event.target;
    if (viggo && viggo.autocomplete && target.tagName == 'INPUT' && target.type == 'text') {
        viggo.autocomplete.initializeInput(target);
        if (target.viggoAutoComplete) {
            await viggo.autocomplete.load(false, (<viggo.autocomplete>target.viggoAutoComplete).name);
        }
    }
}, true);

viggo.ready(function () {
    viggo.autocomplete.initialize();
});
