/// <reference path="viggo.ts" />
/// <reference path="viggo.dom.ts" />
/// <reference path="viggo.ajax.ts" />
/// <reference path="viggo.effect.ts" />

module viggo {
    document.addEventListener('mousedown', function (event) {
        let target = <HTMLElement>(<HTMLElement>event.target).closest('.ordering > li:not(.noorder)');
        if (target) {
            let ul = target.parentElement!;
            let listTop = viggo.dom.getPos(ul).top - (<HTMLElement>ul.firstElementChild).offsetTop;
            let startY = event.pageY;
            let moved = false;
            //event.preventDefault();
            event.stopPropagation();

            var mousemove = function (ev: MouseEvent) {
                var found = null;
                ul.classList.add('isordering');
                target.classList.add('order');
                var scroll = 0;
                if (viggo.isTablet()) {
                    scroll = viggo.getScrollTop(ul);
                }

                for (var i = 0; i < ul.childNodes.length && !found; i++) {
                    var node = <HTMLElement>ul.childNodes[i];
                    if (node.nodeType == 1) {
                        if (node != target && node.offsetTop <= ev.pageY - (listTop - scroll) && node.offsetTop + node.offsetHeight > ev.pageY - (listTop - scroll)) {
                            found = node;
                        }
                    }
                }
                if (!found) {
                    if (ev.pageY + scroll < viggo.dom.getPos(<HTMLElement>ul.firstElementChild).top) {
                        found = ul.firstElementChild;
                    } else if (ev.pageY + scroll > viggo.dom.getPos(<HTMLElement>ul.lastElementChild).top + (<HTMLElement>ul.lastElementChild).offsetHeight) {
                        found = ul.querySelector('.noorder');
                    } else {
                        found = target;
                    }
                }
                var offset = ev.pageY - startY;
                if (target != found) {
                    var dif = target.offsetTop;
                    if (target.nextElementSibling != found) {
                        let movingTarget: Element | null = null;
                        if (offset < 0) {
                            movingTarget = target.previousElementSibling;
                        } else {
                            movingTarget = target.nextElementSibling;
                        }
                        let oldY = 0;
                        if (movingTarget) {
                            oldY = movingTarget.getBoundingClientRect().y;
                        }
                        ul.insertBefore(target, found);
                        let newY = 0;
                        if (movingTarget) {
                            newY = movingTarget.getBoundingClientRect().y;
                        }
                        if (movingTarget) {
                            (<HTMLElement>movingTarget).style.position = 'relative';
                            new viggo.effect({
                                element: movingTarget,
                                style: 'top',
                                duration: 200,
                                from: oldY - newY,
                                to: 0,
                                removeStyle: true,
                                complete: function () {
                                    (<HTMLElement>movingTarget).style.position = '';
                                }
                            });
                        }
                        moved = true;
                    }

                    dif -= target.offsetTop;
                    offset -= dif;
                    startY -= dif;
                }
                if (target == ul.firstElementChild) {
                    offset = Math.max(offset, 0);
                } else if (target == ul.lastElementChild || (target.nextElementSibling && target.nextElementSibling.className.split(' ').indexOf('noorder') != -1)) {
                    offset = Math.min(offset, 0);
                }
                target.style.top = offset + 'px';
                ev.preventDefault();
            };

            var waitFormMove = function (ev: MouseEvent) {
                document.removeEventListener('mousemove', waitFormMove, false);
                document.addEventListener('mousemove', mousemove, false);
                target.style.position = 'relative';
            };

            var mouseup = function (ev: MouseEvent) {
                target.style.top = '';
                target.style.position = '';
                document.removeEventListener('mousemove', mousemove, false);
                document.removeEventListener('mouseup', mouseup, false);
                document.removeEventListener('mousemove', waitFormMove, false);
                target.classList.remove('order');
                ul.classList.remove('isordering');
                if (moved) {
                    var id = ul.dataset.orderingRowId;
                    var functionId = ul.dataset.orderingFunctionId;
                    var url = window.location.pathname.match(/^\/(?:Cms|Info)/i);
                    new viggo.ajax({
                        method: 'post',
                        url: '/Shared/Ordering/Ordering',
                        data: {
                            functionId: functionId,
                            id: id,
                            order: Array.prototype.filter.call(ul.children, li => li.dataset.orderingId).map(li => li.dataset.orderingId).join(',')
                        }
                    });
                }
            };

            document.addEventListener('mouseup', mouseup, false);
            document.addEventListener('mousemove', waitFormMove, false);
        }
    }, false);
}