module viggo {

    abstract class AbstractPicker {
        protected domContainer: HTMLElement;
        protected date!: Date;
        protected owner?: Element;
        protected callback: ((date: Date | Date[]) => void) | null = null;
        constructor(options: PickerOptions) {
            this.initialize(options);
            this.domContainer = this.createDomContainer(options.className);
            if (options.footerElement) {
                this.domContainer.appendChild(options.footerElement);
            }
            if (options.headerElement) {
                this.domContainer.insertBefore(options.headerElement, this.domContainer.firstChild);
            }
            this.repaint(this.date);
            this.appendContainer();
        }
        protected initialize(options: PickerOptions) {
            this.date = new Date(options.date);
            this.owner = options.owner;
            this.callback = options.callback || null;
        }
        protected createDomContainer(className?: string): HTMLElement {
            return viggo.dom.tag('div', {
                className: className || '',
                style: { position: 'relative' },
                onmousedown: (event: MouseEvent) => {
                    event.stopPropagation();
                }
            });
        }
        protected appendContainer() {
            if (this.owner) {
                this.owner.appendChild(this.domContainer);
            }
        }
        protected abstract repaint(date: Date): void;
    }

    enum PickerMode {
        day = "day",
        month = "month",
        year = "year"
    }

    interface PickerOptions {
        date: Date,
        className?: string,
        headerElement?: Element,
        footerElement?: Element,
        callback?: (date: Date|Date[]) => void,
        owner?: Element
    }

    interface DatePickerOptions extends PickerOptions {
        multiselect?: boolean,
        intervalStart?: Date,
        intervalEnd?: Date
    }

    interface WeekPickerOptions extends DatePickerOptions {

    }

    interface DatePickerInputOptions extends DatePickerOptions {
        input: HTMLInputElement
    }

    interface TimePickerInputOptions extends PickerOptions {
        input: HTMLInputElement,
        minuteinterval?: number,
        seconds?: boolean,
        secondinterval?: number
    }

    interface PickerEventDetail {
        newDate: Date|Date[],
        oldDate: Date
    }

    export type DatePickerEvent = CustomEvent<PickerEventDetail>;

    export class DatePicker extends AbstractPicker {
        private selected: HTMLTableDataCellElement|null = null;
        protected mode!: PickerMode;
        public selectedList: Date[] = [];
        private selectedDate!: Date;
        private multiselect!: boolean;
        private table!: HTMLTableElement;
        protected intervalStart?: Date;
        protected intervalEnd?: Date;
        private pickerFor: string = "";

        constructor(options: DatePickerOptions) {
            super(options);
        }
        protected initialize(options: DatePickerOptions) {
            console.log(options);
            super.initialize(options);
            this.date.setHours(0, 0, 0, 0);
            this.mode = PickerMode.day;
            this.intervalStart = options.intervalStart;
            this.intervalEnd = options.intervalEnd;
            this.multiselect = options.multiselect || false;
            this.selectedDate = new Date(this.date);
            this.selectedList = [];
            if (this.multiselect) {
                this.selectedList.push(this.selectedDate);
            }

            if("input" in options) {
                let inputOptions = options as DatePickerInputOptions;
                this.pickerFor = inputOptions.input.id ?? inputOptions.input.name ?? "";
            }

        }
        protected createDomContainer(className?: string): HTMLElement {
            let elm = super.createDomContainer(className);
            elm.classList.add('pick-date');
            elm.classList.add('date-picker');
            return elm;
        }
        private setMode(mode: PickerMode, date: Date) {
            this.mode = mode;
            this.repaint(date);
        }
        public inSelected(date: Date, mode: PickerMode) {
            var result = false;
            if (this.multiselect) {
                for (var i = 0; i < this.selectedList.length && !result; i++) {
                    result = this.compareDates(date, this.selectedList[i], mode);
                }
            } else {
                result = this.compareDates(date, this.date, mode);
            }
            return result;
        }
        private compareDates(d1: Date, d2: Date, mode: PickerMode) {
            var result = true;
            switch (mode) {
                case PickerMode.day:
                    result = result && d1.getDate() == d2.getDate();
                case PickerMode.month:
                    result = result && d1.getMonth() == d2.getMonth();
                case PickerMode.year:
                    result = result && d1.getFullYear() == d2.getFullYear();
            }
            return result;
        }
        public toggleSelected(date: Date) {
            var result = true;
            for (var i = 0; i < this.selectedList.length && result; i++) {
                result = date.getFullYear() != this.selectedList[i].getFullYear() ||
                    date.getMonth() != this.selectedList[i].getMonth() ||
                    date.getDate() != this.selectedList[i].getDate();
            }
            if (result) {
                this.selectedList.push(date);
            } else {
                this.selectedList.splice(i-1, 1);
            }
            this.selectedList.sort(function(a, b) {
                return a.getTime() - b.getTime();
            });
            return result;
        }
        protected selectDate(date: Date, td: HTMLTableDataCellElement) {
            if (this.selected) {
                this.selected.classList.remove('selected');
            }
            this.selected = td;
            td.classList.add('selected');
        }
        private createDayPicker(date: Date) {
            var table = viggo.dom.tag('table', { dataset: { mode: this.mode, pickerFor: this.pickerFor }, onclick: (event: MouseEvent) => { event.stopPropagation(); } });
            var caption = viggo.dom.tag('caption', null,
                viggo.dom.tag('i', { className: 'o-flaticon-arrow-left-simple previous', onclick: () => { this.previous(date);} }),
                viggo.dom.tag('span', {
                    onclick: () => {
                        this.setMode(PickerMode.month, date);
                    }
                }, date.format('MMMM yyyy')),
                viggo.dom.tag('i', { className: 'o-flaticon-arrow-right-simple next', onclick: () => { this.next(date);} })
            );
            table.appendChild(caption);
            var colgroup = table.appendChild(viggo.dom.tag('colgroup'));
            for (var i = 0; i < 8; i++) {
                colgroup.appendChild(viggo.dom.tag('col', { className: i ? ('day' + i) : 'weeks' }));
            }
            var thead = viggo.dom.tag('thead');
            var tr = viggo.dom.tag('tr');
            var d = new Date(2012, 6, 1, 12, 0, 0, 0);
            for (i = 0; i < 8; i++) {
                tr.appendChild(viggo.dom.tag('th', null, i ? d.format('dddd').substring(0, 2) : __('Week')));
                d.setDate(d.getDate() + 1);
            }
            thead.appendChild(tr);
            table.appendChild(thead);

            this.selected = null;

            if (this.intervalStart || this.intervalEnd) {
                table.classList.add('interval-date');
            }

            table.appendChild(this.generateCells(date));
            table.appendChild(viggo.dom.tag('tfoot', null,
                viggo.dom.tag('tr', null,
                    viggo.dom.tag('td', {colSpan: 8},
                        viggo.dom.tag('button', {
                            type: 'button',
                            className: 'today',
                            onclick: () => {
                                let d = new Date();
                                d.setHours(0, 0, 0, 0);
                                this.setDate(d);
                            }
                        }, __('Today'))
                    )
                )
            ));

            return table;
        }
        protected generateCells(date: Date) {
            var calendar = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
            var day = (calendar.getDay() + 6) % 7;

            calendar.setDate(calendar.getDate() - day);

            let tbody = viggo.dom.tag('tbody');
            let me = this;
            let click = {
                onmousedown: function (this: HTMLTableCellElement, event: MouseEvent) {
                    var date = <Date>this.viggoDate;
                    if (me.multiselect) {
                        event.preventDefault();
                        event.stopPropagation();
                        me.toggleSelected(date);
                    } else {
                        me.selectDate(date, this);
                    }
                    me.setDate(date);
                }
            };
            do {
                let tr = viggo.dom.tag('tr');
                let td: HTMLTableDataCellElement;
                for (var i = 0; i < 8; i++) {
                    if (!i) {
                        let th = viggo.dom.tag('th', null, calendar.getWeekOfYear()[1]);
                        tr.appendChild(th);
                    } else {
                        td = viggo.dom.tag('td', click, calendar.getDate());
                        if (calendar.getMonth() != date.getMonth()) {
                            td.classList.add('out');
                        } else if (calendar.isToday()) {
                            this.setCellClassName(td, tr, 'today');
                        }
                        if (this.inSelected(calendar, PickerMode.day)) {
                            this.selected = td;
                            this.setCellClassName(td, tr, 'selected');
                        } else if (this.intervalStart && this.intervalStart <= calendar && this.date >= calendar) {
                            this.setCellClassName(td, tr, 'interval');
                            if (this.intervalStart.getTime() == calendar.getTime()) {
                                this.setCellClassName(td, tr, 'interval-start');
                            }
                        } else if (this.intervalEnd && this.intervalEnd >= calendar && this.date <= calendar) {
                            this.setCellClassName(td, tr, 'interval');
                            if (this.intervalEnd.getTime() == calendar.getTime()) {
                                this.setCellClassName(td, tr, 'interval-end');
                            }
                        }
                        td.viggoDate = new Date(calendar.getFullYear(), calendar.getMonth(), calendar.getDate(), 0, 0, 0, 0);
                        calendar.setDate(calendar.getDate() + 1);
                        tr.appendChild(td);
                    }
                }
                tbody.appendChild(tr);
            } while (calendar.getMonth() == date.getMonth());

            return tbody;
        }
        protected setCellClassName(td: HTMLTableDataCellElement, tr: HTMLTableRowElement, className: string) {
            td.classList.add(className);
        }
        private createMonthPicker(date: Date) {
            var table = viggo.dom.tag('table', { dataset: { mode: this.mode }, onclick: (event: MouseEvent) => { event.stopPropagation(); } });
            table.appendChild(viggo.dom.tag('caption', null,
                viggo.dom.tag('i', { className: 'o-flaticon-arrow-left-simple previous', onclick: () => { this.previous(date);} }),
                viggo.dom.tag('span', {
                    onclick: () => {
                        this.setMode(PickerMode.year, date);
                    }
                }, date.format('yyyy')),
                viggo.dom.tag('i', { className: 'o-flaticon-arrow-right-simple next', onclick: () => { this.next(date);} })
            ));
            var tr = null;
            var tbody = table.appendChild(viggo.dom.tag('tbody'));
            for (var i = 0; i < 12; i++) {
                var d = new Date(date);
                d.setMonth(i);
                if (i % 4 == 0) {
                    tr = tbody.appendChild(viggo.dom.tag('tr'));
                }
                if (d.getMonth() != i) {
                    d.setDate(0); // set to last day of month
                }
                if (tr) {
                    let td = tr.appendChild(viggo.dom.tag('td', {
                        onclick: ((clickDate) => {
                            return () => {
                                this.setMode(PickerMode.day, clickDate);
                            };
                        })(new Date(d))
                    }, d.format('MMM')));
                    if (this.inSelected(d, PickerMode.month)) {
                        td.classList.add('selected');
                    }
                }
            }
            return table;
        }
        private createYearPicker(date: Date) {
            var table = viggo.dom.tag('table', { dataset: { mode: this.mode },  onclick: (event: MouseEvent) => { event.stopPropagation(); }});
            var d1 = new Date(date);
            d1.setFullYear(Math.floor(d1.getFullYear() / 10) * 10);
            var d2 = new Date(d1);
            d2.setFullYear(d2.getFullYear() + 9);
            table.appendChild(viggo.dom.tag('caption', null,
                viggo.dom.tag('i', { className: 'o-flaticon-arrow-left-simple previous', onclick: () => { this.previous(date); } }),
                viggo.dom.tag('span', null, d1.getFullYear() + '-' + d2.getFullYear()),
                viggo.dom.tag('i', { className: 'o-flaticon-arrow-right-simple next', onclick: () => { this.next(date); } })
            ));

            d1.setFullYear(d1.getFullYear() - 1);
            var tbody = table.appendChild(viggo.dom.tag('tbody'));
            var tr = null;
            for (var i = 0; i < 12; i++) {
                if (i % 4 == 0) {
                    tr = tbody.appendChild(viggo.dom.tag('tr'));
                }
                if (tr) {
                    var td = tr.appendChild(viggo.dom.tag('td', {
                        onclick: ((clickDate) => {
                            return () => {
                                this.setMode(PickerMode.month, clickDate);
                            }
                        })(new Date(d1))
                    }, d1.getFullYear()));

                    if (this.inSelected(d1, PickerMode.year)) {
                        td.classList.add('selected');
                    }
                    if (i % 11 == 0) {
                        td.classList.add('out');
                    }
                    d1.setFullYear(d1.getFullYear() + 1);
                }
            }
            return table;
        }

        protected repaint(date: Date, sameMonth?: boolean) {
            let table: HTMLTableElement;
            switch (this.mode) {
                case PickerMode.day:
                    table = this.createDayPicker(date);
                    break;
                case PickerMode.month:
                    table = this.createMonthPicker(date);
                    break;
                case PickerMode.year:
                    table = this.createYearPicker(date);
                    break;
            }

            let finish = () => {
                if (this.table) {
                    this.table.remove();
                }
                this.domContainer.appendChild(table);
                this.table = table;
            };
            if (!sameMonth && this.table) {
                new viggo.effect({
                    element: this.table,
                    from: 1,
                    to: 0,
                    style: 'opacity',
                    duration: 100,
                    complete: () => {
                        finish();
                        new viggo.effect({
                            element: this.table,
                            from: 0,
                            to: 1,
                            style: 'opacity',
                            duration: 100
                        });
                    }
                });
            } else {
                finish();
            }
        }

        private next(date: Date) {
            this.scrollCalendar(date, 1);
        }

        private previous(date: Date) {
            this.scrollCalendar(date, -1);
        }

        private scrollCalendar(date: Date, multiplier: number) {
            date = new Date(date);
            switch (this.mode) {
                case 'day':
                    let day = date.getDate();
                    date.setMonth(date.getMonth() + 1 * multiplier);
                    if (day != date.getDate()) {
                        date.setDate(0);
                    }
                    break;
                case 'month':
                    date.setFullYear(date.getFullYear() + 1 * multiplier);
                    break;
                case 'year':
                    date.setFullYear(date.getFullYear() + 10 * multiplier);
                    break;
            }
            this.repaint(date);
        }

        public setDate(date: Date) {
            let sameMonth = this.date.getFullYear() == date.getFullYear() && this.date.getMonth() == date.getMonth();
            let sameDate = sameMonth && date.getDate() == this.date.getDate();
            this.date = new Date(date);
            this.date.setHours(0, 0, 0, 0);
            this.selectedDate = new Date(this.date);
            let detail: Date|Date[] = new Date(this.date);
            if (this.multiselect) {
                sameDate = false;
                detail = [];
                for (var i = 0; i < this.selectedList.length; i++) {
                    detail.push(new Date(this.selectedList[i]));
                }
            }
            if (!sameDate) {
                if (this.callback) {
                    this.callback(detail);
                }
                this.repaint(date, sameMonth);
            }
            return detail;
        }
    }

    export class WeekPicker extends DatePicker {
        private regex = /(\d+|__)\s*,\s*(\d{4}|____)/;
        constructor(options: WeekPickerOptions) {
            super(options);
            /*
            // if we need to implement this with an input
            var m = this.input.value.match(this.regex);
            if (!m) {
                textInput.value = __('Week {week}, {year}', {week: '__', year: '____'});
            }
            */
        }
        /*
        private match() {
            return this.input.value.match(this.regex) || ['__, ____', '__', '____'];
        }
        */
        private getMonday(date: Date) {
            let week = date.getWeekOfYear();
            return Date.getDateOfWeek(week[1], week[0]);
        }
        public setDate(date: Date) {
            date = this.getMonday(date);
            return super.setDate(date);
        }
        public toggleSelected(date: Date) {
            date = this.getMonday(date);
            return super.toggleSelected(date);
        }
        protected createDomContainer(className?: string) {
            let e = super.createDomContainer(className);
            e.classList.add('date-picker');
            e.classList.remove('pick-date');
            e.classList.add('pick-week');
            return e;
        }
    }

    class FocusedInputControl {
        private input: HTMLInputElement;
        private scrollElement: Element | Document | null = null;
        private scrollListener!: (event: Event) => void;
        private modalCloseListener!: () => void;
        private modalShowListener!: () => void;
        public scrollAdd = 0;
        constructor(input: HTMLInputElement, scrollCallback: (scroll: number) => void) {
            this.input = input;
            this.scrollListener = (event: Event) => {
                this.scrollAdd = (<HTMLElement>event.target).scrollTop;
                scrollCallback(this.scrollAdd);
            };
            if (this.scrollElement = viggo.dom.parentFilter(this.input, (e) => {
                    return e.nodeType == 1 && viggo.getStyle(e, 'overflow-y') in { scroll: 1, auto: 1 };
            })) {
                this.scrollElement.addEventListener('scroll', this.scrollListener, false);
                this.scrollListener(<any>{target: this.scrollElement});
            }
            this.modalShowListener = () => {
                viggo.modal.addEventListener('scroll', this.scrollListener);
                let elm = viggo.modal.getLatestModalElement();
                if (elm) {
                    this.scrollAdd = elm.scrollTop;
                }
            };
            this.modalCloseListener = () => {
                viggo.modal.removeEventListener('scroll', this.scrollListener);
                this.scrollAdd = 0;
            };
            viggo.modal.addEventListener('show', this.modalShowListener);
            viggo.modal.addEventListener('close', this.modalCloseListener);
        }
        public remove() {
            if (this.scrollElement && this.scrollListener) {
                this.scrollElement.removeEventListener('scroll', this.scrollListener, false);
                this.scrollElement = null;
            }
            viggo.modal.removeEventListener('show', this.modalShowListener);
            viggo.modal.removeEventListener('close', this.modalCloseListener);
        }
    }

    enum PickerSelectMode {
        remove = 'remove',
        keep = 'keep'
    }

    export class DatePickerInput extends DatePicker {
        private input!: HTMLInputElement;
        private datePos: number = -1;
        private monthPos: number = -1;
        private yearPos: number = -1;
        private cursorTimeout: number = 0;
        private intervalInput?: HTMLInputElement;
        private regex = /^(\d\d|__)-(\d\d|__)-(\d{4}|____)$/;
        private inputControl!: FocusedInputControl;
        private selectMode!: PickerSelectMode;
        constructor(options: DatePickerInputOptions) {
            super(options);
        }

        protected initialize(options: DatePickerInputOptions) {
            super.initialize(options);
            this.owner = document.body;
            this.input = options.input;
            this.selectMode = <PickerSelectMode>this.input.dataset.pickerSelectMode || 'remove';
            this.input.viggoPicker = this;
            this.inputControl = new FocusedInputControl(this.input, (scroll: number) => {
                if (this.domContainer) {
                    this.domContainer.style.marginTop = -scroll + 2 + 'px';
                }
            });

            let m = this.input.value.match(this.regex);
            if (!m) {
                this.input.value = '__-__-____';
            }

            if (!this.intervalStart && this.input.dataset.start) {
                let input = this.getRelatedElement(this.input.dataset.start);
                if (input) {
                    this.intervalInput = input;
                    let start = viggo.func.getDateFromInput(input);
                    if (start) {
                        this.intervalStart = start;
                    }
                }
            }
            if (!this.intervalEnd && this.input.dataset.end) {
                let input = this.getRelatedElement(this.input.dataset.end);
                if (input) {
                    this.intervalInput = input;
                    let end = viggo.func.getDateFromInput(<HTMLInputElement>input);
                    if (end) {
                        this.intervalEnd = end;
                    }
                }
            }

            this.initializeInput();
        }

        private getRelatedElement(name: string) {
            let form = this.input.closest('form');
            let input: HTMLInputElement | null;
            if (form) {
                input = form.querySelector(`input[name="${name}"]`);
            } else {
                input = <HTMLInputElement | null>document.getElementById(name) || document.querySelector(`input[name="${name}"]`);
            }
            return input;
        }

        public remove() {
            this.domContainer.remove();
            this.inputControl.remove();
        }

        protected selectDate(date: Date, td: HTMLTableDataCellElement) {
            super.selectDate(date, td);
            if (this.selectMode == 'remove') {
                this.remove();
            }
        }

        protected appendContainer() {
            super.appendContainer();
            let mousedown = (event: MouseEvent) => {
                if (event.target != this.input) {
                    document.removeEventListener('mousedown', mousedown, false);
                    this.remove();
                }
            };
            document.addEventListener('mousedown', mousedown, false);
        }

        public setDate(date: Date, dispatchEvent = true) {
            let oldDate = new Date(this.date);
            let detail = super.setDate(date);
            let eventDetail = {
                newDate: detail,
                oldDate: oldDate
            };
            this.input.value = date.format('dd-MM-yyyy');
            if (dispatchEvent) {
                if (this.intervalInput && !this.input.className.match(/syncWith_[^ ]/)) {
                    if (this.intervalStart && this.intervalStart > date) {
                        this.intervalInput.value = this.input.value;
                        this.intervalStart = date;
                    } else if (this.intervalEnd && this.intervalEnd < date) {
                        this.intervalInput.value = this.input.value;
                        this.intervalEnd = date;
                    }
                }
                let event = new CustomEvent("datechange", { bubbles: true, cancelable: false, detail: eventDetail });
                this.input.dispatchEvent(event);
            }
            return detail;
        }
        protected createDomContainer(className?: string) {
            let e = super.createDomContainer(className);
            e.classList.add('date-picker');
            className = this.input.dataset.pickerClass;
            if (className) {
                e.classList.add(...className.split(' '));
            }
            e.style.position = viggo.modal.getLatestModal() ? 'fixed' : 'absolute';
            let pos = viggo.dom.getPos(this.input);
            e.style.top = pos.top + this.input.offsetHeight + 'px';
            e.style.left = pos.left + 'px';
            e.style.marginTop = -this.inputControl.scrollAdd + 2 + 'px';
            return e;
        }
        private initializeInput() {
            let keydown = (event: KeyboardEvent) => {
                switch (event.keyCode) {
                    case 48: case 49: case 50: case 51: case 52: case 53: case 54: case 55: case 56: case 57: // NUMBERS
                        this.addChar(event.keyCode - 48);
                        break;
                    case 96: case 97: case 98: case 99: case 100: case 101: case 102: case 103: case 104: case 105: // NUMERIC KEYBOARD
                        this.addChar(event.keyCode - 96);
                        break;
                    case 38: // UP
                    case 40: // DOWN
                        var add = 39 - event.keyCode;
                        if (this.datePos > -1) {
                            this.addDate(add);
                        } else if (this.monthPos > -1) {
                            this.addMonth(add);
                        } else {
                            this.addYear(add);
                        }
                        this.reselect();
                        break;
                    case 39: // RIGHT
                        this.yearPos = this.monthPos;
                        this.monthPos = this.datePos;
                        this.datePos = -1;
                        if (this.monthPos == this.yearPos) {
                            this.yearPos = 0;
                        }
                        this.reselect();
                        break;
                    case 37: // LEFT
                        this.datePos = this.monthPos;
                        this.monthPos = this.yearPos;
                        this.yearPos = -1;
                        if (this.datePos == this.monthPos) {
                            this.datePos = 0;
                        }
                        this.reselect();
                        break;
                    case 9: // TAB
                        if ((this.datePos > -1 && !event.shiftKey) || (this.yearPos > -1 && event.shiftKey)) {
                            this.monthPos = 0;
                            this.datePos = this.yearPos = -1;
                            this.reselect();
                        } else if (this.monthPos > -1) {
                            this.datePos = this.monthPos = this.yearPos = -1;
                            if (event.shiftKey) {
                                this.datePos = 0;
                            } else {
                                this.yearPos = 0;
                            }
                            this.reselect();
                        } else {
                            this.remove();
                            return;
                        }
                        break;
                    default:
                        if (event.ctrlKey || event.altKey || event.metaKey || (event.keyCode >= 112 && event.keyCode <= 123)) {
                            return; // accept ctrl, alt, meta and F-keys
                        }
                        break;
                }
                event.preventDefault();
            };

            var mouseup = (event: MouseEvent) => {
                event.preventDefault();
                this.selectFromCursor(this.input.selectionStart||0);
            };

            var blur = () => {
                this.input.removeEventListener('keydown', keydown, false);
                this.input.removeEventListener('mouseup', mouseup, false);
                this.input.removeEventListener('blur', blur, false);
                this.setValue(this.input.value, this.input.value != this.date.format('dd-MM-yyyy'));
            };
            this.input.addEventListener('keydown', keydown, false);
            this.input.addEventListener('mouseup', mouseup, false);
            this.input.addEventListener('blur', blur, false);

            setTimeout(() => {
                this.selectFromCursor(this.input.selectionStart || 0);
            }, 10);
        }
        private match() {
            return this.input.value.match(this.regex) || ['__-__-____', '__', '__', '____'];
        }
        // only used for type=date
        private setValue(value: string, dispatchEvent = true) {
            var m = value.match(this.regex);
            if (m && m[0].indexOf('_') == -1 && m[1] != '00' && m[2] != '00' && m[3][0] != '0') {
                var max = new Date(parseInt(m[3]), parseInt(m[2]), 0).getDate();
                if (parseInt(m[1]) > max) {
                    m[1] = max.toString();
                }
                var d = new Date(parseInt(m[3]), parseInt(m[2]) - 1, parseInt(m[1]), 0, 0, 0, 0);
                this.setDate(d, dispatchEvent);
            } else {
                (<HTMLInputElement>this.input).value = value;
            }
        }
        // only used for type=date
        private addChar(chr: number) {
            this.delayReset();
            let match = this.match();
            let d: string|string[] = match[1];
            let m: string|string[] = match[2];
            let y: string|string[] = match[3];

            if (this.datePos > -1) {
                d = d.replace(/_/g, '0').split("");
                if (this.datePos == 0) {
                    d[0] = d[1] = '0';
                }
                d[0] = d[1];
                d[1] = chr.toString();

                if ((chr > 3 && this.datePos == 0) || this.datePos == 1) {
                    this.monthPos = 0;
                    this.datePos = -1;
                    if (d[0] == d[1] && d[1] == '0') {
                        d[1] = '1';
                    }
                } else {
                    this.datePos++;
                }
                d = d.join("");
                if (parseInt(d) > 31)
                    d = '31';
            } else if (this.monthPos > -1) {
                m = m.replace(/_/g, '0').split("");
                if (this.monthPos == 0) {
                    m[0] = m[1] = '0';
                }
                m[0] = m[1];
                m[1] = chr.toString();
                if ((this.monthPos == 0 && chr > 1) || this.monthPos == 1) {
                    this.monthPos = -1;
                    this.yearPos = 0;
                    if (m[0] == m[1] && m[1] == '0') {
                        m[1] = '1';
                    }
                } else {
                    this.monthPos++;
                }
                m = m.join("");
                if (parseInt(m) > 12)
                    m = '12';
            } else if (this.yearPos > -1 && this.yearPos < 4) {
                if (this.yearPos == 0) {
                    y = '0000';
                }
                y = y.replace(/_/g, '0').split("");
                for (var i = 0; i < 4; i++) {
                    y[i] = y[i + 1];
                }
                y[3] = chr.toString();
                this.yearPos++;
                y = y.join("");
            }

            this.setValue(d + '-' + m + '-' + y, false);
            this.reselect();
        }
        // only used for type=date
        private delayReset() {
            var me = this;
            clearTimeout(this.cursorTimeout);
            this.cursorTimeout = window.setTimeout(function () {
                me.resetCursor();
            }, 1000);
        }
        // only used for type=date
        private resetCursor() {
            if (this.datePos > -1) {
                this.datePos = 0;
                this.monthPos = this.yearPos = -1;
            } else if (this.monthPos > -1) {
                this.monthPos = 0;
                this.datePos = this.yearPos = -1;
            } else {
                this.yearPos = 0;
                this.datePos = this.monthPos = -1;
            }
        }
        // only used for type=date
        private selectFromCursor(cursor: number) {
            this.datePos = -1;
            this.monthPos = -1;
            this.yearPos = -1;
            if (cursor < 3) {
                this.datePos = 0;
            } else if (cursor < 6) {
                this.monthPos = 0;
            } else {
                this.yearPos = 0;
            }
            this.reselect();
        }
        // only used for type=date
        private reselect() {
            let input = <HTMLInputElement>this.input;
            if (this.datePos > -1) {
                input.selectionStart = 0;
                input.selectionEnd = input.selectionStart + 2;
            } else if (this.monthPos > -1) {
                input.selectionStart = 3;
                input.selectionEnd = input.selectionStart + 2;
            } else {
                input.selectionStart = 6;
                input.selectionEnd = input.selectionStart + 4;
            }
        }
        private zero(n: number|string) {
            return n < 10 ? '0' + n : n.toString();
        }
        // only used for type=date
        private addDate(step: number) {
            let m = this.match();
            let value = m[1];
            if (value == '__') {
                value = new Date().getDate().toString();
            } else {
                var max = 31;
                if (m[0].indexOf('_') == -1) {
                    max = new Date(parseInt(m[3]), parseInt(m[2]), 0).getDate();
                }
                value = ((parseInt(value, 10) + step - 1 + max) % max + 1).toString();
            }
            this.setValue(this.zero(value) + '-' + m[2] + '-' + m[3], false);
        }
        // only used for type=date
        private addMonth(step: number) {
            var m = this.match();
            var value = m[2];
            if (value == '__') {
                value = (new Date().getMonth() + 1).toString();
            } else {
                value = ((parseInt(value, 10) + step - 1 + 12) % 12 + 1).toString();
            }
            this.setValue(m[1] + '-' + this.zero(value) + '-' + m[3], false);
        }
        // only used for type=date
        private addYear(step: number) {
            var m = this.match();
            var value = m[3];
            if (value == '____') {
                value = new Date().getFullYear().toString();
            } else {
                value = (parseInt(value, 10) + step).toString();
            }
            this.setValue(m[1] + '-' + m[2] + '-' + this.zero(value), false);
        }
    }

    interface StaticCalendarOptions {
        date?: Date,
        id?: string,
        input?: HTMLElement,
        callback?: (date: Date|Date[]) => void,
        data?: any,
        dateField?: string|string[],
        method?: string,
        url?: string,
        replace?: string,
        multiselect?: boolean,
        type?: string
    }

    export function createCalendar(object: StaticCalendarOptions) {
        if (!object.callback) {
            object.callback = (date: Date|Date[]) => {
                let d: string|string[];
                if (date instanceof Array) {
                    d = [];
                    for (let i = 0; i < date.length; i++) {
                        d.push(date[i].format('dd-MM-yyyy'));
                    }
                } else {
                    d = date.format('dd-MM-yyyy');
                }
                let ajaxData = object.data;
                if (object.dateField instanceof Array) {
                    for (let i = 0; i < object.dateField.length; i++) {
                        ajaxData[object.dateField[i]] = d;
                    }
                } else if (typeof object.dateField == 'string') {
                    ajaxData[object.dateField] = d;
                }
                if (object.url) {
                    new viggo.ajax({
                        method: object.method || 'get',
                        url: object.url,
                        convert: 'html',
                        data: ajaxData,
                        complete: function (data: DocumentFragment) {
                            if (object.replace) {
                                let replace = document.getElementById(object.replace);
                                if (replace) {
                                    viggo.dom.empty(replace);
                                    replace.appendChild(data);
                                }
                            }
                        }
                    });
                }
            };
        }
        if (!object.input && object.id) {
            let input = document.getElementById(object.id);
            if (input) {
                object.input = input;
            }
        }
        let className: string[] = ['center', 'right'];
        let start: Date|null = null;
        let end: Date|null = null;
        if (object.input) {
            className = className.filter(c => object.input!.classList.contains(c));
            if (object.input.dataset.start) {
                let input = document.getElementById(object.input.dataset.start);
                if (input) {
                    start = viggo.func.getDateFromInput(<HTMLInputElement>input);
                }
            }
            if (object.input.dataset.end) {
                let input = document.getElementById(object.input.dataset.end);
                if (input) {
                    end = viggo.func.getDateFromInput(object.input.dataset.end);
                }
            }
        }
        let c = DatePicker;
        if (object.type == "week") {
            c = WeekPicker;
        }
        return new c({
            date: object.date || new Date(),
            className: className.join(' '),
            callback: object.callback,
            owner: object.input,
            multiselect: object.multiselect,
            intervalStart: <Date|undefined>start,
            intervalEnd: <Date|undefined>end
        });
    }

    document.addEventListener('focus', function(event){
        var target = <HTMLInputElement>event.target;
        if (target.tagName == 'INPUT' && target.type == 'text' && (target.classList.contains('date') || target.classList.contains('week'))) {
            let type = target.classList.contains('week') ? 'week' : 'date';
            switch (type) {
                case 'date':
                    let date = viggo.func.getDateTimeFromValue(target.value) || new Date();
                    let options: DatePickerInputOptions = {
                        date: date,
                        input: target,
                        callback: (date: Date|Date[]) => {
                            target.value = (<Date>date).format('dd-MM-yyyy');
                        }
                    };
                    new DatePickerInput(options);
                    break;
                /*
                // pretty sure this isn't used
                case 'week':
                    let m = target.value.match(/(\d+)\s*,\s*(\d{4})/);
                    if (m) {
                        value = Date.getDateOfWeek(m[1], m[2]);
                    }
                    break;
                */
            }
		}
	}, true);

    export class TimePicker extends AbstractPicker {
        protected static regex = /^([01]\d|2[0-3]|--):([0-5]\d|--)$/;
        protected domElement!: HTMLElement;
        protected value!: string;
        private spanHours!: Element | null;
        private spanMinutes!: Element | null;
        protected initialize(options: PickerOptions) {
            this.spanHours = null;
            this.spanMinutes = null;
            this.value = options.date.format('HH:mm');
            super.initialize(options);
        }
        protected repaint() {
            let g: SVGGElement, line: SVGLineElement;
            if (this.domElement) {
                this.domElement.remove();
            }
            this.domElement = viggo.dom.tag('div', {
                onclick: (event: MouseEvent) => {
                    event.stopPropagation();
                }
            },
                viggo.dom.tag('h5', null,
                    this.spanHours = viggo.dom.tag('span', {
                        onclick: () => {
                            this.hideClock(g, () => {
                                this.createHours(g, line);
                                this.showClock(g);
                            });
                        }
                    }, this.value.substring(0, 2)),
                    viggo.dom.text(':'),
                    this.spanMinutes = viggo.dom.tag('span', {
                        onclick: () => {
                            this.hideClock(g, () => {
                                this.createMinutes(g, line);
                                this.showClock(g);
                            });
                        }
                    }, this.value.substring(3, 5))
                ),
                viggo.dom.svg('svg', { viewBox: '0,0,400,400' },
                    g = viggo.dom.svg('g', { transform: 'translate(200,200)' },
                        viggo.dom.svg('circle', { 'class': 'back', r: '192' }),
                        line = viggo.dom.svg('line', { 'class': 'hour-hand', x1: 0, y1: 0, x2: 0, y2: 0 }),
                        viggo.dom.svg('circle', { 'class': 'center', r: '15' })
                    )
                )
            );

            this.createHours(g, line);
            this.domContainer.appendChild(this.domElement);
        }
        protected createHours(g: SVGGElement, line: SVGLineElement) {
            var me = this;
            var click = function (this: SVGCircleElement, event: MouseEvent) {
                event.stopPropagation();
                // dataset is supported on svg elements, but is currently only in candidate recomendation
                // https://developer.mozilla.org/en-US/docs/Web/API/SVGElement/dataset
                me.setValue(me.zero((<any>this).dataset.hour) + me.value.substring(2, 5));
                me.hideClock(g, function () {
                    me.createMinutes(g, line);
                    me.showClock(g);
                });
            };

            this.resetClock(g, line);

            var hour = parseInt(me.value.substring(0, 2));

            for (var j = 0; j < 2; j++) {
                for (var i = 0; i < 12; i++) {
                    var x = -(162 - j * 52) * Math.sin(-Math.PI * 2 * i / 12);
                    var y = -(162 - j * 52) * Math.cos(-Math.PI * 2 * i / 12);

                    var h = j * 12 + i;

                    var circle = viggo.dom.svg('circle', { 'class': 'hour', r: 30, cx: x, cy: y, 'data-hour': h });
                    if (h == hour) {
                        circle.classList.add('selected');
                        line.x2.baseVal.value = x;
                        line.y2.baseVal.value = y;
                    }
                    var text = viggo.dom.svg('text', { 'class': 'hour', x: x, y: y + 7 }, h);
                    g.appendChild(circle);
                    g.appendChild(text);
                    circle.addEventListener('click', click, false);
                }
            }
        }
        protected createMinutes(g: SVGGElement, line: SVGLineElement) {
            var me = this;
            var click = function (this: SVGCircleElement, event: MouseEvent) {
                event.stopPropagation();
                me.setValue(me.value.substring(0, 3) + me.zero((<any>this).dataset.minute));
                me.hideClock(g, () => {
                    me.remove();
                });
            };

            this.resetClock(g, line);

            var minute = parseInt(me.value.substring(3, 5));

            for (var i = 0; i < 60; i += 5) {
                var x = -162 * Math.sin(-Math.PI * 2 * i / 60);
                var y = -162 * Math.cos(-Math.PI * 2 * i / 60);

                var circle = viggo.dom.svg('circle', { 'class': 'minute', r: 30, cx: x, cy: y, 'data-minute': i });
                if (minute == i) {
                    circle.classList.add('selected');
                    line.x2.baseVal.value = x;
                    line.y2.baseVal.value = y;
                }
                var text = viggo.dom.svg('text', { 'class': 'minute', x: x, y: y + 7 }, i);
                g.appendChild(circle);
                g.appendChild(text);
                circle.addEventListener('click', click, false);
            }
        }
        protected resetClock(g: SVGGElement, line: SVGLineElement) {
            for (var i = g.childNodes.length - 1; i >= 0; i--) {
                var child = g.children[i]
                if (child.classList.contains('hour') || child.classList.contains('minute')) {
                    g.removeChild(child);
                }
            }
            line.x2.baseVal.value = 0;
            line.y2.baseVal.value = 0;
        }
        protected hideClock(g: SVGGElement, callback: () => void) {
            var status = 0;
            var complete = function () {
                status++;
                if (status == 2) {
                    callback();
                };
            };
            new viggo.effect({
                element: g,
                duration: 100,
                style: 'transform',
                from: 'scale(1) translate(200px,200px)',
                to: 'scale(1.05) translate(190px,190px)',
                removeStyle: true,
                complete: complete
            });
            new viggo.effect({
                element: g,
                duration: 100,
                style: 'opacity',
                from: 1,
                to: 0,
                removeStyle: true,
                complete: complete
            });
        }
        protected showClock(g: SVGGElement) {
            new viggo.effect({
                element: g,
                duration: 100,
                style: 'transform',
                from: 'scale(.95) translate(210px,210px)',
                to: 'scale(1) translate(200px,200px)',
                removeStyle: true
            });
            new viggo.effect({
                element: g,
                duration: 100,
                style: 'opacity',
                from: 0,
                to: 1,
                removeStyle: true
            });
        }
        public remove() {
            this.domContainer.remove();
        }
        public setValue(value: string) {
            var m = value.match(TimePicker.regex);
            if (!m) {
                value = '--:--';
            } else if (this.spanHours && this.spanMinutes) {
                this.spanHours.textContent = m[1];
                this.spanMinutes.textContent = m[2];
            }
            let oldValue = this.value || '';
            this.value = value;
            let changed = oldValue != value;
            let detail: PickerEventDetail | null = null;

            if (changed) {
                let newHour = parseInt(value);
                let newMinute = parseInt(value.substring(3, 5));
                detail = {
                    newDate: new Date(1970, 0, 1, newHour, newMinute, 0, 0),
                    oldDate: new Date(1970, 0, 1, parseInt(oldValue), parseInt(oldValue.substring(3, 5)), 0, 0)
                };
                let node = this.domElement.querySelector('svg .selected');
                if (node) {
                    node.classList.remove('selected');
                }
                node = this.domElement.querySelector('svg circle.hour[data-hour="' + newHour + '"]');
                if (node) {
                    node.classList.add('selected');
                }
                node = this.domElement.querySelector('svg circle.minute[data-minute="' + newMinute + '"]');
                if (node) {
                    node.classList.add('selected');
                }
                if (this.callback) {
                    this.callback(detail.newDate);
                }
            }
            return detail;
        }
        protected zero(n: string | number) {
            return n < 10 ? '0' + n : n.toString();
        }
        protected createDomContainer(className?: string) {
            let e = super.createDomContainer(className);
            e.classList.add('time-picker');
            e.style.position = viggo.modal.getLatestModal() ? 'fixed' : 'absolute';
            return e;
        }
    }

    export class TimePickerInput extends TimePicker {
        private cursorTimeout: number = 0;
        // A note on the hour/minute positions: -1 indicates not in selection. 0 is first char being edited, 1 is the second char.
        private hourPos: number = -1;
        private minutePos: number = -1;
        private selectedHour: Element|null = null;
        private selectedMinute: Element|null = null;
        private input!: HTMLInputElement;
        private inputControl!: FocusedInputControl;
        private minuteinterval: number = 5;
        private eventDetail: PickerEventDetail | null = null;
        constructor(options: TimePickerInputOptions) {
            super(options);

            setTimeout(() => {
                this.selectFromCursor(this.input.selectionStart || 0);
            }, 10);
        }

        protected initialize(options: TimePickerInputOptions) {
            this.minuteinterval = options.minuteinterval || 5;
            this.input = options.input;
            this.owner = options.owner || document.body;
            this.value = options.input.value;
            this.inputControl = new FocusedInputControl(this.input, (scroll: number) => {
                if (this.domContainer) {
                    this.domContainer.style.marginTop = -scroll + 2 + 'px';
                }
            });

            let keydown = (event: KeyboardEvent) => {
                switch (event.keyCode) {
                    case 48: case 49: case 50: case 51: case 52: case 53: case 54: case 55: case 56: case 57: // NUMBERS
                        this.addChar(event.keyCode - 48);
                        break;
                    case 96: case 97: case 98: case 99: case 100: case 101: case 102: case 103: case 104: case 105: // NUMERIC KEYBOARD
                        this.addChar(event.keyCode - 96);
                        break;
                    case 38: // UP
                    case 40: // DOWN
                        var add = 39 - event.keyCode;
                        if (this.hourPos > -1) {
                            this.addHour(add);
                        } else {
                            this.addMinute(add * this.minuteinterval);
                        }
                        this.reselect();
                        break;
                    case 39: // RIGHT
                        if (this.minutePos !== -1) {
                            this.minutePos = -1;
                        } else {
                            this.minutePos = 0;
                        }
                        this.hourPos = -1;
                        this.reselect();
                        break;
                    case 37: // LEFT
                        if (this.minutePos !== -1) {
                            this.hourPos = 0;
                            this.minutePos = -1;
                        } else {
                            this.minutePos = 0;
                            this.hourPos = -1;
                        }
                        this.reselect();
                        break;
                    case 9: // TAB
                        if (this.hourPos > -1 && !event.shiftKey) {
                            this.hourPos = -1;
                            this.minutePos = 0;
                        } else if (this.minutePos > -1 && event.shiftKey) {
                            this.hourPos = 0;
                            this.minutePos = -1;
                        } else {
                            this.remove();
                            return; // tab out
                        }
                        this.reselect();
                        break;
                    default:
                        if (event.ctrlKey || event.altKey || event.metaKey || (event.keyCode >= 112 && event.keyCode <= 123)) {
                            return; // accept ctrl, alt, meta and F-keys
                        }
                        break;
                }
                event.preventDefault();
            };

            var mouseup = (event: MouseEvent) => {
                event.preventDefault();
                this.selectFromCursor(this.input.selectionStart || 0);
            };

            var blur = () => {
                this.input.removeEventListener('keydown', keydown, false);
                this.input.removeEventListener('mouseup', mouseup, false);
                this.input.removeEventListener('blur', blur, false);
                let m = this.match();
                let t: string = m[2];
                if (t != '--') {
                    t = (Math.floor(parseInt(t, 10) / this.minuteinterval) * this.minuteinterval).toString();
                    m[2] = this.zero(t);
                    this.input.value = m[1] + ':' + m[2];
                }
            };
            this.input.addEventListener('keydown', keydown, false);
            this.input.addEventListener('mouseup', mouseup, false);
            this.input.addEventListener('blur', blur, false);

            this.setValue(this.input.value);
        }

        private match() {
            return this.value.match(TimePickerInput.regex) || ['--:--', '--', '--'];
        }
        protected createDomContainer(className?: string) {
            let e = super.createDomContainer(className);
            className = this.input.dataset.pickerClass;
            if (className) {
                e.classList.add(...className.split(' '));
            }
            let pos = viggo.dom.getPos(this.input);
            e.style.top = pos.top + this.input.offsetHeight + 'px';
            e.style.left = pos.left + 'px';
            e.style.marginTop = -this.inputControl.scrollAdd + 2 + 'px';
            return e;
        }
        protected appendContainer() {
            super.appendContainer();
            let mousedown = (event: MouseEvent) => {
                if (event.target != this.input) {
                    document.removeEventListener('mousedown', mousedown, false);
                    this.remove();
                }
            };
            document.addEventListener('mousedown', mousedown, false);
        }
        public setValue(value: string, delayEvent: boolean = false) {
            let result = super.setValue(value);
            if (result) {
                this.input.value = value;
                if (delayEvent) {
                    this.eventDetail = result;
                } else {
                    this.eventDetail = null;
                    this.dispatchEvent(result);
                }
            }
            return result;
        }
        private dispatchEvent(detail: PickerEventDetail) {
            let event = new CustomEvent('datechange', { bubbles: true, cancelable: false, detail: detail });
            this.input.dispatchEvent(event);
        }
        private selectFromCursor(cursor: number) {
            if (cursor < 3) {
                this.hourPos = 0;
                this.minutePos = -1;
            } else {
                this.hourPos = -1;
                this.minutePos = 0;
            }
            this.reselect();
        }
        private reselect() {
            var g = <SVGGElement>this.domElement.querySelector('svg g'),
                line = <SVGLineElement>this.domElement.querySelector('svg line');
            let input = <HTMLInputElement>this.input;
            if (this.hourPos > -1) {
                input.selectionStart = 0;
                this.createHours(g, line);
            } else if (this.minutePos > -1) {
                input.selectionStart = 3;
                this.createMinutes(g, line);
            } else {
                input.selectionStart = 6;
            }
            input.selectionEnd = input.selectionStart + 2;
        }
        private delayReset() {
            clearTimeout(this.cursorTimeout);
            this.cursorTimeout = window.setTimeout(() => {
                this.resetCursor();
                if (this.eventDetail != null) {
                    this.dispatchEvent(this.eventDetail);
                    this.eventDetail = null;
                }
            }, 1000);
        }
        private resetCursor() {
            if (this.hourPos > -1) {
                this.hourPos = 0;
                this.minutePos = -1;
            } else {
                this.minutePos = 0;
                this.hourPos = -1;
            }
        }
        private addChar(chr: number) {
            this.delayReset();
            let match = this.match();
            let hourArray: string[] = match[1].replace(/-/g, '0').split("");
            let minuteArray: string[] = match[2].replace(/-/g, '0').split("");

            if (this.hourPos > -1) {
                if (this.hourPos == 0) {
                    hourArray[0] = '0';
                    hourArray[1] = chr.toString();
                    if (chr > 2) {
                        this.minutePos = 0;
                        this.hourPos = -1;
                    } else {
                        this.hourPos++;
                    }
                } else {
                    hourArray[0] = chr.toString();
                    hourArray = hourArray.reverse();
                    this.minutePos = 0;
                    this.hourPos = -1;
                }
            } else if (this.minutePos > -1 && this.minutePos < 2) {
                if (this.minutePos == 0) {
                    minuteArray[0] = '0';
                    minuteArray[1] = chr.toString();
                } else {
                    minuteArray[0] = chr.toString();
                    minuteArray = minuteArray.reverse();
                }
                this.minutePos++;
            }

            let hour: number|string = parseInt(hourArray.join(""));
            let minute: number | string = parseInt(minuteArray.join(""));
            hour = Math.min(hour, 23);
            if (hour < 10) {
                hour = '0' + hour;
            }
            minute = Math.min(minute, 59);
            if (minute < 10) {
                minute = '0' + minute;
            }

            this.setValue(hour + ':' + minute, this.hourPos == 1 || this.minutePos == 1);
            this.reselect();
        }
        private addHour(step: number) {
            var m = this.match();
            var value = m[1];
            if (value == '--') {
                value = step > 0 ? '0' : '23';
            } else {
                value = ((parseInt(value, 10) + step + 24) % 24).toString();
            }
            this.setValue(this.zero(value)+':'+m[2]);
        }
        private addMinute(step: number) {
            let m = this.match();
            let value = m[2];
            if (value == '--') {
                value = (step > 0 ? 0 : 60 - this.minuteinterval).toString();
            } else {
                value = ((parseInt(value, 10) + step + 60) % 60).toString();
            }
            this.setValue(m[1] + ':' + this.zero(value));
        }
        public remove() {
            super.remove();
            this.inputControl.remove();
        }
    }


    document.addEventListener('focus', function (event) {
        var target = <HTMLInputElement>event.target;
        if (target.tagName == 'INPUT' && target.type == 'text' && (target.classList.contains('time') || target.classList.contains('timeseconds'))) {
            let d = target.value.match(/^(\d+):(\d+)(?::(\d+))?$/);
            let options: TimePickerInputOptions = {
                input: target,
                className: ['right', 'center'].filter(x => target.classList.contains(x)).join(' '),
                date: d ? new Date(1970, 0, 1, parseInt(d[1]), parseInt(d[2]), d[3]?parseInt(d[3]):0) : new Date(),
                minuteinterval: target.dataset.minuteinterval ? parseInt(target.dataset.minuteinterval) : 5
            };
            new TimePickerInput(options);
        }
    }, true);

    document.addEventListener('datechange', (ev: Event) => {
        let event = <CustomEvent<PickerEventDetail>>ev;
        let target = <HTMLInputElement>event.target;
        let m = target.className.match(/syncWith_([^ ]+)/);
        if (m) {
            if (target.form) {
                let sync = <HTMLInputElement>target.form.elements.namedItem(m[1]);
                if (sync) {
                    let dif = (<Date>event.detail.newDate).getTime() - event.detail.oldDate.getTime(), d;
                    switch (true) {
                        case target.classList.contains('date') && sync.classList.contains('date'):
                            m = sync.value.match(/^(\d\d)-(\d\d)-(\d{4})$/);
                            if (m) {
                                var day = 1000 * 60 * 60 * 24;
                                dif = Math.round(dif / day);
                                d = new Date(parseInt(m[3]), parseInt(m[2], 10) - 1, parseInt(m[1]), 0, 0, 0, 0);
                                d.setDate(d.getDate() + dif);
                                sync.value = d.format('dd-MM-yyyy');
                            }
                            break;
                        case target.classList.contains('time') && sync.classList.contains('time'):
                            m = sync.value.match(/^(\d\d):(\d\d)$/);
                            if (m) {
                                var minute = 1000 * 60;
                                dif = Math.round(dif / minute);
                                d = new Date(1970, 0, 1, parseInt(m[1]), parseInt(m[2]), 0, 0);
                                d.setMinutes(d.getMinutes() + dif);
                                sync.value = d.format('HH:mm');
                            }
                            break;
                    }
                }
            }
        }
    }, true);
}